<div class="alib-content-wrapper">
  <div id="alib-raids-content">
    <div class="alib-left-sidebar">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div class="alib-acc-header alib-raids-acc-text">ALPHASCAPE V4.0 (O12S)</div>
          </mat-expansion-panel-header>
          <a routerLink="o12s">
            <div class="acc-button">
              PHASE 1
            </div>
          </a>
          <a routerLink="o12s2">
            <div class="acc-button">
              PHASE 2
            </div>
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div id="alib-raid-viewer">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
