import { Component, OnInit } from '@angular/core';
import BadgerAccordion from 'badger-accordion';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-gliderskin',
  templateUrl: './gliderskin.component.html',
  styleUrls: ['./gliderskin.component.less']
})

export class GliderskinComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Final Fantasy XIV Knowledgebase - Gliderskin Treasure Map Locations'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Treasure Maps, Gliderskin Maps, ffxiv gliderskin treasure maps, shadowbringers treasure maps'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Gliderskin Treasure Maps - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit() {
    const accordionDomNode = document.querySelector('.js-badger-accordion-gliderskin');
    const accordion = new BadgerAccordion(accordionDomNode);
  }

  ngAfterViewInit() {
    var accordionPanels = document.getElementsByClassName("badger-accordion__panel") as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < accordionPanels.length; i++) {
      accordionPanels[i].classList.add("badger-accordion-4row");
    }
  }

  funcClearMap(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.style.backgroundImage = "none";
  }

  funcClearMarker(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.innerHTML = "";
  }

  funcClearIcons(): void {
    var smallMapIcon = document.getElementsByClassName("smallmapimg") as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < smallMapIcon.length; i++) {
      smallMapIcon[i].classList.remove("activeimg");
    }
  }

  funcMapFadeIn(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.classList.add("fade");
  }

  funcMapFadeOut(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.classList.remove("fade");
  }

  treasuremapChangeMap(event) {
    this.funcClearIcons();
    var selectedMap = event.currentTarget.attributes.data.nodeValue;
    var mapViewer = document.getElementById("map-viewer");
    function funcChangeMap() {
      mapViewer.style.backgroundImage = 'url(assets/img/maps/' + selectedMap + '.webp';
    }
    if (mapViewer.style.backgroundImage.includes(selectedMap)) {
      this.funcMapFadeOut();
      setTimeout(this.funcClearMap, 150);
      setTimeout(this.funcClearMarker, 150);
    } else {
      this.funcMapFadeOut();
      setTimeout(funcChangeMap, 150);
      setTimeout(this.funcClearMarker, 150);
      setTimeout(this.funcMapFadeIn, 200);
    }
  }

  treasuremapMoveFlag(event) {
    this.funcClearIcons();
    event.currentTarget.classList.add("activeimg");
    var mapViewer = document.getElementById("map-viewer");
    var coords = event.currentTarget.attributes.data.nodeValue
    var coordsX = (((parseFloat(coords.split(",")[0]) / (parseFloat(coords.split(",")[2]) - 1)) * 100) - ((1 / parseFloat(coords.split(",")[2])) * 100)).toFixed(3);
    var coordsY = (((parseFloat(coords.split(",")[1]) / (parseFloat(coords.split(",")[2]) - 1)) * 100) - ((1 / parseFloat(coords.split(",")[2])) * 100)).toFixed(3);
    function funcAddMarker() {
      if (document.getElementsByClassName("g-marker")[0]) {
        var marker = document.getElementsByClassName("g-marker") as HTMLCollectionOf<HTMLElement>;
        marker[0].style.margin = coordsY + '% 0% 0%' + coordsX + '%';
      } else {
        mapViewer.innerHTML = '<div class="g-marker" style="margin:' + coordsY + '% 0% 0% ' + coordsX + '%"></div>';
      }
    }
    funcAddMarker();
  }
}
