<h1 class="seoh1">Final Fantasy XIV - Peisteskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-boarskin">
    <dt>
      <button class="js-badger-accordion-header">
        THANALAN
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="17.2,12.5,42" src="assets/img/treasuremap-icons/peisteskin/centralthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.8,19.3,42" src="assets/img/treasuremap-icons/peisteskin/centralthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.3,23.1,42" src="assets/img/treasuremap-icons/peisteskin/centralthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="19.1,20.7,42" src="assets/img/treasuremap-icons/peisteskin/easternthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="25.2,25.5,42" src="assets/img/treasuremap-icons/peisteskin/easternthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="30.4,26.4,42" src="assets/img/treasuremap-icons/peisteskin/easternthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="NorthernThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">NORTHERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="20.1,16.5,42" src="assets/img/treasuremap-icons/peisteskin/northernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.7,18.6,42" src="assets/img/treasuremap-icons/peisteskin/northernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="23.4,25.8,42" src="assets/img/treasuremap-icons/peisteskin/northernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthernThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTHERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="24.8,20.9,42" src="assets/img/treasuremap-icons/peisteskin/southernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.9,24.2,42" src="assets/img/treasuremap-icons/peisteskin/southernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="26.1,37.8,42" src="assets/img/treasuremap-icons/peisteskin/southernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="10.8,5.8,42" src="assets/img/treasuremap-icons/peisteskin/westernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.9,6.6,42" src="assets/img/treasuremap-icons/peisteskin/westernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="19.8,27.9,42" src="assets/img/treasuremap-icons/peisteskin/westernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        LA NOSCEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="30.0,20.5,42" src="assets/img/treasuremap-icons/peisteskin/easternlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="23.8,21.7,42" src="assets/img/treasuremap-icons/peisteskin/easternlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.4,35.2,42" src="assets/img/treasuremap-icons/peisteskin/easternlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="LowerLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">LOWER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="33.9,16.0,42" src="assets/img/treasuremap-icons/peisteskin/lowerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.5,24.6,42" src="assets/img/treasuremap-icons/peisteskin/lowerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.3,30.7,42" src="assets/img/treasuremap-icons/peisteskin/lowerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="MiddleLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="13.9,11.6,42" src="assets/img/treasuremap-icons/peisteskin/middlelanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="14.3,14.0,42" src="assets/img/treasuremap-icons/peisteskin/middlelanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.5,14.8,42" src="assets/img/treasuremap-icons/peisteskin/middlelanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="OuterLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">OUTER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="22.7,12.1,42" src="assets/img/treasuremap-icons/peisteskin/outerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.6,14.3,42" src="assets/img/treasuremap-icons/peisteskin/outerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.7,15.3,42" src="assets/img/treasuremap-icons/peisteskin/outerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="UpperLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">UPPER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="27.3,17.6,42" src="assets/img/treasuremap-icons/peisteskin/upperlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="9.4,21.7,42" src="assets/img/treasuremap-icons/peisteskin/upperlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="13.6,21.9,42" src="assets/img/treasuremap-icons/peisteskin/upperlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="17.6,15.5,42" src="assets/img/treasuremap-icons/peisteskin/westernlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="14.6,17.0,42" src="assets/img/treasuremap-icons/peisteskin/westernlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.6,19.1,42" src="assets/img/treasuremap-icons/peisteskin/westernlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        THE BLACK SHROUD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="12.3,17.3,42" src="assets/img/treasuremap-icons/peisteskin/centralshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.6,19.4,42" src="assets/img/treasuremap-icons/peisteskin/centralshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.2,28.9,42" src="assets/img/treasuremap-icons/peisteskin/centralshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EastShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EAST SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="28.6,18.3,42" src="assets/img/treasuremap-icons/peisteskin/eastshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="28.5,20.8,42" src="assets/img/treasuremap-icons/peisteskin/eastshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="17.8,24.6,42" src="assets/img/treasuremap-icons/peisteskin/eastshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="NorthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">NORTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="21.9,20.8,42" src="assets/img/treasuremap-icons/peisteskin/northshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.4,23.8,42" src="assets/img/treasuremap-icons/peisteskin/northshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.1,31.8,42" src="assets/img/treasuremap-icons/peisteskin/northshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="22.8,24.4,42" src="assets/img/treasuremap-icons/peisteskin/southshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="29.0,24.3,42" src="assets/img/treasuremap-icons/peisteskin/southshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="30.5,24.8,42" src="assets/img/treasuremap-icons/peisteskin/southshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasCentralHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS CENTRAL HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="31.0,16.3,42" src="assets/img/treasuremap-icons/peisteskin/coerthascentral01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="9.0,20.3,42" src="assets/img/treasuremap-icons/peisteskin/coerthascentral02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="6.6,30.1,42" src="assets/img/treasuremap-icons/peisteskin/coerthascentral03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="MorDhona" (click)="treasuremapChangeMap($event)">
        MOR DHONA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="10.7,14.3,42" src="assets/img/treasuremap-icons/peisteskin/mordhona01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.4,16.2,42" src="assets/img/treasuremap-icons/peisteskin/mordhona02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.3,17.6,42" src="assets/img/treasuremap-icons/peisteskin/mordhona03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer" style="position:relative">
</div>
