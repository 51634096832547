<h1 class="seoh1">Final Fantasy XIV - Maelstrom Hunting Log Locations</h1>
<div id="maelstrom-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-maelstrom">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">UPPER LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="13.4,21.9,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kobold.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Kobold Pickman</p></div>
                                <div class="level">Lv 24</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="19.6,27.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/AmaljaaHunter.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Amalj'aa Hunter</p></div>
                                <div class="level">Lv 18</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="24.9,20.7,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/AmaljaaHunter.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Amalj'aa Bruiser</p></div>
                                <div class="level">Lv 27</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="19,21.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sylph.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sylvan Groan</p></div>
                                <div class="level">Lv 23</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="19,21.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sylph.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sylvan Sough</p></div>
                                <div class="level">Lv 23</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="22.7,28.2,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ixal.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ixali Straightbeak</p></div>
                                <div class="level">Lv 27</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="32.5,15.5,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ixal.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ixali Wildtalon</p></div>
                                <div class="level">Lv 37</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="Halatali" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">HALATALI</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Imp.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Heckler Imp</p></div>
                                <div class="level">Lv 20</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                        <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dullahan.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Doctore</p></div>
                                <div class="level">Lv 20</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/FireSprite.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Firemane</p></div>
                                <div class="level">Lv 20</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="28,25.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kobold.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Kobold Pitman</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="25.4,16.3,42,6;22.6,14.9,42,8;22.6,12.8,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kobold.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Kobold Bedesman</p></div>
                            <div class="level">Lv 41-44</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="22.6,12.8,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kobold.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Kobold Priest</p></div>
                            <div class="level">Lv 41-44</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19,20.1,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sahagin.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Shelfscale Sahagin</p></div>
                            <div class="level">Lv 44</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.9,34.9,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/AmaljaaHunter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Amalj'aa Divinator</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="22.3,15.9,42,5;19.9,20.8,42,12;19.1,26,42,2;20.9,23.9,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/AmaljaaHunter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Amalj'aa Pugilist</p></div>
                            <div class="level">Lv 45-47</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="24.8,21.5,42,3;22.4,21.1,42,8;28.5,20.8,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sylph.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sylvan Sigh</p></div>
                            <div class="level">Lv 42-43</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SunkenTempleofQarn-InnerSanctum" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SUNKEN TEMPLE OF QARN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Temple Bat</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bogy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>The Condemned</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">6</div>
                    </div>
                    <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Taurus.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Teratotaur</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="22.4,8.2,42,12" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kobold.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>U'Ghamaro Bedesman</p></div>
                            <div class="level">Lv 48</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.9,19.9,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sahagin.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Trenchtooth Sahagin</p></div>
                            <div class="level">Lv 48</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="14.4,14.7,42,12" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sahagin.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sapsa Shelfclaw</p></div>
                            <div class="level">Lv 47-49</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="29,20.3,42,32" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/AmaljaaHunter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Zahar'ak Archer</p></div>
                            <div class="level">Lv 48-49</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="20,19.5,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ixal.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ixali Boldwing</p></div>
                            <div class="level">Lv 47</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="28.8,17.7,42,2;30,13.5,42,16" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sylph.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sylpheed Screech</p></div>
                            <div class="level">Lv 45-47</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="34.1,22.1,42,18" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ixal.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Natalan Fogcaller</p></div>
                            <div class="level">Lv 49</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="34.1,22.1,42,18" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ixal.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Natalan Boldwing</p></div>
                            <div class="level">Lv 49</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="TheWanderersPalace" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">THE WANDERER'S PALACE</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Tonberry.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Tonberry</p></div>
                            <div class="level">Lv 50</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="20.9,20.9,42,0" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/GiantBavarois.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Giant Bavarois</p></div>
                            <div class="level">Lv 50</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
    </dl>
</div>