<h1 class="seoh1">Final Fantasy XIV - Female Hyur Midlander Voice Browser</h1>
<div class="alib-pagetitle">HYUR MIDLANDER</div>
<div class="alib-charactervoices-content">
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 1</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm01combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm01combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm01combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm01combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm01combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm01combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm01yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm01no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm01chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm01laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm01happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm01cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm01huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm01surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm01upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm01fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm01angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm01furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm01stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm01doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 2</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm02combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm02combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm02combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm02combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm02combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm02combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm02yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm02no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm02chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm02laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm02happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm02cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm02huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm02surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm02upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm02fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm02angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm02furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm02stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm02doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 3</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm03combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm03combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm03combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm03combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm03combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm03combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm03yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm03no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm03chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm03laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm03happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm03cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm03huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm03surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm03upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm03fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm03angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm03furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm03stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm03doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 4</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm04combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm04combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm04combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm04combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm04combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm04combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm04yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm04no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm04chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm04laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm04happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm04cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm04huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm04surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm04upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm04fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm04angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm04furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm04stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm04doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 5</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm05combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm05combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm05combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm05combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm05combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm05combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm05yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm05no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm05chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm05laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm05happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm05cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm05huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm05surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm05upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm05fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm05angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm05furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm05stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm05doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 6</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm06combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm06combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm06combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm06combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm06combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm06combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm06yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm06no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm06chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm06laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm06happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm06cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm06huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm06surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm06upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm06fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm06angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm06furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm06stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm06doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 7</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm07combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm07combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm07combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm07combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm07combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm07combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm07yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm07no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm07chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm07laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm07happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm07cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm07huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm07surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm07upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm07fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm07angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm07furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm07stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm07doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 8</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm08combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm08combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm08combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm08combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm08combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm08combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm08yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm08no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm08chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm08laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm08happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm08cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm08huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm08surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm08upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm08fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm08angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm08furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm08stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm08doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 9</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm09combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm09combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm09combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm09combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm09combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm09combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm09yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm09no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm09chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm09laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm09happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm09cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm09huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm09surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm09upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm09fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm09angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm09furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm09stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm09doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 10</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm10combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm10combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm10combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm10combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm10combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm10combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm10yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm10no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm10chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm10laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm10happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm10cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm10huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm10surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm10upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm10fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm10angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm10furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm10stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm10doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 11</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm11combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm11combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm11combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm11combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm11combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm11combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm11yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm11no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm11chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm11laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm11happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm11cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm11huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm11surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm11upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm11fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm11angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm11furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm11stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm11doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 12</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm12combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm12combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm12combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm12combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm12combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhm12combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhm12yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhm12no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhm12chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhm12laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhm12happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhm12cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhm12huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhm12surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhm12upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhm12fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhm12angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhm12furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhm12stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhm12doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
