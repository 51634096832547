import { Component, OnInit } from '@angular/core';
import BadgerAccordion from 'badger-accordion';
import { Meta,Title } from '@angular/platform-browser';

declare var storeNormalMarkers: any;

@Component({
  selector: 'app-acstormblood',
  templateUrl: './acstormblood.component.html',
  styleUrls: ['./acstormblood.component.less']
})
export class AcstormbloodComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Stormblood Aether Current Locations'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, Aether Currents, sharlayan.org, stormblood aether currents'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Stormblood Aether Currents - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit() {
    const accordionDomNode = document.querySelectorAll('.js-badger-accordion-acshb');
    Array.from(accordionDomNode).forEach((accordion) => {
      const ba = new BadgerAccordion(accordion);
    })
  }

  ngAfterViewInit() {
    var accordionPanels = document.getElementsByClassName("badger-accordion__panel") as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < accordionPanels.length; i++) {
      accordionPanels[i].classList.add("badger-accordion-1sline");
    }
  }

  funcClearMap(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.style.backgroundImage = "none";
  }

  funcClearMarkers(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.innerHTML = "";
  }

  funcClearButtons(): void {
    var toggleQuestButtons = document.getElementsByClassName("js-badger-accordion-header-inner") as HTMLCollectionOf<HTMLElement>;
    var mainButtons = document.getElementsByClassName("js-badger-accordion-header") as HTMLCollectionOf<HTMLElement>;
    var extraButtons = document.getElementsByClassName("button") as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < toggleQuestButtons.length; i++) {
      toggleQuestButtons[i].classList.remove("active");
    }
    for (var j = 0; j < mainButtons.length; j++) {
      mainButtons[j].classList.remove("active");
    }
    for (var k = 0; k < extraButtons.length; k++) {
      extraButtons[k].classList.remove("active");
    }
  }

  funcMapFadeIn(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.classList.add("fade");
  }

  funcMapFadeOut(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.classList.remove("fade");
  }

  acChangeMap(event) {
    if (event.currentTarget.classList.contains("button")) {
      const accordionDomNode = document.querySelectorAll('.js-badger-accordion-acshb');
      Array.from(accordionDomNode).forEach((accordion) => {
        const ba = new BadgerAccordion(accordion);
        ba.closeAll();
      })
    }
    this.funcClearButtons();
    var selectedMapData = event.currentTarget.attributes.data.nodeValue.split(";");
    var mapViewer = document.getElementById("map-viewer");
    function funcChangeMap() {
      mapViewer.style.backgroundImage = 'url(assets/img/maps/' + selectedMapData[0] + '.webp';
    }
    function funcAddNormalMarkers() {
      for (var i = 2; i < selectedMapData.length; i++) {
        var coordsX = (((parseFloat(selectedMapData[i].split(",")[0]) / (selectedMapData[1] - 1)) * 100) - ((1 / selectedMapData[1]) * 100)).toFixed(3);
        var coordsY = (((parseFloat(selectedMapData[i].split(",")[1]) / (selectedMapData[1] - 1)) * 100) - ((1 / selectedMapData[1]) * 100)).toFixed(3);
        mapViewer.innerHTML += '<div class="g-marker-ac-normal" style="margin:' + coordsY + '% 0% 0% ' + coordsX + '%"></div>';
        this.storeNormalMarkers = mapViewer.innerHTML;
      }
    }
    if (mapViewer.style.backgroundImage.includes(selectedMapData[0])) {
      this.funcMapFadeOut();
      setTimeout(this.funcClearMap, 150);
      setTimeout(this.funcClearMarkers, 150);
      setTimeout(this.funcClearButtons, 150);
      storeNormalMarkers = "";
    } else {
      this.funcMapFadeOut();
      this.funcClearButtons();
      setTimeout(funcChangeMap, 150);
      setTimeout(this.funcClearMarkers, 150);
      setTimeout(funcAddNormalMarkers, 150);
      setTimeout(this.funcMapFadeIn, 200);
      event.currentTarget.classList.add("active");
    }
  }

  acToggleQuestMarkers(event) {
    var mapViewer = document.getElementById("map-viewer");
    if (event.currentTarget.classList.contains("active")) {
      mapViewer.innerHTML = storeNormalMarkers;
      event.currentTarget.classList.remove("active");
    } else {
      event.currentTarget.classList.add("active");
      var coordsArray = event.currentTarget.attributes.data.nodeValue.split(";");
      for (var i = 1; i < coordsArray.length; i++) {
        var mapSizeModifier = 42;
        var finalNum = (1 / mapSizeModifier) * 100;
        var coordsX = (((parseFloat(coordsArray[i].split(",")[0]) / (coordsArray[0] - 1)) * 100) - ((1 / coordsArray[0]) * 100)).toFixed(3);
        var coordsY = (((parseFloat(coordsArray[i].split(",")[1]) / (coordsArray[0] - 1)) * 100) - ((1 / coordsArray[0]) * 100)).toFixed(3);
        mapViewer.innerHTML += '<div class="g-marker-ac-quest" style="margin:' + coordsY + '% 0% 0% ' + coordsX + '%"></div>';
      }
    }
  }
}
