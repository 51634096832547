import { Component, OnInit } from '@angular/core';
import { SightseeingLog } from '../sightseeinglog';
import { SIGHTSEEINGLOG } from '../list-sightseeinglog';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-sightseeinglog',
  templateUrl: './sightseeinglog.component.html',
  styleUrls: ['./sightseeinglog.component.less']
})
export class SightseeinglogComponent implements OnInit {

  sightseeinglogs = SIGHTSEEINGLOG;
  selectedSightseeingLog: SightseeingLog;
  subscription: Subscription;

  currentHours: string;
  currentMinutes: string;

  searchText: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.checkTime();
  }

  onSelect (sightseeinglog: SightseeingLog): void {
    if (document.getElementsByClassName("active").length !== 0) {
      var prevSelectedSightseeingLog = document.getElementsByClassName("active") as HTMLCollectionOf<HTMLElement>;
      prevSelectedSightseeingLog[0].classList.remove("active");
    }
    this.selectedSightseeingLog = sightseeinglog;
    var prevSelectedSightseeingLog = document.getElementsByClassName("active") as HTMLCollectionOf<HTMLElement>;
    var selectedSightseeingLog = document.getElementById(String(sightseeinglog.dbid));
    var mapviewer = document.getElementById("alib-map-viewer") as HTMLElement;
    var selectedMap = sightseeinglog.location.replace(/\s/g,"").replace("'", "");
    var coordinates = sightseeinglog.coords.split(";");
    var maxCoordinates = sightseeinglog.maxcoords - 1;

    function funcChangeMap() {
      mapviewer.style.backgroundImage = "url('assets/img/maps/" + selectedMap + ".webp')";
    }

    function funcAddMarkers() {
      for (var i = 0; i < coordinates.length; i++) {
        var coordsX = (((parseFloat(coordinates[i].split(",")[0]) / maxCoordinates) * 100) - ((1 / maxCoordinates) * 100)).toFixed(3);
        var coordsY = (((parseFloat(coordinates[i].split(",")[1]) / maxCoordinates) * 100) - ((1 / maxCoordinates) * 100)).toFixed(3);
        mapviewer.innerHTML += '<div class="alib-marker" style="margin-left:' + coordsX + '%;margin-top:' + coordsY + '%"></div>';
      }
    }
    selectedSightseeingLog.classList.add("active");
    this.funcMapFadeOut();
    setTimeout(this.funcClearMap, 150);
    setTimeout(funcChangeMap, 150);
    setTimeout(funcAddMarkers, 150);
    setTimeout(this.funcMapFadeIn, 200);
  }

  funcMapFadeIn() {
    var mapviewer = document.getElementById("alib-map-viewer") as HTMLElement;
    mapviewer.classList.add("fade");
  }

  funcMapFadeOut() {
    var mapviewer = document.getElementById("alib-map-viewer") as HTMLElement;
    mapviewer.classList.remove("fade");
  }

  funcClearMap() {
    var mapviewer = document.getElementById("alib-map-viewer") as HTMLElement;
    mapviewer.innerHTML = "";
  }

  checkTime() {
    var localEpoch = (new Date()).getTime();
    var epoch= localEpoch * 20.571428571428573;
    this.currentMinutes = String(epoch / (1000 * 60) % 60).split(".")[0];
    this.currentHours = String(epoch / (1000 * 60 * 60) % 24).split(".")[0];
    var timeButton = document.getElementById("sortbytime");
    timeButton.innerHTML = this.currentHours + ":" + this.currentMinutes;
    var logElements = document.getElementsByClassName("sslog-element");
    for (var i = 0; i < logElements.length; i++) {
      var startTime = parseInt(logElements[i].children[1].children[1].children[0].innerHTML.split("-")[0].split(":")[0]);
      var endTime = parseInt(logElements[i].children[1].children[1].children[0].innerHTML.split("-")[1].split(":")[0]);
      if (startTime > endTime) {
        if (parseInt(this.currentHours) > startTime || parseInt(this.currentHours) < endTime) {
          logElements[i].classList.add("sslog-active");
        } else {
          logElements[i].classList.remove("sslog-active");
        }
      } else if (startTime < endTime) {
        if (parseInt(this.currentHours) > startTime && parseInt(this.currentHours) < endTime) {
          logElements[i].classList.add("sslog-active");
        } else {
          logElements[i].classList.remove("sslog-active");
        }
      }
    }
  }
}
