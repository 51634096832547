import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mlalafell',
  templateUrl: './mlalafell.component.html',
  styleUrls: ['./mlalafell.component.less']
})
export class MlalafellComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  playSound(event) {
    var clickedid = event.currentTarget.id;
    var volumeAmount = (parseInt((document.getElementById("charactervoicesVolume") as HTMLInputElement).value)) / 100;
    var audioFileUrl = ('assets/audio/charactervoices/') + clickedid + ('.mp3');
    var audioFile = new Audio(audioFileUrl);
    audioFile.pause();
    audioFile.currentTime = 0;
    audioFile.volume = volumeAmount;
    audioFile.play();
  }
}
