import { EliteHunts } from './elitehunt';

export const ELITEHUNTS: EliteHunts[] = [
  {
    rank: 'B',
    name: 'Barbastelle',
    location: 'Lower La Noscea',
    coords: '30.0,14.2;29.0,17.0;28.6,18.3;31.5,16.5;33.0,16.0;33.5,17.3;28.5,20.6;25.1,22.2;25.9,24.8;24.3,36.5;26.4,26.2;20.2,31.9;19.9,34.2;16.8,35.2;19.0,35.3;20.7,35.5;22.6,34.3;23.0,36.0;19.9,37.4;22.8,38.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Unktehi',
    location: 'Lower La Noscea',
    coords: '30.0,14.2;29.0,17.0;28.6,18.3;31.5,16.5;33.0,16.0;33.5,17.3;28.5,20.6;25.1,22.2;25.9,24.8;24.3,36.5;26.4,26.2;20.2,31.9;19.9,34.2;16.8,35.2;19.0,35.3;20.7,35.5;22.6,34.3;23.0,36.0;19.9,37.4;22.8,38.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Croakadile',
    location: 'Lower La Noscea',
    coords: '30.0,14.2;29.0,17.0;28.6,18.3;31.5,16.5;33.0,16.0;33.5,17.3;28.5,20.6;25.1,22.2;25.9,24.8;24.3,36.5;26.4,26.2;20.2,31.9;19.9,34.2;16.8,35.2;19.0,35.3;20.7,35.5;22.6,34.3;23.0,36.0;19.9,37.4;22.8,38.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Skogs Fru',
    location: 'Middle La Noscea',
    coords: '16.6,17.8;18.6,9.3;15.9,10.7;14.0,11.6;17.0,11.4;14.0,13.6;15.1,15.3;16.3,14.0;18.3,13.4;20.4,13.7;20.8,15.5;18.6,16.3;17.1,17.1;18.2,18.0;21.0,18.2;23.1,17.1;19.3,20.4;20.2,21.2;19.9,22.4;23.9,19.5;24.3,20.9;22.9,22.6;23.0,24.2;25.0,23.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Vogaal Ja',
    location: 'Middle La Noscea',
    coords: '16.6,17.8;18.6,9.3;15.9,10.7;14.0,11.6;17.0,11.4;14.0,13.6;15.1,15.3;16.3,14.0;18.3,13.4;20.4,13.7;20.8,15.5;18.6,16.3;17.1,17.1;18.2,18.0;21.0,18.2;23.1,17.1;19.3,20.4;20.2,21.2;19.9,22.4;23.9,19.5;24.3,20.9;22.9,22.6;23.0,24.2;25.0,23.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Croque-Mitaine',
    location: 'Middle La Noscea',
    coords: '16.6,17.8;18.6,9.3;15.9,10.7;14.0,11.6;17.0,11.4;14.0,13.6;15.1,15.3;16.3,14.0;18.3,13.4;20.4,13.7;20.8,15.5;18.6,16.3;17.1,17.1;18.2,18.0;21.0,18.2;23.1,17.1;19.3,20.4;20.2,21.2;19.9,22.4;23.9,19.5;24.3,20.9;22.9,22.6;23.0,24.2;25.0,23.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Bloody Mary',
    location: 'Eastern La Noscea',
    coords: '22.2,25.2;202.2,25.1;18.3,26.1;17.9,27.4;21.3,28.0;21.2,29.6;14.8,28.8;15.0,30.5;17.3,30.0;19.5,31.2;21.5,31.0;20.5,33.1;16.5,32.5;16.9,33.9;27.5,25.0;30.0,24.7;28.0,26.5;28.2,28.0;30.7,26.7;32.0,27.2;26.5,30.9;26.6,33.1;26.0,33.8;29.0,35.5;31.6,36.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Hellsclaw',
    location: 'Eastern La Noscea',
    coords: '22.2,25.2;202.2,25.1;18.3,26.1;17.9,27.4;21.3,28.0;21.2,29.6;14.8,28.8;15.0,30.5;17.3,30.0;19.5,31.2;21.5,31.0;20.5,33.1;16.5,32.5;16.9,33.9;25.6,20.4;25.3,21.8;28.6,20.7;29.7,21.3;28.9,19.3;30.3,19.3;30.9,19.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Garlok',
    location: 'Eastern La Noscea',
    coords: '22.2,25.2;202.2,25.1;18.3,26.1;17.9,27.4;21.3,28.0;21.2,29.6;14.8,28.8;15.0,30.5;17.3,30.0;19.5,31.2;21.5,31.0;20.5,33.1;16.5,32.5;16.9,33.9;27.5,25.0;30.0,24.7;28.0,26.5;28.2,28.0;30.7,26.7;32.0,27.2;26.5,30.9;26.6,33.1;26.0,33.8;29.0,35.5;31.6,36.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Dark Helmet',
    location: 'Western La Noscea',
    coords: '13.8,35.2;16.9,36.0;16.2,34.3;32.9,29.8;31.7,28.8;30.8,28.7;34.0,28.2;31.2,27.4;28.9,25.2;27.7,23.9;26.0,23.5;24.7,22.7;23.3,24.7;23.1,21.6;19.8,22.0;19.5,19.6;20.1,18.3;17.0,19.4;14.5,17.0;14.1,15.8;14.6,14.2;12.8,14.4;16.0,14.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Nahn',
    location: 'Western La Noscea',
    coords: '13.8,35.2;16.9,36.0;16.2,34.3;32.9,29.8;31.7,28.8;30.8,28.7;34.0,28.2;31.2,27.4;28.9,25.2;27.7,23.9;26.0,23.5;24.7,22.7;23.3,24.7;23.1,21.6;19.8,22.0;19.5,19.6;20.1,18.3;17.0,19.4;14.5,17.0;14.1,15.8;14.6,14.2;12.8,14.4;16.0,14.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Bonnacon',
    location: 'Western La Noscea',
    coords: '13.8,35.2;16.9,36.0;16.2,34.3;32.9,29.8;31.7,28.8;30.8,28.7;34.0,28.2;31.2,27.4;28.9,25.2;27.7,23.9;26.0,23.5;24.7,22.7;23.3,24.7;23.1,21.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Myradrosh',
    location: 'Upper La Noscea',
    coords: '12.7,25.5;13.7,25.8;12.5,23.7;11.5,22.7;12.1,21.7;10.5,21.5;27.6,19.9;28.2,21.4;29.0,21.2;28.7,22.1;27.3,23.8;29.4,23.9;28.0,25.8;30.5,25.4;33.4,24.0;33.2,25.9;35.2,23.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Marberry',
    location: 'Upper La Noscea',
    coords: '12.7,25.5;13.7,25.8;12.5,23.7;11.5,22.7;12.1,21.7;10.5,21.5;27.6,19.9;28.2,21.4;29.0,21.2;28.7,22.1;27.3,23.8;29.4,23.9;28.0,25.8;30.5,25.4;33.4,24.0;33.2,25.9;35.2,23.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Nandi',
    location: 'Upper La Noscea',
    coords: '12.7,25.5;13.7,25.8;12.5,23.7;11.5,22.7;12.1,21.7;10.5,21.5;27.6,19.9;28.2,21.4;29.0,21.2;28.7,22.1;27.3,23.8;29.4,23.9;28.0,25.8;30.5,25.4;33.4,24.0;33.2,25.9;35.2,23.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Vuokho',
    location: 'Outer La Noscea',
    coords: '15.0,17.8;16.3,17.8;14.6,16.7;13.6,16.2;15.2,14.8;14.0,14.0;15.3,12.6;15.5,10.4;21.3,14.6;22.9,15.2;22.9,16.2;24.6,16.2;23.0,13.2;21.8,10.3;21.8,9.6;21.7,8.3;21.5,6.8;22.4,6.8;23.7,9.3;24.5,7.3;27.0,5.2;27.4,7.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Cornu',
    location: 'Outer La Noscea',
    coords: '15.0,17.8;16.3,17.8;14.6,16.7;13.6,16.2;15.2,14.8;14.0,14.0;15.3,12.6;15.5,10.4;21.3,14.6;22.9,15.2;22.9,16.2;24.6,16.2;23.0,13.2;21.8,10.3;21.8,9.6;21.7,8.3;21.5,6.8;22.4,6.8;23.7,9.3;24.5,7.3;27.0,5.2;27.4,7.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Chernobog',
    location: 'Outer La Noscea',
    coords: '15.0,17.8;16.3,17.8;14.6,16.7;13.6,16.2;15.2,14.8;14.0,14.0;15.3,12.6;15.5,10.4;21.3,14.6;22.9,15.2;22.9,16.2;24.6,16.2;23.0,13.2;21.8,10.3;21.8,9.6;21.7,8.3;21.5,6.8;22.4,6.8;23.7,9.3;24.5,7.3;27.0,5.2;27.4,7.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Albin the Ashen',
    location: 'Southern Thanalan',
    coords: '19.7,19.2;21.4,20.7;18.4,21.2;24.1,21.3;28.4,20.7;31.1,18.3;32.8,19.8;16.7,23.1;18.5,22.8;20.9,23.0;15.9,24.5;19.7,24.6;17.1,25.6;24.3,24.8;21.0,26.3;20.2,28.4;21.4,29.5;23.7,30.2;18.7,30.3;21.0,32.0;17.0,32.8;13.7,34.8;14.7,38.8;19.2,38.3;22.5,38.7;25.2,40.5;24.3,36.8;28.5,34.8;21.7,34.2;23.3,33.3;19.5,17.0;17.2,16.8;17.2,11.7;18.2,11.1;18.0,10.0;21.2,10.7;23.8,12.4;24.6,11.3;24.7,9.3;23.2,7.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Zanig\'oh',
    location: 'Southern Thanalan',
    coords: '19.7,19.2;21.4,20.7;18.4,21.2;24.1,21.3;28.4,20.7;31.1,18.3;32.8,19.8;16.7,23.1;18.5,22.8;20.9,23.0;15.9,24.5;19.7,24.6;17.1,25.6;24.3,24.8;21.0,26.3;20.2,28.4;21.4,29.5;23.7,30.2;18.7,30.3;21.0,32.0;17.0,32.8;13.7,34.8;14.7,38.8;19.2,38.3;22.5,38.7;25.2,40.5;24.3,36.8;28.5,34.8;21.7,34.2;23.3,33.3;19.5,17.0;17.2,16.8;17.2,11.7;18.2,11.1;18.0,10.0;21.2,10.7;23.8,12.4;24.6,11.3;24.7,9.3;23.2,7.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Nunyunuwi',
    location: 'Southern Thanalan',
    coords: '19.7,19.2;21.4,20.7;18.4,21.2;24.1,21.3;28.4,20.7;31.1,18.3;32.8,19.8;16.7,23.1;18.5,22.8;20.9,23.0;15.9,24.5;19.7,24.6;17.1,25.6;24.3,24.8;21.0,26.3;20.2,28.4;21.4,29.5;23.7,30.2;18.7,30.3;21.0,32.0;17.0,32.8;13.7,34.8;14.7,38.8;19.2,38.3;22.5,38.7;25.2,40.5;24.3,36.8;28.5,34.8;21.7,34.2;23.3,33.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Sewer Syrup',
    location: 'Western Thanalan',
    coords: '18.8,29.3;20.2,29.2;21.2,27.7;20.,25.6;22.7,24.7;26.1,26.1;22.1,24.0;21.2,24.0;21.9,22.0;22.8,20.0;26.1,19.1;26.9,16.9;18.3,17.4;18.2,15.0;16.9,16.3;13.9,7.9;15.0,5.9;11.9,7.6;12.0,5.9;10.1,6.5;10.2,5.3;8.4,5.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Alectryon',
    location: 'Western Thanalan',
    coords: '18.8,29.3;20.2,29.2;21.2,27.7;20.,25.6;22.7,24.7;26.1,26.1;22.1,24.0;21.2,24.0;21.9,22.0;22.8,20.0;26.1,19.1;26.9,16.9;18.3,17.4;18.2,15.0;16.9,16.3;13.9,7.9;15.0,5.9;11.9,7.6;12.0,5.9;10.1,6.5;10.2,5.3;8.4,5.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Zona Seeker',
    location: 'Western Thanalan',
    coords: '18.8,29.3;20.2,29.2;21.2,27.7;20.,25.6;22.7,24.7;26.1,26.1;22.1,24.0;21.2,24.0;21.9,22.0;22.8,20.0;26.1,19.1;26.9,16.9;18.3,17.4;18.2,15.0;16.9,16.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Ovjang',
    location: 'Central Thanalan',
    coords: '23.7,34.9;22.3,31.6;23.8,31.8;25.8,29.8;20.4,26.1;18.8,25.3;19.4,23.6;16.9,23.2;19.1,21.7;21.6,21.1;27.1,21.5;29.5,20.9;27.8,18.0;25.4,17.9;17.9,19.0;16.2,19.5;18.2,17.5;20.6,16.4;22.0,15.5;16.5,16.4;21.7,13.7;17.9,13.5;16.2,12.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Sabotender Bailarina',
    location: 'Central Thanalan',
    coords: '23.7,34.9;22.3,31.6;23.8,31.8;25.8,29.8;20.4,26.1;18.8,25.3;19.4,23.6;16.9,23.2;19.1,21.7;21.6,21.1;27.1,21.5;29.5,20.9;27.8,18.0;25.4,17.9;17.9,19.0;16.2,19.5;18.2,17.5;20.6,16.4;22.0,15.5;16.5,16.4;21.7,13.7;17.9,13.5;16.2,12.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Brontes',
    location: 'Central Thanalan',
    coords: '23.7,34.9;22.3,31.6;23.8,31.8;25.8,29.8;20.4,26.1;18.8,25.3;19.4,23.6;16.9,23.2;19.1,21.7;21.6,21.1;27.1,21.5;29.5,20.9;27.8,18.0;25.4,17.9;17.9,19.0;16.2,19.5;18.2,17.5;20.6,16.4;22.0,15.5;16.5,16.4;21.7,13.7;17.9,13.5;16.2,12.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Gatling',
    location: 'Eastern Thanalan',
    coords: '12.8,29.0;15.3,26.8;14.6,26.1;16.5,26.0;17.8,25.6;17.3,24.7;19.7,24.1;18.8,22.5;17.4,21.3;17.3,20.0;15.6,20.1;14.5,18.7;12.6,18.9;10.3,19.1;12.8,15.9;14.5,16.3;17.3,27.9;19.4,28.5;20.2,28.5;23.4,26.7;25.3,25.6;26.6,25.0;27.8,25.8;29.2,26.7;28.0,21.3;24.9,23.2;23.2,22.8;23.8,21.1;22.8,19.7;24.1,18.9;26.5,19.1;27.2,17.8;25.8,17.6;29.5,17.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Maahes',
    location: 'Eastern Thanalan',
    coords: '12.8,29.0;15.3,26.8;14.6,26.1;16.5,26.0;17.8,25.6;17.3,24.7;19.7,24.1;18.8,22.5;17.4,21.3;17.3,20.0;15.6,20.1;14.5,18.7;12.6,18.9;10.3,19.1;12.8,15.9;14.5,16.3;17.3,27.9;19.4,28.5;20.2,28.5;23.4,26.7;25.3,25.6;26.6,25.0;27.8,25.8;29.2,26.7;28.0,21.3;24.9,23.2;23.2,22.8;23.8,21.1;22.8,19.7;24.1,18.9;26.5,19.1;27.2,17.8;25.8,17.6;29.5,17.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Lampalagua',
    location: 'Eastern Thanalan',
    coords: '12.8,29.0;15.3,26.8;14.6,26.1;16.5,26.0;17.8,25.6;17.3,24.7;19.7,24.1;18.8,22.5;17.4,21.3;17.3,20.0;15.6,20.1;14.5,18.7;12.6,18.9;10.3,19.1;12.8,15.9;14.5,16.3;17.3,27.9;19.4,28.5;20.2,28.5;23.4,26.7;25.3,25.6;26.6,25.0;27.8,25.8;29.2,26.7;28.0,21.3;24.9,23.2;23.2,22.8;23.8,21.1;22.8,19.7;24.1,18.9;26.5,19.1;27.2,17.8;25.8,17.6;29.5,17.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Flame Sergeant Dalvag',
    location: 'Northern Thanalan',
    coords: '16.0,14.5;16.0,15.9;17.2,15.8;16.8,17.0;19.0,16.8;18.2,17.4;18.7,17.9;15.4,18.5;16.3,19.4;23.9,22.9;24.2,23.2;23.6,24.1;21.0,25.0;23.6,25.2;23.3,26.2;22.1,27.0;20.5,27.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Dalvag\'s Final Flame',
    location: 'Northern Thanalan',
    coords: '16.0,14.5;16.0,15.9;17.2,15.8;16.8,17.0;19.0,16.8;18.2,17.4;18.7,17.9;15.4,18.5;16.3,19.4;23.9,22.9;24.2,23.2;23.6,24.1;21.0,25.0;23.6,25.2;23.3,26.2;22.1,27.0;20.5,27.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Minhocao',
    location: 'Northern Thanalan',
    coords: '16.0,14.5;16.0,15.9;17.2,15.8;16.8,17.0;19.0,16.8;18.2,17.4;18.7,17.9;15.4,18.5;16.3,19.4;23.9,22.9;24.2,23.2;23.6,24.1;21.0,25.0;23.6,25.2;23.3,26.2;22.1,27.0;20.5,27.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Monarch Ogrefly',
    location: 'South Shroud',
    coords: '16.9,18.4;18.8,18.5;15.7,19.7;16.9,21.5;18.5,22.0;16.9,23.5;16.0,27.7;15.7,28.4;17.3,30.5;16.3,31.9;17.1,32.5;18.3,31.0;19.0,28.5;19.3,27.4;21.4,27.6;22.5,25.6;22.9,24.0;22.6,22.7;21.5,21.7;22.2,19.2;24.1,18.1;27.7,17.6;27.0,20.0;26.9,22.7;28.9,24.7;30.4,25.6;31.6,23.7;32.7,24.0',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Ghede Ti Malice',
    location: 'South Shroud',
    coords: '16.9,18.4;18.8,18.5;15.7,19.7;16.9,21.5;18.5,22.0;16.9,23.5;16.0,27.7;15.7,28.4;17.3,30.5;16.3,31.9;17.1,32.5;18.3,31.0;19.0,28.5;19.3,27.4;21.4,27.6;22.5,25.6;22.9,24.0;22.6,22.7;21.5,21.7;22.2,19.2;24.1,18.1;27.7,17.6;27.0,20.0;26.9,22.7;28.9,24.7;30.4,25.6;31.6,23.7;32.7,24.0',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Mindflayer',
    location: 'South Shroud',
    coords: '16.9,18.4;18.8,18.5;15.7,19.7;16.9,21.5;18.5,22.0;16.9,23.5;16.0,27.7;15.7,28.4;17.3,30.5;16.3,31.9;17.1,32.5;18.3,31.0;19.0,28.5;19.3,27.4;21.4,27.6;22.5,25.6;22.9,24.0;22.6,22.7;21.5,21.7;22.2,19.2;24.1,18.1;27.7,17.6;27.0,20.0;26.9,22.7;28.9,24.7;30.4,25.6;31.6,23.7;32.7,24.0',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'White Joker',
    location: 'Central Shroud',
    coords: '9.6,16.8;10.0,18.8;12.1,19.3;12.7,20.7;10.4,21.7;11.6,23.5;15.7,24.0;14.2,19.5;16.6,17.8;15.7,19.5;16.0,21.1;19.0,18.3;21.7,16.3;22.0,24.5;23.4,27.5;21.5,30.0;26.7,20.5;27.0,22.6;28.1,22.0;29.6,23.2;29.0,19.5;31.0,18.5;28.7,14.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Forneus',
    location: 'Central Shroud',
    coords: '9.6,16.8;10.0,18.8;12.1,19.3;12.7,20.7;10.4,21.7;11.6,23.5;15.7,24.0;14.2,19.5;16.6,17.8;15.7,19.5;16.0,21.1;19.0,18.3;21.7,16.3;22.0,24.5;23.4,27.5;21.5,30.0;26.7,20.5;27.0,22.6;28.1,22.0;29.6,23.2;29.0,19.5;31.0,18.5;28.7,14.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Laideronnette',
    location: 'Central Shroud',
    coords: '9.6,16.8;10.0,18.8;12.1,19.3;12.7,20.7;10.4,21.7;11.6,23.5;15.7,24.0;14.2,19.5;16.6,17.8;15.7,19.5;16.0,21.1;19.0,18.3;21.7,16.3;22.0,24.5;23.4,27.5;21.5,30.0;26.7,20.5;27.0,22.6;28.1,22.0;29.6,23.2;29.0,19.5;31.0,18.5;28.7,14.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Stinging Sophie',
    location: 'East Shroud',
    coords: '13.5,23.2;14.3,25.3;14.4,27.6;20.0,28.7;23.8,30.8;21.9,28.1;19.0,25.4;15.6,23.9;17.4,22.9;16.8,21.7;21.2,21.8;23.0,21.7;25.8,25.2;27.6,24.6;25.2,23.5;27.5,22.5;25.7,20.3;29.0,20.5;31.7,21.5;27.7,18.7;27.0,17.8;30.2,17.4;26.7,16.0;31.8,15.4;29.8,13.3;28.1,12.7;29.5,11.5;26.0,13.1;24.2,15.3;23.8,16.5;24.7,17.4;26.4,11.2;25.0,9.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Melt',
    location: 'East Shroud',
    coords: '13.5,23.2;14.3,25.3;14.4,27.6;20.0,28.7;23.8,30.8;21.9,28.1;19.0,25.4;15.6,23.9;17.4,22.9;16.8,21.7;21.2,21.8;23.0,21.7;25.8,25.2;27.6,24.6;25.2,23.5;27.5,22.5;25.7,20.3;29.0,20.5;31.7,21.5;27.7,18.7;27.0,17.8;30.2,17.4;26.7,16.0;31.8,15.4;29.8,13.3;28.1,12.7;29.5,11.5;26.0,13.1;24.2,15.3;23.8,16.5;24.7,17.4;26.4,11.2;25.0,9.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Wulgaru',
    location: 'East Shroud',
    coords: '13.5,23.2;14.3,25.3;14.4,27.6;20.0,28.7;23.8,30.8;21.9,28.1;19.0,25.4;15.6,23.9;17.4,22.9;16.8,21.7;21.2,21.8;23.0,21.7;25.8,25.2;27.6,24.6;25.2,23.5;27.5,22.5;25.7,20.3;29.0,20.5;31.7,21.5;27.7,18.7;27.0,17.8;30.2,17.4;26.7,16.0;31.8,15.4;29.8,13.3;28.1,12.7;29.5,11.5;26.0,13.1;24.2,15.3;23.8,16.5;24.7,17.4;26.4,11.2;25.0,9.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Phecda',
    location: 'North Shroud',
    coords: '16.5,25.4;16.0,28.2;18.2,28.8;18.1,27.2;19.4,28.6;21.5,30.0;22.4,28.1;24.5,28.2;25.8,25.9;27.9,24.3;24.7,25.7;22.1,23.6;18.6,20.4;22.6,20.6;23.4,20.0;24.4,20.2;25.9,22.8;27.6,22.3;28.6,21.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Girtab',
    location: 'North Shroud',
    coords: '16.5,25.4;16.0,28.2;18.2,28.8;18.1,27.2;19.4,28.6;21.5,30.0;22.4,28.1;24.5,28.2;26.9,27.2;25.8,25.9;27.9,24.3;24.7,25.7;22.1,23.6;18.6,20.4;22.6,20.6;23.4,20.0;24.4,20.2;25.9,22.8;27.6,22.3;28.6,21.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Thousand-cast Theda',
    location: 'North Shroud',
    coords: '16.5,25.4;16.0,28.2;18.2,28.8;18.1,27.2;19.4,28.6;21.5,30.0;22.4,28.1;24.5,28.2;26.9,27.2;25.8,25.9;27.9,24.3;24.7,25.7;22.1,23.6;18.6,20.4;22.6,20.6;23.4,20.0;24.4,20.2;25.9,22.8;27.6,22.3;28.6,21.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Leech King',
    location: 'Mor Dhona',
    coords: '16.9,17.4;16.6,16.1;14.3,13.9;12.6,13.8;13.6,12.1;11.8,11.8;13.9,10.4;15.7,11.7;16.0,10.4;19.2,8.0;23.1,10.7;24.7,10.7;23.2,12.2;25.2,12.8;26.7,12.7;28.2,13.5;28.9,14.7;30.2,15.1;32.5,14.4;33.5,12.0;31.3,11.3;32.3,10.2;32.4,8.6;32.3,6.9;30.5,6.3;29.0,6.4;29.1,7.7;26.8,8.5;27.0,8.9;27.4,10.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Kurrea',
    location: 'Mor Dhona',
    coords: '16.9,17.4;16.6,16.1;14.3,13.9;12.6,13.8;13.6,12.1;11.8,11.8;13.9,10.4;15.7,11.7;16.0,10.4;19.2,8.0;23.1,10.7;24.7,10.7;23.2,12.2;25.2,12.8;26.7,12.7;28.2,13.5;28.9,14.7;30.2,15.1;32.5,14.4;33.5,12.0;31.3,11.3;32.3,10.2;32.4,8.6;32.3,6.9;30.5,6.3;29.0,6.4;29.1,7.7;26.8,8.5;27.0,8.9;27.4,10.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Agrippa the Mighty',
    location: 'Mor Dhona',
    coords: '16.9,17.4;16.6,16.1;14.3,13.9;12.6,13.8;13.6,12.1;11.8,11.8;13.9,10.4;15.7,11.7;16.0,10.4;19.2,8.0;12.6,16.0;12.2,16.9;10.4,15.3;9.7,13.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Naul',
    location: 'Coerthas Central Highlands',
    coords: '6.5,28.7;8.9,27.3;10.9,27.4;10.9,28.9;12.6,28.1;13.3,25.1;14.9,25.6;15.7,27.5;15.5,28.7;17.7,29.3;19.8,29.0;21.2,28.2;23.4,26.8;25.5,25.5;28.1,25.6;29.1,27.6;30.2,29.8;30.6,31.0;24.6,23.7;27.4,21.9;24.1,21.6;27.1,20.7;24.5,19.9;32.8,23.7;34.8,22.8;31.8,16.8;35.0,15.3;31.5,14.2;28.3,14.9;30.1,12.5;30.0,10.6;32.0,7.0;28.8,7.8;28.0,11.4;25.2,11.2;24.5,9.1;22.9,8.0;27.1,13.4;24.7,13.4;21.6,18.9;20.4,16.2;19.5,18.7;17.5,18.8;16.7,20.4;15.3,18.5;14.2,19.8;14.2,17.9;11.6,19.7;10.5,18.3;11.7,14.2;8.8,14.9;8.8,13.4;8.9,11.9;8.4,21.0;6.5,19.9;5.6,18.0;4.1,16.6;5.2,15.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Marraco',
    location: 'Coerthas Central Highlands',
    coords: '6.5,28.7;8.9,27.3;10.9,27.4;10.9,28.9;12.6,28.1;13.3,25.1;14.9,25.6;15.7,27.5;15.5,28.7;17.7,29.3;19.8,29.0;21.2,28.2;23.4,26.8;25.5,25.5;28.1,25.6;29.1,27.6;30.2,29.8;30.6,31.0;24.6,23.7;27.4,21.9;24.1,21.6;27.1,20.7;24.5,19.9;32.8,23.7;34.8,22.8;31.8,16.8;35.0,15.3;31.5,14.2;28.3,14.9;30.1,12.5;30.0,10.6;32.0,7.0;28.8,7.8;28.0,11.4;25.2,11.2;24.5,9.1;22.9,8.0;27.1,13.4;24.7,13.4;21.6,18.9;20.4,16.2;19.5,18.7;17.5,18.8;16.7,20.4;15.3,18.5;14.2,19.8;14.2,17.9;11.6,19.7;10.5,18.3;11.7,14.2;8.8,14.9;8.8,13.4;8.9,11.9;8.4,21.0;6.5,19.9;5.6,18.0;4.1,16.6;5.2,15.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Safat',
    location: 'Coerthas Central Highlands',
    coords: '6.5,28.7;8.9,27.3;10.9,27.4;10.9,28.9;12.6,28.1;13.3,25.1;14.9,25.6;15.7,27.5;15.5,28.7;17.7,29.3;19.8,29.0;21.2,28.2;23.4,26.8;25.5,25.5;28.1,25.6;29.1,27.6;30.2,29.8;30.6,31.0;24.6,23.7;27.4,21.9;24.1,21.6;27.1,20.7;24.5,19.9;32.8,23.7;34.8,22.8;31.8,16.8;35.0,15.3;31.5,14.2;28.3,14.9;30.1,12.5;30.0,10.6;32.0,7.0;28.8,7.8;28.0,11.4;25.2,11.2;24.5,9.1;22.9,8.0;27.1,13.4;24.7,13.4;21.6,18.9;20.4,16.2;19.5,18.7;17.5,18.8;16.7,20.4;15.3,18.5;14.2,19.8;14.2,17.9;11.6,19.7;10.5,18.3;11.7,14.2;8.8,14.9;8.8,13.4;8.9,11.9;8.4,21.0;6.5,19.9;5.6,18.0;4.1,16.6;5.2,15.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Alteci',
    location: 'Coerthas Western Highlands',
    coords: '15.1,12.1;6.7,12.5;18.0,13.1;10.0,13.2;11.6,17.3;22.0,17.7;18.1,19.0;22.1,20.7;17.4,25.8;23.5,28.1;16.7,29.7;18.7,31.2',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Kreutzet',
    location: 'Coerthas Western Highlands',
    coords: '28.0,7.9;35.6,9.6;26.1,11.6;36.3,12.5;29.4,13.0;27.5,15.9;32.5,17.6;34.1,20.8;28.2,21.5;33.8,22.5;30.0,26.8;25.0,32.4',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Mirka',
    location: 'Coerthas Western Highlands',
    coords: '15.1,12.1;6.7,12.5;18.0,13.1;10.0,13.2;11.6,17.3;22.0,17.7;18.1,19.0;22.1,20.7;17.4,25.8;23.5,28.1;16.7,29.7;18.7,31.2;28.0,7.9;35.6,9.6;26.1,11.6;36.3,12.5;29.4,13.0;27.5,15.9;32.5,17.6;34.1,20.8;28.2,21.5;33.8,22.5;30.0,26.8;25.0,32.4',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Lyuba',
    location: 'Coerthas Western Highlands',
    coords: '15.1,12.1;6.7,12.5;18.0,13.1;10.0,13.2;11.6,17.3;22.0,17.7;18.1,19.0;22.1,20.7;17.4,25.8;23.5,28.1;16.7,29.7;18.7,31.2;28.0,7.9;35.6,9.6;26.1,11.6;36.3,12.5;29.4,13.0;27.5,15.9;32.5,17.6;34.1,20.8;28.2,21.5;33.8,22.5;30.0,26.8;25.0,32.4',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Kaiser Behemoth',
    location: 'Coerthas Western Highlands',
    coords: '28.0,7.9;35.6,9.6;26.1,11.6;36.3,12.5;29.4,13.0;27.5,15.9;32.5,17.6;34.1,20.8;15.1,12.1;6.7,12.5;18.0,13.1;10.0,13.2;11.6,17.3;22.0,17.7;18.1,19.0;22.1,20.7;17.4,25.8;16.7,29.7',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Squonk',
    location: 'The Sea Of Clouds',
    coords: '31.5,19.1;36.5,21.2;20.9,21.5;14.6,23.5;24.5,25.3;26.1,29.1;13.8,29.2;17.9,29.9;34.1,31.6;18.3,31.7;26.6,33.7;31.7,36.1;36.2,38.5',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Sanu Vali of Dancing Wings',
    location: 'The Sea Of Clouds',
    coords: '29.4,6.6;15.6,7.5;21.8,7.7;7.5,8.4;36.9,8.7;19.3,9.5;23.3,10.0;25.1,13.1;15.4,14.6;37.4,14.7;21.6,16.1;9.1,16.7;6.5,19.3',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Enkelados',
    location: 'The Sea Of Clouds',
    coords: '19.3,9.5;23.3,10.0;25.1,13.1;21.6,16.1;15.4,14.6;9.1,16.7;6.5,19.3;14.6,23.5;20.9,21.5;31.5,19.1;36.5,21.2;24.5,25.3;13.8,29.2;17.9,29.9;18.3,31.7;26.1,29.1;26.6,33.7;31.7,36.1;34.1,31.6',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Sisiutl',
    location: 'The Sea Of Clouds',
    coords: '7.5,8.4;15.6,7.5;19.3,9.5;23.3,10.0;25.1,13.1;21.6,16.1;15.4,14.6;9.1,16.7;6.5,19.3;14.6,23.5;20.9,21.5;31.5,19.1;36.5,21.2;24.5,25.3;21.8,7.7;29.4,6.6;36.9,8.7;37.4,14.7;34.1,31.6;36.2,38.5',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Bird of Paradise',
    location: 'The Sea Of Clouds',
    coords: '31.5,19.1;36.5,21.2;20.9,21.5;14.6,23.5;24.5,25.3;26.1,29.1;13.8,29.2;17.9,29.9;34.1,31.6;18.3,31.7;26.6,33.7;31.7,36.1;36.2,38.5;29.4,6.6;15.6,7.5;21.8,7.7;7.5,8.4;36.9,8.7;19.3,9.5;23.3,10.0;25.1,13.1;15.4,14.6;37.4,14.7;21.6,16.1;9.1,16.7;6.5,19.3',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Scitalis',
    location: 'The Churning Mists',
    coords: '25.9,8.0;28.2,10.6;28.5,20.3;24.8,20.5;33.5,20.8;37.2,26.2;26.4,27.6;36.1,29.1;22.4,31.1;31.9,32.6',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'The Scarecrow',
    location: 'The Churning Mists',
    coords: '9.5,8.5;7.4,11.6;15.2,14.5;7.7,15.7;6.7,20.3;11.8,20.2;13.9,23.5;18.1,24.3;16.8,28.0;15.7,30.2;6.4,35.6;9.9,38.7',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Bune',
    location: 'The Churning Mists',
    coords: '7.3,11.6;15.2,14.4;7.7,15.7;11.9,20.2;24.8,20.5;13.9,23.5;18.1,24.4;37.1,26.1;26.4,27.6;16.8,28.0;36.0,29.1;15.7,30.2;22.5,31.1;31.9,32.6',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Agathos',
    location: 'The Churning Mists',
    coords: '25.9,8.0;9.5,8.4;28.2,10.6;7.4,11.6;7.8,15.8;6.6,20.3;28.4,20.3;33.6,20.8;37.1,26.1;36.0,29.1;15.7,30.2;22.4,31.0;31.9,32.6;6.5,35.6;10.1,38.7',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Gandarewa',
    location: 'The Churning Mists',
    coords: '25.9,8.0;9.5,8.4;28.2,10.6;7.4,11.6;15.2,14.4;7.8,15.8;11.9,20.2;6.6,20.3;28.4,20.3;33.6,20.8;13.9,23.5;18.1,24.4;37.1,26.1;26.4,27.6;16.8,28.0;36.0,29.1;15.7,30.2;22.5,31.1;31.9,32.6;6.5,35.6;10.1,38.7',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Lycidas',
    location: 'Azys Lla',
    coords: '17.3,8.6;18.5,13.3;13.9,16.9;34.4,26.7;39.0,27.6;29.9,28.8;36.3,34.2;29.7,35.1;36.4,37.2',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Omni',
    location: 'Azys Lla',
    coords: '32.9,5.7;37.7,7.1;33.8,12.9;9.6,26.6;16.1,29.4;11.4,30.4;8.3,34.0;12.9,38.2',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Campacti',
    location: 'Azys Lla',
    coords: '17.3,8.6;18.5,13.3;13.9,16.9;34.4,26.7;39.0,27.6;29.9,28.8;36.3,34.2;29.7,35.1;36.4,37.2;32.9,5.7;37.7,7.1;33.8,12.9;9.6,26.6;16.1,29.4;11.4,30.4;8.3,34.0;12.9,38.2',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Stench Blossom',
    location: 'Azys Lla',
    coords: '17.3,8.6;18.5,13.3;13.9,16.9;34.4,26.7;39.0,27.6;29.9,28.8;36.3,34.2;29.7,35.1;36.4,37.2;32.9,5.7;37.7,7.1;33.8,12.9;9.6,26.6;16.1,29.4;11.4,30.4;8.3,34.0;12.9,38.2',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Leucrotta',
    location: 'Azys Lla',
    coords: '17.3,8.6;18.5,13.3;13.9,16.9;34.4,26.7;39.0,27.6;29.9,28.8;36.3,34.2;29.7,35.1;36.4,37.2;32.9,5.7;37.7,7.1;33.8,12.9;9.6,26.6;16.1,29.4;11.4,30.4;8.3,34.0;12.9,38.2',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Gnath Cometdrone',
    location: 'The Dravanian Forelands',
    coords: '12.3,27.6;20.1,30.1;27.8,30.6;14.3,31.4;21.2,34.0;10.6,35.4;17.0,35.9;27.4,37.3;18.0,38.8',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Thextera',
    location: 'The Dravanian Forelands',
    coords: '26.4,7.5;33.9,8.6;34.9,13.6;32.4,18.2;28.9,21.8;25.7,24.9;38.6,25.6;34.6,28.5;20.0,20.0',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Pylraster',
    location: 'The Dravanian Forelands',
    coords: '13.6,15.1;16.9,11.3;26.4,7.5;33.9,8.6;20.0,20.0;28.9,21.8;12.3,27.6;14.3,31.4;10.6,35.4;17.0,35.9;18.0,38.8;21.2,34.0;20.1,30.1;24.8,32.3;27.8,30.6;25.7,24.9;',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Lord of the Wyverns',
    location: 'The Dravanian Forelands',
    coords: '13.6,15.1;16.9,11.3;26.4,7.5;33.9,8.6;20.0,20.0;28.9,21.8;12.3,27.6;14.3,31.4;10.6,35.4;17.0,35.9;18.0,38.8;21.2,34.0;20.1,30.1;24.8,32.3;27.8,30.6;25.7,24.9;',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Senmurv',
    location: 'The Dravanian Forelands',
    coords: '12.3,27.6;20.1,30.1;27.8,30.6;14.3,31.4;21.2,34.0;10.6,35.4;17.0,35.9;27.4,37.3;18.0,38.8;26.4,7.5;33.9,8.6;34.9,13.6;32.4,18.2;28.9,21.8;25.7,24.9;38.6,25.6;34.6,28.5;20.0,20.0',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Pterygotus',
    location: 'The Dravanian Hinterlands',
    coords: '15.1,25.5;15.1,21.6;9.4,21.4;5.4,22.2;13.1,16.5;8.6,28.1;12.6,29.3;15.5,32.1;8.5,34.0;14.5,35.7;16.2,37.6',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'False Gigantopithecus',
    location: 'The Dravanian Hinterlands',
    coords: '26.3,36.9;26.6,29.4;26.8,25.5;38.0,28.4;34.2,26.9;34.0,24.1;24.0,23.5;36.0,21.9;31.8,20.2;26.9,20.1;26.7,16.6',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Slipkinx Steeljoints',
    location: 'The Dravanian Hinterlands',
    coords: '26.3,36.9;26.6,29.4;26.8,25.5;38.0,28.4;34.2,26.9;34.0,24.1;24.0,23.5;36.0,21.9;31.8,20.2;26.9,20.1;26.7,16.6;15.1,25.5;15.1,21.6;9.4,21.4;5.4,22.2;13.1,16.5',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Stolas',
    location: 'The Dravanian Hinterlands',
    coords: '26.3,36.9;26.6,29.4;26.8,25.5;38.0,28.4;34.2,26.9;34.0,24.1;24.0,23.5;36.0,21.9;31.8,20.2;26.9,20.1;15.1,25.5;8.6,28.1;12.6,29.3;15.5,32.1;8.5,34.0;14.5,35.7;16.2,37.6',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'S',
    name: 'The Pale Rider',
    location: 'The Dravanian Hinterlands',
    coords: '26.3,36.9;26.6,29.4;26.8,25.5;38.0,28.4;34.2,26.9;34.0,24.1;24.0,23.5;36.0,21.9;31.8,20.2;26.9,20.1;26.7,16.6;15.1,25.5;8.6,28.1;12.6,29.3;15.5,32.1;8.5,34.0;14.5,35.7;16.2,37.6;15.1,21.6;9.4,21.4;5.4,22.2;13.1,16.5',
    maxcoords: 44,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Shadow-dweller Yamini',
    location: 'The Fringes',
    coords: '34.6,17.5;33.8,20.8;27.7,23.6;24.9,28.2;25.7,31.8;28.8,30.8;33.2,33.2;',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Ouzelum',
    location: 'The Fringes',
    coords: '10.3,14.5;12.2,17.9;8.6,25.2;8.2,30.4;15.3,26.0;16.7,24.0;17.4,21.5;14.3,12.2;17.8,12.9;18.2,8.2;21.0,10.5;25.3,11.8;24.0,18.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Orcus',
    location: 'The Fringes',
    coords: '15.3,26.0;16.7,24.0;17.4,21.5;34.6,17.5;27.7,23.6;24.9,28.2;25.7,31.8;33.2,33.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Erle',
    location: 'The Fringes',
    coords: '10.3,14.5;12.2,17.9;8.6,25.2;8.2,30.4;14.3,12.2;17.8,12.9;18.2,8.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Udumbara',
    location: 'The Fringes',
    coords: '8.2,30.4;16.7,24.0;17.8,12.9;18.2,8.2;25.3,11.8;27.7,23.6;24.9,28.2;28.8,30.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Gwas-y-neidr',
    location: 'The Peaks',
    coords: '7.8,25.8;12.6,28.0;11.8,32.8;5.8,34.9;16.7,23.0;18.0,26.7;23.7,24.1;26.5,29.7;23.5,36.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Buccaboo',
    location: 'The Peaks',
    coords: '11.1,8.1;8.7,12.2;5.6,14.7;26.9,7.8;26.0,11.9;23.0,15.0;32.0,8.3;36.3,12.4;25.1,20.1',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Vochstein',
    location: 'The Peaks',
    coords: '11.1,8.1;8.7,12.2;5.6,14.7;26.9,7.8;26.0,11.9;23.0,15.0;32.0,8.3;36.3,12.4;25.1,20.1;7.8,25.8;12.6,28.0;11.8,32.8;5.8,34.9;16.7,23.0;18.0,26.7;23.7,24.1;23.5,36.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Aqrabuamelu',
    location: 'The Peaks',
    coords: '11.1,8.1;8.7,12.2;23.0,15.0;36.3,12.4;25.1,20.1;7.8,25.8;12.6,28.0;11.8,32.8;5.8,34.9;16.7,23.0;18.0,26.7;23.7,24.1;23.5,36.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Bone Crawler',
    location: 'The Peaks',
    coords: '11.1,8.1;8.7,12.2;5.6,14.7;23.0,15.0;32.0,8.3;36.3,12.4;12.6,28.0;11.8,32.8;18.0,26.7;23.7,24.1;23.5,36.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Kiwa',
    location: 'The Lochs',
    coords: '14.0,20.5;18.8,25.6;18.8,31.7;24.4,32.9;23.3,25.8;25.0,16.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Manes',
    location: 'The Lochs',
    coords: '6.3,8.1;7.6,14.0;18.3,7.5;22.5,10.3;35.0,11.0;4.7,29.2;7.0,31.0;10.5,33.6;26.2,34.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Manisha',
    location: 'The Lochs',
    coords: '14.0,20.5;18.8,25.6;24.4,32.9;6.3,8.1;7.6,14.0;29.0,8.0;7.2,26.0;7.0,31.0;30.0,36.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Luminare',
    location: 'The Lochs',
    coords: '14.0,20.5;18.8,25.6;18.8,31.7;24.4,32.9;7.6,14.0;29.0,8.0;35.0,11.0;4.7,29.2;7.2,26.0;3.0,34.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Salt and Light',
    location: 'The Lochs',
    coords: '14.0,20.5;18.8,25.6;24.4,32.9;6.3,8.1;29.0,8.0;7.2,26.0;10.5,33.6;3.0,34.8;30.0,36.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Guhuo Niao',
    location: 'The Ruby Sea',
    coords: '7.5,5.9;25.9,7.0;18.9,8.5;16.5,10.6;36.6,17.8;34.9,21.2;32.0,24.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Gauki Strongblade',
    location: 'The Ruby Sea',
    coords: '14.4,13.8;5.0,22.8;25.2,26.1;6.9,29.7;27.2,31.6;23.8,33.2;31.0,38.7',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Funa Yurei',
    location: 'The Ruby Sea',
    coords: '25.9,7.1;18.8,8.6;14.4,13.9;5.0,22.8;32.0,24.1;6.8,29.8;27.2,31.6;23.8,33.1;31.0,38.7',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Oni Yumemi',
    location: 'The Ruby Sea',
    coords: '25.9,7.1;18.8,8.6;14.4,13.9;5.0,22.8;32.0,24.1;6.8,29.8;27.2,31.6;23.8,33.1;31.0,38.7',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Okina',
    location: 'The Ruby Sea',
    coords: '25.9,7.1;18.8,8.6;34.9,21.2;32.0,24.3;6.8,29.8;27.2,31.6;23.9,33.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Deidar',
    location: 'Yanxia',
    coords: '32.3,18.6;24.6,21.6;34.1,26.6;23.6,27.2;12.3,30.6;17.7,34.2;29.8,35.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Gyorai Quickstrike',
    location: 'Yanxia',
    coords: '21.6,9.4;18.3,10.4;27.2,10.4;28.6,11.3;23.6,11.4;16.7,13.8;18.2,16.1',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Gajasura',
    location: 'Yanxia',
    coords: '21.6,9.4;27.2,10.4;28.6,11.3;23.6,11.4;16.7,13.8;18.2,16.1;12.2,30.6;17.6,34.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Angada',
    location: 'Yanxia',
    coords: '21.6,9.4;27.2,10.4;28.6,11.3;23.6,11.4;16.7,13.8;18.2,16.1;12.2,30.6;17.6,34.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Gamma',
    location: 'Yanxia',
    coords: '21.6,9.4;27.2,10.4;28.6,11.3;23.6,11.4;16.7,13.8;12.2,30.6;17.6,34.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Aswang',
    location: 'The Azim Steppe',
    coords: '27.3,9.0;13.4,10.1;19.2,10.2;34.6,15.0;22.3,15.9;12.4,17.2;27.8,18.7;17.3,18.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Kurma',
    location: 'The Azim Steppe',
    coords: '9.7,22.2;14.8,25.5;9.7,25.8;11.3,28.3;15.5,30.3;24.4,30.3;17.6,34.3;21.7,34.4;26.7,34.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Girimekhala',
    location: 'The Azim Steppe',
    coords: '9.7,22.2;14.8,25.5;9.7,25.8;11.3,28.3;15.5,30.3;24.4,30.3;17.6,34.3;21.7,34.4;26.7,34.4;27.3,9.0;13.4,10.1;19.2,10.2;34.6,15.0;22.3,15.9;12.4,17.2;27.8,18.7;17.3,18.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Sum',
    location: 'The Azim Steppe',
    coords: '9.7,22.2;14.8,25.5;9.7,25.8;11.3,28.3;15.5,30.3;24.4,30.3;17.6,34.3;21.7,34.4;26.7,34.4;27.3,9.0;13.4,10.1;19.2,10.2;34.6,15.0;22.3,15.9;12.4,17.2;27.8,18.7;17.3,18.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Orghana',
    location: 'The Azim Steppe',
    coords: '9.7,22.2;14.8,25.5;9.7,25.8;11.3,28.3;15.5,30.3;24.4,30.3;17.6,34.3;21.7,34.4;26.7,34.4;27.3,9.0;13.4,10.1;19.2,10.2;34.6,15.0;22.3,15.9;12.4,17.2;27.8,18.7;17.3,18.9',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Worm of the Well',
    location: 'Amh Araeng',
    coords: '11.5,19.2;19.2,16.2;22.7,10.2;9.8,11.6;16.4,10.3;19.2,24.8;16.4,23.9;19.3,28.8;17.3,32.1',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Juggler Hecatomb',
    location: 'Amh Araeng',
    coords: '30.1,13.7;28.6,20.3;33.5,21.5;28.2,25.9;23.2,29.7;28.9,35.8;33.0,34.2;20.0,36.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Maliktender',
    location: 'Amh Araeng',
    coords: '30.1,13.7;28.6,20.3;33.5,21.5;28.2,25.9;23.2,29.7;28.9,35.8;33.0,34.2;20.0,36.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Sugaar',
    location: 'Amh Araeng',
    coords: '9.4,11.4;16.3,10.2;19.3,16.1;11.5,19.4;16.3,24.1;18.9,24.8;19.2,29.0;17.3,31.7;20.0,36.8;23.2,29.7;28.9,35.8;33.0,34.2;28.2,25.9;33.5,21.5;28.6,20.3;30.1,13.7',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Tarchia',
    location: 'Amh Araeng',
    coords: '30.1,13.7;28.6,20.3;33.5,21.5;28.2,25.9;28.9,35.8;33.0,34.2;20.0,36.8;11.5,19.2;19.2,16.2;9.8,11.6;16.4,10.3;19.2,24.8;16.4,23.9;17.3,32.1',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Domovoi',
    location: 'Il Mheg',
    coords: '24.7,36.4;19.2,34.0;22.8,28.8;14.6,34.6;9.2,30.4;7.0,22.8;10.9,20.5;11.0,15.1',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Vulpangue',
    location: 'Il Mheg',
    coords: '20.7,8.8;25.8,6.9;29.2,5.1;33.8,7.3;32.1,12.9;27.0,19.0',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'O Poorest Pauldia',
    location: 'Il Mheg',
    coords: '24.7,36.4;19.2,34.0;22.8,28.8;14.6,34.6;9.2,30.4;7.0,22.8;10.9,20.5;11.0,15.1;20.7,8.8;25.8,6.9;29.2,5.1;33.8,7.3;32.1,12.9;27.0,19.0',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'The Mudman',
    location: 'Il Mheg',
    coords: '24.7,36.4;19.2,34.0;22.8,28.8;14.6,34.6;9.2,30.4;7.0,22.8;10.9,20.5;11.0,15.1;20.7,8.8;25.8,6.9;29.2,5.1;33.8,7.3;32.1,12.9;27.0,19.0',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Aglaope',
    location: 'Il Mheg',
    coords: '24.7,36.4;19.2,34.0;22.8,28.8;14.6,34.6;9.2,30.4;7.0,22.8;10.9,20.5;11.0,15.1;20.7,8.8;25.8,6.9;29.2,5.1;33.8,7.3;32.1,12.9;27.0,19.0',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Coquecigrue',
    location: 'Kholusia',
    coords: '20.5,23.8;11.6,18.3;15.4,16.2;27.7,19.3;30.6,19.2;24.1,16.9;21.9,14.5;20.4,11.4;16.7,6.9;24.9,11.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Indomitable',
    location: 'Kholusia',
    coords: '9.4,26.0;14.8,24.8;21.1,30.7;24.3,30.4;29.5,30.2;26.8,24.0;33.9,25.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Li\'l Murderer',
    location: 'Kholusia',
    coords: '20.5,23.8;11.6,18.3;15.4,16.2;27.7,19.3;30.6,19.2;24.1,16.9;21.9,14.5;20.4,11.4;16.7,6.9;24.9,11.4;9.4,26.0;14.8,24.8;21.1,30.7;24.3,30.4;29.5,30.2;26.8,24.0;33.9,25.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Huracan',
    location: 'Kholusia',
    coords: '20.5,23.8;11.6,18.3;15.4,16.2;27.7,19.3;30.6,19.2;24.1,16.9;21.9,14.5;20.4,11.4;16.7,6.9;24.9,11.4',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Forgiven Pedantry',
    location: 'Kholusia',
    coords: '20.5,23.8;11.6,18.3;15.4,16.2;27.7,19.3;30.6,19.2;24.1,16.9;21.9,14.5;20.4,11.4;16.7,6.9;24.9,11.4;9.4,26.0;14.8,24.8;21.1,30.7;24.3,30.4;29.5,30.2;26.8,24.0;33.9,25.2',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'La Velue',
    location: 'Lakeland',
    coords: '16.9,22.8;8.1,22.0;12.1,17.2;11.1,12.4;19.5,10.0;27.4,15.2;30.0,18.8;13.5,24.5;23.2,12.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Itzpapalotl',
    location: 'Lakeland',
    coords: '37.1,11.6;35.5,15.3;30.7,22.0;35.3,32.4;27.2,30.9;26.7,37.7;22.9,29.5;25.5,23.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Nariphon',
    location: 'Lakeland',
    coords: '16.9,22.8;8.1,22.0;12.1,17.2;11.1,12.4;19.5,10.0;27.4,15.2;30.0,18.8;37.1,11.6;35.5,15.3;30.7,22.0;35.3,32.4;27.2,30.9;26.7,37.7;22.9,29.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Nuckelavee',
    location: 'Lakeland',
    coords: '26.7,37.7;27.2,30.9;16.9,22.8;13.5,24.5;25.5,23.8',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Tyger',
    location: 'Lakeland',
    coords: '25.5,23.8;13.5,24.5;26.7,37.7;27.2,30.9;16.9,22.8;30.7,22.0;30.0,18.8;35.5,15.3;37.1,11.6;27.4,15.2;19.5,10.0;11.1,12.4;12.1,17.2;23.2,12.3',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Mindmaker',
    location: 'The Rak\'tika Greatwood',
    coords: '9.1,18.6;9.2,24.4;14.5,22.1;17.4,24.2;15.0,29.7;17.2,35.4;12.1,35.6;8.5,34.7',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Pachamama',
    location: 'The Rak\'tika Greatwood',
    coords: '22.4,10.7;22.0,13.7;26.6,15.2;26.3,24.0;34.0,23.2;29.6,26.0;24.6,30.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Grassman',
    location: 'The Rak\'tika Greatwood',
    coords: '9.1,18.6;9.2,24.4;14.5,22.1;17.4,24.2;15.0,29.7;17.2,35.4;12.1,35.6;8.5,34.7;26.3,24.0;34.0,23.2;29.6,26.0;24.6,30.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Supay',
    location: 'The Rak\'tika Greatwood',
    coords: '9.1,18.6;9.2,24.4;14.5,22.1;17.4,24.2;15.0,29.7;17.2,35.4;12.1,35.6;8.5,34.7;22.4,10.7;22.0,13.7;26.6,15.2;26.3,24.0;34.0,23.2;29.6,26.0;24.6,30.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Ixtab',
    location: 'The Rak\'tika Greatwood',
    coords: '9.1,18.6;9.2,24.4;14.5,22.1;17.4,24.2;15.0,29.7;17.2,35.4;12.1,35.6;8.5,34.7;22.4,10.7;22.0,13.7;26.6,15.2;26.3,24.0;34.0,23.2;29.6,26.0;24.6,30.6',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Deacon',
    location: 'The Tempest',
    coords: '24.2,25.8;29.7,22.7;34.1,21.7;37.5,16.0;36.1,11.4;30.9,10.6;31.2,3.6;35.8,20.2;28.5,8.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'B',
    name: 'Gilshs Aath Swiftclaw',
    location: 'The Tempest',
    coords: '24.4,12.2;21.0,7.3;18.0,14.0;14.9,10.5;15.4,20.3;11.2,5.3;8.6,8.6;13.6,17.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Baal',
    location: 'The Tempest',
    coords: '24.2,25.8;29.7,22.7;34.1,21.7;37.5,16.0;36.1,11.4;30.9,10.6;31.2,3.6;35.8,20.2;28.5,8.5;24.4,12.2;21.0,7.3;18.0,14.0;14.9,10.5;15.4,20.3;11.2,5.3;8.6,8.6;13.6,17.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'A',
    name: 'Rusalka',
    location: 'The Tempest',
    coords: '24.2,25.8;29.7,22.7;34.1,21.7;37.5,16.0;36.1,11.4;30.9,10.6;31.2,3.6;35.8,20.2;28.5,8.5;24.4,12.2;21.0,7.3;18.0,14.0;14.9,10.5;15.4,20.3;11.2,5.3;8.6,8.6;13.6,17.5',
    maxcoords: 42,
    condition: '',
  },
  {
    rank: 'S',
    name: 'Gunitt',
    location: 'The Tempest',
    coords: '24.2,25.8;29.7,22.7;34.1,21.7;37.5,16.0;36.1,11.4;30.9,10.6;31.2,3.6;35.8,20.2;28.5,8.5;24.4,12.2;21.0,7.3;18.0,14.0;14.9,10.5;15.4,20.3;13.6,17.5',
    maxcoords: 42,
    condition: '',
  }, 
];