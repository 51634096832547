<h1 class="seoh1">Final Fantasy XIV - Male Miqo'te Voice Browser</h1>
<div class="alib-pagetitle">MIQO'TE</div>
<div class="alib-charactervoices-content">
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 1</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml03combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml03combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml03combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml03combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml03combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml03combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="ml03yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="ml03no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="ml03chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="ml03laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="ml03happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="ml03cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="ml03huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="ml03surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="ml03upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="ml03fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="ml03angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="ml03furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="ml03stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="ml03doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 2</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm02combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm02combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm02combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm02combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm02combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm02combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mm02yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mm02no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mm02chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mm02laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mm02happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mm02cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mm02huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mm02surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mm02upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mm02fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mm02angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mm02furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mm02stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mm02doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 3</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm01combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm01combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm01combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm01combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm01combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm01combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mhm01yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mhm01no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mhm01chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mhm01laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mhm01happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mhm01cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mhm01huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mhm01surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mhm01upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mhm01fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mhm01angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mhm01furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mhm01stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mhm01doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 4</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml06combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml06combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml06combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml06combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml06combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml06combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="ml06yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="ml06no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="ml06chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="ml06laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="ml06happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="ml06cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="ml06huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="ml06surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="ml06upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="ml06fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="ml06angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="ml06furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="ml06stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="ml06doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 5</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm05combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm05combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm05combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm05combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm05combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm05combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mm05yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mm05no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mm05chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mm05laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mm05happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mm05cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mm05huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mm05surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mm05upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mm05fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mm05angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mm05furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mm05stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mm05doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 6</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm04combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm04combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm04combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm04combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm04combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm04combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mhm04yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mhm04no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mhm04chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mhm04laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mhm04happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mhm04cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mhm04huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mhm04surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mhm04upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mhm04fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mhm04angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mhm04furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mhm04stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mhm04doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 7</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml09combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml09combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml09combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml09combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml09combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml09combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="ml09yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="ml09no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="ml09chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="ml09laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="ml09happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="ml09cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="ml09huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="ml09surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="ml09upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="ml09fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="ml09angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="ml09furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="ml09stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="ml09doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 8</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm08combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm08combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm08combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm08combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm08combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm08combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mm08yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mm08no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mm08chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mm08laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mm08happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mm08cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mm08huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mm08surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mm08upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mm08fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mm08angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mm08furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mm08stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mm08doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 9</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm07combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm07combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm07combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm07combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm07combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm07combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mhm07yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mhm07no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mhm07chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mhm07laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mhm07happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mhm07cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mhm07huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mhm07surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mhm07upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mhm07fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mhm07angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mhm07furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mhm07stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mhm07doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 10</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml12combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml12combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml12combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml12combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml12combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="ml12combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="ml12yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="ml12no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="ml12chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="ml12laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="ml12happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="ml12cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="ml12huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="ml12surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="ml12upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="ml12fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="ml12angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="ml12furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="ml12stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="ml12doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 11</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm11combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm11combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm11combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm11combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm11combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mm11combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mm11yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mm11no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mm11chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mm11laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mm11happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mm11cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mm11huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mm11surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mm11upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mm11fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mm11angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mm11furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mm11stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mm11doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 12</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm10combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm10combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm10combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm10combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm10combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="mhm10combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="mhm10yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="mhm10no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="mhm10chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="mhm10laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="mhm10happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="mhm10cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="mhm10huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="mhm10surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="mhm10upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="mhm10fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="mhm10angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="mhm10furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="mhm10stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="mhm10doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
