<h1 class="seoh1">Final Fantasy XIV - Lancer Hunting Log Locations</h1>
<div id="lancer-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-lancer">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">CENTRAL SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.5,16.2,42,5;22.6,17.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/LittleLadybug.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Little Ladybug</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="24.5,18.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mushroom.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Forest Funguar</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="26.3,20.5,42,4;26.5,18.1,42,4;25.8,21.6,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Black Eft</p></div>
                                <div class="level">Lv 6</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="25.0,23.5,42,5;24.0,24.2,42,4;22.0,20.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Bog Yarzon</p></div>
                                <div class="level">Lv 7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="29.8,23.4,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hoglet</p></div>
                                <div class="level">Lv 8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="31.4,20.9,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Anole</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="19.2,27.3,42,8;21.4,27.0,42,5;19.9,18.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Miteling.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Diremite</p></div>
                                <div class="level">Lv 10-12</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.1,26.4,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ground Squirrel</p></div>
                                <div class="level">Lv 2</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="25.6,26.9,42,5;25.9,28.5,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Miteling.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Miteling</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="28.0,24.9,42,5;27.3,23.5,42,5;29.5,23.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/OpoOpo.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Opo-opo</p></div>
                                <div class="level">Lv 5-9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="28.2,21.8,42,4;26.4,21.8,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Microchu</p></div>
                                <div class="level">Lv 6-8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="12.6,27.0,42,4;12.8,25.8,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Slug.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Tree Slug</p></div>
                                <div class="level">Lv 10-12</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="28.1,29.7,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Arbor Buzzard</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="24.6,18.0,42,3;22.3,16.5,42,3;20.9,16.9,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Treant Sapling</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.1,25.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mandragora.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mandragora</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="13.0,23.8,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Wild Hoglet</p></div>
                            <div class="level">Lv 14</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="14.9,28.0,42,4;17.0,25.8,42,4;20.4,29.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/OpoOpo.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lemur</p></div>
                            <div class="level">Lv 15-18</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="14.9,28.0,42,4;19.5,29.1,42,4;17.0,25.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Weevil.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Boring Weevil</p></div>
                            <div class="level">Lv 15-18</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.1,28.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mushroom.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Faerie Funguar</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.0,27.2,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gnat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Giant Gnat</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.7,30.1,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Poacher.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Boar Poacher</p></div>
                            <div class="level">Lv 18</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="20.6,25.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ziz Goblin</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="18.4,24.8,42,5;16.4,22.8,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Black Bat</p></div>
                            <div class="level">Lv 20-23</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.5,17.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Beater</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="15.0,17.5,42,5;17.6,22.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Antelope.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Antelope Doe</p></div>
                            <div class="level">Lv 20-23</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="29.1,35.5,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Apkallu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Apkallu</p></div>
                            <div class="level">Lv 30</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.1,21.9,42,5;11.1,21.3,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Forest Yarson</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="9.2,21.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurl Pup</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.5,16.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Fallen Mage</p></div>
                            <div class="level">Lv 26</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="23.9,9.4,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Corpse Brigade Knuckledancer</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.3,18.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lindwurm</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.6,29.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Overgrown Ivy</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="17.2,27.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Slug.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Shroud Hare</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="17.2,27.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ziz</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="16.5,29.4,42,3;17.9,28.4,42,3;19.7,28.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Clay Golem</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="17.1,18.5,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Orobon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Smallmouth Orobon</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="19.8,22.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bark Eft</p></div>
                            <div class="level">Lv 23</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="30.2,20.9,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Poacher.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurlclaw Hunter</p></div>
                            <div class="level">Lv 29</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="18.1,30.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Orobon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bigmouth Orobon</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="18.7,28.0,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Jungle Coeurl</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="17.0,31.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Goobbue</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.4,15.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Basalt Golem</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.6,16.6,42,9" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Velociraptor</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="33.3,25.6,42,4;34.9,23.9,42,4;28.5,25.6,42,4;27.5,23.2,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/MamoolJaSophist.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mamool Ja Breeder</p></div>
                            <div class="level">Lv 31-33</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.0,32.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Russet Yarzon</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="19.4,35.9,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Smoke Bomb</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="17.0,22.1,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DeathGaze.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Death Gaze</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.9,24.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ochu</p></div>
                            <div class="level">Lv 40</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="26.9,24.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Molted Ziz</p></div>
                            <div class="level">Lv 40</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.7,19.6,42,4;24.7,20.6,42,4;28.3,23.4,42,4;25.1,25.9,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Highland Goobbue</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="26.0,22.0,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Croc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Feral Croc</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="24.8,13.1,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ogre.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redhorn Ogre</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="23.6,18.2,42,4;24.2,16.1,42,4;22.5,17.1,42,4;20.3,16.0,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Snowstorm Goobbue</p></div>
                            <div class="level">Lv 39</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="15.4,35.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Skeleton.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dead Man's Moan</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                        <div class="huntlog-element" data="13.4,16.9,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/ShelfclawReaver.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Shelfclaw Reaver</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="28.2,25.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Quartz Doblyn</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="21.9,26.8,42,8;23.5,23.2,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Basilisk.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Basilisk</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.0,19.5,42,4;32.0,19.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Biast.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Zahar'ak Battle Drake</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="32.5,20.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>3rd Cohort Signifier</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="27.7,18.3,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dreamtoad</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.7,24.8,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wild Hog</p></div>
                                <div class="level">Lv 45-46</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.7,30.1,42,7;15.4,29.6,42,7;11.3,28.6,42,7;7.0,28.6,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Biast.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Biast</p></div>
                                <div class="level">Lv 45-47</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="11.8,30.6,42,5;13.2,31.3,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Snow Wolf</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="17.0,15.6,42,4;17.0,17.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Raging Harrier</p></div>
                                <div class="level">Lv 45</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="27.4,11.0,42,3;30.4,14.,42,3;31.8,12.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gigas.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Gigas Shramana</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="12.2,12.6,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>5th Cohort Hoplomachus</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                        <div class="huntlog-element" data="31.0,5.6,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ogre.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hapalit</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>