import { GatheringList } from './interface-gathering';

export const GATHERINGLIST: GatheringList[] = [
    ////// MINING
  //// CENTRAL THANANALN
// Spineless Basin
  {
    name: 'Wind Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin 1'
  },
  {
    name: 'Lightning Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin 1'
  },
  {
    name: 'Copper Ore',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin 1'
  },
  {
    name: 'Muddy Water',
    type: 'Mining',
    itemlevel: '3',
    nodelevel: '5',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin 1'
  },
  {
    name: 'Wind Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin 2'
  },
  {
    name: 'Lightning Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin 2'
  },
  {
    name: 'Bone Chip',
    type: 'Mining',
    itemlevel: '5',
    nodelevel: '5',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin 2'
  },
// Black Brush
  {
    name: 'Lightning Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '10',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Black Brush'
  },












  {
    name: 'Lightning Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Black Brush'
  },
  {
    name: 'Lightning Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '10',
    location: 'Central Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Black Brush'
  },
  {
    name: 'Lightning Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'Spineless Basin'
  },
  {
    name: 'Lightning Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'The Clutch'
  },
  {
    name: 'Lightning Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Central Thanalan',
    requirements: '',
    coords: 'The Clutch'
  },








  {
    name: 'Fire Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Western Thanalan',
    requirements: '',
    coords: 'Hammerlea'
  },
  {
    name: 'Fire Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Eastern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Eastern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '45',
    location: 'Eastern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Middle La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Eastern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Middle La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Middle La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Middle La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Eastern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Fire Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '25',
    location: 'Eastern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Western La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '25',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '40',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Coerthas Central Highlands',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '40',
    location: 'Coerthas Central Highlands',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Western La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Western La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Western La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '25',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Western La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '25',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '30',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '30',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '35',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Coerthas Central Highlands',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '45',
    location: 'Coerthas Central Highlands',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Western La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '35',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Ice Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '40',
    location: 'South Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '35',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Northern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'East Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '50',
    location: 'East Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '40',
    location: 'Northern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '10',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '20',
    location: 'East Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '50',
    location: 'East Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Wind Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '20',
    location: 'East Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '10',
    location: 'Lower La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '45',
    location: 'Upper La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '10',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '20',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Lower La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Lower La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '25',
    location: 'Upper La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Upper La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '45',
    location: 'Upper La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '10',
    location: 'Lower La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '20',
    location: 'Lower La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '10',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '30',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Lower La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '25',
    location: 'Upper La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '45',
    location: 'Upper La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Earth Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '30',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '35',
    location: 'Southern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Mor Dhona',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Southern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Southern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '35',
    location: 'Southern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Mor Dhona',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '35',
    location: 'Southern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Lightning Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Southern Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '35',
    location: 'Eastern La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '10',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Quarrying',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '30',
    location: 'Eastern La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '40',
    location: 'Eastern La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '50',
    location: 'Eastern La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '35',
    location: 'Eastern La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '40',
    location: 'Eastern La Noscea',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Water Shard',
    type: 'Harvesting',
    itemlevel: '1',
    nodelevel: '15',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Copper Ore',
    type: 'Mining',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Muddy Water',
    type: 'Mining',
    itemlevel: '3',
    nodelevel: '5',
    location: 'Western Thanalan',
    requirements: '',
    coords: ''
  },
  {
    name: 'Latex',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Latex',
    type: 'Logging',
    itemlevel: '1',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Allagan Snail',
    type: 'Logging',
    itemlevel: '2',
    nodelevel: '5',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Maple Sap',
    type: 'Logging',
    itemlevel: '2',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Maple Log',
    type: 'Logging',
    itemlevel: '3',
    nodelevel: '5',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Maple Log',
    type: 'Logging',
    itemlevel: '3',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Cinnamon',
    type: 'Logging',
    itemlevel: '4',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Maple Branch',
    type: 'Logging',
    itemlevel: '5',
    nodelevel: '5',
    location: 'Central Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Maple Branch',
    type: 'Logging',
    itemlevel: '5',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },
  {
    name: 'Beehive Chip',
    type: 'Logging',
    itemlevel: '5',
    nodelevel: '5',
    location: 'North Shroud',
    requirements: '',
    coords: ''
  },

























];
