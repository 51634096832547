import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit {
  name = 'sharlayan.org';

  constructor() {}

  ngOnInit() { }

  ngAfterViewInit(): void {
    //FFXIV EVENTS
    var testlogo = true;
    var isHatchingTide = false;
    //DATE CHECK
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth() + 1;
    var currentDate = new Date().getDate();
    //LOGOS
    var logo2 = document.getElementById("alib-home-logo");
    var logo = document.getElementById("alib-rdck-logo");
    var easterLogo = 'url("assets/img/logos/logo-easter.webp")';
    //LOGO
    //INGAME EVENTS
    if (isHatchingTide) {
      logo.style.backgroundImage = easterLogo;
    }
    //IRL HOLIDAYS
    //EASTER
    if (currentYear === 2021 && currentMonth === 4 && currentDate === 4) {logo.style.backgroundImage = easterLogo}
    if (currentYear === 2022 && currentMonth === 4 && currentDate === 17) {logo.style.backgroundImage = easterLogo}
    if (currentYear === 2023 && currentMonth === 4 && currentDate === 9) {logo.style.backgroundImage = easterLogo}
  }
}
