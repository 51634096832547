    <div class="g-left-sidebar" style="overflow: hidden">
        <a routerLink="gladiator">
            <div class="button-sq" id="gladiator" style="background-image:url('assets/img/icons/class-gladiator.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="pugilist">
            <div class="button-sq" id="pugilist" style="background-image:url('assets/img/icons/class-pugilist.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="marauder">
            <div class="button-sq" id="marauder" style="background-image:url('assets/img/icons/class-marauder.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="lancer">
            <div class="button-sq" id="lancer" style="background-image:url('assets/img/icons/class-lancer.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="archer">
            <div class="button-sq" id="archer" style="background-image:url('assets/img/icons/class-archer.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="rogue">
            <div class="button-sq" id="rogue" style="background-image:url('assets/img/icons/class-rogue.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="conjurer">
            <div class="button-sq" id="conjurer" style="background-image:url('assets/img/icons/class-conjurer.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="thaumaturge">
            <div class="button-sq" id="thaumaturge" style="background-image:url('assets/img/icons/class-thaumaturge.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="arcanist">
            <div class="button-sq" id="arcanist" style="background-image:url('assets/img/icons/class-arcanist.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="immortalflames">
            <div class="button-sq" id="immortalflames" style="background-image:url('assets/img/icons/gc-immortalflames.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="maelstrom">
            <div class="button-sq" id="maelstrom" style="background-image:url('assets/img/icons/gc-maelstrom.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <a routerLink="twinadder">
            <div class="button-sq" id="twinadder" style="background-image:url('assets/img/icons/gc-twinadder.webp')" (click)="btnShowClassMenu($event)"></div>
        </a>
        <router-outlet></router-outlet>
    </div>
    <div id="map-viewer" style="position:relative;width:85.5vh;height:85.5vh;margin-top:6vh">
    </div>