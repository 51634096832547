<a href="https://canary.discord.com/api/oauth2/authorize?client_id=740237498982203444&redirect_uri=https%3A%2F%2Fwww.sharlayan.org%2Flogin&response_type=code&scope=identify" style="width:100%;height:100%;">
    <div class="oauth-button" id="DOA-login">
        <p>LOG IN</p>
    </div>
</a>
<div class="oauth-button" id="DOA-loggedin" (click)="oAuthLogOut()">
    <div id="oauth-avatar"></div>
    <div id="oauth-username">
        <p></p>
    </div>
    <div id="oauth-logout"><p>LOG OUT</p></div>
</div>