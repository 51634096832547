import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fhyur-midlander',
  templateUrl: './fhyur-midlander.component.html',
  styleUrls: ['./fhyur-midlander.component.less']
})
export class FhyurMidlanderComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Female Hyur Midlander Character Voices'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, ffxiv female hyur midlander character voices, ffxiv hyur voices'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Female Hyur Midlander Voices - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }

  playSound(event) {
    var clickedid = event.currentTarget.id;
    var volumeAmount = (parseInt((document.getElementById("charactervoicesVolume") as HTMLInputElement).value)) / 100;
    var audioFileUrl = ('assets/audio/charactervoices/') + clickedid + ('.mp3');
    var audioFile = new Audio(audioFileUrl);
    audioFile.pause();
    audioFile.currentTime = 0;
    audioFile.volume = volumeAmount;
    audioFile.play();
  }
}
