<h1 class="seoh1">Final Fantasy XIV - Marauder Hunting Log Locations</h1>
<div id="marauder-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-marauder">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MiddleLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="20.9,23.6,42,5;22.2,24.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/LittleLadybug.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Little Ladybug</p></div>
                                <div class="level">Lv 1-2</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="22.4,24.0,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wharf Rat</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.6,21.6,42,5;23.3,20.3,42,5;21.1,20.4,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Bee Cloud</p></div>
                                <div class="level">Lv 4-6</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="21.7,17.9,42,8;19.2,15.4,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mandragora.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Tiny Mandragora</p></div>
                                <div class="level">Lv 5-7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.3,18.7,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bogy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Bogy</p></div>
                                <div class="level">Lv 7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="18.6,17.3,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Buffalo.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wounded Aurochs</p></div>
                                <div class="level">Lv 8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.1,17.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Grounded Raider</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="15.7,13.9,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Crab.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Megalocrab</p></div>
                                <div class="level">Lv 10</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="LowerLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">LOWER LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="25.0,27.2,42,8;26.1,22.4,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aurelia.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Aurelia</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="28.6,20.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dodo.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wild Dodo</p></div>
                                <div class="level">Lv 4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="LowerLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">LOWER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.3,37.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Firefly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Firefly</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="28.9,19.3,42,3;25.6,22.3,42,3;22.8,24.6,42,3;23.3,23.1,42,3;25.4,26.3,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mossless Goobbue</p></div>
                            <div class="level">Lv 12-17</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="20.8,34.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SnappingShrew.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Moraby Mole</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="18.3,34.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Eggdigger</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="33.5,28.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dodo.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Fat Dodo</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="33.3,27.4,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Rhotano Buccaneer</p></div>
                            <div class="level">Lv 14</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="26.6,24.7,42,4;24.4,23.5,42,4;25.8,23.4,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Dusk Bat</p></div>
                            <div class="level">Lv 15-16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="26.4,23.4,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/TucoTuco.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Hedgemole</p></div>
                            <div class="level">Lv 15</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="24.3,22.9,42,5;23.5,24.3,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Rothlyt Pelican</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="21.5,23.0,42,5;22.8,20.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mantis.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Killer Mantis</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.3,25.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Wild Wolf</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.3,24.4,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Thickshell.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Stoneshell</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.4,7.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Yarzon Scavenger</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="24.5,22.2,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Fleece.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Phurble</p></div>
                            <div class="level">Lv 25-29</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="24.1,18.5,42,5;26.7,17.7,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Axe Beak</p></div>
                            <div class="level">Lv 25-26</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="21.1,8.6,42,4;15.0,14.3,42,6;16.6,15.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Cactuar.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sabotender</p></div>
                            <div class="level">Lv 25-27</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="16.3,12.9,42,5;18.9,10.4,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Basilisk.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sandskin Peiste</p></div>
                            <div class="level">Lv 26-28</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="16.4,23.3,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Diseased Treant</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.4,18.3,42,4;24.5,18.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CoeurlclawCutter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redbelly Larcener</p></div>
                            <div class="level">Lv 27</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="17.2,27.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Slug.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Shroud Hare</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="21.3,30.4,42,7;19.2,28.7,42,4;17.6,28.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Balloon</p></div>
                            <div class="level">Lv 25-28</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.4,18.1,42,5;14.2,17.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mushroom.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Toadstool</p></div>
                            <div class="level">Lv 29-32</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="11.1,22.9,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/FloatingEye.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Floating Eye</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="24.1,21.1,42,6;19.0,35.0,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Grass Raptor</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="31.1,33.7,42,5;30.8,35.6,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Crab.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Snipper</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="31.4,26.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aurelia.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bloodshore Bell</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="18.7,28.0,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Jungle Coeurl</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="22.2,14.1,42,6;22.9,11.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Grenade</p></div>
                            <div class="level">Lv 41-44</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.4,35.9,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Smoke Bomb</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.4,22.3,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Morbol.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Stroper</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.3,29.4,42,8;15.7,30.4,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Tortoise.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Adamantoise</p></div>
                            <div class="level">Lv 31-33</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.1,27.4,42,6;21.4,29.0,42,5;29.9,14.6,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Snow Wolf Pup</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="25.9,20.1,42,9;22.2,16.5,42,4;28.2,13.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ornery Karakul</p></div>
                            <div class="level">Lv 36-39</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="26.7,19.6,42,4;24.7,20.6,42,4;28.3,23.4,42,4;25.1,25.9,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Highland Goobbue</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="24.8,13.1,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ogre.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redhorn Ogre</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="26.9,10.3,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aevis.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Downy Aevis</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="23.6,18.2,42,4;24.2,16.1,42,4;22.5,17.1,42,4;20.3,16.0,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Snowstorm Goobbue</p></div>
                            <div class="level">Lv 39</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="15.4,35.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Skeleton.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dead Man's Moan</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">OUTER LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.9,7.1,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Synthetic Doblyn</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="25.6,20.9,42,3;27.4,21.1,42,3;30.2,21.1,42,3;30.1,19.3,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>2nd Cohort Laquearius</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="28.2,25.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Quartz Doblyn</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.7,23.1,42,3;20.5,23.8,42,3;16.2,25.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Tortoise.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Iron Tortoise</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="11.1,7.2,42,3;12.0,6.1,42,3;9.8,5.6,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>4th Cohort Secutor</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="26.9,24.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Molted Ziz</p></div>
                                <div class="level">Lv 40</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                        <div class="huntlog-element" data="23.3,21.0,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Morbol.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Morbol</p></div>
                                <div class="level">Lv 43</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="24.1,17.0,42,4;23.3,18.2,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Milkroot Cluster</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">CENTRAL SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="10.0,17.8,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Crater Golem</p></div>
                                <div class="level">Lv 43</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.7,24.8,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wild Hog</p></div>
                                <div class="level">Lv 45-46</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="19.7,19.5,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Watchwolf</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.7,30.1,42,7;15.4,29.6,42,7;11.3,28.6,42,7;7.0,28.6,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Biast.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Biast</p></div>
                                <div class="level">Lv 45-47</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="12.2,12.5,42,3;9.3,14.1,42,3;10.8,15.0,42,3;11.7,16.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>5th Cohort Signifer</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>