<h1 class="seoh1">Final Fantasy XIV - Mini Cactpot Solver</h1>
<div id="content">
    <div id="table">
        <div class="square" id="sqnw">
            <div class="icon sqnw"></div>
        </div>
        <div class="square" id="sqn1">
            <div class="icon sqn"></div>
        </div>
        <div class="square" id="sqn2">
            <div class="icon sqn"></div>
        </div>
        <div class="square" id="sqn3">
            <div class="icon sqn"></div>
        </div>
        <div class="square" id="sqne">
            <div class="icon sqne"></div>
        </div>
        
        <div class="square" id="sqw1">
            <div class="icon"></div>
        </div>
        <div class="square" id="cnw">
            <div class="numsel hidden" id="numsel00"></div>
            <div class="numpad" id="input00" data-input="0">
                <div class="num" (click)="inputDetected('001')">1</div>
                <div class="num" (click)="inputDetected('002')">2</div>
                <div class="num" (click)="inputDetected('003')">3</div>
                <div class="num" (click)="inputDetected('004')">4</div>
                <div class="num" (click)="inputDetected('005')">5</div>
                <div class="num" (click)="inputDetected('006')">6</div>
                <div class="num" (click)="inputDetected('007')">7</div>
                <div class="num" (click)="inputDetected('008')">8</div>
                <div class="num" (click)="inputDetected('009')">9</div>
            </div>
        </div>
        <div class="square" id="cn">
            <div class="numsel hidden" id="numsel01"></div>
            <div class="numpad" id="input01" data-input="0">
                <div class="num" (click)="inputDetected('011')">1</div>
                <div class="num" (click)="inputDetected('012')">2</div>
                <div class="num" (click)="inputDetected('013')">3</div>
                <div class="num" (click)="inputDetected('014')">4</div>
                <div class="num" (click)="inputDetected('015')">5</div>
                <div class="num" (click)="inputDetected('016')">6</div>
                <div class="num" (click)="inputDetected('017')">7</div>
                <div class="num" (click)="inputDetected('018')">8</div>
                <div class="num" (click)="inputDetected('019')">9</div>
            </div>
        </div>
        <div class="square" id="cne">
            <div class="numsel hidden" id="numsel02"></div>
            <div class="numpad" id="input02" data-input="0">
                <div class="num" (click)="inputDetected('021')">1</div>
                <div class="num" (click)="inputDetected('022')">2</div>
                <div class="num" (click)="inputDetected('023')">3</div>
                <div class="num" (click)="inputDetected('024')">4</div>
                <div class="num" (click)="inputDetected('025')">5</div>
                <div class="num" (click)="inputDetected('026')">6</div>
                <div class="num" (click)="inputDetected('027')">7</div>
                <div class="num" (click)="inputDetected('028')">8</div>
                <div class="num" (click)="inputDetected('029')">9</div>
            </div>
        </div>
        <div class="none"></div>

        <div class="square" id="sqw2">
            <div class="icon"></div>
        </div>
        <div class="square" id="cw">
            <div class="numsel hidden" id="numsel10"></div>
            <div class="numpad" id="input10" data-input="0">
                <div class="num" (click)="inputDetected('101')">1</div>
                <div class="num" (click)="inputDetected('102')">2</div>
                <div class="num" (click)="inputDetected('103')">3</div>
                <div class="num" (click)="inputDetected('104')">4</div>
                <div class="num" (click)="inputDetected('105')">5</div>
                <div class="num" (click)="inputDetected('106')">6</div>
                <div class="num" (click)="inputDetected('107')">7</div>
                <div class="num" (click)="inputDetected('108')">8</div>
                <div class="num" (click)="inputDetected('109')">9</div>
            </div>
        </div>
        <div class="square" id="cc">
            <div class="numsel hidden" id="numsel11"></div>
            <div class="numpad" id="input11" data-input="0">
                <div class="num" (click)="inputDetected('111')">1</div>
                <div class="num" (click)="inputDetected('112')">2</div>
                <div class="num" (click)="inputDetected('113')">3</div>
                <div class="num" (click)="inputDetected('114')">4</div>
                <div class="num" (click)="inputDetected('115')">5</div>
                <div class="num" (click)="inputDetected('116')">6</div>
                <div class="num" (click)="inputDetected('117')">7</div>
                <div class="num" (click)="inputDetected('118')">8</div>
                <div class="num" (click)="inputDetected('119')">9</div>
            </div>
        </div>
        <div class="square" id="ce">
            <div class="numsel hidden" id="numsel12"></div>
            <div class="numpad" id="input12" data-input="0">
                <div class="num" (click)="inputDetected('121')">1</div>
                <div class="num" (click)="inputDetected('122')">2</div>
                <div class="num" (click)="inputDetected('123')">3</div>
                <div class="num" (click)="inputDetected('124')">4</div>
                <div class="num" (click)="inputDetected('125')">5</div>
                <div class="num" (click)="inputDetected('126')">6</div>
                <div class="num" (click)="inputDetected('127')">7</div>
                <div class="num" (click)="inputDetected('128')">8</div>
                <div class="num" (click)="inputDetected('129')">9</div>
            </div>
        </div>
        <div class="none"></div>

        <div class="square" id="sqw3">
            <div class="icon"></div>
        </div>
        <div class="square" id="csw">
            <div class="numsel hidden" id="numsel20"></div>
            <div class="numpad" id="input20" data-input="0">
                <div class="num" (click)="inputDetected('201')">1</div>
                <div class="num" (click)="inputDetected('202')">2</div>
                <div class="num" (click)="inputDetected('203')">3</div>
                <div class="num" (click)="inputDetected('204')">4</div>
                <div class="num" (click)="inputDetected('205')">5</div>
                <div class="num" (click)="inputDetected('206')">6</div>
                <div class="num" (click)="inputDetected('207')">7</div>
                <div class="num" (click)="inputDetected('208')">8</div>
                <div class="num" (click)="inputDetected('209')">9</div>
            </div>
        </div>
        <div class="square" id="cs">
            <div class="numsel hidden" id="numsel21"></div>
            <div class="numpad" id="input21" data-input="0">
                <div class="num" (click)="inputDetected('211')">1</div>
                <div class="num" (click)="inputDetected('212')">2</div>
                <div class="num" (click)="inputDetected('213')">3</div>
                <div class="num" (click)="inputDetected('214')">4</div>
                <div class="num" (click)="inputDetected('215')">5</div>
                <div class="num" (click)="inputDetected('216')">6</div>
                <div class="num" (click)="inputDetected('217')">7</div>
                <div class="num" (click)="inputDetected('218')">8</div>
                <div class="num" (click)="inputDetected('219')">9</div>
            </div>
        </div>
        <div class="square" id="cse">
            <div class="numsel hidden" id="numsel22"></div>
            <div class="numpad" id="input22" data-input="0">
                <div class="num" (click)="inputDetected('221')">1</div>
                <div class="num" (click)="inputDetected('222')">2</div>
                <div class="num" (click)="inputDetected('223')">3</div>
                <div class="num" (click)="inputDetected('224')">4</div>
                <div class="num" (click)="inputDetected('225')">5</div>
                <div class="num" (click)="inputDetected('226')">6</div>
                <div class="num" (click)="inputDetected('227')">7</div>
                <div class="num" (click)="inputDetected('228')">8</div>
                <div class="num" (click)="inputDetected('229')">9</div>
            </div>
        </div>
        <div class="none"></div>

        <div class="none"></div>
        <div class="none"></div>
        <div class="square" style="cursor:pointer" (click)="clearAll()"><p>RESET</p></div>
        <div class="none"></div>
        <div class="none"></div>
    </div>
</div>