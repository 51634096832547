<h1 class="seoh1">Final Fantasy XIV - Female Hyur Highlander Voice Browser</h1>
<div class="alib-pagetitle">HYUR HIGHLANDER</div>
<div class="alib-charactervoices-content">
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 1</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh01combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh01combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh01combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh01combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh01combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh01combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh01yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh01no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh01chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh01laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh01happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh01cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh01huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh01surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh01upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh01fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh01" (click)="playSound($event)"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh01furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh01stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh01doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 2</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh02combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh02combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh02combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh02combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh02combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh02combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh02yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh02no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh02chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh02laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh02happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh02cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh02huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh02surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh02upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh02fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh02angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh02furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh02stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh02doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 3</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh03combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh03combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh03combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh03combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh03combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh03combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh03yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh03no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh03chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh03laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh03happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh03cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh03huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh03surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh03upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh03fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh03angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh03furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh03stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh03doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 4</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh04combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh04combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh04combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh04combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh04combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh04combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh04yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh04no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh04chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh04laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh04happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh04cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh04huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh04surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh04upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh04fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh04angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh04furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh04stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh04doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 5</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh05combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh05combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh05combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh05combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh05combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh05combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh05yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh05no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh05chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh05laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh05happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh05cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh05huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh05surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh05upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh05fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh05angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh05furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh05stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh05doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 6</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh06combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh06combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh06combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh06combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh06combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh06combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh06yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh06no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh06chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh06laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh06happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh06cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh06huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh06surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh06upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh06fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh06angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh06furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh06stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh06doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 7</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh07combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh07combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh07combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh07combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh07combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh07combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh07yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh07no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh07chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh07laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh07happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh07cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh07huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh07surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh07upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh07fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh07angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh07furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh07stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh07doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 8</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh08combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh08combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh08combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh08combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh08combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh08combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh08yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh08no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh08chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh08laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh08happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh08cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh08huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh08surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh08upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh08fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh08angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh08furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh08stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh08doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 9</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh09combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh09combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh09combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh09combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh09combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh09combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh09yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh09no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh09chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh09laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh09happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh09cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh09huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh09surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh09upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh09fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh09angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh09furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh09stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh09doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 10</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh10combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh10combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh10combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh10combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh10combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh10combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh10yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh10no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh10chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh10laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh10happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh10cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh10huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh10surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh10upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh10fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh10angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh10furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh10stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh10doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 11</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh11combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh11combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh11combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh11combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh11combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh11combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh11yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh11no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh11chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh11laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh11happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh11cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh11huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh11surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh11upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh11fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh11angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh11furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh11stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh11doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 12</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh12combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh12combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh12combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh12combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh12combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fhh12combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fhh12yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fhh12no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fhh12chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fhh12laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fhh12happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fhh12cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fhh12huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fhh12surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fhh12upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fhh12fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fhh12angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fhh12furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fhh12stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fhh12doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
