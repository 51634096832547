import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CheckboxControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-discordoauth',
  templateUrl: './discordoauth.component.html',
  styleUrls: ['./discordoauth.component.less']
})
export class DiscordoauthComponent implements OnInit {

  userInfo: string;

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void { }

  ngAfterContentInit(): void {
    var oauthToken = window.location.href.split("=")[1];
    if (oauthToken !== undefined) {
      this.getOAuthData();
    }
    for (var i = 0; i < document.cookie.split(";").length; i++) {
      if (document.cookie.split(";")[i].includes("DiscordOAuth")) {
        this.userInfo = document.cookie.split(";")[i].split("=")[1];
        var cookiesOAuthInfo = document.cookie.split(";")[i];
        var loginButton = document.getElementById("DOA-login");
        var loggedinButton = document.getElementById("DOA-loggedin");
        var loggedinAvatar = document.getElementById("oauth-avatar");
        var loggedinUsername = document.getElementById("oauth-username");
        loginButton.style.display = "none";
        loggedinButton.style.display = "block";
        loggedinUsername.children[0].innerHTML = cookiesOAuthInfo.split(",")[1];
        loggedinAvatar.style.backgroundImage = "url('https://cdn.discordapp.com/avatars/" + cookiesOAuthInfo.split(",")[0].split("=")[1] + "/" + cookiesOAuthInfo.split(",")[2] + ".png?size=64')";
        this.checkUser(this.userInfo.split(",")[0]);
      }
    }
  }

  setOAuthCookies() {
    document.cookie = "DiscordOAuth=" + this.userInfo;
    this.checkUser(this.userInfo.split(",")[0])
  }

  getOAuthData() {
    var fetchedData;
    var oauthToken = window.location.href.split("=")[1];
    const data = {
      client_id: '740237498982203444',
      client_secret: 'EOHHqyVGC34fA4bpSkXZi86N_7K4rK_U',
      grant_type: 'authorization_code',
      redirect_uri: 'https://www.sharlayan.org/login',
      code: oauthToken,
      scope: 'identity',
    }
    fetch('https://discord.com/api/oauth2/token', {
      method: 'POST',
      body: new URLSearchParams(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(res => res.json())
    .then(data => fetchedData = data)
    .then(() => fetch('https://discord.com/api/oath2/token', {
      method: 'POST',
      body: fetchedData,
    }))
    .then(res => res.json())
    .then(info => fetch('https://discord.com/api/users/@me', {
      headers: {
        authorization: `${fetchedData.token_type} ${fetchedData.access_token}`,
      },
    }))
    .then(res => res.json())
    .then(data => this.userInfo = data.id + "," + data.username + "," + data.avatar)
    .then(() => this.setOAuthCookies())
  }

  oAuthLogOut() {
    document.cookie = "DiscordOAuth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    location.reload();
  }

  checkUser(id) {
    var payload = "https://agora.sharlayan.org/checkuser.php?id=" + id;
    return this.httpClient.get(payload)
      .subscribe(response => {
        if (response == true) {
          this.getUserData(id);
          console.log("UserID found in Agara.");
        } else if (response == false) {
          this.createUser(id);
        } else {
        }
      });
  }

  createUser(id) {
    var data01 = localStorage.getItem('ownedCards');
    if (data01 != null) {
      var payload = "https://agora.sharlayan.org/createuser.php?id=" + id + "&data01=" + data01;
      this.httpClient.post<any>(payload, { title: '9875123' })
      .subscribe();
    } else {
      var payload = "https://agora.sharlayan.org/createuser.php?id=" + id;
      this.httpClient.post<any>(payload, { title: '9875123' })
      .subscribe();
    }
  }

  getUserData(id) {
    console.log("Fetching user data");
    var payload = "https://agora.sharlayan.org/getuserdata.php?id=" + id;
    return this.httpClient.get(payload)
      .subscribe(response => {
        if (response["data01"] != null) {
          console.log("Triad Card Checklist data found, retrieving")
          localStorage.setItem('ownedCards', response["data01"]);
        }
      })
  }
}
