import { SightseeingLog } from './sightseeinglog';

export const SIGHTSEEINGLOG: SightseeingLog[] = [
    {
        id: 1,
        dbid: 1,
        name: "Barracuda Piers",
        location: "Limsa Lominsa Upper Decks",
        coords: "9.6,7.8",
        maxcoords: 21.4,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 2,
        dbid: 2,
        name: "The Astalicia",
        location: "Limsa Lominsa Lower Decks",
        coords: "7.1,15.1",
        maxcoords: 21.4,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 3,
        dbid: 3,
        name: "Seasong Grotto",
        location: "Middle La Noscea",
        coords: "20.3,19.1",
        maxcoords: 42,
        weather1: "Rain",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/pray",
        patch: 1,
    },
    {
        id: 4,
        dbid: 4,
        name: "The Skylift",
        location: "Middle La Noscea",
        coords: "16.1,17.4",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 5,
        dbid: 5,
        name: "La Thagran Eastroad",
        location: "Middle La Noscea",
        coords: "25.4,27.6",
        maxcoords: 42,
        weather1: "Clouds",
        weather2: "",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 6,
        dbid: 6,
        name: "The Salt Strand",
        location: "Lower La Noscea",
        coords: "23.1,40.2",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 7,
        dbid: 7,
        name: "Red Rooster Stead",
        location: "Lower La Noscea",
        coords: "33.5,19.3",
        maxcoords: 42,
        weather1: "Fog",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 8,
        dbid: 8,
        name: "Brewer's Beacon",
        location: "Western La Noscea",
        coords: "30,30.8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 9,
        dbid: 9,
        name: "The Leatherworkers' Guild",
        location: "Old Gridania",
        coords: "12.9,7.9",
        maxcoords: 21.4,
        weather1: "Clouds",
        weather2: "",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 10,
        dbid: 10,
        name: "Apkallu Falls",
        location: "Old Gridania",
        coords: "10.8,6.4",
        maxcoords: 21.4,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 11,
        dbid: 11,
        name: "Bentbranch Meadows",
        location: "Central Shroud",
        coords: "21.8,21.9",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/sit",
        patch: 1,
    },
    {
        id: 12,
        dbid: 12,
        name: "The Sanctum of the Twelve",
        location: "East Shroud",
        coords: "17.7,18.2",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/pray",
        patch: 1,
    },
    {
        id: 13,
        dbid: 13,
        name: "Little Solace",
        location: "East Shroud",
        coords: "22.3,26.3",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 14,
        dbid: 14,
        name: "Royal Promenade",
        location: "Ul'dah - Steps of Thal",
        coords: "11.2,11.6",
        maxcoords: 21.4,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/salute",
        patch: 1,
    },
    {
        id: 15,
        dbid: 15,
        name: "The Gold Court",
        location: "Ul'dah - Steps of Thal",
        coords: "11.6,11.2",
        maxcoords: 21.4,
        weather1: "Clouds",
        weather2: "",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 16,
        dbid: 16,
        name: "The Jewel of the Desert",
        location: "Western Thanalan",
        coords: "22.4,22.4",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 17,
        dbid: 17,
        name: "The Ruins of Sil'dih",
        location: "Central Thanalan",
        coords: "16,23",
        maxcoords: 42,
        weather1: "Fog",
        weather2: "",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 18,
        dbid: 18,
        name: "The Lonely Giant",
        location: "Eastern Thanalan",
        coords: "19.6,24.7",
        maxcoords: 42,
        weather1: "Rain",
        weather2: "Showers",
        starttime: "17:00",
        endtime: "18:00",
        emote: "/comfort",
        patch: 1,
    },
    {
        id: 19,
        dbid: 19,
        name: "The Invisible City",
        location: "Eastern Thanalan",
        coords: "14.3,18.6",
        maxcoords: 42,
        weather1: "Clouds",
        weather2: "",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 20,
        dbid: 20,
        name: "Highbridge",
        location: "Eastern Thanalan",
        coords: "21.1,20.8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/pray",
        patch: 1,
    },
    {
        id: 21,
        dbid: 21,
        name: "Woad Whisper Canyon",
        location: "Middle La Noscea",
        coords: "20.1,13.2",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 22,
        dbid: 22,
        name: "Summerford Farms",
        location: "Middle La Noscea",
        coords: "25.8,17",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 23,
        dbid: 23,
        name: "The Grey Fleet",
        location: "Lower La Noscea",
        coords: "31.6,12.8",
        maxcoords: 42,
        weather1: "Rain",
        weather2: "",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 24,
        dbid: 24,
        name: "Hidden Falls",
        location: "Eastern La Noscea",
        coords: "32.7,23.5",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/sit",
        patch: 1,
    },
    {
        id: 25,
        dbid: 25,
        name: "Gullperch Tower",
        location: "Eastern La Noscea",
        coords: "29.6,33.8",
        maxcoords: 42,
        weather1: "Rain",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 26,
        dbid: 26,
        name: "The Navigator",
        location: "Western La Noscea",
        coords: "26.9,26.6",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "17:00",
        endtime: "18:00",
        emote: "/pray",
        patch: 1,
    },
    {
        id: 27,
        dbid: 27,
        name: "The Ship Graveyard",
        location: "Western La Noscea",
        coords: "17.1,36.2",
        maxcoords: 42,
        weather1: "Gales",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 28,
        dbid: 28,
        name: "Camp Skull Valley",
        location: "Western La Noscea",
        coords: "22.8,22.4",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 29,
        dbid: 29,
        name: "Tidegate",
        location: "Western La Noscea",
        coords: "19.4,23",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 30,
        dbid: 30,
        name: "Camp Bronze Lake",
        location: "Upper La Noscea",
        coords: "30.8,22.4",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "17:00",
        endtime: "18:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 31,
        dbid: 31,
        name: "Thalaos",
        location: "Upper La Noscea",
        coords: "12.9,22",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 32,
        dbid: 32,
        name: "Jijiroon's Trading Post",
        location: "Upper La Noscea",
        coords: "29,25.5",
        maxcoords: 42,
        weather1: "Thunderstorms",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 33,
        dbid: 33,
        name: "The Floating City of Nym",
        location: "Outer La Noscea",
        coords: "12.7,15.1",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 34,
        dbid: 34,
        name: "Camp Overlook",
        location: "Outer La Noscea",
        coords: "17,16",
        maxcoords: 42,
        weather1: "Clouds",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 35,
        dbid: 35,
        name: "U'Ghamaro Mines",
        location: "Outer La Noscea",
        coords: "23.4,11.8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 36,
        dbid: 36,
        name: "The Hermit's Hovel",
        location: "Outer La Noscea",
        coords: "15.4,10.1",
        maxcoords: 42,
        weather1: "Rain",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/sit",
        patch: 1,
    },
    {
        id: 37,
        dbid: 37,
        name: "The Carline Canopy",
        location: "New Gridania",
        coords: "14.3,14.3",
        maxcoords: 21.4,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 38,
        dbid: 38,
        name: "The Lancer's Guild",
        location: "Old Gridania",
        coords: "14.3,6",
        maxcoords: 21.4,
        weather1: "Rain",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 39,
        dbid: 39,
        name: "The Bannock",
        location: "Central Shroud",
        coords: "23.4,19.9",
        maxcoords: 42,
        weather1: "Rain",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 40,
        dbid: 40,
        name: "Haukke Manor",
        location: "Central Shroud",
        coords: "13.6,23.1",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 41,
        dbid: 41,
        name: "The Guardian Tree",
        location: "CentralShroud",
        coords: "16.4,22.4",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 42,
        dbid: 42,
        name: "Rainbow Bridge",
        location: "Central Shroud",
        coords: "26.5,18.8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "11:00",
        endtime: "13:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 43,
        dbid: 43,
        name: "The Seedbed",
        location: "East Shroud",
        coords: "21,10.7",
        maxcoords: 42,
        weather1: "Thunder",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 44,
        dbid: 44,
        name: "Buscarron's Druthers",
        location: "South Shroud",
        coords: "17.8,20.1",
        maxcoords: 42,
        weather1: "Thunderstorms",
        weather2: "",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 45,
        dbid: 45,
        name: "South Shroud Landing",
        location: "South Shroud",
        coords: "14.7,33.9",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 46,
        dbid: 46,
        name: "Urth's Gift",
        location: "South Shroud",
        coords: "33.3,23.9",
        maxcoords: 42,
        weather1: "Fog",
        weather2: "",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 47,
        dbid: 47,
        name: "Quarrymill",
        location: "South Shroud",
        coords: "25.4,21.1",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 48,
        dbid: 48,
        name: "Ixali Logging Grounds",
        location: "North Shroud",
        coords: "18.5,19.6",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 49,
        dbid: 49,
        name: "Fallen Neurolink",
        location: "North Shroud",
        coords: "16,32",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 50,
        dbid: 50,
        name: "Aldel Springs",
        location: "North Shroud",
        coords: "15.5,27.4",
        maxcoords: 42,
        weather1: "Clouds",
        weather2: "",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 51,
        dbid: 51,
        name: "Castrum Marinum",
        location: "Western Thanalan",
        coords: "8.8,5.2",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "17:00",
        endtime: "18:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 52,
        dbid: 52,
        name: "Vesper Bay",
        location: "Western Thanalan",
        coords: "12.4,14.8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/point",
        patch: 1,
    },
    {
        id: 53,
        dbid: 53,
        name: "Black Brush Station",
        location: "Central Thanalan",
        coords: "21.4,17.5",
        maxcoords: 42,
        weather1: "DustStorms",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 54,
        dbid: 54,
        name: "Gate of Nald",
        location: "Central Thanalan",
        coords: "18.5,26.1",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/sit",
        patch: 1,
    },
    {
        id: 55,
        dbid: 55,
        name: "The Burning Wall",
        location: "Eastern Thanalan",
        coords: "30.7,26.5",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 56,
        dbid: 56,
        name: "The Golden Bazaar",
        location: "Eastern Thanalan",
        coords: "10,16.7",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 57,
        dbid: 57,
        name: "Thal's Respite",
        location: "Eastern Thanalan",
        coords: "25.2,14.7",
        maxcoords: 42,
        weather1: "Showers",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/pray",
        patch: 1,
    },
    {
        id: 58,
        dbid: 58,
        name: "Nald's Reflection",
        location: "Southern Thanalan",
        coords: "12.2,22.9",
        maxcoords: 42,
        weather1: "Fog",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/pray",
        patch: 1,
    },
    {
        id: 59,
        dbid: 59,
        name: "Zahar'ak",
        location: "Southern Thanalan",
        coords: "19.3,20.5",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 60,
        dbid: 60,
        name: "The Sagolii Desert",
        location: "Southern Thanalan",
        coords: "21.3,38.6",
        maxcoords: 42,
        weather1: "HeatWaves",
        weather2: "",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 61,
        dbid: 61,
        name: "The Sunken Temple of Qarn",
        location: "Southern Thanalan",
        coords: "23.8,11.8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 62,
        dbid: 62,
        name: "Minotaur Malm",
        location: "Southern Thanalan",
        coords: "14.6,26.5",
        maxcoords: 42,
        weather1: "HeatWaves",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/psych",
        patch: 1,
    },
    {
        id: 63,
        dbid: 63,
        name: "East Watchtower",
        location: "Northern Thanalan",
        coords: "22.2,25.7",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/salute",
        patch: 1,
    },
    {
        id: 64,
        dbid: 64,
        name: "Ceruleum Pipeline",
        location: "Northern Thanalan",
        coords: "20.7,29.6",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 65,
        dbid: 65,
        name: "Bluefog",
        location: "Northern Thanalan",
        coords: "20.8,22.5",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 66,
        dbid: 66,
        name: "Raubahn's Push",
        location: "Northern Thanalan",
        coords: "20,17.8",
        maxcoords: 42,
        weather1: "Clouds",
        weather2: "",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 67,
        dbid: 67,
        name: "Abandoned Amajina Mythril Mine",
        location: "Northern Thanalan",
        coords: "26.5,22.8",
        maxcoords: 42,
        weather1: "Fog",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 68,
        dbid: 68,
        name: "The Nail",
        location: "Coerthas Central Highlands",
        coords: "25.4,29.8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "17:00",
        endtime: "18:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 69,
        dbid: 69,
        name: "The Observatorium",
        location: "Coerthas Central Highlands",
        coords: "25.5,29.8",
        maxcoords: 42,
        weather1: "Fog",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 70,
        dbid: 70,
        name: "The Frozen Fang",
        location: "Coerthas Central Highlands",
        coords: "11.7,15.8",
        maxcoords: 42,
        weather1: "Blizzards",
        weather2: "",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 71,
        dbid: 71,
        name: "The Holy See of Ishgard",
        location: "Coerthas Central Highlands",
        coords: "12.8,17.3",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 72,
        dbid: 72,
        name: "Boulder Downs",
        location: "Coerthas Central Highlands",
        coords: "7.8,29",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "17:00",
        endtime: "18:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 73,
        dbid: 73,
        name: "The Fury's Gaze",
        location: "Coerthas Central Highlands",
        coords: "7.9,31.3",
        maxcoords: 42,
        weather1: "Blizzards",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 74,
        dbid: 74,
        name: "Snowcloak",
        location: "Coerthas Central Highlands",
        coords: "2.2,21.4",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "8:00",
        endtime: "12:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 75,
        dbid: 75,
        name: "Camp Dragonhead",
        location: "Coerthas Central Highlands",
        coords: "26.5,17.7",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 76,
        dbid: 76,
        name: "The Steel Vigil",
        location: "Coerthas Central Highlands",
        coords: "28.2,10.3",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 77,
        dbid: 77,
        name: "Castrum Centri",
        location: "Mor Dhona",
        coords: "9.8,13.5",
        maxcoords: 42,
        weather1: "Gloom",
        weather2: "",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 78,
        dbid: 78,
        name: "The Crystal Tower",
        location: "Mor Dhona",
        coords: "27.4,8",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 79,
        dbid: 79,
        name: "Rathefrost",
        location: "Mor Dhona",
        coords: "18.6,17.7",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "12:00",
        endtime: "17:00",
        emote: "/lookout",
        patch: 1,
    },
    {
        id: 80,
        dbid: 80,
        name: "The Keeper of the Lake",
        location: "Mor Dhona",
        coords: "26.1,11.2",
        maxcoords: 42,
        weather1: "FairSkies",
        weather2: "ClearSkies",
        starttime: "17:00",
        endtime: "18:00",
        emote: "/sit",
        patch: 1,
    },
    {
        id: 1,
        dbid: 81,
        name: "Falcon's Nest",
        location: "Coerthas Western Highlands",
        coords: "32.1,36.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 2,
        dbid: 82,
        name: "Camp Riversmeet",
        location: "Coerthas Western Highlands",
        coords: "20.8,23.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 3,
        dbid: 83,
        name: "The Dreaming Dragon",
        location: "Coerthas Western Highlands",
        coords: "10.4,18",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 4,
        dbid: 84,
        name: "The Dusk Vigil",
        location: "Coerthas Western Highlands",
        coords: "20.1,6.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 5,
        dbid: 85,
        name: "Gorgagne Mills",
        location: "Coerthas Western Highlands",
        coords: "31.8,4.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 6,
        dbid: 86,
        name: "Hemlock",
        location: "Coerthas Western Highlands",
        coords: "36.2,19.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 7,
        dbid: 87,
        name: "The Bed of Bones",
        location: "Coerthas Western Highlands",
        coords: "20.6,36.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 8,
        dbid: 88,
        name: "Loth ast Gnath",
        location: "The Dravanian Forelands",
        coords: "27.4,36.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 9,
        dbid: 89,
        name: "Anyx Minor",
        location: "The Dravanian Forelands",
        coords: "12,39.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 10,
        dbid: 90,
        name: "Anyx Trine",
        location: "The Dravanian Forelands",
        coords: "16.5,23.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 11,
        dbid: 91,
        name: "The Hundred Throes",
        location: "The Dravanian Forelands",
        coords: "29.6,6.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 12,
        dbid: 92,
        name: "Halo",
        location: "The Dravanian Forelands",
        coords: "8.2,6.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/pray",
        patch: 2,
    },
    {
        id: 13,
        dbid: 93,
        name: "Tailfeather",
        location: "The Dravanian Forelands",
        coords: "33.9,23.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 14,
        dbid: 94,
        name: "Sohm Al",
        location: "The Dravanian Forelands",
        coords: "11.5,13.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 15,
        dbid: 95,
        name: "Moghome",
        location: "The Churning Mists",
        coords: "29.4,35.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 16,
        dbid: 96,
        name: "The Aery",
        location: "The Churning Mists",
        coords: "29.2,13",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 17,
        dbid: 97,
        name: "Tharl Oom Khash",
        location: "The Churning Mists",
        coords: "18.6,6.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 18,
        dbid: 98,
        name: "Zenith",
        location: "The Churning Mists",
        coords: "7.8,27",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 19,
        dbid: 99,
        name: "The Lost Landlord",
        location: "The Churning Mists",
        coords: "17.6,37.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/pray",
        patch: 2,
    },
    {
        id: 20,
        dbid: 100,
        name: "The House of Letters",
        location: "The Churning Mists",
        coords: "35.1,20.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 21,
        dbid: 101,
        name: "The Rookery",
        location: "The Churning Mists",
        coords: "23.2,18.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 22,
        dbid: 102,
        name: "Camp Cloudtop",
        location: "The Sea of Clouds",
        coords: "15.2,37.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 23,
        dbid: 103,
        name: "The Nidifice",
        location: "The Sea of Clouds",
        coords: "37.2,40.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/doze",
        patch: 2,
    },
    {
        id: 24,
        dbid: 104,
        name: "Voor Sian Siran",
        location: "The Sea of Clouds",
        coords: "39.9,21.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 25,
        dbid: 105,
        name: "Mok Oogl Island",
        location: "The Sea of Clouds",
        coords: "13,8.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 26,
        dbid: 106,
        name: "Hall of the Fallen Plume",
        location: "The Sea of Clouds",
        coords: "18.4,27",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 27,
        dbid: 107,
        name: "Ok'Vundu Vana",
        location: "The Sea of Clouds",
        coords: "25,23.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 28,
        dbid: 108,
        name: "Hengr's Crucible",
        location: "The Sea of Clouds",
        coords: "38,11.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 29,
        dbid: 109,
        name: "The Arkhitekton",
        location: "The Dravanian Hinterlands",
        coords: "40.1,21.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 30,
        dbid: 110,
        name: "The Answering Quarter",
        location: "The Dravanian Hinterlands",
        coords: "17.9,23.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 31,
        dbid: 111,
        name: "The Cenotaph",
        location: "The Dravanian Hinterlands",
        coords: "22.1,27.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 32,
        dbid: 112,
        name: "The Tipped Ewer",
        location: "The Dravanian Hinterlands",
        coords: "10.1,35.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 33,
        dbid: 113,
        name: "Great Gubal Library",
        location: "The Dravanian Hinterlands",
        coords: "28.8,37.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 34,
        dbid: 114,
        name: "The Orn Wild",
        location: "The Dravanian Hinterlands",
        coords: "32.7,11.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 35,
        dbid: 115,
        name: "Saint Mocianne's Arboretum",
        location: "The Dravanian Hinterlands",
        coords: "13,21.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 36,
        dbid: 116,
        name: "The Gration",
        location: "Azys Lla",
        coords: "39.2,17",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 37,
        dbid: 117,
        name: "The Fractal Continuum",
        location: "Azys Lla",
        coords: "33.4,35.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 38,
        dbid: 118,
        name: "Anithesis",
        location: "Azys Lla",
        coords: "6,30.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 39,
        dbid: 119,
        name: "Aetherochemical Research Facility",
        location: "Azys Lla",
        coords: "10.9,35.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 40,
        dbid: 120,
        name: "Helix",
        location: "Azys Lla",
        coords: "6.1,9.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 41,
        dbid: 121,
        name: "Quarantine Block",
        location: "Azys Lla",
        coords: "9.4,21.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 42,
        dbid: 122,
        name: "Recombination Labs",
        location: "Azys Lla",
        coords: "30.4,11.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 43,
        dbid: 123,
        name: "The Pike",
        location: "Coerthas Western Highlands",
        coords: "32.6,28.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/rally",
        patch: 2,
    },
    {
        id: 44,
        dbid: 124,
        name: "Black Iron Bridge",
        location: "Coerthas Western Highlands",
        coords: "29.9,23.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 45,
        dbid: 125,
        name: "Dragonspit",
        location: "Coerthas Western Highlands",
        coords: "9.1,10.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/sit",
        patch: 2,
    },
    {
        id: 46,
        dbid: 126,
        name: "The Slate Mountains",
        location: "Coerthas Western Highlands",
        coords: "12.8,8.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/me",
        patch: 2,
    },
    {
        id: 47,
        dbid: 127,
        name: "Whilom River",
        location: "The Dravanian Forelands",
        coords: "23.4,39.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 48,
        dbid: 128,
        name: "Loth ast Vath",
        location: "The Dravanian Forelands",
        coords: "24.2,18.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 49,
        dbid: 129,
        name: "The Hissing Cobbles",
        location: "The Dravanian Forelands",
        coords: "34.3,15.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 50,
        dbid: 130,
        name: "The Danneroad",
        location: "The Dravanian Forelands",
        coords: "18.6,32.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 51,
        dbid: 131,
        name: "Statue of the Unsung",
        location: "The Churning Mists",
        coords: "33.8,32.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/pray",
        patch: 2,
    },
    {
        id: 52,
        dbid: 132,
        name: "Landlord Colony",
        location: "The Churning Mists",
        coords: "37.3,14.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 53,
        dbid: 133,
        name: "The Old Father",
        location: "The Churning Mists",
        coords: "14.7,25",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 54,
        dbid: 134,
        name: "Coldwind",
        location: "The Sea of Clouds",
        coords: "6.4,5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 55,
        dbid: 135,
        name: "The Shattered Back",
        location: "The Sea of Clouds",
        coords: "26.7,6.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 56,
        dbid: 136,
        name: "Provenance",
        location: "The Sea of Clouds",
        coords: "9.9,28.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 57,
        dbid: 137,
        name: "The Sage's Cataract",
        location: "The Dravanian Hinterlands",
        coords: "12.2,13",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 58,
        dbid: 138,
        name: "The Path of Knowing",
        location: "The Dravanian Hinterlands",
        coords: "19.7,38.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 59,
        dbid: 139,
        name: "The Daggers",
        location: "The Dravanian Hinterlands",
        coords: "30.4,31.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 60,
        dbid: 140,
        name: "Centrifugal Crystal Engine",
        location: "Azys Lla",
        coords: "10,14.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 61,
        dbid: 141,
        name: "Biomass Incubation Complex",
        location: "Azys Lla",
        coords: "35.5,6.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 62,
        dbid: 142,
        name: "The Cathedral",
        location: "Azys Lla",
        coords: "25.9,28.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 2,
    },
    {
        id: 1,
        dbid: 143,
        name: "Castellum Velodyna",
        location: "The Fringes",
        coords: "21.9,26.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 2,
        dbid: 144,
        name: "Gyr Kehim",
        location: "The Fringes",
        coords: "24.2,16.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 3,
        dbid: 145,
        name: "Schism",
        location: "The Fringes",
        coords: "23.2,7.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 4,
        dbid: 146,
        name: "Castrum Oriens",
        location: "The Fringes",
        coords: "9.3,10.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 5,
        dbid: 147,
        name: "Dimworld",
        location: "The Fringes",
        coords: "8.6,26.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 6,
        dbid: 148,
        name: "Djanan Qhat",
        location: "The Fringes",
        coords: "36.5,16.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 7,
        dbid: 149,
        name: "The Peering Stones",
        location: "The Fringes",
        coords: "30,25.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 8,
        dbid: 150,
        name: "Hidden Tear",
        location: "The Peaks",
        coords: "33.2,10.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 9,
        dbid: 151,
        name: "Coldhearth",
        location: "The Peaks",
        coords: "27,36.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 10,
        dbid: 152,
        name: "Nyunkrepf's Hope",
        location: "The Peaks",
        coords: "22,32.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 11,
        dbid: 153,
        name: "Ala Gannha",
        location: "The Peaks",
        coords: "25.1,5.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 12,
        dbid: 154,
        name: "Specula Imperatoris",
        location: "The Peaks",
        coords: "19.9,23.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 13,
        dbid: 155,
        name: "The Arms of Meed",
        location: "The Peaks",
        coords: "8.1,37.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 14,
        dbid: 156,
        name: "The Ziggurat",
        location: "The Peaks",
        coords: "18.3,14.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 15,
        dbid: 157,
        name: "Emprise",
        location: "The Peaks",
        coords: "7.5,7.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 16,
        dbid: 158,
        name: "Ala Mhigo",
        location: "The Lochs",
        coords: "23.6,33.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 17,
        dbid: 159,
        name: "The Ala Mhigan Quarter",
        location: "The Lochs",
        coords: "35.2,33.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 18,
        dbid: 160,
        name: "Sothwatch",
        location: "The Lochs",
        coords: "13.8,35.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 19,
        dbid: 161,
        name: "The Divine Audience",
        location: "The Lochs",
        coords: "20.6,16.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/pray",
        patch: 3,
    },
    {
        id: 20,
        dbid: 162,
        name: "The Hidden Tunnel",
        location: "The Lochs",
        coords: "33.9,30.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 21,
        dbid: 163,
        name: "Porta Praetoria",
        location: "The Lochs",
        coords: "5.9,22",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 22,
        dbid: 164,
        name: "The Sekiseigumi Barracks",
        location: "Kugane",
        coords: "14.3,9.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 23,
        dbid: 165,
        name: "Bokairo Inn",
        location: "Kugane",
        coords: "9.4,7.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/sit",
        patch: 3,
    },
    {
        id: 24,
        dbid: 166,
        name: "Kogane Dori",
        location: "Kugane",
        coords: "13.2,12.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 25,
        dbid: 167,
        name: "Kogane Alleyways",
        location: "Kugane",
        coords: "11.9,11.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 26,
        dbid: 168,
        name: "Shiokaze Hostelry",
        location: "Kugane",
        coords: "10,10",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 27,
        dbid: 169,
        name: "Tamamizu",
        location: "The Ruby Sea",
        coords: "25.9,13",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 28,
        dbid: 170,
        name: "Shoal Rock",
        location: "The Ruby Sea",
        coords: "32.9,8.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 29,
        dbid: 171,
        name: "Heaven-on-High",
        location: "The Ruby Sea",
        coords: "24.1,5.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 30,
        dbid: 172,
        name: "Sakazuki",
        location: "The Ruby Sea",
        coords: "31.6,37.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 31,
        dbid: 173,
        name: "The Isle of Zekki",
        location: "The Ruby Sea",
        coords: "10.1,26.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 32,
        dbid: 175,
        name: "Isari",
        location: "The Ruby Sea",
        coords: "6.4,10.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 33,
        dbid: 176,
        name: "The Swallow's Compass",
        location: "Yanxia",
        coords: "12.4,26.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 34,
        dbid: 177,
        name: "Castrum Fluminis",
        location: "Yanxia",
        coords: "30.3,32.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 35,
        dbid: 178,
        name: "Namai",
        location: "Yanxia",
        coords: "34.3,18.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 36,
        dbid: 179,
        name: "Prism Lake",
        location: "Yanxia",
        coords: "30.4,6.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 37,
        dbid: 180,
        name: "Doma Castle",
        location: "Yanxia",
        coords: "14.9,6.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 38,
        dbid: 181,
        name: "Dairyu Moon Gates",
        location: "Yanxia",
        coords: "19.6,20.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 39,
        dbid: 182,
        name: "Yuzuka Manor",
        location: "Yanxia",
        coords: "15.2,31.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 40,
        dbid: 183,
        name: "Ceol Aen",
        location: "The Azim Steppe",
        coords: "14.2,9.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 41,
        dbid: 184,
        name: "Dotharl Khaa",
        location: "The Azim Steppe",
        coords: "12.2,32",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 42,
        dbid: 185,
        name: "The Dusk Throne",
        location: "The Azim Steppe",
        coords: "19.8,33.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 43,
        dbid: 186,
        name: "Reunion",
        location: "The Azim Steppe",
        coords: "34.4,31.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 44,
        dbid: 187,
        name: "Chakha Zoh",
        location: "The Azim Steppe",
        coords: "20,12.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/pray",
        patch: 3,
    },
    {
        id: 45,
        dbid: 188,
        name: "The Dawn Throne",
        location: "The Azim Steppe",
        coords: "22.7,21.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 46,
        dbid: 189,
        name: "The Destroyer",
        location: "Rhalgr's Reach",
        coords: "11.4,14",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 47,
        dbid: 190,
        name: "Bloodstorm",
        location: "Rhalgr's Reach",
        coords: "10.5,9.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 48,
        dbid: 191,
        name: "The Yawn",
        location: "The Fringes",
        coords: "27.5,25.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "18:00",
        endtime: "5:00",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 49,
        dbid: 192,
        name: "Ala Ghiri",
        location: "The Peaks",
        coords: "14.3,36.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 50,
        dbid: 193,
        name: "Specula Imperatoris 2",
        location: "The Peaks",
        coords: "20.1,22.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 51,
        dbid: 194,
        name: "The Sunken Destroyer",
        location: "The Lochs",
        coords: "17.1,19.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 52,
        dbid: 195,
        name: "Ala Mhigan Quarter 2",
        location: "The Lochs",
        coords: "36,33.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 53,
        dbid: 196,
        name: "The Statue of Zuiko",
        location: "Kugane",
        coords: "11.1,9.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 54,
        dbid: 197,
        name: "Rakuza District",
        location: "Kugane",
        coords: "9.8,8.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 55,
        dbid: 198,
        name: "Tenkonto",
        location: "Kugane",
        coords: "12.5,10.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 56,
        dbid: 199,
        name: "Kugane Ofunakura",
        location: "Kugane",
        coords: "9.9,12.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 57,
        dbid: 200,
        name: "Shisui of the Violet Tides",
        location: "The Ruby Sea",
        coords: "5.0,36.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 58,
        dbid: 201,
        name: "East Othard Coastline",
        location: "The Ruby Sea",
        coords: "9.5,19",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "5:00",
        endtime: "8:00",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 59,
        dbid: 202,
        name: "Crick",
        location: "The Ruby Sea",
        coords: "21.5,12",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 60,
        dbid: 203,
        name: "Imperial Hypersonic Assault Craft L-XXIII",
        location: "Yanxia",
        coords: "35.6,38.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 61,
        dbid: 204,
        name: "Mol Iloh",
        location: "The Azim Steppe",
        coords: "31.3,11.5",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 62,
        dbid: 205,
        name: "Moai Statue",
        location: "The Azim Steppe",
        coords: "21.3,20.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 3,
    },
    {
        id: 1,
        dbid: 206,
        name: "The Rotunda",
        location: "The Crystarium",
        coords: "8.6,11.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 2,
        dbid: 207,
        name: "Musica Universalis",
        location: "The Crystarium",
        coords: "10.4,13.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 3,
        dbid: 208,
        name: "The Cabinet of Curiosity",
        location: "The Crystarium",
        coords: "9.9,5.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 4,
        dbid: 209,
        name: "Rapture",
        location: "The Crystarium",
        coords: "11,4.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 5,
        dbid: 210,
        name: "Temenos Rookery",
        location: "The Crystarium",
        coords: "7.4,9.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 6,
        dbid: 211,
        name: "The Glory Gate",
        location: "Eulmore",
        coords: "11.7,8.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 7,
        dbid: 212,
        name: "The Derelicts",
        location: "Eulmore",
        coords: "12.5,14",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 8,
        dbid: 213,
        name: "Eulmoran Army Headquarters",
        location: "Eulmore",
        coords: "11.1,11.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 9,
        dbid: 214,
        name: "The Beehive",
        location: "Eulmore",
        coords: "12.3,10.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 10,
        dbid: 215,
        name: "Fort Jobb",
        location: "Lakeland",
        coords: "37.4,20.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 11,
        dbid: 216,
        name: "Radisca's Round",
        location: "Lakeland",
        coords: "18.4,18.7",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 12,
        dbid: 217,
        name: "Laxan Loft",
        location: "Lakeland",
        coords: "22.1,15.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 13,
        dbid: 218,
        name: "The Ostall Imperative",
        location: "Lakeland",
        coords: "6.3,15.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 14,
        dbid: 219,
        name: "The Hour of Certain Durance",
        location: "Lakeland",
        coords: "8.7,22.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 15,
        dbid: 220,
        name: "Sullen",
        location: "Lakeland",
        coords: "21.5,36.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 16,
        dbid: 221,
        name: "Cracked Shell Beach",
        location: "Kholusia",
        coords: "33.2,28.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 17,
        dbid: 222,
        name: "White Oil Falls",
        location: "Kholusia",
        coords: "28.8,22.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 18,
        dbid: 223,
        name: "Gatetown",
        location: "Kholusia",
        coords: "23.6,38.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 19,
        dbid: 224,
        name: "Wright",
        location: "Kholusia",
        coords: "18.2,29.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 20,
        dbid: 225,
        name: "The Ladder",
        location: "Kholusia",
        coords: "12.1,22.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 21,
        dbid: 226,
        name: "Tomra",
        location: "Kholusia",
        coords: "13.6,9.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 22,
        dbid: 227,
        name: "The Duergar's Tewel",
        location: "Kholusia",
        coords: "37.1,11.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 23,
        dbid: 228,
        name: "The Red Serai",
        location: "Amh Araeng",
        coords: "33.2,13.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 24,
        dbid: 229,
        name: "Mord Souq",
        location: "Amh Araeng",
        coords: "25.3,16.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 25,
        dbid: 230,
        name: "The Pristine Palace of Amh Malik",
        location: "Amh Araeng",
        coords: "28.5,31.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 26,
        dbid: 231,
        name: "Mount Biran Mines",
        location: "Amh Araeng",
        coords: "22.1,9.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 27,
        dbid: 232,
        name: "Twine",
        location: "Amh Araeng",
        coords: "11.1,16.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 28,
        dbid: 233,
        name: "Kelk",
        location: "Amh Araeng",
        coords: "20.4,21.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 29,
        dbid: 234,
        name: "Lydha Lran",
        location: "Il Mheg",
        coords: "14.8,31.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 30,
        dbid: 235,
        name: "The Bookman's Shelves",
        location: "Il Mheg",
        coords: "8.7,16.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 31,
        dbid: 236,
        name: "Pla Enni",
        location: "Il Mheg",
        coords: "20.2,4.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 32,
        dbid: 237,
        name: "Deepwood Swim",
        location: "Il Mheg",
        coords: "21.4,20.9",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 33,
        dbid: 238,
        name: "Lyhe Ghiah",
        location: "Il Mheg",
        coords: "20.8,16.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 34,
        dbid: 239,
        name: "Saint Fathric's Temple",
        location: "Il Mheg",
        coords: "35.7,24.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 35,
        dbid: 240,
        name: "Fort Gohn",
        location: "The Rak'tika Greatwood",
        coords: "13.6,32.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 36,
        dbid: 241,
        name: "Fruit of the Protector",
        location: "The Rak'tika Greatwood",
        coords: "8.9,25.1",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 37,
        dbid: 242,
        name: "The Covered Halls of Dwatl",
        location: "The Rak'tika Greatwood",
        coords: "4.3,27.2",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 38,
        dbid: 243,
        name: "Lozatl's Conquest",
        location: "The Rak'tika Greatwood",
        coords: "14.1,18.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 39,
        dbid: 244,
        name: "Fanow",
        location: "The Rak'tika Greatwood",
        coords: "29.1,19",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 40,
        dbid: 245,
        name: "The Morning Stars",
        location: "The Rak'tika Greatwood",
        coords: "26.4,10",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 41,
        dbid: 246,
        name: "The Ondo Cups",
        location: "The Tempest",
        coords: "33.1,16.3",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 42,
        dbid: 247,
        name: "The Workbench",
        location: "The Tempest",
        coords: "34.5,25.4",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 43,
        dbid: 248,
        name: "Where the Dry Return",
        location: "The Tempest",
        coords: "37.2,6.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 44,
        dbid: 249,
        name: "Purpure",
        location: "The Tempest",
        coords: "34.3,30.6",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
    {
        id: 45,
        dbid: 250,
        name: "Amaurot",
        location: "The Tempest",
        coords: "13.7,36.8",
        maxcoords: 42,
        weather1: "",
        weather2: "",
        starttime: "",
        endtime: "",
        emote: "/lookout",
        patch: 4,
    },
];