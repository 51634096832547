<div class="alib-content-wrapper">
  <div id="gatheringsearch">
      <input type="text" [(ngModel)]="searchText" placeholder="Search">
  </div>
  <div id="gatheringsearchresults">
    <ul class="gatheringlist" *ngIf="(gatheringlist | filter: searchText).length > 0">
      <li class="gatheringsr" *ngFor="let gatheringlist of gatheringlist | sortBy: 'asc|desc':'location' | filter: searchText; let i = index" (click)="onSelect(gatheringlist)">
        <span class="icon">
          <span class="hidden">{{gatheringlist.name}}</span>
          <span class="itemlevel">Lv. {{gatheringlist.itemlevel}}</span>
          <span class="type">
            <span class="hidden">{{gatheringlist.type}}</span>
          </span>
        </span>
        <span class="info">
          <span class="nodelevel">Lv. {{gatheringlist.nodelevel}}</span>
          <span class="name">{{gatheringlist.name}}</span>
          <span class="location">{{gatheringlist.location}}</span>
          <span class="requirements">{{gatheringlist.requirements}}</span>
        </span>
        <span class="coords">
          <span class="hidden">{{gatheringlist.coords}}</span>
        </span>
      </li>
    </ul>
  </div>
  <div id="alib-map-viewer">
    <div id="alib-map-flag-area">
      <div class="marker"></div>
    </div>
  </div>
</div>
