import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-aethercurrents',
  templateUrl: './aethercurrents.component.html',
  styleUrls: ['./aethercurrents.component.less']
})
export class AethercurrentsComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Aether Current Locations'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Aether Currents, ffxiv aether currents'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Aether Currents - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }
}
