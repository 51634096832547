import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unhiddenleather',
  templateUrl: './unhiddenleather.component.html',
  styleUrls: ['./unhiddenleather.component.less']
})
export class UnhiddenleatherComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
