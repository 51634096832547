import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    setTimeout(() => {
      window.location.href = 'http://www.sharlayan.org';
    }, 3000);
  }
}
