<div class="g-left-sidebar">
  <a routerLink="fhyur-midlander">
    <div class="button">HYUR MIDLANDER</div>
  </a>
  <a routerLink="fhyur-highlander">
    <div class="button">HYUR HIGHLANDER</div>
  </a>
  <a routerLink="felezen">
    <div class="button">ELEZEN</div>
  </a>
  <a routerLink="flalafell">
    <div class="button">LALAFELL</div>
  </a>
  <a routerLink="fmiqote">
    <div class="button">MIQO'TE</div>
  </a>
  <a routerLink="froegadyn">
    <div class="button">ROEGADYN</div>
  </a>
  <div class="button disabled-button disabled-text">AU RA</div>
  <div class="button disabled-button disabled-text">VIERA</div>
</div>
<div id="alib-charactervoices-content-wrapper">
  <router-outlet></router-outlet>
</div>
