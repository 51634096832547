<h1 class="seoh1">Final Fantasy XIV - Gazelleskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-gazelleskin">
    <dt>
      <button class="js-badger-accordion-header" data="TheAzimSteppe" (click)="treasuremapChangeMap($event)">
        THE AZIM STEPPE
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="29.6,11.8,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.5,15.9,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.9,22.1,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="4.8,25.6,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="16.5,25.0,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.0,32.8,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.8,37.0,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.2,35.4,42" src="assets/img/treasuremap-icons/gazelleskin/azimsteppe08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheFringes" (click)="treasuremapChangeMap($event)">
        THE FRINGES
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="9.4,12.4,42" src="assets/img/treasuremap-icons/gazelleskin/fringes01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.4,12.0,42" src="assets/img/treasuremap-icons/gazelleskin/fringes02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.5,16.6,42" src="assets/img/treasuremap-icons/gazelleskin/fringes03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.9,20.0,42" src="assets/img/treasuremap-icons/gazelleskin/fringes04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.5,20.6,42" src="assets/img/treasuremap-icons/gazelleskin/fringes05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="8.3,28.0,42" src="assets/img/treasuremap-icons/gazelleskin/fringes06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.2,31.1,42" src="assets/img/treasuremap-icons/gazelleskin/fringes07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.9,31.4,42" src="assets/img/treasuremap-icons/gazelleskin/fringes08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheLochs" (click)="treasuremapChangeMap($event)">
        THE LOCHS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="5.9,6.7,42" src="assets/img/treasuremap-icons/gazelleskin/lochs01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.2,7.9,42" src="assets/img/treasuremap-icons/gazelleskin/lochs02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.9,6.3,42" src="assets/img/treasuremap-icons/gazelleskin/lochs03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="8.6,17.6,42" src="assets/img/treasuremap-icons/gazelleskin/lochs04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.7,17.5,42" src="assets/img/treasuremap-icons/gazelleskin/lochs05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="19.7,23.3,42" src="assets/img/treasuremap-icons/gazelleskin/lochs06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.8,28.6,42" src="assets/img/treasuremap-icons/gazelleskin/lochs07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.5,32.0,42" src="assets/img/treasuremap-icons/gazelleskin/lochs08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="ThePeaks" (click)="treasuremapChangeMap($event)">
        THE PEAKS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="12.4,5.8,42" src="assets/img/treasuremap-icons/gazelleskin/peaks01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="16.1,9.3,42" src="assets/img/treasuremap-icons/gazelleskin/peaks02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="33.2,9.8,42" src="assets/img/treasuremap-icons/gazelleskin/peaks03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="26.0,13.7,42" src="assets/img/treasuremap-icons/gazelleskin/peaks04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.4,22.8,42" src="assets/img/treasuremap-icons/gazelleskin/peaks05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="8.6,32.5,42" src="assets/img/treasuremap-icons/gazelleskin/peaks06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.7,33.0,42" src="assets/img/treasuremap-icons/gazelleskin/peaks07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.9,31.6,42" src="assets/img/treasuremap-icons/gazelleskin/peaks08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheRubySea" (click)="treasuremapChangeMap($event)">
        THE RUBY SEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="16.9,9.6,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.3,8.9,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="5.2,15.0,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.8,18.3,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.6,25.4,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="7.7,29.6,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.7,29.9,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.6,36.4,42" src="assets/img/treasuremap-icons/gazelleskin/rubysea08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Yanxia" (click)="treasuremapChangeMap($event)">
        YANXIA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="20.0,5.6,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.9,5.5,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.7,13.8,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.1,18.7,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.1,30.1,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.1,27.7,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.0,28.5,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.8,35.4,42" src="assets/img/treasuremap-icons/gazelleskin/yanxia08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>