<h1 class="seoh1">Final Fantasy XIV - Shadowbringers Aether Current Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-acshb">
    <dt>
      <button class="js-badger-accordion-header" data="AmhAraeng;42;21.1,9.0;29.8,10.4;30.7,15.3;14.6,16.7;23.9,17.5;30.9,21.5;24.7,28.0;14.8,29.8;28.3,32.2;24.4,35.5" (click)="acChangeMap($event)">
        AMH ARAENG
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;12.9,16.9;11.8,17.4;11.3,17.5;26.8,18.5;15.8,19.1" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="IlMheg;42;21.8,4.4;30.1,6.0;24.0,8.7;14.9,10.1;25.1,13.0;20.3,14.9;10.4,24.8;16.8,24.6;23.1,32.9;10.5,34.8" (click)="acChangeMap($event)">
        IL MHEG
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;30.4,7.5;30.3,8.3;9.2,17.1;15.7,30.4;14.1,32.6" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Kholusia;42;34.7,9.7;18.9,13.4;34.1,17.2;20.2,21.1;11.0,21.2;34.9,24.0;34.4,32.5;7.6,34.0;23.8,36.9;17.6,37.0" (click)="acChangeMap($event)">
        KHOLUSIA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;12.4,9.2;18.7,17.6;18.8,17.9;15.3,28.6;15.3,29.5" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Lakeland;42;35.4,11.1;5.7,14.3;32.0,14.6;34.6,17.0;9.4,17.7;18.6,18.4;37.3,21.1;35.5,21.5;30.1,24.0;33.5,28.8" (click)="acChangeMap($event)">
        LAKELAND
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;7.4,14.4;6.1,15.4;8.0,17.0" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheRaktikaGreatwood;42;26.3,11.8;28.6,15.5;34.6,16.0;9.0,17.9;19.3,22.6;31.7,22.9;4.2,24.9;28.2,25.5;13.3,31.6;8.8,32.5" (click)="acChangeMap($event)">
        THE RAK'TIKA GREATWOOD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;31.3,16.9;30.5,17.4;29.4,17.8;19.8,27.6;18.8,27.7" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheTempest;42;29.1,7.2;33.6,9.4;22.8,11.2;7.0,13.6;28.2,15.9;39.5,16.8;14.5,16.9;22.3,18.0;5.3,19.4;35.7,23.1" (click)="acChangeMap($event)">
        THE TEMPEST
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;32.1,16.6;8.8,26.5;13.7,27.6;9.5,28.2;7.2,31.4" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
  </dl>
  <div class="button" data="TheCrystarium;21.5;12.0,16.4;8.5,9.7" (click)="acChangeMap($event)">
    THE CRYSTARIUM
  </div>
</div>
<div id="map-viewer">
</div>
