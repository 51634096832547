import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from "@angular/core";

import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './discordoauth/login/login.component';

import { KnowledgebaseComponent } from './knowledgebase/knowledgebase.component';

import { RaidsComponent } from './raids/raids.component';
  import { O12sComponent } from './raids/o12s/o12s.component';
  import { O12s2Component } from './raids/o12s2/o12s2.component';

import { TriadsolverComponent } from './triadsolver/triadsolver.component';
  import { KingelmerComponent } from './triadsolver/kingelmer/kingelmer.component';

import { MinicactpotsolverComponent } from './minicactpotsolver/minicactpotsolver.component';

import { CardlistComponent } from './cardlist/cardlist.component';

import { CharactervoicesComponent } from './charactervoices/charactervoices.component';
  import { FemaleComponent } from './charactervoices/female/female.component';
    import { FhyurMidlanderComponent } from './charactervoices/female/fhyur-midlander/fhyur-midlander.component';
    import { FhyurHighlanderComponent } from './charactervoices/female/fhyur-highlander/fhyur-highlander.component';
    import { FelezenComponent } from './charactervoices/female/felezen/felezen.component';
    import { FlalafellComponent } from './charactervoices/female/flalafell/flalafell.component';
    import { FmiqoteComponent } from './charactervoices/female/fmiqote/fmiqote.component';
    import { FroegadynComponent } from './charactervoices/female/froegadyn/froegadyn.component';
  import { MaleComponent } from './charactervoices/male/male.component';
    import { MhyurMidlanderComponent } from './charactervoices/male/mhyur-midlander/mhyur-midlander.component';
    import { MhyurHighlanderComponent } from './charactervoices/male/mhyur-highlander/mhyur-highlander.component';

    import { MlalafellComponent } from './charactervoices/male/mlalafell/mlalafell.component';
    import { MmiqoteComponent } from './charactervoices/male/mmiqote/mmiqote.component';

import { TreasuremapsComponent } from './treasuremaps/treasuremaps.component';
  import { ZonureskinComponent } from './treasuremaps/zonureskin/zonureskin.component';
  import { GliderskinComponent } from './treasuremaps/gliderskin/gliderskin.component';
  import { FabledthiefsComponent } from './treasuremaps/fabledthiefs/fabledthiefs.component';
  import { GazelleskinComponent } from './treasuremaps/gazelleskin/gazelleskin.component';
  import { GaganaskinComponent } from './treasuremaps/gaganaskin/gaganaskin.component';
  import { DragonskinComponent } from './treasuremaps/dragonskin/dragonskin.component';
  import { WyvernskinComponent } from './treasuremaps/wyvernskin/wyvernskin.component';
  import { ArchaeoskinComponent } from './treasuremaps/archaeoskin/archaeoskin.component';
  import { UnhiddenleatherComponent } from './treasuremaps/unhiddenleather/unhiddenleather.component';
  import { PeisteskinComponent } from './treasuremaps/peisteskin/peisteskin.component';
  import { BoarskinComponent } from './treasuremaps/boarskin/boarskin.component';
  import { ToadskinComponent } from './treasuremaps/toadskin/toadskin.component';
  import { GoatskinComponent } from './treasuremaps/goatskin/goatskin.component';
  import { LeatherComponent } from './treasuremaps/leather/leather.component';
  import { SaigaskinComponent } from './treasuremaps/saigaskin/saigaskin.component';
  import { KumbhiraskinComponent } from './treasuremaps/kumbhiraskin/kumbhiraskin.component';

import { ElitehuntsComponent } from './elitehunts/elitehunts.component';

import { GatheringComponent } from './gathering/gathering.component';

import { HuntinglogComponent } from './huntinglog/huntinglog.component';
  import { HlgladiatorComponent } from './huntinglog/hlgladiator/hlgladiator.component';
  import { HlpugilistComponent } from './huntinglog/hlpugilist/hlpugilist.component';
  import { HlmarauderComponent } from './huntinglog/hlmarauder/hlmarauder.component';
  import { HllancerComponent } from './huntinglog/hllancer/hllancer.component';
  import { HlarcherComponent } from './huntinglog/hlarcher/hlarcher.component';
  import { HlrogueComponent } from './huntinglog/hlrogue/hlrogue.component';
  import { HlconjurerComponent } from './huntinglog/hlconjurer/hlconjurer.component';
  import { HlthaumaturgeComponent } from './huntinglog/hlthaumaturge/hlthaumaturge.component';
  import { HlarcanistComponent } from './huntinglog/hlarcanist/hlarcanist.component';
  import { HlimmortalflamesComponent } from './huntinglog/hlimmortalflames/hlimmortalflames.component';
  import { HlmaelstromComponent } from './huntinglog/hlmaelstrom/hlmaelstrom.component';
  import { HltwinadderComponent } from './huntinglog/hltwinadder/hltwinadder.component';

import { SightseeinglogComponent } from './sightseeinglog/sightseeinglog.component';

import { AethercurrentsComponent } from './aethercurrents/aethercurrents.component';
  import { AcheavenswardComponent } from './aethercurrents/acheavensward/acheavensward.component';
  import { AcstormbloodComponent } from './aethercurrents/acstormblood/acstormblood.component';
  import { AcshadowbringersComponent } from './aethercurrents/acshadowbringers/acshadowbringers.component';
  import { AcendwalkerComponent } from './aethercurrents/acendwalker/acendwalker.component';

import { ReportComponent } from './report/report.component';

import { DevComponent } from './dev/dev.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'encyclopedia',
    component: KnowledgebaseComponent,
  },
  {
    path: 'raids',
    component: RaidsComponent,
    children: [
      {
        path: 'o12s',
        component: O12sComponent,
      },
      {
        path: 'o12s2',
        component: O12s2Component,
      }
    ]
  },
  {
    path: 'tripletriad',
    component: TriadsolverComponent,
    children: [
      {
        path: 'kingelmer',
        component: KingelmerComponent,
      }
    ]
  },
  {
    path: 'minicactpot',
    component: MinicactpotsolverComponent,
  },
  {
    path: 'charactervoices',
    component: CharactervoicesComponent,
    children: [
      {
        path: 'female',
        component: FemaleComponent,
        children: [
          {
            path: 'fhyur-midlander',
            component: FhyurMidlanderComponent,
          },
          {
            path: 'fhyur-highlander',
            component: FhyurHighlanderComponent,
          },
          {
            path: 'felezen',
            component: FelezenComponent,
          },
          {
            path: 'flalafell',
            component: FlalafellComponent,
          },
          {
            path: 'fmiqote',
            component: FmiqoteComponent,
          },
          {
            path: 'froegadyn',
            component: FroegadynComponent,
          },
        ]
      },
      {
        path: 'male',
        component: MaleComponent,
        children: [
          {
            path: 'mhyur-midlander',
            component: MhyurMidlanderComponent,
          },
          {
            path: 'mhyur-highlander',
            component: MhyurHighlanderComponent,
          },
          {
            path: 'mlalafell',
            component: MlalafellComponent,
          },
          {
            path: 'mmiqote',
            component: MmiqoteComponent,
          },
        ]
      },
    ]
  },
  {
    path: 'cardlist',
    component: CardlistComponent,
  },
  {
    path: 'treasuremaps',
    component: TreasuremapsComponent,
    children: [
      {
        path: '',
        component: TreasuremapsComponent
      },
      {
        path: 'zonureskin',
        component: ZonureskinComponent
      },
      {
        path: 'gliderskin',
        component: GliderskinComponent
      },
      {
        path: 'fabledthiefs',
        component: FabledthiefsComponent
      },
      {
        path: 'gazelleskin',
        component: GazelleskinComponent
      },
      {
        path: 'gaganaskin',
        component: GaganaskinComponent
      },
      {
        path: 'dragonskin',
        component: DragonskinComponent
      },
      {
        path: 'wyvernskin',
        component: WyvernskinComponent
      },
      {
        path: 'archaeoskin',
        component: ArchaeoskinComponent
      },
      {
        path: 'unhiddenleather',
        component: UnhiddenleatherComponent
      },
      {
        path: 'peisteskin',
        component: PeisteskinComponent
      },
      {
        path: 'boarskin',
        component: BoarskinComponent
      },
      {
        path: 'toadskin',
        component: ToadskinComponent
      },
      {
        path: 'goatskin',
        component: GoatskinComponent
      },
      {
        path: 'leather',
        component: LeatherComponent
      },
      {
        path: 'saigaskin',
        component: SaigaskinComponent
      },
      {
        path: 'kumbhiraskin',
        component: KumbhiraskinComponent
      },
    ]
  },
  {
    path: 'elitehunts',
    component: ElitehuntsComponent
  },
  {
    path: 'gathering',
    component: GatheringComponent,
  },
  {
    path: 'huntinglog',
    component: HuntinglogComponent,
    children: [
      {
        path: 'gladiator',
        component: HlgladiatorComponent,
      },
      {
        path: 'pugilist',
        component: HlpugilistComponent,
      },
      {
        path: 'marauder',
        component: HlmarauderComponent,
      },
      {
        path: 'lancer',
        component: HllancerComponent,
      },
      {
        path: 'archer',
        component: HlarcherComponent,
      },
      {
        path: 'rogue',
        component: HlrogueComponent,
      },
      {
        path: 'conjurer',
        component: HlconjurerComponent,
      },
      {
        path: 'thaumaturge',
        component: HlthaumaturgeComponent,
      },
      {
        path: 'arcanist',
        component: HlarcanistComponent,
      },
      {
        path: 'immortalflames',
        component: HlimmortalflamesComponent,
      },
      {
        path: 'maelstrom',
        component: HlmaelstromComponent,
      },
      {
        path: 'twinadder',
        component: HltwinadderComponent,
      },
    ]
  },
  {
    path: 'sightseeinglog',
    component: SightseeinglogComponent
  },
  {
    path: 'aethercurrents',
    component: AethercurrentsComponent,
    children: [
      {
        path: 'heavensward',
        component: AcheavenswardComponent
      },
      {
        path: 'stormblood',
        component: AcstormbloodComponent
      },
      {
        path: 'shadowbringers',
        component: AcshadowbringersComponent
      },
      {
        path: 'endwalker',
        component: AcendwalkerComponent
      },
    ]
  },
  {
    path: 'report',
    component: ReportComponent
  },
  {
    path: 'dev',
    component: DevComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
