
  <div id="content">
    <div id="logo" style="background-image: url('assets/img/logos/logo-default.webp');"></div>
    <div id="welcome">
      <p style="font-size:2em">is currently in open development.</p><br>
      <p>Feel free to look around.</p>
    </div>
    <div id="dsc-newsfeed">
      <p style="font-size:1.75em">UPDATES<br></p>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">28/06/2022</div>
        <div class="message">
          <b>v0.51.0</b><br>
          Added mini cactpot solver.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">27/06/2022</div>
        <div class="message">
          <b>v0.50.1</b><br>
          Added a missing aether current in Labyrinthos. (Thanks Dhelia Cathalyst on Odin)
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">14/06/2022</div>
        <div class="message">
          <b>v0.50.0</b><br>
          Added dragonskin tresure maps. Minor code optimizations.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">13/05/2022</div>
        <div class="message">
          <b>v0.49.0</b><br>
          Updated framework. Finished kumbhiraskin treasure maps. Minor design changes.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">04/01/2022</div>
        <div class="message">
          <b>v0.48.0</b><br>
          Added a bunch of kumbhiraskin treasure maps. Minor code improvements.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">14/12/2021</div>
        <div class="message">
          <b>v0.47.1</b><br>
          Added a few more saigaskin treasure maps.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">13/12/2021</div>
        <div class="message">
          <b>v0.47.0</b><br>
          Added endwalker aether currents.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">12/12/2021</div>
        <div class="message">
          <b>v0.46.1</b><br>
          Added a few more endwalker treasure map locations. Minor design changes to fit rebranding.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">11/12/2021</div>
        <div class="message">
          <b>v0.46.0</b><br>
          Rebranded to sharlayan.org. Added some endwalker treasure map locations.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">09/10/2021</div>
        <div class="message">
          <b>v0.45.0</b><br>
          Added Triple Triad card checklist.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">08/10/2021</div>
        <div class="message">
          <b>v0.44.0</b><br>
          Added Discord login functionality. This will sync your cookies with our database, allowing for ease of use across devices.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">03/10/2021</div>
        <div class="message">
          <b>v0.43.1</b><br>
          Disabled triple triad solver until future improvements can be made. Fixed some minor bugs.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">02/10/2021</div>
        <div class="message">
          <b>v0.43.0</b><br>
          Added immortal flames, maelstrom, and twin adder hunting logs.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">30/09/2021</div>
        <div class="message">
          <b>v0.42.0</b><br>
          Added arcanist hunting logs.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">30/09/2021</div>
        <div class="message">
          <b>v0.41.0</b><br>
          Added conjurer hunting logs. Fixed a few bugs.
        </div>
      </div>
      <div class="dsc-message">
        <div class="avatar">
          <div class="picture"></div>
        </div>
        <div class="user">chris</div>
        <div class="date">28/09/2021</div>
        <div class="message">
          <b>v0.40.0</b><br>
          Added rogue and thaumaturge hunting logs.
        </div>
      </div>
    </div>
    <div id="roadmap">
      <p style="font-size:1.75em;text-align:center">ROADMAP<br></p>
      <p style="font-size:1.25em;text-align:left">SOON<br></p>
      <p>Endwalker treasure maps</p>
      <p>Endwalker triad cards</p>
      <p>Sightseeing log</p>
      <p>Mount checklist</p>
      <p>Minion checklist</p>
      <br>
      <p style="font-size:1.25em;text-align:left">LATER<br></p>
      <p>Updates to the elite hunts UI</p>
      <p>Triple Triad npc guides</p>
      <p>Optimization for non-desktop devices</p>
      <p>Triple Triad card drop chances</p>
    </div>
  </div>

