import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GatheringList } from '../interface-gathering';
import { GATHERINGLIST } from '../list-gathering';

@Component({
  selector: 'app-gathering',
  templateUrl: './gathering.component.html',
  styleUrls: ['./gathering.component.less']
})
export class GatheringComponent implements OnInit {

  gatheringlist = GATHERINGLIST;
  selectedGatheringList: GatheringList;

  searchText: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    var gatlisticon = document.getElementsByClassName("icon") as HTMLCollectionOf<HTMLElement>;
    for (var glii = 0; glii<gatlisticon.length; glii++) {
      var gatlisticonchild = gatlisticon[glii].children[0];
      var gatlistname = gatlisticonchild.textContent.replace(/\s/g, '');
      gatlisticon[glii].style.backgroundImage = ("url('assets/img/items/") + (gatlistname) + (".webp')");
    }
    var gatlisttype = document.getElementsByClassName("type") as HTMLCollectionOf<HTMLElement>;
    for (var glti = 0; glti<gatlisttype.length; glti++) {
      var gatlisttypechild = gatlisttype[glti].children[0];
      if (gatlisttypechild.innerHTML === "Mining") {
        gatlisttype[glti].style.backgroundImage = "url('assets/img/icons/alib-icon-gatmining.webp')";
      }
      if (gatlisttypechild.innerHTML === "Quarrying") {
        gatlisttype[glti].style.backgroundImage = "url('assets/img/icons/alib-icon-gatquarrying.webp')";
      }
      if (gatlisttypechild.innerHTML === "Logging") {
        gatlisttype[glti].style.backgroundImage = "url('assets/img/icons/alib-icon-gatlogging.webp')";
      }
      if (gatlisttypechild.innerHTML === "Harvesting") {
        gatlisttype[glti].style.backgroundImage = "url('assets/img/icons/alib-icon-gatharvesting.webp')";
      }
    }
  }

  onSelect (gatheringlist: GatheringList): void {
    this.selectedGatheringList = gatheringlist;
    var gatlistlocation = gatheringlist.location.replace(/\s/g, '');
    var gatlistcoords = gatheringlist.coords.replace(/\s/g, '');
    var mapimaget = document.getElementById("alib-map-viewer");
    var mapareaflagt = document.getElementById("alib-map-flag-area");
    function mapfadein() {mapimaget.classList.add("fade")}
    function mapfadeout() {mapimaget.classList.remove("fade")}
    function setflag() {mapimaget.style.backgroundImage = ("url('assets/img/maps/") + (gatlistlocation) + (".webp')")}
    if (mapimaget.style.backgroundImage.includes(gatlistlocation)) {} else {
      mapfadeout();
      setTimeout(setflag, 150);
      setTimeout(mapfadein, 200);
    }
////// Mining
    if (this.selectedGatheringList.type === "Mining") {
      if (gatlistlocation === "CentralThanalan") {
        if (gatlistcoords === "SpinelessBasin1") {
          mapareaflagt.style.transform = "scale(1.5)";
          mapareaflagt.style.margin = "61.2% 0% 0% 42.7%";
        }
        if (gatlistcoords === "SpinelessBasin2") {
          mapareaflagt.style.transform = "scale(1.5)";
          mapareaflagt.style.margin = "64.8% 0% 0% 60%";
        }
      }
    }
  }

}
