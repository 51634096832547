<div class="alib-raids-overview">
  <video src="assets/video/test.webm"></video>
</div>
<div class="alib-raids-roleselect-wrapper">
  <div class="alib-raids-roleselect tank" id="o12stank" onclick="alibContentToggle(this.id)"></div>
  <div class="alib-raids-roleselect healer" id="o12shealer" onclick="alibContentToggle(this.id)"></div>
  <div class="alib-raids-roleselect dps" id="o12sdps" onclick="alibContentToggle(this.id)"></div>
</div>
<div class="alib-togglecontent" id="o12stankcontent">
  tank stuff
</div>
<div class="alib-togglecontent" id="o12shealercontent">
  healer stuffs
</div>
<div class="alib-togglecontent" id="o12sdpscontent">
  dps stuff
</div>
