  <div class="g-left-sidebar">
    <div id="cardsearch">
      <input type="text" [(ngModel)]="searchText" placeholder="Search">
    </div>
    <div class="button" id="ownedcardstoggle" (click)="btnHideOwned()">HIDE OWNED CARDS</div>
    <div class="divider">ONLY SHOW CARDS FROM</div>
    <div class="button-sq" (click)="funcBtnClearAll()">
      <div class="tooltipwrap" data-tooltip-location="right" data-tooltip="ALL"></div>
    </div>
    <div class="button-sq" id="sortbynpcbtn" style="background-image:url('assets/img/tripletriad/type_npc.webp')" (click)="btnSortByNpc()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="NPC"></div>
    </div>
    <div class="button-sq" id="sortbydutybtn" style="background-image:url('assets/img/tripletriad/type_duty.webp')" (click)="btnSortByDuty()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="DUTY"></div>
    </div>
    <div class="button-sq" id="sortbypackbtn" style="background-image:url('assets/img/tripletriad/type_pack.webp')" (click)="btnSortByPack()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="PACK"></div>
    </div>
    <div class="button-sq" id="sortbymgpbtn" style="background-image:url('assets/img/tripletriad/type_mgp.webp')" (click)="btnSortByMgp()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="MGP"></div>
    </div>
    <div class="button-sq" id="sortbyachievementbtn" style="background-image:url('assets/img/tripletriad/type_achievement.webp')" (click)="btnSortByAchievement()">
      <div class="tooltipwrap" data-tooltip-location="left" data-tooltip="ACHIEVEMENT"></div>
    </div>
    <div class="button-sq" id="sortbyfatebtn" style="background-image:url('assets/img/tripletriad/type_fate.webp')" (click)="btnSortByFate()">
      <div class="tooltipwrap" data-tooltip-location="right" data-tooltip="FATE"></div>
    </div>
    <div class="button-sq" id="sortbygembtn" style="background-image:url('assets/img/tripletriad/type_gem.webp')" (click)="btnSortByGem()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="GEM"></div>
    </div>
    <div class="button-sq" id="sortbysackbtn" style="background-image:url('assets/img/tripletriad/type_sack.webp')" (click)="btnSortBySack()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="SACK"></div>
    </div>
    <div class="button-sq" id="sortbytournamentbtn" style="background-image:url('assets/img/tripletriad/type_tournament.webp')" (click)="btnSortByTournament()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="TOURNAMENT"></div>
    </div>
    <div class="button-sq" id="sortbyeventbtn" style="background-image:url('assets/img/tripletriad/type_event.webp')" (click)="btnSortByEvent()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="EVENT"></div>
    </div>
    <div class="button-sq" id="sortbyscripbtn" style="background-image:url('assets/img/tripletriad/type_scrip.webp')" (click)="btnSortByScrip()">
      <div class="tooltipwrap" data-tooltip-location="left" data-tooltip="SCRIP"></div>
    </div>
    <div class="button-sq" id="sortbysealbtn" style="background-image:url('assets/img/tripletriad/type_seal.webp')" (click)="btnSortBySeal()">
      <div class="tooltipwrap" data-tooltip-location="right" data-tooltip="ALLIED SEALS"></div>
    </div>
    <div class="button-sq" id="sortbyclusterbtn" style="background-image:url('assets/img/tripletriad/type_cluster.webp')" (click)="btnSortByCluster()">
      <div class="tooltipwrap" data-tooltip-location="bottom" data-tooltip="BOZJAN CLUSTERS"></div>
    </div>
  </div>
  <div id=cardlist-content>
    <ul class="triadcards" id="cardlist">
      <li *ngFor="let triadcard of triadcards | sortBy: 'asc':'number'" class="cardlistelem" id="{{triadcard.id}}" (click)="onSelect(triadcard)">
        <span class="number">{{triadcard.number}}</span>
        <span class="icon" style="background-image:url('assets/img/tripletriad/{{triadcard.icon}}.webp')"></span>
        <span class="rarity" style="background-image:url('assets/img/tripletriad/rarity_{{triadcard.rarity}}.webp')"></span>
        <span class="info">
          <span class="name">{{triadcard.name}}</span>
          <span class="type {{triadcard.type1}}" style="background-image:url('assets/img/tripletriad/type_{{triadcard.type1}}.webp');background-color: #212121"></span>
          <span class="method" style="background-color: #212121">{{triadcard.method1}}</span>
          <span class="droprate" style="background-color: #212121">{{triadcard.droprate1}}</span>
          <span class="type {{triadcard.type2}}" style="background-image:url('assets/img/tripletriad/type_{{triadcard.type2}}.webp')"></span>
          <span class="method">{{triadcard.method2}}</span>
          <span class="droprate">{{triadcard.droprate2}}</span>
          <span class="type {{triadcard.type3}}" style="background-image:url('assets/img/tripletriad/type_{{triadcard.type3}}.webp');background-color: #212121"></span>
          <span class="method" style="background-color: #212121">{{triadcard.method3}}</span>
          <span class="droprate" style="background-color: #212121">{{triadcard.droprate3}}</span>
          <span class="type {{triadcard.type4}}" style="background-image:url('assets/img/tripletriad/type_{{triadcard.type4}}.webp')"></span>
          <span class="method">{{triadcard.method4}}</span>
          <span class="droprate">{{triadcard.droprate4}}</span>
          <span class="type {{triadcard.type5}}" style="background-image:url('assets/img/tripletriad/type_{{triadcard.type5}}.webp');background-color: #212121"></span>
          <span class="method" style="background-color: #212121">{{triadcard.method5}}</span>
          <span class="droprate" style="background-color: #212121">{{triadcard.droprate5}}</span>
        </span>
        <span class="checkbox">
          <div class="cl-checkbox"></div>
        </span>
        <span class="patch">{{triadcard.patch}}</span>
      </li>
    </ul>
  </div>
