<app-preloadvoiceicons></app-preloadvoiceicons>
  <div class="genderselect">
    <a routerLink="female">
      <div id="genderselect-f">♀</div>
    </a>
    <a routerLink="male">
      <div id="genderselect-m">♂</div>
    </a>
    <div id="alib-charactervoices-volume">
      <div id="label">VOLUME</div>
      <div id="slider-container">
        <input type="range" min="1" max="100" value="25" class="slider" id="charactervoicesVolume" (click)="changeVolume()">
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-genderselect-content">
    <router-outlet></router-outlet>
  </div>
