<h1 class="seoh1">Final Fantasy XIV - Zonureskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-zonureskin">
    <dt>
      <button class="js-badger-accordion-header" data="AmhAraeng" (click)="treasuremapChangeMap($event)">
        AMH ARAENG
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="32.4,8.5,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="36.0,11.4,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.6,12.9,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="34.0,17.4,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="26.9,23.8,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.2,30.7,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.3,14.2,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.2,29.9,42" src="assets/img/treasuremap-icons/zonureskin/amharaeng08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="IlMheg" (click)="treasuremapChangeMap($event)">
        IL MHEG
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="31.2,4.1,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.8,7.6,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="33.3,10.4,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.0,12.4,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.5,13.0,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="7.4,17.4,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.9,20.5,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="14.1,27.6,42" src="assets/img/treasuremap-icons/zonureskin/ilmheg08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Kholusia" (click)="treasuremapChangeMap($event)">
        KHOLUSIA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="20.3,9.0,42" src="assets/img/treasuremap-icons/zonureskin/kholusia01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.1,10.8,42" src="assets/img/treasuremap-icons/zonureskin/kholusia02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.9,13.8,42" src="assets/img/treasuremap-icons/zonureskin/kholusia03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.3,16.9,42" src="assets/img/treasuremap-icons/zonureskin/kholusia04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.1,16.9,42" src="assets/img/treasuremap-icons/zonureskin/kholusia05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.0,17.2,42" src="assets/img/treasuremap-icons/zonureskin/kholusia06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="7.9,18.0,42" src="assets/img/treasuremap-icons/zonureskin/kholusia07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="33.3,31.6,42" src="assets/img/treasuremap-icons/zonureskin/kholusia08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Lakeland" (click)="treasuremapChangeMap($event)">
        LAKELAND
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="18.2,7.6,42" src="assets/img/treasuremap-icons/zonureskin/lakeland01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.7,12.3,42" src="assets/img/treasuremap-icons/zonureskin/lakeland02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.8,12.8,42" src="assets/img/treasuremap-icons/zonureskin/lakeland03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="38.1,13.8,42" src="assets/img/treasuremap-icons/zonureskin/lakeland04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.9,17.0,42" src="assets/img/treasuremap-icons/zonureskin/lakeland05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="8.3,21.1,42" src="assets/img/treasuremap-icons/zonureskin/lakeland06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.4,25.4,42" src="assets/img/treasuremap-icons/zonureskin/lakeland07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="34.8,25.7,42" src="assets/img/treasuremap-icons/zonureskin/lakeland08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheRaktikaGreatwood" (click)="treasuremapChangeMap($event)">
        THE RAK'TIKA GREATWOOD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="24.4,15.4,42" src="assets/img/treasuremap-icons/zonureskin/raktika01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="34.5,17.0,42" src="assets/img/treasuremap-icons/zonureskin/raktika02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.6,19.5,42" src="assets/img/treasuremap-icons/zonureskin/raktika03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.5,24.0,42" src="assets/img/treasuremap-icons/zonureskin/raktika04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.2,22.4,42" src="assets/img/treasuremap-icons/zonureskin/raktika05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.9,27.6,42" src="assets/img/treasuremap-icons/zonureskin/raktika06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.7,32.4,42" src="assets/img/treasuremap-icons/zonureskin/raktika07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="26.1,34.7,42" src="assets/img/treasuremap-icons/zonureskin/raktika08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheTempest" (click)="treasuremapChangeMap($event)">
        THE TEMPEST
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="32.8,5.1,42" src="assets/img/treasuremap-icons/zonureskin/tempest01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="19.1,8.1,42" src="assets/img/treasuremap-icons/zonureskin/tempest02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.0,11.0,42" src="assets/img/treasuremap-icons/zonureskin/tempest03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.5,11.5,42" src="assets/img/treasuremap-icons/zonureskin/tempest04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.9,14.8,42" src="assets/img/treasuremap-icons/zonureskin/tempest05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="16.2,18.5,42" src="assets/img/treasuremap-icons/zonureskin/tempest06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="37.3,17.8,42" src="assets/img/treasuremap-icons/zonureskin/tempest07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.0,20.7,42" src="assets/img/treasuremap-icons/zonureskin/tempest08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>
