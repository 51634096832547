<h1 class="seoh1">Final Fantasy XIV - Heavensward Aether Current Locations</h1>
<div class="g-left-sidebar">
  <div class="button" data="AzysLla;44;10.0,10.0;27.0,10.0;9.0,11.0;37.0,11.0;18.0,31.0" (click)="acChangeMap($event)">
    AZYS LLA
  </div>
  <dl class="accordion js-badger-accordion-acshb">
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasWesternHighlands;44;20.5,8.9;31.5,11.8;9.3,15.0;16.3,18.7;30.0,19.3;20.8,21.3;15.8,22.3;29.1,26.2;35.1,27.0;30.6,33.7" (click)="acChangeMap($event)">
        COERTHAS WESTERN HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="44;16.0,21.0;17.0,22.0;16.0,23.0;18.0,23.0;32.0,35.0" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheChurningMists;44;34.5,15.8;29.3,19.9;27.4,26.1;7.0,27.4;20.8,27.4;28.4,28.3;21.0,30.1;16.9,33.6;35.9,33.8;30.9,35.7" (click)="acChangeMap($event)">
        THE CHURNING MISTS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="44;32.0,15.0;12.0,29.0;27.0,33.0;29.0,36.0" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheDravanianForelands;44;12.9,14.0;31.2,16.8;24.5,19.8;39.9,21.9;32.3,23.6;14.4,27.0;37.8,28.3;17.3,29.6;26.7,30.0;30.6,36.2" (click)="acChangeMap($event)">
        THE DRAVANIAN FORELANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="44;25.0,19.0;24.0,20.0;16.0,23.0;31.0,24.0;32.0,24.0" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheDravanianHinterlands;44;18.5,13.5;12.8,16.9;24.5,19.0;29.5,21.3;31.9,22.0;14.7,24.7;37.1,25.5;13.4,35.9;21.5,36.9;25.1,36.9" (click)="acChangeMap($event)">
        THE DRAVANIAN HINTERLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="44;21.0,18.0;12.0,37.0;13.0,37.0" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheSeaofClouds;44;15.2,9.3;27.3,10.9;18.9,11.6;12.5,11.8;11.2,15.5;7.3,20.3;7.3,25.8;9.2,33.0;10.6,34.2;16.2,37.6" (click)="acChangeMap($event)">
        THE SEA OF CLOUDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="44;10.0,14.0;9.0,15.0;10.0,16.0;17.0,37.0" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
  </dl>
  <div class="button" data="Idyllshire;11.2;5.0,6.0;7.0,6.0" (click)="acChangeMap($event)">
    IDYLLSHIRE
  </div>
  <div class="button" data="IshgardFoundation;21.5;13.0,11.0" (click)="acChangeMap($event)">
    ISHGARD (FOUNDATION)
  </div>
  <div class="button" data="IshgardPillars;21.5;11.0,11.0" (click)="acChangeMap($event)">
    ISHGARD (PILLARS)
  </div>
</div>
<div id="map-viewer">
</div>
