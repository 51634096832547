import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from './filter.pipe';
import { SortByPipe } from './sort.pipe';
import { HttpClientModule } from '@angular/common/http';

import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { PreloadvoiceiconsComponent } from './charactervoices/preloadvoiceicons/preloadvoiceicons.component';
import { DiscordoauthComponent } from './discordoauth/discordoauth.component';
import { LoginComponent } from './discordoauth/login/login.component';

import { KnowledgebaseComponent } from './knowledgebase/knowledgebase.component';

import { RaidsComponent } from './raids/raids.component';
  import { O12sComponent } from './raids/o12s/o12s.component';
  import { O12s2Component } from './raids/o12s2/o12s2.component';

import { TriadsolverComponent } from './triadsolver/triadsolver.component';
  import { KingelmerComponent } from './triadsolver/kingelmer/kingelmer.component';

import { MinicactpotsolverComponent } from './minicactpotsolver/minicactpotsolver.component';

import { CharactervoicesComponent } from './charactervoices/charactervoices.component';
  import { FemaleComponent } from './charactervoices/female/female.component';
    import { FhyurMidlanderComponent } from './charactervoices/female/fhyur-midlander/fhyur-midlander.component';
    import { FhyurHighlanderComponent } from './charactervoices/female/fhyur-highlander/fhyur-highlander.component';
    import { FelezenComponent } from './charactervoices/female/felezen/felezen.component';
    import { FlalafellComponent } from './charactervoices/female/flalafell/flalafell.component';
    import { FmiqoteComponent } from './charactervoices/female/fmiqote/fmiqote.component';
    import { FroegadynComponent } from './charactervoices/female/froegadyn/froegadyn.component';
  import { MaleComponent } from './charactervoices/male/male.component';
    import { MhyurMidlanderComponent } from './charactervoices/male/mhyur-midlander/mhyur-midlander.component';
    import { MhyurHighlanderComponent } from './charactervoices/male/mhyur-highlander/mhyur-highlander.component';

    import { MlalafellComponent } from './charactervoices/male/mlalafell/mlalafell.component';
    import { MmiqoteComponent } from './charactervoices/male/mmiqote/mmiqote.component';

import { CardlistComponent } from './cardlist/cardlist.component';

import { TreasuremapsComponent } from './treasuremaps/treasuremaps.component';
  import { ZonureskinComponent } from './treasuremaps/zonureskin/zonureskin.component';
  import { GliderskinComponent } from './treasuremaps/gliderskin/gliderskin.component';
  import { FabledthiefsComponent } from './treasuremaps/fabledthiefs/fabledthiefs.component';
  import { GazelleskinComponent } from './treasuremaps/gazelleskin/gazelleskin.component';
  import { GaganaskinComponent } from './treasuremaps/gaganaskin/gaganaskin.component';
  import { DragonskinComponent } from './treasuremaps/dragonskin/dragonskin.component';
  import { WyvernskinComponent } from './treasuremaps/wyvernskin/wyvernskin.component';
  import { ArchaeoskinComponent } from './treasuremaps/archaeoskin/archaeoskin.component';
  import { UnhiddenleatherComponent } from './treasuremaps/unhiddenleather/unhiddenleather.component';
  import { PeisteskinComponent } from './treasuremaps/peisteskin/peisteskin.component';
  import { BoarskinComponent } from './treasuremaps/boarskin/boarskin.component';
  import { ToadskinComponent } from './treasuremaps/toadskin/toadskin.component';
  import { GoatskinComponent } from './treasuremaps/goatskin/goatskin.component';
  import { LeatherComponent } from './treasuremaps/leather/leather.component';
  import { SaigaskinComponent } from './treasuremaps/saigaskin/saigaskin.component';
  import { KumbhiraskinComponent } from './treasuremaps/kumbhiraskin/kumbhiraskin.component';

import { ElitehuntsComponent } from './elitehunts/elitehunts.component';

import { GatheringComponent } from './gathering/gathering.component';

import { HuntinglogComponent } from './huntinglog/huntinglog.component';
  import { HlgladiatorComponent } from './huntinglog/hlgladiator/hlgladiator.component';
  import { HlpugilistComponent } from './huntinglog/hlpugilist/hlpugilist.component';
  import { HlmarauderComponent } from './huntinglog/hlmarauder/hlmarauder.component';
  import { HllancerComponent } from './huntinglog/hllancer/hllancer.component';
  import { HlarcherComponent } from './huntinglog/hlarcher/hlarcher.component';
  import { HlrogueComponent } from './huntinglog/hlrogue/hlrogue.component';
  import { HlconjurerComponent } from './huntinglog/hlconjurer/hlconjurer.component';
  import { HlthaumaturgeComponent } from './huntinglog/hlthaumaturge/hlthaumaturge.component';
  import { HlarcanistComponent } from './huntinglog/hlarcanist/hlarcanist.component';
  import { HlimmortalflamesComponent } from './huntinglog/hlimmortalflames/hlimmortalflames.component';
  import { HlmaelstromComponent } from './huntinglog/hlmaelstrom/hlmaelstrom.component';
  import { HltwinadderComponent } from './huntinglog/hltwinadder/hltwinadder.component';

  import { SightseeinglogComponent } from './sightseeinglog/sightseeinglog.component';

import { AethercurrentsComponent } from './aethercurrents/aethercurrents.component';
  import { AcheavenswardComponent } from './aethercurrents/acheavensward/acheavensward.component';
  import { AcstormbloodComponent } from './aethercurrents/acstormblood/acstormblood.component';
  import { AcshadowbringersComponent } from './aethercurrents/acshadowbringers/acshadowbringers.component';
  import { AcendwalkerComponent } from './aethercurrents/acendwalker/acendwalker.component';

import { ReportComponent } from './report/report.component';

import { DevComponent } from './dev/dev.component';

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    SortByPipe,
    TreasuremapsComponent,
    ElitehuntsComponent,
    SidebarComponent,
    HomeComponent,
    PreloaderComponent,
    PreloadvoiceiconsComponent,
    ZonureskinComponent,
    GliderskinComponent,
    GatheringComponent,
    AethercurrentsComponent,
    CharactervoicesComponent,
    FemaleComponent,
    MaleComponent,
    FhyurMidlanderComponent,
    TriadsolverComponent,
    KingelmerComponent,
    FabledthiefsComponent,
    ReportComponent,
    RaidsComponent,
    O12sComponent,
    AcheavenswardComponent,
    AcstormbloodComponent,
    AcshadowbringersComponent,
    O12s2Component,
    GazelleskinComponent,
    GaganaskinComponent,
    ArchaeoskinComponent,
    LeatherComponent,
    GoatskinComponent,
    ToadskinComponent,
    BoarskinComponent,
    PeisteskinComponent,
    UnhiddenleatherComponent,
    WyvernskinComponent,
    DragonskinComponent,
    CardlistComponent,
    DevComponent,
    FhyurHighlanderComponent,
    FelezenComponent,
    FlalafellComponent,
    FmiqoteComponent,
    FroegadynComponent,
    MhyurMidlanderComponent,
    MhyurHighlanderComponent,
    MlalafellComponent,
    MmiqoteComponent,
    HuntinglogComponent,
    HlgladiatorComponent,
    DiscordoauthComponent,
    LoginComponent,
    HlpugilistComponent,
    HlmarauderComponent,
    HllancerComponent,
    HlarcherComponent,
    HlrogueComponent,
    HlconjurerComponent,
    HlthaumaturgeComponent,
    HlarcanistComponent,
    HlmaelstromComponent,
    HlimmortalflamesComponent,
    HltwinadderComponent,
    SightseeinglogComponent,
    SaigaskinComponent,
    KumbhiraskinComponent,
    KnowledgebaseComponent,
    AcendwalkerComponent,
    MinicactpotsolverComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    MatExpansionModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
export class MatAccordion { }
