import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-o12s',
  templateUrl: './o12s.component.html',
  styleUrls: ['./o12s.component.less']
})
export class O12sComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
