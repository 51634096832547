import { Component, OnInit } from '@angular/core';
import BadgerAccordion from 'badger-accordion';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-huntinglog',
  templateUrl: './huntinglog.component.html',
  styleUrls: ['./huntinglog.component.less']
})
export class HuntinglogComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Hunting Log Locations'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Hunting Log, ffxiv hunting log'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Hunting Log Locations - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit() {
    const accordionDomNode = document.querySelector('.js-badger-accordion-huntlog');
    const accordion = new BadgerAccordion(accordionDomNode);
  }

  ngAfterViewInit() {
    var accordionPanels = document.getElementsByClassName("badger-accordion__panel") as HTMLCollectionOf<HTMLElement>;
  }

  btnShowClassMenu(event): void {
    var buttonssq = document.getElementsByClassName("button-sq") as HTMLCollectionOf<HTMLElement>;
    for (var j = 0; j < buttonssq.length; j++) {
      buttonssq[j].classList.remove("active");
    }
    event.currentTarget.classList.add("active");
  }
}
