<h1 class="seoh1">Final Fantasy XIV - Endwalker Aether Current Locations</h1>
<div class="g-left-sidebar">
    <div class="button" data="Elpis;42;34.0,23.6;11.8,31.2;6.3,29.7;10.3,24.9;13.6,15.5;16.3,20.7;13.4,7.6;18.5,32.5;6.2,19.3;24.5,21.5" (click)="acChangeMap($event)">
        ELPIS
    </div>
    <div class="button" data="Garlemald;42;23.1,23.4;29.6,31.8;24.1,18.0;23.0,11.1;18.5,13.0;9.4,14.9;11.1,22.8;29.1,11.8;25.3,34.3;17.7,29.9" (click)="acChangeMap($event)">
        GARLEMALD
    </div>
    <div class="button" data="Labyrinthos;42;28.4,6.0;22.1,15.7;18.9,35.0;10.5,34.7;11.3,19.0;18.0,16.6;22.3,25.0;31.4,16;15.1,13.5;36.4,22.8" (click)="acChangeMap($event)">
        LABYRINTHOS
    </div>
    <div class="button" data="MareLamentorum;42;22.3,18.1;18.9,20.0;22.0,10.5;21.9,13.7;27.8,9.5;34.5,13.3;33.3,23.7;29.2,27.6;6.8,6.8;11.8,9.5" (click)="acChangeMap($event)">
        MARE LAMENTORUM
    </div>
    <div class="button" data="Thavnair;42;32.4,18.2;23.8,14.5;27.3,29.9;17.9,32.2;20.4,7.2;22.5,25.2;27.5,30.9;11.8,10.2;19.1,15.7;23.3,12.5" (click)="acChangeMap($event)">
        THAVNAIR
    </div>
    <div class="button" data="UltimaThule;42;16.7,15.5;14.8,14.2;13.8,8.9;21.7,6.3;30.0,7.8;32.2,26.2;34.2,27.2;34.8,29.6;32.8,29.5;36.5,28.6" (click)="acChangeMap($event)">
        ULTIMA THULE
    </div>
  </div>
  <div id="map-viewer">
  </div>
  