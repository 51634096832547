<h1 class="seoh1">Final Fantasy XIV - Archer Hunting Log Locations</h1>
<div id="archer-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-archer">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">CENTRAL SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.5,16.2,42,5;22.6,17.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/LittleLadybug.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Little Ladybug</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="24.5,18.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Funguar.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Forest Funguar</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="23.5,23.7,42,4;22.9,21.8,42,3;21.1,18.3,42,5;26.9,18.1,42,5;26.4,20.5,42,5;25.4,22.1,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/WaterSprite.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Water Sprite</p></div>
                                <div class="level">Lv 5-9</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="26.3,20.5,42,4;26.5,18.1,42,4;25.8,21.6,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Black Eft</p></div>
                                <div class="level">Lv 6</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="31.4,20.9,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Anole</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="26.7,25.3,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Imp.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Trickster Imp</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="22.7,26.2,42,8;20.7,30.3,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Roselet.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Roselet</p></div>
                                <div class="level">Lv 10-13</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.1,26.4,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ground Squirrel</p></div>
                                <div class="level">Lv 2</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="25.6,26.9,42,5;25.9,28.5,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Miteling.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Miteling</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="27.4,25.7,42,3;26.9,25.1,42,3;27.0,23.4,42,3;27.0,22.2,42,3;28.1,21.9,42,3;27.8,21.2,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Midge Swarm</p></div>
                                <div class="level">Lv 5-6</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>



        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="20.7,30.3,42,8;19.0,27.6,42,8;22.7,26.2,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hornet.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Hornet Swarm</p></div>
                            <div class="level">Lv 10-13</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="28.1,29.7,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Arbor Buzzard</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="24.6,18.0,42,3;22.3,16.5,42,3;20.9,16.9,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Treant Sapling</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                    <div class="huntlog-element" data="19.4,27.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Skeleton.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Magicked Bones</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.5,27.7,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goblin.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Goblin Hunter</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                    <div class="huntlog-element" data="14.1,25.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mandragora.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mandragora</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                    <div class="huntlog-element" data="13.0,23.8,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Wild Hoglet</p></div>
                            <div class="level">Lv 14</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="14.9,28.0,42,4;17.0,25.8,42,4;20.4,29.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/OpoOpo.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lemur</p></div>
                            <div class="level">Lv 15-18</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="19.1,28.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Funguar.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Faerie Funguar</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="19.0,27.2,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gnat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Giant Gnat</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="19.7,30.1,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Poacher.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Raptor Poacher</p></div>
                            <div class="level">Lv 18</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="18.3,24.8,42,5;16.4,23.3,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Wild Boar</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.0,17.5,42,5;17.6,22.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Antelope.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Antelope Doe</p></div>
                            <div class="level">Lv 20-23</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.3,24.4,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Thickshell.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Stoneshell</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="13.1,21.9,42,5;11.1,21.3,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Forest Yarzon</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="29.1,35.5,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Apkallu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Apkallu</p></div>
                            <div class="level">Lv 30</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.4,7.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Yarzon Scavenger</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="22.4,19.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Chasm Buzzard</p></div>
                            <div class="level">Lv 25</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="16.3,12.9,42,5;18.9,10.4,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Basilisk.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sandskin Peiste</p></div>
                            <div class="level">Lv 26-28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="16.4,23.3,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Diseased Treant</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="19.5,19.0,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Overgrown Offering</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="22.9,19.3,42,3;24.4,18.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Poacher.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redbelly Sharpeye</p></div>
                            <div class="level">Lv 26-27</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.7,20.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Chigoe.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Jumping Djigga</p></div>
                            <div class="level">Lv 24</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="18.1,26.1,42,5;16.5,25.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Miteling.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Banemite</p></div>
                            <div class="level">Lv 27</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="17.2,27.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ziz</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.4,18.1,42,5;14.2,17.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Funguar.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Toadstool</p></div>
                            <div class="level">Lv 29-32</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="20.0,32.4,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Giant Pelican</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="31.4,26.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aurelia.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bloodshore Bell</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="18.7,28.0,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Jungle Coeurl</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.3,12.5,42,3;15.4,10.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/OpoOpo.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ringtail</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="15.2,18.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Highland Condor</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="28.2,23.5,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Salamander</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="22.8,32.0,42,5;24.5,33.1,42,3;22.5,34.0,42,3;22.0,35.7,42,3;20.6,33.8,42,3;16.3,33.4,42,3;15.2,36.1,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sandworm.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sandworm</p></div>
                            <div class="level">Lv 31-32</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="13.0,32.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Russet Yarzon</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="19.4,35.9,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Smoke Bomb</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="19.4,38.6,42,4;21.4,38.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/FallenPikeman.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Fallen Pikeman</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="11.2,16.8,42,9" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SprigganGraverobber.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Spriggan</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="11.1,22.9,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/FloatingEye.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Floating Eye</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="25.3,19.7,42,3;26.1,20.6,42,3;25.1,22.1,42,3;26.5,22.9,42,3;25.2,23.5,42,3;27.1,25.4,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/IceSprite.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ice Sprite</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="26.0,22.0,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Croc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Feral Croc</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="27.6,14.3,42,4;31.1,15.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Vodoriga.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Vodoriga</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="3.4,21.5,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Croc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Baritine Croc</p></div>
                            <div class="level">Lv 40</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="12.1,36.0,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lammergeyer</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="15.4,35.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Skeleton.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dead Man's Moan</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="13.4,16.9,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/ShelfclawReaver.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Shelfeye Reaver</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="22.3,18.9,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CoeurlclawCutter.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Tempered Gladiator</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="24.4,21.9,42,5;25.1,20.5,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/FloatingEye.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ahriman</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="26.0,20.8,42,5;27.3,22.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Old-Growth Treant</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="23.3,21.0,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Morbol.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Morbol</p></div>
                                <div class="level">Lv 43</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="28.7,16.5,42,4;27.2,15.8,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sylphlands Condor</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                        <div class="huntlog-element" data="23.7,14.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kedtrap.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Milkroot Sapling</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.1,24.9,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lesser Kalong</p></div>
                                <div class="level">Lv 36-37</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="11.3,18.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hippocerf.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hippocerf</p></div>
                                <div class="level">Lv 40</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                        <div class="huntlog-element" data="9.7,13.6,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dragonfly.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dragonfly</p></div>
                                <div class="level">Lv 40</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                        <div class="huntlog-element" data="14.0,26.9,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Giant.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Giant Reader</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="13.5,10.8,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Mudpuppy</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">5</div>
                        </div>
                        <div class="huntlog-element" data="28.8,6.8,42,5;33.1,11.3,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hippocerf.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hippogryph</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="12.1,12.4,42,3;10.6,13.0,42,3;10.3,14.9,42,3;12.9,16.6,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>5th Cohort Secutor</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>