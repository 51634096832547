<img src="assets/img/game-icons/orders-engage.webp">
<img src="assets/img/game-icons/emotes-yes.webp">
<img src="assets/img/game-icons/emotes-no.webp">
<img src="assets/img/game-icons/emotes-chuckle.webp">
<img src="assets/img/game-icons/emotes-laugh.webp">
<img src="assets/img/game-icons/emotes-happy.webp">
<img src="assets/img/game-icons/emotes-cheer.webp">
<img src="assets/img/game-icons/emotes-huh.webp">
<img src="assets/img/game-icons/emotes-surprised.webp">
<img src="assets/img/game-icons/emotes-upset.webp">
<img src="assets/img/game-icons/emotes-fume.webp">
<img src="assets/img/game-icons/emotes-angry.webp">
<img src="assets/img/game-icons/emotes-furious.webp">
<img src="assets/img/game-icons/emotes-stretch.webp">
<img src="assets/img/game-icons/emotes-doze.webp">
