<h1 class="seoh1">Final Fantasy XIV - Saigaskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
    <dl class="accordion js-badger-accordion-saigaskin">
        <!--
      <dt>
        <button class="js-badger-accordion-header" data="Elpis" (click)="treasuremapChangeMap($event)">
          ELPIS
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
        </div>
      </dd>
    -->
      <dt>
        <button class="js-badger-accordion-header" data="Garlemald" (click)="treasuremapChangeMap($event)">
          GARLEMALD
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="17.9,33.1,42" src="assets/img/treasuremap-icons/saigaskin/garlemald01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="13.2,22.3,42" src="assets/img/treasuremap-icons/saigaskin/garlemald02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="7.4,9.0,42" src="assets/img/treasuremap-icons/saigaskin/garlemald03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="29.2,28.9,42" src="assets/img/treasuremap-icons/saigaskin/garlemald04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="26.1,21.6,42" src="assets/img/treasuremap-icons/saigaskin/garlemald05.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="Labyrinthos" (click)="treasuremapChangeMap($event)">
          LABYRINTHOS
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="20.3,11.4,42" src="assets/img/treasuremap-icons/saigaskin/labyrinthos01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="31.6,23.0,42" src="assets/img/treasuremap-icons/saigaskin/labyrinthos02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="14.6,7.2,42" src="assets/img/treasuremap-icons/saigaskin/labyrinthos03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="30.2,16.0,42" src="assets/img/treasuremap-icons/saigaskin/labyrinthos04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="11.8,36.2,42" src="assets/img/treasuremap-icons/saigaskin/labyrinthos05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="8.4,18.3,42" src="assets/img/treasuremap-icons/saigaskin/labyrinthos06.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="MareLamentorum" (click)="treasuremapChangeMap($event)">
          MARE LAMENTORUM
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="29.4,31.6,42" src="assets/img/treasuremap-icons/saigaskin/marelamentorum01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="19.6,33.6,42" src="assets/img/treasuremap-icons/saigaskin/marelamentorum02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="24.7,26.1,42" src="assets/img/treasuremap-icons/saigaskin/marelamentorum03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="29.1,37.4,42" src="assets/img/treasuremap-icons/saigaskin/marelamentorum04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="16.7,22.5,42" src="assets/img/treasuremap-icons/saigaskin/marelamentorum05.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="Thavnair" (click)="treasuremapChangeMap($event)">
          THAVNAIR
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="10.0,16.6,42" src="assets/img/treasuremap-icons/saigaskin/thavnair01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="20.5,22.9,42" src="assets/img/treasuremap-icons/saigaskin/thavnair02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="19.0,32.5,42" src="assets/img/treasuremap-icons/saigaskin/thavnair03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="30.0,28.6,42" src="assets/img/treasuremap-icons/saigaskin/thavnair04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="19.0,12.8,42" src="assets/img/treasuremap-icons/saigaskin/thavnair05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="24.2,11.7,42" src="assets/img/treasuremap-icons/saigaskin/thavnair06.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="27.3,23.7,42" src="assets/img/treasuremap-icons/saigaskin/thavnair07.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="UltimaThule" (click)="treasuremapChangeMap($event)">
          ULTIMA THULE
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="10.1,19.9,42" src="assets/img/treasuremap-icons/saigaskin/ultimathule01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="21.8,30.6,42" src="assets/img/treasuremap-icons/saigaskin/ultimathule02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="31.5,13.5,42" src="assets/img/treasuremap-icons/saigaskin/ultimathule03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="22.0,6.2,42" src="assets/img/treasuremap-icons/saigaskin/ultimathule04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="7.4,24.3,42" src="assets/img/treasuremap-icons/saigaskin/ultimathule05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="10.4,33.5,42" src="assets/img/treasuremap-icons/saigaskin/ultimathule06.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="26.8,13.8,42" src="assets/img/treasuremap-icons/saigaskin/ultimathule07.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
    </dl>
  </div>
  <div id="map-viewer">
  </div>
  