<h1 class="seoh1">Final Fantasy XIV - Female Lalafell Voice Browser</h1>
<div class="alib-pagetitle">LALAFELL</div>
<div class="alib-charactervoices-content">
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 1</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl01combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl01combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl01combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl01combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl01combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl01combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl01yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl01no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl01chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl01laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl01happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl01cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl01huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl01surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl01upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl01fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl01angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl01furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl01stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl01doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 2</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl02combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl02combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl02combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl02combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl02combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl02yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl02no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl02chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl02laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl02happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl02cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl02huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl02surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl02upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl02fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl02angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl02furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl02stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl02doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 3</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe01combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe01combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe01combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe01combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe01combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe01combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fe01yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fe01no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fe01chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fe01laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fe01happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fe01cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fe01huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fe01surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fe01upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fe01fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fe01angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fe01furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fe01stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fe01doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 4</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl04combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl04combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl04combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl04combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl04combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl04combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl04yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl04no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl04chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl04laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl04happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl04cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl04huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl04surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl04upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl04fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl04angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl04furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl04stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl04doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 5</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl05combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl05combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl05combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl05combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl05combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl05combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl05yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl05no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl05chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl05laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl05happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl05cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl05huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl05surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl05upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl05fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl05angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl05furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl05stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl05doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 6</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe04combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe04combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe04combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe04combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe04combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe04combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fe04yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fe04no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fe04chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fe04laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fe04happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fe04cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fe04huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fe04surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fe04upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fe04fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fe04angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fe04furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fe04stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fe04doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 7</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl07combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl07combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl07combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl07combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl07combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl07combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl07yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl07no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl07chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl07laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl07happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl07cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl07huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl07surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl07upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl07fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl07angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl07furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl07stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl07doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 8</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl08combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl08combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl08combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl08combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl08combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl08combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl08yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl08no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl08chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl08laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl08happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl08cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl08huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl08surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl08upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl08fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl08angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl08furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl08stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl08doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 9</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe07combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe07combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe07combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe07combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe07combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe07combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fe07yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fe07no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fe07chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fe07laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fe07happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fe07cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fe07huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fe07surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fe07upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fe07fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fe07angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fe07furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fe07stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fe07doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 10</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl10combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl10combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl10combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl10combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl10combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl10combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl10yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl10no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl10chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl10laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl10happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl10cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl10huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl10surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl10upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl10fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl10angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl10furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl10stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl10doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 11</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl11combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl11combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl11combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl11combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl11combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fl11combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fl11yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fl11no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fl11chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fl11laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fl11happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fl11cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fl11huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fl11surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fl11upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fl11fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fl11angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fl11furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fl11stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fl11doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
  <div class="alib-charactervoices-player-wrapper">
    <br>
    <h1>VOICE 12</h1>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe10combat1" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe10combat2" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe10combat3" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe10combat4" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe10combat5" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/orders-engage.webp')">
        <div class="button" id="fe10combat6" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="combat"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-yes.webp')">
        <div class="button" id="fe10yes" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/yes"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-no.webp')">
        <div class="button" id="fe10no" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/no"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-chuckle.webp')">
        <div class="button" id="fe10chuckle" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/chuckle"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-laugh.webp')">
        <div class="button" id="fe10laugh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/laugh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-happy.webp')">
        <div class="button" id="fe10happy" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/happy"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-cheer.webp')">
        <div class="button" id="fe10cheer" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/cheer"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-huh.webp')">
        <div class="button" id="fe10huh" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/huh"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-surprised.webp')">
        <div class="button" id="fe10surprised" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/surprised"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-upset.webp')">
        <div class="button" id="fe10upset" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/upset"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-fume.webp')">
        <div class="button" id="fe10fume" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/fume"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-angry.webp')">
        <div class="button" id="fe10angry" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/angry"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-furious.webp')">
        <div class="button" id="fe10furious" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/furious"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-stretch.webp')">
        <div class="button" id="fe10stretch" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/stretch"></div>
      </div>
    </div>
    <div class="alib-charactervoices-player">
      <div class="alib-charactervoices-player-icon" style="background-image:url('assets/img/game-icons/emotes-doze.webp')">
        <div class="button" id="fe10doze" (click)="playSound($event)" data-tooltip-location="bottom" data-tooltip="/doze"></div>
      </div>
    </div>
  </div>
