<h1 class="seoh1">Final Fantasy XIV - Conjurer Hunting Log Locations</h1>
<div id="lancer-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-conjurer">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">CENTRAL SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.5,16.2,42,5;22.6,17.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/LittleLadybug.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Little Ladybug</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="24.5,18.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mushroom.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Forest Funguar</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="25.5,20.6,42,5;27.4,18.5,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Chigoe.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Chigoe</p></div>
                                <div class="level">Lv 5-9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="23.5,23.7,42,4;22.9,21.8,42,3;21.1,18.3,42,5;26.9,18.1,42,5;26.4,20.5,42,5;25.4,22.1,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/WaterSprite.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Water Sprite</p></div>
                                <div class="level">Lv 5-9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="26.7,25.3,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Syrphid Swarm</p></div>
                                <div class="level">Lv 8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.1,26.4,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ground Squirrel</p></div>
                                <div class="level">Lv 2</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="25.6,26.9,42,5;25.9,28.5,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Miteling.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Miteling</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="27.4,25.7,42,3;26.9,25.1,42,3;27.0,23.4,42,3;27.0,22.2,42,3;28.1,21.9,42,3;27.8,21.2,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Midge Swarm</p></div>
                                <div class="level">Lv 5-6</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="28.2,21.8,42,4;26.4,21.8,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Microchu</p></div>
                                <div class="level">Lv 6-8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="14.3,27.3,42,5;13,26,42,3;11.6,23.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Northern Vulture</p></div>
                                <div class="level">Lv 11-13</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="12.6,27.0,42,4;12.8,25.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Slug.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Tree Slug</p></div>
                            <div class="level">Lv 10-12</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="13.5,27.7,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goblin.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Goblin Hunter</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="14.1,25.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mandragora.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mandragora</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="14.9,28.0,42,4;19.5,29.1,42,4;17.0,25.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Weevil.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Boring Weevil</p></div>
                            <div class="level">Lv 15-18</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.1,28.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mushroom.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Faerie Funguar</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.0,27.2,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gnat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Giant Gnat</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.7,30.1,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CoeurlclawCutter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Wolf Poacher</p></div>
                            <div class="level">Lv 18</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="18.4,24.8,42,5;16.4,22.8,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Black Bat</p></div>
                            <div class="level">Lv 20-23</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="28.1,29.7,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Arbor Buzzard</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.4,27.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Firefly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Firefly</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.5,17.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Beater</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.3,24.4,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Thickshell.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Stoneshell</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="29.1,35.5,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Apkallu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Apkallu</p></div>
                            <div class="level">Lv 30</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.8,6.9,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Laughing Toad</p></div>
                            <div class="level">Lv 23</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="13.8,10.0,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lead Coblyn</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="21.1,8.6,42,4;15.0,14.3,42,6;16.6,15.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Cactuar.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sabotender</p></div>
                            <div class="level">Lv 25-27</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.6,23.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Roerunner</p></div>
                            <div class="level">Lv 26</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="16.4,23.3,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Diseased Treant</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="15.7,20.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Firefly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Glowfly</p></div>
                            <div class="level">Lv 24</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.8,22.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bark Eft</p></div>
                            <div class="level">Lv 23</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="26.8,18.2,42,5;26.8,22.1,42,3;21.9,19.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Antelope.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Antelope Stag</p></div>
                            <div class="level">Lv 25-28</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="27.5,21.4,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goblin.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Goblin Thug</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.4,18.1,42,5;14.2,17.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mushroom.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Toadstool</p></div>
                            <div class="level">Lv 29-32</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.5,26,42,5;17.2,27.1,42,4;14.9,28.8,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Gigantoad</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="31.4,26.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aurelia.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bloodshore Bell</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="33.3,25.6,42,4;34.9,23.9,42,4;28.5,25.6,42,4;27.5,23.2,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/MamoolJaSophist.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mamool Ja Infiltrator</p></div>
                            <div class="level">Lv 31-33</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="22.8,32.0,42,5;24.5,33.1,42,3;22.5,34.0,42,3;22.0,35.7,42,3;20.6,33.8,42,3;16.3,33.4,42,3;15.2,36.1,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Sandworm.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sandworm</p></div>
                            <div class="level">Lv 31-32</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.3,18.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lindwurm</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="11.4,20.3,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bogy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Revenant</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="18.1,30.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Orobon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bigmouth Orobon</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="23.1,24.9,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Deepvoid Deathmouse</p></div>
                            <div class="level">Lv 36-37</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="23.4,25.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Firefly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Will-o'-the-wisp</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.1,25.1,42,6;22.4,23.2,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Dryad</p></div>
                            <div class="level">Lv 37</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="25.9,20.1,42,9;22.2,16.5,42,4;28.2,13.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ornery Karakul</p></div>
                            <div class="level">Lv 36-39</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="26.9,10.3,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aevis.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Downy Aevis</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="9.7,13.6,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dragonfly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Dragonfly</p></div>
                            <div class="level">Lv 40</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">OUTER LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="22.2,14.1,42,6;22.9,11.8,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Grenade</p></div>
                                <div class="level">Lv 41-44</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="12.1,36.0,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lammergeyer</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="15.4,35.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Skeleton.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dead Man's Moan</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                        <div class="huntlog-element" data="14.3,17.1,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aurelia.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sea Wasp</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="29.8,20.3,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/5thCohortVanguard.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>2nd Cohort Vanguard</p></div>
                                <div class="level">Lv 50</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="27.7,24.1,42,8;25.6,26.9,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Fleece.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Golden Fleece</p></div>
                                <div class="level">Lv 40-42</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="24.4,21.9,42,5;25.1,20.5,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/FloatingEye.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ahriman</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="29.1,20.7,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>3rd Cohort Hoplomachus</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="25.9,13.1,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mushroom.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sylph Bonnet</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.1,24.9,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lesser Kalong</p></div>
                                <div class="level">Lv 36-37</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="11.3,18.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hippocerf.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hippocerf</p></div>
                                <div class="level">Lv 40</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="11.8,30.6,42,5;13.2,31.3,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Snow Wolf</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="12.2,12.5,42,3;9.3,14.1,42,3;10.8,15.0,42,3;11.7,16.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>5th Cohort Eques</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>