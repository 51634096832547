<h1 class="seoh1">Final Fantasy XIV - Fabled Thief's Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-fabledthiefs">
    <dt>
      <button class="js-badger-accordion-header" data="TheAzimSteppe" (click)="treasuremapChangeMap($event)">
        THE AZIM STEPPE
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="19.0,23.5,42" src="assets/img/treasuremap-icons/fabledthiefs/azimsteppe01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.3,25.3,42" src="assets/img/treasuremap-icons/fabledthiefs/azimsteppe02.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheLochs" (click)="treasuremapChangeMap($event)">
        THE LOCHS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="21.2,15.8,42" src="assets/img/treasuremap-icons/fabledthiefs/lochs01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.3,19.0,42" src="assets/img/treasuremap-icons/fabledthiefs/lochs02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.0,21.0,42" src="assets/img/treasuremap-icons/fabledthiefs/lochs03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.5,25.6,42" src="assets/img/treasuremap-icons/fabledthiefs/lochs04.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheRubySea" (click)="treasuremapChangeMap($event)">
        THE RUBY SEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="28.5,14.6,42" src="assets/img/treasuremap-icons/fabledthiefs/rubysea01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.8,18.2,42" src="assets/img/treasuremap-icons/fabledthiefs/rubysea02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.4,22.9,42" src="assets/img/treasuremap-icons/fabledthiefs/rubysea03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.1,25.5,42" src="assets/img/treasuremap-icons/fabledthiefs/rubysea04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="34.7,30.5,42" src="assets/img/treasuremap-icons/fabledthiefs/rubysea05.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Yanxia" (click)="treasuremapChangeMap($event)">
        YANXIA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="11.5,9.0,42" src="assets/img/treasuremap-icons/fabledthiefs/yanxia01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="8.3,16.4,42" src="assets/img/treasuremap-icons/fabledthiefs/yanxia02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="9.2,32.7,42" src="assets/img/treasuremap-icons/fabledthiefs/yanxia03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.3,34.8,42" src="assets/img/treasuremap-icons/fabledthiefs/yanxia04.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>