import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-charactervoices',
  templateUrl: './charactervoices.component.html',
  styleUrls: ['./charactervoices.component.less']
})
export class CharactervoicesComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Character Voice Previews'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, ffxiv character voices'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Character Voices - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    var getVolumeCookie = document.cookie.match(new RegExp('(^| )' + "userpref_volume" + '=([^;]+)'));
    if (getVolumeCookie != null) {
      var userPrefVolume = getVolumeCookie[2];
      (document.getElementById("charactervoicesVolume") as HTMLInputElement).value = userPrefVolume;
    }
  }

  changeVolume() {
    var sliderAmount = (document.getElementById("charactervoicesVolume") as HTMLInputElement).value;
    document.cookie = "userpref_volume=" + (sliderAmount);
  }
}
