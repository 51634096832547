<h1 class="seoh1">Final Fantasy XIV - Boarskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-boarskin">
    <dt>
      <button class="js-badger-accordion-header">
        THANALAN
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="16.7,16.1,42" src="assets/img/treasuremap-icons/boarskin/centralthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.4,20.7,42" src="assets/img/treasuremap-icons/boarskin/centralthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="26.7,30.8,42" src="assets/img/treasuremap-icons/boarskin/centralthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="14.7,16.4,42" src="assets/img/treasuremap-icons/boarskin/easternthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="10.5,22.9,42" src="assets/img/treasuremap-icons/boarskin/easternthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="20.7,28.7,42" src="assets/img/treasuremap-icons/boarskin/easternthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="NorthernThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">NORTHERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="19.8,18.6,42" src="assets/img/treasuremap-icons/boarskin/northernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="19.6,21.1,42" src="assets/img/treasuremap-icons/boarskin/northernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.5,23.6,42" src="assets/img/treasuremap-icons/boarskin/northernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthernThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTHERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="21.1,29.0,42" src="assets/img/treasuremap-icons/boarskin/southernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="12.9,35.6,42" src="assets/img/treasuremap-icons/boarskin/southernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="17.6,39.9,42" src="assets/img/treasuremap-icons/boarskin/southernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="15.6,17.7,42" src="assets/img/treasuremap-icons/boarskin/westernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.9,20.9,42" src="assets/img/treasuremap-icons/boarskin/westernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.9,25.7,42" src="assets/img/treasuremap-icons/boarskin/westernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        LA NOSCEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="16.6,24.6,42" src="assets/img/treasuremap-icons/boarskin/easternlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.6,25.0,42" src="assets/img/treasuremap-icons/boarskin/easternlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="26.6,35.0,42" src="assets/img/treasuremap-icons/boarskin/easternlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="LowerLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">LOWER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="28.3,20.1,42" src="assets/img/treasuremap-icons/boarskin/lowerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.0,21.7,42" src="assets/img/treasuremap-icons/boarskin/lowerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.8,35.9,42" src="assets/img/treasuremap-icons/boarskin/lowerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="MiddleLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="22.8,17.0,42" src="assets/img/treasuremap-icons/boarskin/middlelanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="17.6,19.9,42" src="assets/img/treasuremap-icons/boarskin/middlelanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="23.1,26.2,42" src="assets/img/treasuremap-icons/boarskin/middlelanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="OuterLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">OUTER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="22.7,11.5,42" src="assets/img/treasuremap-icons/boarskin/outerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.2,12.5,42" src="assets/img/treasuremap-icons/boarskin/outerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.5,17.0,42" src="assets/img/treasuremap-icons/boarskin/outerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="UpperLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">UPPER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="11.7,21.7,42" src="assets/img/treasuremap-icons/boarskin/upperlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.9,21.5,42" src="assets/img/treasuremap-icons/boarskin/upperlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="36.1,24.8,42" src="assets/img/treasuremap-icons/boarskin/upperlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="19.0,22.5,42" src="assets/img/treasuremap-icons/boarskin/westernlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="30.7,29.8,42" src="assets/img/treasuremap-icons/boarskin/westernlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.7,35.0,42" src="assets/img/treasuremap-icons/boarskin/westernlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        THE BLACK SHROUD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="28.5,20.6,42" src="assets/img/treasuremap-icons/boarskin/centralshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="13.9,21.6,42" src="assets/img/treasuremap-icons/boarskin/centralshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.3,25.2,42" src="assets/img/treasuremap-icons/boarskin/centralshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EastShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EAST SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="17.6,19.3,42" src="assets/img/treasuremap-icons/boarskin/eastshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.2,21.8,42" src="assets/img/treasuremap-icons/boarskin/eastshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="12.9,27.3,42" src="assets/img/treasuremap-icons/boarskin/eastshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="NorthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">NORTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="17.7,26.1,42" src="assets/img/treasuremap-icons/boarskin/northshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.7,26.5,42" src="assets/img/treasuremap-icons/boarskin/northshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.5,28.0,42" src="assets/img/treasuremap-icons/boarskin/northshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="19.3,19.1,42" src="assets/img/treasuremap-icons/boarskin/southshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="26.6,22.4,42" src="assets/img/treasuremap-icons/boarskin/southshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="20.1,28.7,42" src="assets/img/treasuremap-icons/boarskin/southshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasCentralHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS CENTRAL HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="23.5,8.5,42" src="assets/img/treasuremap-icons/boarskin/coerthascentral01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="7.7,12.6,42" src="assets/img/treasuremap-icons/boarskin/coerthascentral02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="6.0,18.5,42" src="assets/img/treasuremap-icons/boarskin/coerthascentral03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="MorDhona" (click)="treasuremapChangeMap($event)">
        MOR DHONA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="15.3,11.0,42" src="assets/img/treasuremap-icons/boarskin/mordhona01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.4,10.9,42" src="assets/img/treasuremap-icons/boarskin/mordhona02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.8,12.0,42" src="assets/img/treasuremap-icons/boarskin/mordhona03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>
