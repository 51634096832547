<h1 class="seoh1">Final Fantasy XIV - Gliderskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-gliderskin">
    <dt>
      <button class="js-badger-accordion-header" data="AmhAraeng" (click)="treasuremapChangeMap($event)">
        AMH ARAENG
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="19.9,8.8,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.3,13.9,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="19.7,16.7,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.5,19.6,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="9.6,22.6,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.2,24.1,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.1,24.3,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.1,27.8,42" src="assets/img/treasuremap-icons/gliderskin/amharaeng08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="IlMheg" (click)="treasuremapChangeMap($event)">
        IL MHEG
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="6.5,20.6,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.4,21.5,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="4.3,25.5,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="6.7,28.8,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.6,27.9,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.9,35.0,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.3,35.2,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.9,37.2,42" src="assets/img/treasuremap-icons/gliderskin/ilmheg08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Kholusia" (click)="treasuremapChangeMap($event)">
        KHOLUSIA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="28.1,12.8,42" src="assets/img/treasuremap-icons/gliderskin/kholusia01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.0,19.5,42" src="assets/img/treasuremap-icons/gliderskin/kholusia02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="8.5,22.1,42" src="assets/img/treasuremap-icons/gliderskin/kholusia03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="19.5,21.6,42" src="assets/img/treasuremap-icons/gliderskin/kholusia04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.1,24.1,42" src="assets/img/treasuremap-icons/gliderskin/kholusia05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="37.4,27.4,42" src="assets/img/treasuremap-icons/gliderskin/kholusia06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.2,32.3,42" src="assets/img/treasuremap-icons/gliderskin/kholusia07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.6,32.8,42" src="assets/img/treasuremap-icons/gliderskin/kholusia08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Lakeland" (click)="treasuremapChangeMap($event)">
        LAKELAND
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="20.8,9.5,42" src="assets/img/treasuremap-icons/gliderskin/lakeland01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="33.5,15.2,42" src="assets/img/treasuremap-icons/gliderskin/lakeland02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.5,16.1,42" src="assets/img/treasuremap-icons/gliderskin/lakeland03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.7,18.6,42" src="assets/img/treasuremap-icons/gliderskin/lakeland04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.0,21.1,42" src="assets/img/treasuremap-icons/gliderskin/lakeland05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.0,20.4,42" src="assets/img/treasuremap-icons/gliderskin/lakeland06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.2,27.2,42" src="assets/img/treasuremap-icons/gliderskin/lakeland07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="26.4,32.4,42" src="assets/img/treasuremap-icons/gliderskin/lakeland08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheRaktikaGreatwood" (click)="treasuremapChangeMap($event)">
        THE RAK'TIKA GREATWOOD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="30.1,11.5,42" src="assets/img/treasuremap-icons/gliderskin/raktika01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.8,14.4,42" src="assets/img/treasuremap-icons/gliderskin/raktika02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="6.5,21.2,42" src="assets/img/treasuremap-icons/gliderskin/raktika03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="16.0,23.3,42" src="assets/img/treasuremap-icons/gliderskin/raktika04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.0,24.1,42" src="assets/img/treasuremap-icons/gliderskin/raktika05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.3,31.4,42" src="assets/img/treasuremap-icons/gliderskin/raktika06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="6.2,33.8,42" src="assets/img/treasuremap-icons/gliderskin/raktika07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.5,33.0,42" src="assets/img/treasuremap-icons/gliderskin/raktika08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheTempest" (click)="treasuremapChangeMap($event)">
        THE TEMPEST
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="26.1,5.3,42" src="assets/img/treasuremap-icons/gliderskin/tempest01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.2,11.9,42" src="assets/img/treasuremap-icons/gliderskin/tempest02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.6,15.4,42" src="assets/img/treasuremap-icons/gliderskin/tempest03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.1,19.6,42" src="assets/img/treasuremap-icons/gliderskin/tempest04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.9,21.6,42" src="assets/img/treasuremap-icons/gliderskin/tempest05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.2,22.9,42" src="assets/img/treasuremap-icons/gliderskin/tempest06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="36.2,22.4,42" src="assets/img/treasuremap-icons/gliderskin/tempest07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.4,29.9,42" src="assets/img/treasuremap-icons/gliderskin/tempest08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>