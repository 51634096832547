<div class="g-left-sidebar">
  <a routerLink="mhyur-midlander">
    <div class="button">HYUR MIDLANDER</div>
  </a>
  <a routerLink="mhyur-highlander">
    <div class="button">HYUR HIGHLANDER</div>
  </a>
  <div class="button disabled-button disabled-text">ELEZEN</div>
  <div class="button disabled-button disabled-text">LALAFELL</div>
  <div class="button disabled-button disabled-text">MIQO'TE</div>
  <div class="button disabled-button disabled-text">ROEGADYN</div>
  <div class="button disabled-button disabled-text">AU RA</div>
  <div class="button disabled-button disabled-text">HROTHGAR</div>
</div>
<div id="alib-charactervoices-content-wrapper">
  <router-outlet></router-outlet>
</div>
