<div id="nav">
  <nav>
    <div class="nav-button disabled-button disabled-text">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-book.webp')"></div>
      </div>
      <p>ENCYCLOPEDIA</p>
    </div>
    <div class="nav-divider">
      <div class="nav-divider-text">TOOLS</div>
    </div>
    <div class="nav-button disabled-button disabled-text" style="display:none">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-armor.webp')"></div>
      </div>
      <p>BiS SHEET</p>
    </div>
    <div class="nav-button disabled-button disabled-text" style="display:none">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-savage.webp')"></div>
      </div>
      <p>RAID GUIDES</p>
    </div>
    <a routerLink="/charactervoices">
      <div class="nav-button">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-yelling.webp')"></div>
        </div>
        <p>CHARACTER VOICES</p>
      </div>
    </a>
    <div class="nav-button disabled-button disabled-text" style="display:none">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-triad.webp')"></div>
      </div>
      <p>TRIPLE TRIAD</p>
    </div>
    <a routerLink="/cardlist">
      <div class="nav-button">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-cards.webp')"></div>
        </div>
        <p>CARD CHECKLIST</p>
      </div>
    </a>
    <div class="nav-button disabled-button disabled-text" style="display:none">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-chicken.webp')"></div>
      </div>
      <p>MOUNT CHECKLIST</p>
    </div>
    <div class="nav-button disabled-button disabled-text" style="display:none">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-bunny.webp')"></div>
      </div>
      <p>MINION CHECKLIST</p>
    </div>
    <div class="nav-divider">
      <div class="nav-divider-text">SOLVERS</div>
    </div>
    <a routerLink="/minicactpot">
      <div class="nav-button">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-cactpot.webp')"></div>
        </div>
        <p>MINI CACTPOT SOLVER</p>
      </div>
    </a>
    <div class="nav-divider">
      <div class="nav-divider-text">MAPS</div>
    </div>
    <a routerLink="/treasuremaps">
      <div class="nav-button">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-cross.webp')"></div>
        </div>
        <p>TREASURE MAPS</p>
      </div>
    </a>
    <a routerLink="/elitehunts">
      <div class="nav-button">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-enemy.webp')"></div>
        </div>
        <p>ELITE HUNTS</p>
      </div>
    </a>
    <div class="nav-button disabled-button disabled-text" style="display:none">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-pickaxe.webp')"></div>
      </div>
      <p>GATHERING</p>
    </div>
    <a routerLink="/huntinglog">
      <div class="nav-button">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-checklist.webp')"></div>
        </div>
        <p>HUNTING LOG</p>
      </div>
    </a>
    <div class="nav-button disabled-button disabled-text" style="display:none">
      <div class="nav-button-icon-bg disabled-navicon">
        <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-foot.webp')"></div>
      </div>
      <p>SIGHTSEEING LOG</p>
    </div>
    <a routerLink="/aethercurrents">
      <div class="nav-button">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-wing.webp')"></div>
        </div>
        <p>AETHER CURRENTS</p>
      </div>
    </a>
    <div class="nav-divider">
      <div class="nav-divider-text">v{{version}}</div>
    </div>
    <a routerLink="/report">
      <div class="nav-button-ad">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-report.webp')"></div>
        </div>
        <p>REPORT AN ERROR</p>
      </div>
    </a>
    <a href="https://www.ko-fi.com/chriscutie" target="_blank">
      <div class="nav-button-ad">
        <div class="nav-button-icon-bg">
          <div class="nav-button-icon" style="background-image:url('assets/img/icons/alib-nav-heart.webp')"></div>
        </div>
        <p>BUY US A COFFEE</p>
      </div>
    </a>
  </nav>
</div>
