<h1 class="seoh1">Final Fantasy XIV - Aether Current Locations</h1>
  <div id="g-topnav">
    <a routerLink="heavensward">
      <div class="topnav-button">
        <p>HEAVENSWARD</p>
      </div>
    </a>
    <a routerLink="stormblood">
      <div class="topnav-button">
        <p>STORMBLOOD</p>
      </div>
    </a>
    <a routerLink="shadowbringers">
      <div class="topnav-button">
        <p>SHADOWBRINGERS</p>
      </div>
    </a>
    <a routerLink="endwalker">
      <div class="topnav-button">
        <p>ENDWALKER</p>
      </div>
    </a>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>
