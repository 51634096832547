<div class="alib-tts-content">
  <div id="home">
    <div class="title">KING ELMER III SOLVER</div>
    <div class="firstcard" id="a" (click)="funcSolver($event)">YOU GO FIRST</div>
    <div class="firstcard" id="b" (click)="funcSolver($event)">OPPONENT GOES FIRST</div>
    <div class="userdeck">
      <p>YOUR DECK</p>
      <img src="assets/img/tripletriad/Minfilia.webp">
      <img src="assets/img/tripletriad/Sylph.webp">
      <img src="assets/img/tripletriad/Morbol.webp">
      <img src="assets/img/tripletriad/Bomb.webp">
      <img src="assets/img/tripletriad/Moogle.webp">
    </div>
    <div id="faq" data-tooltip-location="bottom" data-tooltip="What is this?
    This is a tool developed to make farming King Elmer III as easy as possible.
    The winrate should be around 85%.
    
    How do I use this?
    The tool will tell you where to place what card, and ask where Elmer placed his.
    If Elmer made a move that isn't in the options, the match will most likely be lost.">FAQ</div>
  </div>
  <div id="solverwrap">
    <div id="navigation">
      <div class="ttsnavbtn" id="return" (click)="funcSolver($event)">RETURN TO START</div>
      <div class="ttsnavbtn" id="prev" (click)="funcSolver($event)">PREVIOUS STEP</div>
    </div>
    <div id="solver">
      <div id="playermove">
        <div id="playercard"></div>
        <div id="playersquare"></div>
      </div>
      <div id="opponentmove">
        <div id="congratulations" style="display:none" (click)="funcSolver($event)">Congratulations!<br>RETURN TO START</div>
        <div class="possiblemove" id="1" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="2" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="3" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="4" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="5" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="6" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="7" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="8" style="display:none" (click)="funcSolver($event)"></div>
        <div class="possiblemove" id="9" style="display:none" (click)="funcSolver($event)"></div>
      </div>
    </div>
  </div>
</div>
