import { Component, ComponentFactoryResolver, OnInit, ɵsetDocument } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-minicactpotsolver',
  templateUrl: './minicactpotsolver.component.html',
  styleUrls: ['./minicactpotsolver.component.less']
})
export class MinicactpotsolverComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Mini Cactpot Solver'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Mini Cactpot Solver'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Mini Cactpot Solver - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }


  inputDetected(value) {
    //console.log("##### inputDetected #####")
    //console.log("inputDetected - detected value " + value[2] + " at square " + value[0] + '' + value[1]);
    document.getElementById("input" + value[0] + '' + value[1]).dataset.input = value[2];
    document.getElementById("input" + value[0] + '' + value[1]).classList.add("hidden");
    document.getElementById("numsel" + value[0] + '' + value[1]).innerHTML = value[2];
    document.getElementById("numsel" + value[0] + '' + value[1]).classList.remove("hidden");
    var state = this.getState();
    if (state == null) {
      //console.log("inputDetected - state is null");
      return;
    }
    switch (state.hiddenIndices.length) {
      case 9:
      case 8:
      case 7:
      case 6:
        //console.log("inputDetected - recommend called");
        this.recommend();
        break;
      case 5:
        //console.log("InputDetected - calc called");
        this.calc();
        break;
      default:
    }
  }

  getState() {
    //console.log("##### getState #####")
    var revealedValues = []
    var hiddenIndices = [];
    var board = new Array(9);
    for (var row = 0; row < 3; row++) {
      for (var col = 0; col < 3; col++) {
        var index = col + row*3;
        var val = parseInt(document.getElementById("input" + row + '' + col).dataset.input);
        //console.log("input" + row + '' + col);
        //console.log("getState val is " + val);
        if (val > 0) {
          revealedValues.push(val);
          board[index] = val;
        } else {
          hiddenIndices.push(index);
        }
      }
    }
    revealedValues.sort();
    var hiddenValues = [1,2,3,4,5,6,7,8,9];
    for (var i = revealedValues.length-1; i >= 0; i--) {
      hiddenValues.splice(revealedValues[i]-1, 1);
    }
    var state = new State();
    state.board = board;
    state.hiddenValues = hiddenValues;
    state.hiddenIndices = hiddenIndices;
    //console.log("getState revealedValues are:")
    //console.log(revealedValues);
    //console.log("getState hiddenValues are:");
    //console.log(hiddenValues);
    return state;
  }

  recommend() {
    //console.log("##### recommend #####")
    this.clear();
    var state = this.getState();
    var newStates = this.permute(state, idx);
    var bestAvgScore = 0;
    var bestIndex = [];
    for (var i = 0; i < state.hiddenIndices.length; i++) {
      var idx = state.hiddenIndices[i];
      var childStates = newStates[idx];
      var totalScore = 0;
      for (var j = 0; j < childStates.length; j++) {
        var childState = childStates[j];
        var maxAvg = childState.getMaxAveragePayout();
        totalScore += maxAvg;
      }
      var avgScore = totalScore / childStates.length;
      if (avgScore > bestAvgScore) {
        bestAvgScore = avgScore;
        bestIndex = [idx];
      } else if (avgScore == bestAvgScore) {
        bestIndex.push(idx);
      }
    }
    for (var i = 0; i < bestIndex.length; i++) {
      idx = bestIndex[i];
      var col = idx % 3;
      var row = idx / 3 | 0;
      ////// HIGHLIGHT A SQUARE HERE
      document.getElementById("input" + row + '' + col).parentElement.classList.add("recommended");
    }
  }

  clear() {
    //console.log("##### clear #####")
    for (var i = 0; i < document.getElementsByClassName("square").length; i++) {
      document.getElementsByClassName("square")[i].classList.remove("recommended");
    }
  }

  clearAll() {
    this.clear();
    for (var i = 0; i < document.getElementsByClassName("numsel").length; i++) {
      document.getElementsByClassName("numsel")[i].classList.add("hidden");
    }
    for (var i = 0; i < document.getElementsByClassName("numpad").length; i++) {
      document.getElementsByClassName("numpad")[i].classList.remove("hidden");
    }
    for (var i = 0; i < 3; i++) {
      for (var j = 0; j < 3; j++) {
        document.getElementById("input" + i + '' + j).dataset.input = "0";
      }
    }
  }

  permute(state, idx) {
    //console.log("##### permute #####")
    var newStates = [];
    if (idx >= 0) {
      if (state.board[idx] > 0) {
        return null;
      }
      for (var j = 0; j < state.hiddenValues.length; j++) {
        var newState = new State();
        newState.copy(state);
        newState.board[idx] = state.hiddenValues[j];
        newState.hiddenIndices.splice(state.hiddenIndices.indexOf(idx), 1);
        newState.hiddenValues.splice(j, 1);
        newStates.push(newState);
      }
    } else {
      //newStates = {};
      for (var i = 0; i < state.hiddenIndices.length; i++) {
        var index = state.hiddenIndices[i];
        newStates[index] = this.permute(state, index);
      }
    }
    return newStates;
  }

  calc() {
    //console.log("##### calc #####")
    this.clear();
    var state = this.getState();
    var averages = state.getAveragePayouts();
    var best = 0;
    var bestElemId = null;
    for (var i = 0; i < averages.length; i++) {
      var val = averages[i];
      var valueIds = ['sqw1','sqw2','sqw3','sqn1','sqn2','sqn3','sqnw','sqne']
      var elemId = valueIds[i];
      if (val > best) {
        //console.log("calc - " + val + " is greater than " + best);
        best = val;
        bestElemId = elemId;
      }
    }
    // RECOMMEND TILE
    //console.log(bestElemId);
    document.getElementById(bestElemId).classList.add("recommended");
  }


  getSum(array, i1, i2, i3) {
    //console.log("##### getSum #####")
    return array[i1] + array[i2] + array[i3];
  }
}

class State {
  board = [];
  hiddenValues = [];
  hiddenIndices = [];
  copy = function(otherState) {
    //console.log("##### State - copy #####")
    for (var i = 0; i < otherState.board.length; i++) {
      this.board.push(otherState.board[i]);
    }
    for (var i = 0; i < otherState.hiddenValues.length; i++) {
      this.hiddenValues.push(otherState.hiddenValues[i]);
    }
    for (var i = 0; i < otherState.hiddenIndices.length; i++) {
      this.hiddenIndices.push(otherState.hiddenIndices[i]);
    }
  };
  getPossibleSums = function(row) {
    //console.log("##### getPossibleSums #####")
    var hiddenSpaces = 0;
    var staticSum = 0;
    var result = [];
    for (var i = 0; i < row.length; i++) {
      if (row[i] > 0) {
        staticSum += row[i];
      } else {
        hiddenSpaces++;
      }
    }
    if (hiddenSpaces > 0) {
      for (var i = 0; i < this.hiddenValues.length; i++) {
        if (hiddenSpaces > 1) {
          for (var j = i+1; j < this.hiddenValues.length; j++) {
            if (hiddenSpaces > 2) {
              for (var k = j+1; k < this.hiddenValues.length; k++) {
                result.push(staticSum + this.hiddenValues[i] + this.hiddenValues[j] + this.hiddenValues[k]);
              }
            } else {
              result.push(staticSum + this.hiddenValues[i] + this.hiddenValues[j]);
            }
          }
        } else {
          result.push(staticSum + this.hiddenValues[i]);
        }
      }
    } else {
      result.push(staticSum);
    }
    return result;
  };
  getRow = function(i1, i2, i3) {
    //console.log("##### State - getRow #####")
    return [this.board[i1], this.board[i2], this.board[i3]];
  };
  getAllRows = function() {
    //console.log("##### State - getAllRows #####")
    var rows = [];
    rows.push(this.getRow(0, 1, 2));
    rows.push(this.getRow(3, 4, 5));
    rows.push(this.getRow(6, 7, 8));
    rows.push(this.getRow(0, 3, 6));
    rows.push(this.getRow(1, 4, 7));
    rows.push(this.getRow(2, 5, 8));
    rows.push(this.getRow(0, 4, 8));
    rows.push(this.getRow(2, 4, 6));
    return rows;
  };
  getAveragePayouts = function() {
    //console.log("##### State - getAveragePayouts #####")
    var result = [];
    var rows = this.getAllRows();
    for (var i = 0; i < rows.length; i++) {
      var sums = this.getPossibleSums(rows[i]);
      var total = 0;
      for (var j = 0; j < sums.length; j++) {
        total += this.payout(sums[j]);
      }
      result.push(total / sums.length);
    }
    return result;
  };
  getMaxAveragePayout = function() {
    //console.log("##### State - getMaxAveragePayout #####")
    var avgs = this.getAveragePayouts();
    var max = 0;
      for (var i = 0; i < avgs.length; i++) {
        if (avgs[i] > max) max = avgs[i];
      }
    return max;
  };
  payout(sum) {
    if (sum == 6) return 10000;
    if (sum == 7) return 36;
    if (sum == 8) return 720;
    if (sum == 9) return 360;
    if (sum == 10) return 80;
    if (sum == 11) return 252;
    if (sum == 12) return 108;
    if (sum == 13) return 72;
    if (sum == 14) return 54;
    if (sum == 15) return 180;
    if (sum == 16) return 72;
    if (sum == 17) return 180;
    if (sum == 18) return 119;
    if (sum == 19) return 36;
    if (sum == 20) return 306;
    if (sum == 21) return 1080;
    if (sum == 22) return 144;
    if (sum == 23) return 1800;
    if (sum == 24) return 3600;
    return 0;
  }
}