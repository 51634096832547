import { TriadCards } from './triadcard';

export const TRIADCARDS: TriadCards[] = [
  {
    number:'001',
    id:'0',
    rarity:'1',
    name:'Dodo',
    icon:'Dodo',
    method1:'Obtained from the first deck',
    type1:'deck',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'002',
    id:'1',
    rarity:'1',
    name:'Tonberry',
    icon:'Tonberry',
    method1:'The Wanderer\'s Palace',
    type1:'duty',
    droprate1:'???',
    method2:'The Wanderer\'s Palace (Hard)',
    type2:'duty',
    droprate2:'???',
    method3:'Memeroon',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'003',
    id:'2',
    rarity:'1',
    name:'Sabotender',
    icon:'Sabotender',
    method1:'Obtained from the first deck',
    type1:'deck',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'004',
    id:'3',
    rarity:'1',
    name:'Spriggan',
    icon:'Spriggan',
    method1:'Triple Triad Master',
    type1:'npc',
    droprate1:'???',
    method2:'Bronze Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'005',
    id:'4',
    rarity:'1',
    name:'Pudding',
    icon:'Pudding',
    method1:'Roger',
    type1:'npc',
    droprate1:'???',
    method2:'Bronze Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'006',
    id:'5',
    rarity:'1',
    name:'Bomb',
    icon:'Bomb',
    method1:'Obtained from the first deck',
    type1:'deck',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'007',
    id:'6',
    rarity:'1',
    name:'Mandragora',
    icon:'Mandragora',
    method1:'Obtained from the first deck',
    type1:'deck',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'008',
    id:'7',
    rarity:'1',
    name:'Coblyn',
    icon:'Coblyn',
    method1:'Maisenta',
    type1:'npc',
    droprate1:'???',
    method2:'Wymond',
    type2:'npc',
    droprate2:'???',
    method3:'Bronze Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'009',
    id:'8',
    rarity:'1',
    name:'Morbol',
    icon:'Morbol',
    method1:'The Aurum Vale',
    type1:'duty',
    droprate1:'???',
    method2:'Roger',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'010',
    id:'9',
    rarity:'1',
    name:'Coeurl',
    icon:'Coeurl',
    method1:'Obtained from the first deck',
    type1:'deck',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'011',
    id:'10',
    rarity:'1',
    name:'Ahriman',
    icon:'Ahriman',
    method1:'Dzemael Darkhold',
    type1:'duty',
    droprate1:'???',
    method2:'Ourdilic',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'012',
    id:'11',
    rarity:'1',
    name:'Goobbue',
    icon:'Goobbue',
    method1:'Aurifort of the Three Clubs',
    type1:'npc',
    droprate1:'???',
    method2:'Mother Miounne',
    type2:'npc',
    droprate2:'???',
    method3:'Bronze Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'013',
    id:'12',
    rarity:'1',
    name:'Chocobo',
    icon:'Chocobo',
    method1:'Guhtwint of the Three Diamonds',
    type1:'npc',
    droprate1:'???',
    method2:'Triple Triad Trader - 600 MGP',
    type2:'mgp',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'014',
    id:'13',
    rarity:'1',
    name:'Amalj\'aa',
    icon:'Amaljaa',
    method1:'Memeroon',
    type1:'npc',
    droprate1:'???',
    method2:'Silver Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'015',
    id:'14',
    rarity:'1',
    name:'Ixal',
    icon:'Ixal',
    method1:'Joellaut',
    type1:'npc',
    droprate1:'???',
    method2:'Jonas of the Three Spades',
    type2:'npc',
    droprate2:'???',
    method3:'Silver Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'016',
    id:'15',
    rarity:'1',
    name:'Sylph',
    icon:'Sylph',
    method1:'Maisenta',
    type1:'npc',
    droprate1:'???',
    method2:'Silver Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'017',
    id:'16',
    rarity:'1',
    name:'Kobold',
    icon:'Kobold',
    method1:'Silver Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'018',
    id:'17',
    rarity:'1',
    name:'Sahagin',
    icon:'Sahagin',
    method1:'Sastasha',
    type1:'duty',
    droprate1:'???',
    method2:'Sastasha (Hard)',
    type2:'duty',
    droprate2:'???',
    method3:'Baderon',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'019',
    id:'18',
    rarity:'1',
    name:'Tataru Taru',
    icon:'TataruTaru',
    method1:'Silver Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'020',
    id:'19',
    rarity:'1',
    name:'Moogle',
    icon:'Moogle',
    method1:'Triple Triad Trader - 840 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'Trachtoum',
    type2:'npc',
    droprate2:'???',
    method3:'Jonas of the Three Spades',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'021',
    id:'20',
    rarity:'2',
    name:'Siren',
    icon:'Siren',
    method1:'Pharos Sirius',
    type1:'duty',
    droprate1:'???',
    method2:'Mimidoa',
    type2:'npc',
    droprate2:'???',
    method3:'Silver Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'022',
    id:'21',
    rarity:'2',
    name:'Ultros & Typhon',
    icon:'Ultros&Typhon',
    method1:'The Dragon\'s Neck',
    type1:'duty',
    droprate1:'???',
    method2:'Helmhart',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'023',
    id:'22',
    rarity:'2',
    name:'Demon Wall',
    icon:'DemonWall',
    method1:'Amdapor Keep',
    type1:'duty',
    droprate1:'???',
    method2:'Amdapor Keep (Hard)',
    type2:'duty',
    droprate2:'???',
    method3:'Buscarron',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'024',
    id:'23',
    rarity:'2',
    name:'Succubus',
    icon:'Succubus',
    method1:'Haukke Manor',
    type1:'duty',
    droprate1:'???',
    method2:'Haukke Manor (Hard)',
    type2:'duty',
    droprate2:'???',
    method3:'Piralnaut',
    type3:'npc',
    droprate3:'???',
    method4:'Bronze Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'025',
    id:'24',
    rarity:'2',
    name:'Chimera',
    icon:'Chimera',
    method1:'Cutter\'s Cry',
    type1:'duty',
    droprate1:'???',
    method2:'Fufulupa',
    type2:'npc',
    droprate2:'???',
    method3:'Bronze Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'026',
    id:'25',
    rarity:'2',
    name:'Blue Dragon',
    icon:'BlueDragon',
    method1:'The Stone Vigil',
    type1:'duty',
    droprate1:'???',
    method2:'The Stone Vigil (Hard)',
    type2:'duty',
    droprate2:'???',
    method3:'Ourdilic',
    type3:'npc',
    droprate3:'???',
    method4:'Bronze Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'027',
    id:'26',
    rarity:'2',
    name:'Scarface Bugaal Ja',
    icon:'ScarfaceBugaalJa',
    method1:'Aurifort of the Three Clubs',
    type1:'npc',
    droprate1:'???',
    method2:'Guhtwint of the Three Diamonds',
    type2:'npc',
    droprate2:'???',
    method3:'Bronze Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'028',
    id:'27',
    rarity:'2',
    name:'Momodi Modi',
    icon:'MomodiModi',
    method1:'Momodi',
    type1:'npc',
    droprate1:'???',
    method2:'Gold Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'029',
    id:'28',
    rarity:'2',
    name:'Baderon Tenfingers',
    icon:'BaderonTenfingers',
    method1:'Baderon',
    type1:'npc',
    droprate1:'???',
    method2:'Gold Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'030',
    id:'29',
    rarity:'2',
    name:'Mother Miounne',
    icon:'MotherMiounne',
    method1:'Mother Miounne',
    type1:'npc',
    droprate1:'???',
    method2:'Gold Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'031',
    id:'30',
    rarity:'2',
    name:'Livia sas Junius',
    icon:'LiviasasJunius',
    method1:'Castrum Meridianum',
    type1:'duty',
    droprate1:'???',
    method2:'Indolent Imperial',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'032',
    id:'31',
    rarity:'2',
    name:'Rhitahtyn sas Arvina',
    icon:'RhitahtynsasArvina',
    method1:'Cape Westwind',
    type1:'duty',
    droprate1:'???',
    method2:'Indolent Imperial',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'033',
    id:'32',
    rarity:'2',
    name:'Biggs & Wedge',
    icon:'BiggsandWedge',
    method1:'Mimidoa',
    type1:'npc',
    droprate1:'???',
    method2:'Sezul Totoloc',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'034',
    id:'33',
    rarity:'2',
    name:'Gerolt',
    icon:'Gerolt',
    method1:'Rowena',
    type1:'npc',
    droprate1:'???',
    method2:'Helmhart',
    type2:'npc',
    droprate2:'???',
    method3:'Gold Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'035',
    id:'34',
    rarity:'2',
    name:'Frixio',
    icon:'Frixio',
    method1:'Marcette',
    type1:'npc',
    droprate1:'???',
    method2:'Piralnaut',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'036',
    id:'35',
    rarity:'2',
    name:'Mutamix Bubblypots',
    icon:'MutamixBubblypots',
    method1:'F\'hobhas',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'037',
    id:'36',
    rarity:'2',
    name:'Memeroon',
    icon:'Memeroon',
    method1:'Memeroon',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'038',
    id:'37',
    rarity:'3',
    name:'Behemoth',
    icon:'Behemoth',
    method1:'Ourdilic',
    type1:'npc',
    droprate1:'???',
    method2:'Sezul Totoloc',
    type2:'npc',
    droprate2:'???',
    method3:'Bronze Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'039',
    id:'38',
    rarity:'3',
    name:'Gilgamesh & Enkidu',
    icon:'GilgameshandEnkidu',
    method1:'Battle on the Big Bridge',
    type1:'duty',
    droprate1:'???',
    method2:'Mythril Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'040',
    id:'49',
    rarity:'3',
    name:'Ifrit',
    icon:'Ifrit',
    method1:'The Bowl of Embers (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'The Bowl of Embers (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Swift',
    type3:'npc',
    droprate3:'???',
    method4:'Silver Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'041',
    id:'40',
    rarity:'3',
    name:'Titan',
    icon:'Titan',
    method1:'The Navel (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'The Navel (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Landenel',
    type3:'npc',
    droprate3:'???',
    method4:'Trachtoum',
    type4:'npc',
    droprate4:'???',
    method5:'Silver Triad Card pack',
    type5:'pack',
    droprate5:'???',
    patch:'2.51',
  },
  {
    number:'042',
    id:'41',
    rarity:'3',
    name:'Garuda',
    icon:'Garuda',
    method1:'The Howling Eye (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'The Howling Eye (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Marcette',
    type3:'npc',
    droprate3:'???',
    method4:'Silver Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'043',
    id:'42',
    rarity:'3',
    name:'Good King Moggle Mog XII',
    icon:'GoodKingMoggleMogXII',
    method1:'Thornmarch (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Thornmarch (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Master Mogzin',
    type3:'npc',
    droprate3:'???',
    method4:'Vorsaile Heuloix',
    type4:'npc',
    droprate4:'???',
    method5:'Silver Triad Card pack',
    type5:'pack',
    droprate5:'???',
    patch:'2.51',
  },
  {
    number:'044',
    id:'43',
    rarity:'3',
    name:'Raya-O-Senna & A-Ruhn-Senna',
    icon:'RayaOSennaandARuhnSenna',
    method1:'Buscarron',
    type1:'npc',
    droprate1:'???',
    method2:'Triple Triad Trader - 4,800 MGP',
    type2:'mgp',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'045',
    id:'44',
    rarity:'3',
    name:'Godbert Manderville',
    icon:'GodbertManderville',
    method1:'Hab',
    type1:'npc',
    droprate1:'???',
    method2:'King Elmer III',
    type2:'npc',
    droprate2:'???',
    method3:'Ruhtwyda of the Three Hearts',
    type3:'npc',
    droprate3:'???',
    method4:'Triple Triad Trader - 9,600 MGP',
    type4:'mgp',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'046',
    id:'45',
    rarity:'3',
    name:'Thancred',
    icon:'Thancred',
    method1:'Fufulupa',
    type1:'npc',
    droprate1:'???',
    method2:'Hab',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'047',
    id:'46',
    rarity:'3',
    name:'Nero tol Scaeva',
    icon:'NerotolScaeva',
    method1:'Indolent Imperial',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'048',
    id:'47',
    rarity:'3',
    name:'Papalymo & Yda',
    icon:'PapalymoandYda',
    method1:'Buscarron',
    type1:'npc',
    droprate1:'???',
    method2:'Vorsaile Heuloix',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'049',
    id:'48',
    rarity:'3',
    name:'Y\'shtola',
    icon:'Yshtola',
    method1:'Gegeruju',
    type1:'npc',
    droprate1:'???',
    method2:'R\'ashaht Rhiki',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'050',
    id:'49',
    rarity:'3',
    name:'Urianger',
    icon:'Urianger',
    method1:'Ruhtwyda of the Three Hearts',
    type1:'npc',
    droprate1:'???',
    method2:'Silver Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'Tataru????',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'051',
    id:'50',
    rarity:'4',
    name:'The Ultima Weapon',
    icon:'TheUltimaWeapon',
    method1:'The Minstrel\'s Ballad: Ultima\'s Bane',
    type1:'duty',
    droprate1:'???',
    method2:'Gold Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'Platinum Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'052',
    id:'51',
    rarity:'4',
    name:'Odin',
    icon:'Odin',
    method1:'Urth\'s Fount',
    type1:'duty',
    droprate1:'???',
    method2:'Landenel',
    type2:'npc',
    droprate2:'???',
    method3:'Mythril Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'053',
    id:'52',
    rarity:'4',
    name:'Ramuh',
    icon:'Ramuh',
    method1:'The Striking Tree (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'The Striking Tree (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Vorsaile Heuloix',
    type3:'npc',
    droprate3:'???',
    method4:'Gold Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'054',
    id:'53',
    rarity:'4',
    name:'Leviathan',
    icon:'Leviathan',
    method1:'The Whorleater (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'The Whorleater (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'R\'ashaht Rhiki',
    type3:'npc',
    droprate3:'???',
    method4:'Gold Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'055',
    id:'54',
    rarity:'4',
    name:'Shiva',
    icon:'Shiva',
    method1:'Akh Afah Amphitheatre (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Akh Afah Amphitheatre (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Platinum Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'Gold Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'056',
    id:'55',
    rarity:'4',
    name:'Minfilia',
    icon:'Minfilia',
    method1:'Gegeruju',
    type1:'npc',
    droprate1:'???',
    method2:'Silver Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'057',
    id:'56',
    rarity:'4',
    name:'Lahabrea',
    icon:'Lahabrea',
    method1:'Silver Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'Platinum Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'058',
    id:'57',
    rarity:'4',
    name:'Cid Garlond',
    icon:'CidGarlond',
    method1:'Sezul Totoloc',
    type1:'npc',
    droprate1:'???',
    method2:'Gold Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'059',
    id:'58',
    rarity:'4',
    name:'Alphinaud & Alisaie',
    icon:'AlphinaudandAlisaie',
    method1:'Joellaut',
    type1:'npc',
    droprate1:'???',
    method2:'Swift',
    type2:'npc',
    droprate2:'???',
    method3:'Platinum Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'060',
    id:'59',
    rarity:'4',
    name:'Louisoix Leveilleur',
    icon:'LouisoixLeveilleur',
    method1:'Rowena',
    type1:'npc',
    droprate1:'???',
    method2:'Platinum Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'061',
    id:'60',
    rarity:'5',
    name:'Bahamut',
    icon:'Bahamut',
    method1:'King Elmer III',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'062',
    id:'61',
    rarity:'5',
    name:'Hildibrand & Nashu Mhakaracca',
    icon:'HildibrandandNashuMhakaracca',
    method1:'Hab',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'063',
    id:'62',
    rarity:'5',
    name:'Nanamo Ul Namo',
    icon:'NanamoUlNamo',
    method1:'Triple Triad Trader - 400,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'Platinum Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'064',
    id:'63',
    rarity:'5',
    name:'Gaius van Baelsar',
    icon:'GaiusvanBaelsar',
    method1:'The Praetorium',
    type1:'duty',
    droprate1:'???',
    method2:'Indolent Imperial',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'065',
    id:'64',
    rarity:'5',
    name:'Merlwyb Bloefhiswyn',
    icon:'MerlwybBloefhiswyn',
    method1:'Mordyn',
    type1:'npc',
    droprate1:'???',
    method2:'O\'kalkaya',
    type2:'npc',
    droprate2:'???',
    method3:'R\'ashaht Rhiki',
    type3:'npc',
    droprate3:'???',
    method4:'Gold Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'066',
    id:'65',
    rarity:'5',
    name:'Kan-E-Senna',
    icon:'KanESenna',
    method1:'Vorsaile Heuloix',
    type1:'npc',
    droprate1:'???',
    method2:'Gold Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'067',
    id:'66',
    rarity:'5',
    name:'Raubahn Aldynn',
    icon:'RaubahnAldynn',
    method1:'Swift',
    type1:'npc',
    droprate1:'???',
    method2:'Gold Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'068',
    id:'67',
    rarity:'1',
    name:'Gaelicat',
    icon:'Gaelicat',
    method1:'Noes',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'069',
    id:'68',
    rarity:'2',
    name:'Vanu Vanu',
    icon:'VanuVanu',
    method1:'Mogmill',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'070',
    id:'69',
    rarity:'2',
    name:'Gnath',
    icon:'Gnath',
    method1:'Mogmill',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'071',
    id:'70',
    rarity:'2',
    name:'Yugiri Mistwalker',
    icon:'YugiriMistwalker',
    method1:'Yellow Moon',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'072',
    id:'71',
    rarity:'2',
    name:'Fat Chocobo',
    icon:'FatChocobo',
    method1:'Vath Deftarm',
    type1:'npc',
    droprate1:'???',
    method2:'"Special Tarasque Force" (Gold)',
    type2:'fate',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'073',
    id:'72',
    rarity:'3',
    name:'Griffin',
    icon:'Griffin',
    method1:'The Dusk Vigil',
    type1:'duty',
    droprate1:'???',
    method2:'Dominiac',
    type2:'npc',
    droprate2:'???',
    method3:'Ercanbald',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'074',
    id:'73',
    rarity:'3',
    name:'Tioman',
    icon:'Tioman',
    method1:'Sohm Al',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'075',
    id:'74',
    rarity:'3',
    name:'Estinien',
    icon:'Estinien',
    method1:'Gibrillont',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'076',
    id:'75',
    rarity:'3',
    name:'Lucia goe Junius',
    icon:'LuciagoeJunius',
    method1:'Wawalago',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'077',
    id:'76',
    rarity:'3',
    name:'Ysayle',
    icon:'Ysayle',
    method1:'Marcechamp',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'078',
    id:'77',
    rarity:'3',
    name:'Hilda',
    icon:'Hilda',
    method1:'Idle Imperial',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'079',
    id:'78',
    rarity:'3',
    name:'Matoya',
    icon:'Matoya',
    method1:'Midnight Dew',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'080',
    id:'79',
    rarity:'3',
    name:'Count Edmont de Fortemps',
    icon:'CountEdmontdeFortemps',
    method1:'Marielle',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'081',
    id:'80',
    rarity:'3',
    name:'Byblos',
    icon:'Byblos',
    method1:'The Great Gubal Library',
    type1:'duty',
    droprate1:'???',
    method2:'Mero Roggo',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'082',
    id:'81',
    rarity:'3',
    name:'Haurchefant',
    icon:'Haurchefant',
    method1:'Triple Team III - Defeat 41 NPCs',
    type1:'achievement',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'083',
    id:'82',
    rarity:'4',
    name:'Aymeric',
    icon:'Aymeric',
    method1:'Yayake',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'084',
    id:'83',
    rarity:'4',
    name:'Ravana',
    icon:'Ravana',
    method1:'Thok ast Thok (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Thok ast Thok (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Vath Deftarm',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'085',
    id:'84',
    rarity:'4',
    name:'Bismarck',
    icon:'Bismarck',
    method1:'The Limitless Blue (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'The Limitless Blue (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Linu Vali',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'086',
    id:'85',
    rarity:'5',
    name:'Nidhogg',
    icon:'Nidhogg',
    method1:'The Aery',
    type1:'duty',
    droprate1:'???',
    method2:'The Final Steps of Faith',
    type2:'duty',
    droprate2:'???',
    method3:'The Minstrel\'s Ballad: Nidhogg\'s Rage',
    type3:'duty',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'087',
    id:'86',
    rarity:'5',
    name:'Midgardsormr',
    icon:'Midgardsormr',
    method1:'Triple-decker IV - obtain 90 Triple Triad cards',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.0',
  },
  {
    number:'088',
    id:'87',
    rarity:'1',
    name:'Deepeye',
    icon:'Deepeye',
    method1:'Dominiac',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'089',
    id:'88',
    rarity:'2',
    name:'Archaeornis',
    icon:'Archaeornis',
    method1:'Elaisse',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'090',
    id:'89',
    rarity:'2',
    name:'Paissa',
    icon:'Paissa',
    method1:'Laniaitte',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'091',
    id:'90',
    rarity:'2',
    name:'Dhalmel',
    icon:'Dhalmel',
    method1:'Laniaitte',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'092',
    id:'91',
    rarity:'2',
    name:'Bandersnatch',
    icon:'Bandersnatch',
    method1:'Voracious Vath',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'093',
    id:'92',
    rarity:'2',
    name:'Crawler',
    icon:'Crawler',
    method1:'Seika',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'094',
    id:'93',
    rarity:'2',
    name:'Poroggo',
    icon:'Poroggo',
    method1:'Seika',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'095',
    id:'94',
    rarity:'3',
    name:'Vedrfolnir',
    icon:'Vedrfolnir',
    method1:'Mogmill',
    type1:'npc',
    droprate1:'???',
    method2:'Mythril Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'096',
    id:'95',
    rarity:'3',
    name:'Coeurlregina',
    icon:'Coeurlregina',
    method1:'Vath Deftarm',
    type1:'npc',
    droprate1:'???',
    method2:'Voracious Vath',
    type2:'npc',
    droprate2:'???',
    method3:'Mythril Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'097',
    id:'96',
    rarity:'3',
    name:'Progenitrix',
    icon:'Progenitrix',
    method1:'Pharos Sirius (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'098',
    id:'97',
    rarity:'3',
    name:'Belladonna',
    icon:'Belladonna',
    method1:'Saint Mocianne\'s Arboretum',
    type1:'duty',
    droprate1:'???',
    method2:'Midnight Dew',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'099',
    id:'98',
    rarity:'3',
    name:'Echidna',
    icon:'Echidna',
    method1:'The Void Ark',
    type1:'duty',
    droprate1:'???',
    method2:'Redbill Storeboy',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'100',
    id:'99',
    rarity:'3',
    name:'Pipin Tarupin',
    icon:'PipinTarupin',
    method1:'Swift',
    type1:'npc',
    droprate1:'???',
    method2:'Mythril Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'101',
    id:'100',
    rarity:'3',
    name:'Julyan Manderville',
    icon:'JulyanManderville',
    method1:'Triple Triad Trader - 14,400 MGP',
    type1:'mgp',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'102',
    id:'101',
    rarity:'3',
    name:'Moglin',
    icon:'Moglin',
    method1:'Mogmill',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'103',
    id:'102',
    rarity:'3',
    name:'Charibert',
    icon:'Charibert',
    method1:'The Vault',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'104',
    id:'103',
    rarity:'3',
    name:'Roundrox',
    icon:'Roundrox',
    method1:'Seika',
    type1:'npc',
    droprate1:'???',
    method2:'Tapklix',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'105',
    id:'104',
    rarity:'4',
    name:'Senor Sabotender',
    icon:'SenorSabotender',
    method1:'Triple Triad Trader - 200,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'106',
    id:'105',
    rarity:'5',
    name:'Regula van Hydrus',
    icon:'RegulavanHydrus',
    method1:'Idle Imperial',
    type1:'npc',
    droprate1:'???',
    method2:'Imperial Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'107',
    id:'106',
    rarity:'5',
    name:'Archbishop Thordan VII',
    icon:'ArchbishopThordanVII',
    method1:'The Minstrel\'s Ballad: Thordan\'s Reign',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.1',
  },
  {
    number:'108',
    id:'107',
    rarity:'2',
    name:'Honoroit',
    icon:'Honoroit',
    method1:'House Fortemps Manservant',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'109',
    id:'108',
    rarity:'2',
    name:'Hoary Boulder & Coultenet',
    icon:'HoaryBoulderandCoultenet',
    method1:'Gold Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'110',
    id:'109',
    rarity:'3',
    name:'Brachiosaur',
    icon:'Brachiosaur',
    method1:'Linu Vali',
    type1:'npc',
    droprate1:'???',
    method2:'Mythril Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'111',
    id:'110',
    rarity:'3',
    name:'Darkscale',
    icon:'Darkscale',
    method1:'Master Mogzin',
    type1:'npc',
    droprate1:'???',
    method2:'"Mogicide" (Gold)',
    type2:'fate',
    droprate2:'',
    method3:'"Rastaban Vibration" (Gold)',
    type3:'fate',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'112',
    id:'111',
    rarity:'3',
    name:'Fenrir',
    icon:'Fenrir',
    method1:'Triple Triad Trader - 72,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'Snowcloak',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'113',
    id:'112',
    rarity:'3',
    name:'Kraken',
    icon:'Kraken',
    method1:'Sastasha (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Mordyn',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'114',
    id:'113',
    rarity:'3',
    name:'Vicegerent to the Warden',
    icon:'VicegerenttotheWarden',
    method1:'The Sunken Temple of Qarn (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Yayake',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'115',
    id:'114',
    rarity:'3',
    name:'Manxome Molaa Ja Ja',
    icon:'ManxomeMolaaJaJa',
    method1:'The Wanderer\'s Palace (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Memeroon',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'116',
    id:'115',
    rarity:'3',
    name:'Ferdiad',
    icon:'Ferdiad',
    method1:'Amdapor Keep (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Redbill Storeboy',
    type2:'npc',
    droprate2:'???',
    method3:'Silver Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'117',
    id:'116',
    rarity:'3',
    name:'Calcabrina',
    icon:'Calcabrina',
    method1:'The Antitower',
    type1:'duty',
    droprate1:'???',
    method2:'Mero Roggo',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'118',
    id:'117',
    rarity:'3',
    name:'Kuribu',
    icon:'Kuribu',
    method1:'The Lost City of Amdapor (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Noes',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'119',
    id:'118',
    rarity:'3',
    name:'Phlegethon',
    icon:'Phlegethon',
    method1:'The Labyrinth of the Ancients',
    type1:'duty',
    droprate1:'???',
    method2:'Klynthota',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'120',
    id:'119',
    rarity:'3',
    name:'Artoriel de Fortemps',
    icon:'ArtorieldeFortemps',
    method1:'House Fortemps Manservant',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'121',
    id:'120',
    rarity:'3',
    name:'Emmanellain de Fortemps',
    icon:'EmmanellaindeFortemps',
    method1:'House Fortemps Manservant',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'122',
    id:'121',
    rarity:'4',
    name:'Xande',
    icon:'Xande',
    method1:'Syrcus Tower',
    type1:'duty',
    droprate1:'???',
    method2:'Klynthota',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'123',
    id:'122',
    rarity:'4',
    name:'Brute Justice',
    icon:'BruteJustice',
    method1:'Alexander - The Burden of the Son',
    type1:'duty',
    droprate1:'???',
    method2:'Tapklix',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'124',
    id:'123',
    rarity:'4',
    name:'Sephirot',
    icon:'Sephirot',
    method1:'Containment Bay S1T7',
    type1:'duty',
    droprate1:'???',
    method2:'Containment Bay S1T7 (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'125',
    id:'124',
    rarity:'4',
    name:'F\'lhaminn',
    icon:'Flhaminn',
    method1:'Triple Triad Trader - 20,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'Platinum Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'126',
    id:'125',
    rarity:'4',
    name:'Vidofnir',
    icon:'Vidofnir',
    method1:'Triple-decker V - obtain 120 Triple Triad cards',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'127',
    id:'126',
    rarity:'5',
    name:'Cloud of Darkness',
    icon:'CloudofDarkness',
    method1:'The World of Darkness',
    type1:'duty',
    droprate1:'???',
    method2:'Klynthota',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.2',
  },
  {
    number:'128',
    id:'127',
    rarity:'2',
    name:'Lolorito Nanarito',
    icon:'LoloritoNanarito',
    method1:'Wymond',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'129',
    id:'128',
    rarity:'2',
    name:'Gibrillont',
    icon:'Gibrillont',
    method1:'Elaisse',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'130',
    id:'129',
    rarity:'2',
    name:'Laniaitte de Haillenarte',
    icon:'LaniaittedeHaillenarte',
    method1:'Marielle',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'131',
    id:'130',
    rarity:'2',
    name:'Rhoswen',
    icon:'Rhoswen',
    method1:'O\'kalkaya',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'132',
    id:'131',
    rarity:'2',
    name:'Carvallain de Gorgagne',
    icon:'CarvallaindeGorgagne',
    method1:'Mordyn',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'133',
    id:'132',
    rarity:'3',
    name:'Kal Myhk',
    icon:'KalMyhk',
    method1:'Master Mogzin',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'134',
    id:'133',
    rarity:'3',
    name:'Waukkeon',
    icon:'Waukkeon',
    method1:'Neverreap',
    type1:'duty',
    droprate1:'???',
    method2:'Linu Vali',
    type2:'npc',
    droprate2:'???',
    method3:'Bronze Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'135',
    id:'134',
    rarity:'3',
    name:'Curator',
    icon:'Curator',
    method1:'The Fractal Continuum',
    type1:'duty',
    droprate1:'???',
    method2:'Idle Imperial',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'136',
    id:'135',
    rarity:'3',
    name:'Mistbeard',
    icon:'Mistbeard',
    method1:'Hullbreaker Isle (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'O\'kalkaya',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'137',
    id:'136',
    rarity:'4',
    name:'Unei & Doga',
    icon:'UneiandDoga',
    method1:'Klynthota',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'138',
    id:'137',
    rarity:'4',
    name:'Tiamat',
    icon:'Tiamat',
    method1:'Idle Imperial',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'139',
    id:'138',
    rarity:'4',
    name:'Calofisteri',
    icon:'Calofisteri',
    method1:'The Weeping City of Mhach',
    type1:'duty',
    droprate1:'???',
    method2:'Redbill Storeboy',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'140',
    id:'139',
    rarity:'5',
    name:'Hraesvelgr',
    icon:'Hraesvelgr',
    method1:'Sohr Khai',
    type1:'duty',
    droprate1:'???',
    method2:'Master Mogzin',
    type2:'npc',
    droprate2:'???',
    method3:'Platinum Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.3',
  },
  {
    number:'141',
    id:'140',
    rarity:'1',
    name:'Apkallu',
    icon:'Apkallu',
    method1:'Wyra "Greenhands" Lyehga',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'142',
    id:'141',
    rarity:'1',
    name:'Colibri',
    icon:'Colibri',
    method1:'Flichoirel The Lordling',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'143',
    id:'142',
    rarity:'1',
    name:'Magitek Death Claw',
    icon:'MagitekDeathClaw',
    method1:'Bronze Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'144',
    id:'143',
    rarity:'2',
    name:'Liquid Flame',
    icon:'LiquidFlame',
    method1:'Bronze Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'145',
    id:'144',
    rarity:'2',
    name:'Lost Lamb',
    icon:'LostLamb',
    method1:'Prideful Stag',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'146',
    id:'145',
    rarity:'2',
    name:'Delivery Moogle',
    icon:'DeliveryMoogle',
    method1:'Bronze Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'147',
    id:'146',
    rarity:'2',
    name:'Magitek Colossus',
    icon:'MagitekColossus',
    method1:'Hall Overseer',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'148',
    id:'147',
    rarity:'3',
    name:'Strix',
    icon:'Strix',
    method1:'The Great Gubal Library (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'Mero Roggo',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'149',
    id:'148',
    rarity:'3',
    name:'Tozol Huatotl',
    icon:'TozolHuatotl',
    method1:'Xelphatol',
    type1:'duty',
    droprate1:'???',
    method2:'Sezul Totoloc',
    type2:'npc',
    droprate2:'???',
    method3:'Bronze Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'150',
    id:'149',
    rarity:'3',
    name:'Alexander Prime',
    icon:'AlexanderPrime',
    method1:'Alexander - The Soul of the Creator',
    type1:'duty',
    droprate1:'???',
    method2:'Alexander - The Soul of the Creator (Savage)',
    type2:'duty',
    droprate2:'???',
    method3:'Tapklix',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'151',
    id:'150',
    rarity:'3',
    name:'Brendt, Brennan, & Bremondt',
    icon:'BrendtBrennanandBremondt',
    method1:'Nell Half-full',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'152',
    id:'151',
    rarity:'4',
    name:'Heavensward Thancred',
    icon:'HeavenswardThancred',
    method1:'Triple-decker VI - obtain 150 Triple Triad cards',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'153',
    id:'152',
    rarity:'4',
    name:'Heavensward Y\'shtola',
    icon:'HeavenswardYshtola',
    method1:'Triple Team V - defeat 60 NPCs',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'154',
    id:'153',
    rarity:'4',
    name:'Nael van Darnus',
    icon:'NaelvanDarnus',
    method1:'Flichoirel The Lordling',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'155',
    id:'154',
    rarity:'5',
    name:'Sophia',
    icon:'Sophia',
    method1:'Containment Bay P1T6',
    type1:'duty',
    droprate1:'???',
    method2:'Containment Bay P1T6 (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.4',
  },
  {
    number:'156',
    id:'155',
    rarity:'1',
    name:'Opo-opo',
    icon:'OpoOpo',
    method1:'Triple Triad Trader - 600 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'157',
    id:'156',
    rarity:'2',
    name:'Adamantoise',
    icon:'Adamantoise',
    method1:'Triple Triad Trader - 840 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'158',
    id:'157',
    rarity:'2',
    name:'Magitek Vanguard',
    icon:'MagitekVanguard',
    method1:'Imperial Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'159',
    id:'158',
    rarity:'2',
    name:'Magitek Gunship',
    icon:'MagitekGunship',
    method1:'Imperial Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'160',
    id:'159',
    rarity:'3',
    name:'Lava Scorpion',
    icon:'LavaScorpion',
    method1:'Sohm Al (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'161',
    id:'160',
    rarity:'3',
    name:'Magitek Predator',
    icon:'MagitekPredator',
    method1:'Imperial Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'162',
    id:'161',
    rarity:'3',
    name:'Magitek Sky Armor',
    icon:'MagitekSkyArmor',
    method1:'Imperial Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'163',
    id:'162',
    rarity:'3',
    name:'The Griffin',
    icon:'TheGriffin',
    method1:'Baelsar\'s Wall',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'164',
    id:'163',
    rarity:'4',
    name:'Diabolos Hollow',
    icon:'DiabolosHollow',
    method1:'Dun Scaith',
    type1:'duty',
    droprate1:'???',
    method2:'Redbill Storeboy',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'165',
    id:'164',
    rarity:'4',
    name:'Armored Weapon',
    icon:'ArmoredWeapon',
    method1:'Imperial Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'166',
    id:'165',
    rarity:'5',
    name:'Zurvan',
    icon:'Zurvan',
    method1:'Containment Bay Z1T9',
    type1:'duty',
    droprate1:'???',
    method2:'Containment Bay Z1T9 (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'167',
    id:'166',
    rarity:'2',
    name:'Gold Saucer Attendant',
    icon:'GoldSaucerAttendant',
    method1:'Triple Triad Trader - 5,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'Make It Rain campaign',
    type2:'event',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'168',
    id:'167',
    rarity:'3',
    name:'Roland',
    icon:'Roland',
    method1:'Triple Triad Trader - 10,000 MGP',
    type1:'mgp',
    droprate1:'???',
    method2:'Make It Rain campaign',
    type2:'event',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'169',
    id:'168',
    rarity:'4',
    name:'Gigi',
    icon:'Gigi',
    method1:'Triple Triad Trader - 90,000 MGP',
    type1:'mgp',
    droprate1:'???',
    method2:'Make It Rain campaign',
    type2:'event',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'3.5',
  },
  {
    number:'170',
    id:'169',
    rarity:'1',
    name:'Namazu',
    icon:'Namazu',
    method1:'Gyoei',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'171',
    id:'170',
    rarity:'2',
    name:'Kojin',
    icon:'Kojin',
    method1:'Tsuzura',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'172',
    id:'171',
    rarity:'2',
    name:'Ananta',
    icon:'Ananta',
    method1:'Garima',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'173',
    id:'172',
    rarity:'2',
    name:'M\'naago',
    icon:'Mnaago',
    method1:'Ercanbald',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'174',
    id:'173',
    rarity:'2',
    name:'Kotokaze',
    icon:'Kotokaze',
    method1:'Kotokaze',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'175',
    id:'174',
    rarity:'3',
    name:'Mammoth',
    icon:'Mammoth',
    method1:'Munglig',
    type1:'npc',
    droprate1:'???',
    method2:'Nigen',
    type2:'npc',
    droprate2:'???',
    method3:'Ogodei',
    type3:'npc',
    droprate3:'???',
    method4:'"Wham, Bam, Thank You, Mammoth" (Gold)',
    type4:'fate',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'176',
    id:'175',
    rarity:'3',
    name:'Phoebad',
    icon:'Phoebad',
    method1:'Umber Torrent',
    type1:'npc',
    droprate1:'???',
    method2:'"Tall Tale" (Gold)',
    type2:'fate',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'177',
    id:'176',
    rarity:'3',
    name:'Susano',
    icon:'Susano',
    method1:'The Pool of Tribute',
    type1:'duty',
    droprate1:'???',
    method2:'The Pool of Tribute (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'178',
    id:'177',
    rarity:'3',
    name:'Lakshmi',
    icon:'Lakshmi',
    method1:'Emanation',
    type1:'duty',
    droprate1:'???',
    method2:'Emanation (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'179',
    id:'178',
    rarity:'3',
    name:'Grynewaht',
    icon:'Grynewaht',
    method1:'Doma Castle',
    type1:'duty',
    droprate1:'???',
    method2:'Imperial Deserter',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'180',
    id:'179',
    rarity:'3',
    name:'Rasho',
    icon:'Rasho',
    method1:'Kaizan',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'181',
    id:'180',
    rarity:'3',
    name:'Cirina',
    icon:'Cirina',
    method1:'Nigen',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'182',
    id:'181',
    rarity:'3',
    name:'Magnai',
    icon:'Magnai',
    method1:'Ogodei',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'183',
    id:'182',
    rarity:'3',
    name:'Sadu',
    icon:'Sadu',
    method1:'Munglig',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'184',
    id:'183',
    rarity:'4',
    name:'Shinryu',
    icon:'Shinryu',
    method1:'The Royal Menagerie',
    type1:'duty',
    droprate1:'???',
    method2:'The Minstrel\'s Ballad: Shinryu\'s Domain',
    type2:'duty',
    droprate2:'???',
    method3:'Ironworks Hand',
    type3:'npc',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'185',
    id:'184',
    rarity:'4',
    name:'Yotsuyu',
    icon:'Yotsuyu',
    method1:'Imperial Deserter',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'186',
    id:'185',
    rarity:'4',
    name:'Krile',
    icon:'Krile',
    method1:'Triple Decker VII - obtain 190 Triple Triad cards',
    type1:'achievement',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'187',
    id:'186',
    rarity:'4',
    name:'Lyse',
    icon:'Lyse',
    method1:'Triple Team VI - defeat 70 NPCs',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'188',
    id:'187',
    rarity:'5',
    name:'Zenos yae Galvus',
    icon:'ZenosyaeGalvus',
    method1:'Ala Mhigo',
    type1:'duty',
    droprate1:'???',
    method2:'Hachinan',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'189',
    id:'188',
    rarity:'5',
    name:'Hien',
    icon:'Hien',
    method1:'Kiuka',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.0',
  },
  {
    number:'190',
    id:'189',
    rarity:'1',
    name:'Mossling',
    icon:'Mossling',
    method1:'Triple Triad Trader - 840 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'191',
    id:'190',
    rarity:'2',
    name:'Chapuli',
    icon:'Chapuli',
    method1:'Triple Triad Trader - 1,200 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'192',
    id:'191',
    rarity:'2',
    name:'Qiqirn Meateater',
    icon:'QiqirnMeateater',
    method1:'Garima',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'193',
    id:'192',
    rarity:'3',
    name:'Hrodric Poisontongue',
    icon:'HrodricPoisontongue',
    method1:'The Drowned City of Skalla',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'194',
    id:'193',
    rarity:'3',
    name:'Fordola rem Lupis',
    icon:'FordolaremLupis',
    method1:'Imperial Deserter',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'195',
    id:'194',
    rarity:'3',
    name:'Rofocale',
    icon:'Rofocale',
    method1:'The Royal City of Rabanastre',
    type1:'duty',
    droprate1:'???',
    method2:'Hanagasa',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'196',
    id:'195',
    rarity:'4',
    name:'Argath Thadalfus',
    icon:'ArgathThadalfus',
    method1:'The Royal City of Rabanastre',
    type1:'duty',
    droprate1:'???',
    method2:'Hanagasa',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'197',
    id:'196',
    rarity:'5',
    name:'Raubahn & Pipin',
    icon:'RaubahnandPipin',
    method1:'Triple Triad Trader - 400,000 MGP',
    type1:'mgp',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.1',
  },
  {
    number:'198',
    id:'197',
    rarity:'1',
    name:'Koja',
    icon:'Koja',
    method1:'Masatsuchi',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'199',
    id:'198',
    rarity:'2',
    name:'Ango',
    icon:'Ango',
    method1:'Isobe',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'200',
    id:'199',
    rarity:'2',
    name:'Guidance Node',
    icon:'GuidanceNode',
    method1:'The Fractal Continuum (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'201',
    id:'200',
    rarity:'2',
    name:'Tansui',
    icon:'Tansui',
    method1:'Yusui',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'202',
    id:'201',
    rarity:'3',
    name:'Genbu',
    icon:'Genbu',
    method1:'Hells\' Lid',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'203',
    id:'202',
    rarity:'3',
    name:'Byakko',
    icon:'Byakko',
    method1:'The Jade Stoa',
    type1:'duty',
    droprate1:'???',
    method2:'The Jade Stoa (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'204',
    id:'203',
    rarity:'3',
    name:'Arenvald Lentinus',
    icon:'ArenvaldLentinus',
    method1:'Umber Torrent',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'205',
    id:'204',
    rarity:'3',
    name:'Lupin',
    icon:'Lupin',
    method1:'Masatsuchi',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'206',
    id:'205',
    rarity:'4',
    name:'Hancock',
    icon:'Hancock',
    method1:'Kikimo',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'207',
    id:'206',
    rarity:'5',
    name:'Hisui & Kurenai',
    icon:'HisuiandKurenai',
    method1:'Isobe',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.2',
  },
  {
    number:'208',
    id:'207',
    rarity:'3',
    name:'Qitian Dasheng',
    icon:'QitianDasheng',
    method1:'The Swallow\'s Compass',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.3',
  },
  {
    number:'209',
    id:'208',
    rarity:'4',
    name:'Tsukuyomi',
    icon:'Tsukuyomi',
    method1:'Castrum Fluminis',
    type1:'duty',
    droprate1:'???',
    method2:'The Minstrel\'s Ballad: Tsukuyomi\'s Pain',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.3',
  },
  {
    number:'210',
    id:'209',
    rarity:'5',
    name:'Yiazmat',
    icon:'Yiazmat',
    method1:'The Ridorana Lighthouse',
    type1:'duty',
    droprate1:'???',
    method2:'Hanagasa',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.3',
  },
  {
    number:'211',
    id:'210',
    rarity:'5',
    name:'Gosetsu',
    icon:'Gosetsu',
    method1:'Triple-decker VIII - obtain 220 Triple Triad cards',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.3',
  },
  {
    number:'212',
    id:'211',
    rarity:'1',
    name:'Wanyudo & Katasharin',
    icon:'Wanyudo&Katasharin',
    method1:'Ushiogi',
    type1:'npc',
    droprate1:'???',
    method2:'Silver-haloed Sack',
    type2:'sack',
    droprate2:'???',
    method3:'Gold-haloed Sack',
    type3:'sack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.35',
  },
  {
    number:'213',
    id:'212',
    rarity:'2',
    name:'Hatamoto',
    icon:'Hatamoto',
    method1:'Ushiogi',
    type1:'npc',
    droprate1:'???',
    method2:'Gold-haloed Sack',
    type2:'sack',
    droprate2:'???',
    method3:'Silver-haloed Sack',
    type3:'sack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.35',
  },
  {
    number:'214',
    id:'213',
    rarity:'3',
    name:'Hiruko',
    icon:'Hiruko',
    method1:'Ushiogi',
    type1:'npc',
    droprate1:'???',
    method2:'Gold-haloed Sack',
    type2:'sack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.35',
  },
  {
    number:'215',
    id:'214',
    rarity:'2',
    name:'Yukinko',
    icon:'Yukinko',
    method1:'Botan',
    type1:'npc',
    droprate1:'???',
    method2:'"Eternity"',
    type2:'fate',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.36',
  },
  {
    number:'216',
    id:'215',
    rarity:'3',
    name:'Happy Bunny',
    icon:'HappyBunny',
    method1:'Botan',
    type1:'npc',
    droprate1:'???',
    method2:'Happy Bunny Silver Treasure Chest',
    type2:'',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.36',
  },
  {
    number:'217',
    id:'216',
    rarity:'3',
    name:'Louhi',
    icon:'Louhi',
    method1:'Botan',
    type1:'npc',
    droprate1:'???',
    method2:'"Louhi On Ice"',
    type2:'fate',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.36',
  },
  {
    number:'218',
    id:'217',
    rarity:'2',
    name:'Muud Suud',
    icon:'MuudSuud',
    method1:'Triple Triad Trader - 1,500 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.4',
  },
  {
    number:'219',
    id:'218',
    rarity:'3',
    name:'Tokkapchi',
    icon:'Tokkapchi',
    method1:'Saint Mocianne\'s Arboretum (Hard)',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.4',
  },
  {
    number:'220',
    id:'219',
    rarity:'3',
    name:'Mist Dragon',
    icon:'MistDragon',
    method1:'The Burn',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.4',
  },
  {
    number:'221',
    id:'220',
    rarity:'3',
    name:'Suzaku',
    icon:'Suzaku',
    method1:'Hells\' Kier',
    type1:'duty',
    droprate1:'???',
    method2:'Hells\' Kier (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.4',
  },
  {
    number:'222',
    id:'221',
    rarity:'3',
    name:'Asahi sas Brutus',
    icon:'AsahisasBrutus',
    method1:'Hachinan',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.4',
  },
  {
    number:'223',
    id:'222',
    rarity:'5',
    name:'Omega',
    icon:'Omega',
    method1:'Alphascape V3.0',
    type1:'duty',
    droprate1:'???',
    method2:'Alphascape V3.0 (Savage)',
    type2:'duty',
    droprate2:'???',
    method3:'Alphascape V4.0',
    type3:'duty',
    droprate3:'???',
    method4:'Alphascape V4.0 (Savage)',
    type4:'duty',
    droprate4:'???',
    method5:'Ironworks Hand',
    type5:'npc',
    droprate5:'???',
    patch:'4.4',
  },
  {
    number:'224',
    id:'223',
    rarity:'1',
    name:'Karakuri Hanya',
    icon:'KarakuriHanya',
    method1:'Hokushin',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.45',
  },
  {
    number:'225',
    id:'224',
    rarity:'3',
    name:'Pazuzu',
    icon:'Pazuzu',
    method1:'Botan',
    type1:'npc',
    droprate1:'???',
    method2:'"Wail In The Willows"',
    type2:'fate',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.45',
  },
  {
    number:'226',
    id:'225',
    rarity:'3',
    name:'Penthesilea',
    icon:'Penthesilea',
    method1:'Hetsukaze',
    type1:'npc',
    droprate1:'???',
    method2:'"Lost Epic"',
    type2:'fate',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.45',
  },
  {
    number:'227',
    id:'226',
    rarity:'5',
    name:'Yojimbo & Diagoro',
    icon:'YojimboandDiagoro',
    method1:'Hokushin',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.45',
  },
  {
    number:'228',
    id:'227',
    rarity:'1',
    name:'Stormblood Tataru Taru',
    icon:'StormbloodTataruTaru',
    method1:'Triple Triad Trader - 96,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.5',
  },
  {
    number:'229',
    id:'228',
    rarity:'3',
    name:'Prometheus',
    icon:'Prometheus',
    method1:'The Ghimlyt Dark',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.5',
  },
  {
    number:'230',
    id:'229',
    rarity:'3',
    name:'Seiryu',
    icon:'Seiryu',
    method1:'The Wreath of Snakes',
    type1:'duty',
    droprate1:'???',
    method2:'The Wreath of Snakes (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.5',
  },
  {
    number:'231',
    id:'230',
    rarity:'3',
    name:'Alpha',
    icon:'Alpha',
    method1:'Ironworks Hand',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.5',
  },
  {
    number:'232',
    id:'231',
    rarity:'4',
    name:'Great Gold Whisker',
    icon:'GreatGoldWhisker',
    method1:'Gyoei',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.5',
  },
  {
    number:'233',
    id:'232',
    rarity:'5',
    name:'Ultima, the High Seraph',
    icon:'UltimatheHighSeraph',
    method1:'The Orbonne Monastery',
    type1:'duty',
    droprate1:'???',
    method2:'Hanagasa',
    type2:'npc',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.5',
  },
  {
    number:'234',
    id:'233',
    rarity:'5',
    name:'Stormblood Alphinaud & Alisaie',
    icon:'StormbloodAlphinaudandAlisaie',
    method1:'Mero Roggo',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.5',
  },
  {
    number:'235',
    id:'234',
    rarity:'2',
    name:'Dvergr',
    icon:'Dvergr',
    method1:'Hetsukaze',
    type1:'npc',
    droprate1:'???',
    method2:'"Duty-free"',
    type2:'fate',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.55',
  },
  {
    number:'236',
    id:'235',
    rarity:'2',
    name:'Ejika Tsunjika',
    icon:'EjikaTsunjika',
    method1:'Hetsukaze',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.55',
  },
  {
    number:'237',
    id:'236',
    rarity:'3',
    name:'Provenance Watcher',
    icon:'ProvenanceWatcher',
    method1:'Hetsukaze',
    type1:'npc',
    droprate1:'???',
    method2:'"Crystalline Provenance"',
    type2:'fate',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.55',
  },
  {
    number:'238',
    id:'237',
    rarity:'4',
    name:'Stormblood Gilgamesh',
    icon:'StormbloodGilgamesh',
    method1:'Kugane Ohashi',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.56',
  },
  {
    number:'239',
    id:'238',
    rarity:'1',
    name:'Amaro',
    icon:'Amaro',
    method1:'Glynard',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'240',
    id:'239',
    rarity:'1',
    name:'Evil Weapon',
    icon:'EvilWeapon',
    method1:'Drery',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'241',
    id:'240',
    rarity:'1',
    name:'Lord and Lady Chai',
    icon:'LordandLadyChai',
    method1:'Ibenart',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'242',
    id:'241',
    rarity:'2',
    name:'Gigantender',
    icon:'Gigantender',
    method1:'Drery',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'243',
    id:'242',
    rarity:'2',
    name:'Feo Ul',
    icon:'FeoUl',
    method1:'Gyuf Uin',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'244',
    id:'243',
    rarity:'2',
    name:'Runar',
    icon:'Runar',
    method1:'Hargra',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'245',
    id:'244',
    rarity:'2',
    name:'Grenoldt',
    icon:'Grenoldt',
    method1:'Saushs Koal',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'246',
    id:'245',
    rarity:'3',
    name:'Philia',
    icon:'Philia',
    method1:'Holminster Switch',
    type1:'duty',
    droprate1:'???',
    method2:'Dream Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'247',
    id:'246',
    rarity:'3',
    name:'Titania',
    icon:'Titania',
    method1:'The Dancing Plague',
    type1:'duty',
    droprate1:'???',
    method2:'The Dancing Plague (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Dream Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'248',
    id:'247',
    rarity:'3',
    name:'Eros',
    icon:'Eros',
    method1:'The Qitana Ravel',
    type1:'duty',
    droprate1:'???',
    method2:'Dream Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'249',
    id:'248',
    rarity:'3',
    name:'Storge',
    icon:'Storge',
    method1:'Malikah\'s Well',
    type1:'duty',
    droprate1:'???',
    method2:'Dream Triad Card pack',
    type2:'pack',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'250',
    id:'249',
    rarity:'3',
    name:'Formidable',
    icon:'Formidable',
    method1:'Hargra',
    type1:'npc',
    droprate1:'???',
    method2:'Kholusia (Rank 3) - 200 Bicolor Gemstones',
    type2:'gem',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'251',
    id:'250',
    rarity:'3',
    name:'Lyna',
    icon:'Lyna',
    method1:'Lamlyn',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'252',
    id:'251',
    rarity:'3',
    name:'Jongleurs of Eulmore',
    icon:'JongleursofEulmore',
    method1:'Grewenn',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'253',
    id:'252',
    rarity:'4',
    name:'Innocence',
    icon:'Innocence',
    method1:'The Crown of the Immaculate',
    type1:'duty',
    droprate1:'???',
    method2:'The Crown of the Immaculate (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'Dream Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'254',
    id:'253',
    rarity:'4',
    name:'Shadowbringers Y\'shtola',
    icon:'ShadowbringersYshtola',
    method1:'Hargra',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'255',
    id:'254',
    rarity:'4',
    name:'Shadowbringers Urianger',
    icon:'ShadowbringersUrianger',
    method1:'Triple Decker IX - obtain 270 Triple Triad cards',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'256',
    id:'255',
    rarity:'4',
    name:'Ran\'jit',
    icon:'Ranjit',
    method1:'Grewenn',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'257',
    id:'256',
    rarity:'5',
    name:'Hades',
    icon:'Hades',
    method1:'The Dying Grasp',
    type1:'duty',
    droprate1:'???',
    method2:'The Minstrel\'s Ballad: Hades\'s Elegy',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'258',
    id:'256',
    rarity:'5',
    name:'Ardbert',
    icon:'Ardbert',
    method1:'Triple Team VII - defeat 92 NPCs',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.0',
  },
  {
    number:'259',
    id:'258',
    rarity:'1',
    name:'Hobgoblin',
    icon:'Hobgoblin',
    method1:'Dream Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'260',
    id:'259',
    rarity:'1',
    name:'Porxie',
    icon:'Porxie',
    method1:'Eo Sigun',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'261',
    id:'260',
    rarity:'2',
    name:'Iguana',
    icon:'Iguana',
    method1:'Dream Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'262',
    id:'261',
    rarity:'2',
    name:'Nu Mou',
    icon:'NuMou',
    method1:'Eo Sigun',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'263',
    id:'262',
    rarity:'2',
    name:'Fuath',
    icon:'Fuath',
    method1:'Dream Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'264',
    id:'263',
    rarity:'3',
    name:'Leannan Sith',
    icon:'LeannanSith',
    method1:'Dream Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'265',
    id:'264',
    rarity:'3',
    name:'Seeker of Solitude',
    icon:'SeekerofSolitude',
    method1:'The Grand Cosmos',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'266',
    id:'265',
    rarity:'4',
    name:'Oracle of Light',
    icon:'OracleofLight',
    method1:'Dream Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'267',
    id:'266',
    rarity:'5',
    name:'Archaeotania',
    icon:'Archaeotania',
    method1:'Dream Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'268',
    id:'267',
    rarity:'5',
    name:'9S',
    icon:'9S',
    method1:'The Copied Factory',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.1',
  },
  {
    number:'269',
    id:'268',
    rarity:'1',
    name:'Flower Basket',
    icon:'FlowerBasket',
    method1:'Il Mheg (Rank 1) - 40 Bicolor Gemstones',
    type1:'gem',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'270',
    id:'269',
    rarity:'1',
    name:'Qitari',
    icon:'Qitari',
    method1:'Redard',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'271',
    id:'270',
    rarity:'2',
    name:'Gnoll',
    icon:'Gnoll',
    method1:'Lakeland (Rank 1) - 60 Bicolor Gemstones',
    type1:'gem',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'272',
    id:'271',
    rarity:'3',
    name:'Batsquatch',
    icon:'Batsquatch',
    method1:'Redard',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'273',
    id:'272',
    rarity:'3',
    name:'Forgiven Obscenity',
    icon:'ForgivenObscenity',
    method1:'Kholusia (Rank 2) - 100 Bicolor Gemstones',
    type1:'gem',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'274',
    id:'273',
    rarity:'3',
    name:'Huaca',
    icon:'Huaca',
    method1:'Rak\'tika Greatwood (Rank 2) - 100 Bicolor Gemstones',
    type1:'gem',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'275',
    id:'274',
    rarity:'3',
    name:'Unknown',
    icon:'Unknown',
    method1:'Anamnesis Anyder',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'276',
    id:'275',
    rarity:'4',
    name:'Ruby Weapon',
    icon:'RubyWeapon',
    method1:'Cinder Drift',
    type1:'duty',
    droprate1:'???',
    method2:'Cinder Drift (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'277',
    id:'276',
    rarity:'5',
    name:'Therion',
    icon:'Therion',
    method1:'The Tempest (Rank 1) - 250 Bicolor Gemstones',
    type1:'gem',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.2',
  },
  {
    number:'278',
    id:'277',
    rarity:'2',
    name:'Lizbeth',
    icon:'Lizbeth',
    method1:'500 Skybuilder\'s Scrips',
    type1:'scrip',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.21',
  },
  {
    number:'279',
    id:'278',
    rarity:'5',
    name:'Varis yae Galvus',
    icon:'VarisyaeGalvus',
    method1:'Memoria Misera (Extreme)',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.25',
  },
  {
    number:'280',
    id:'279',
    rarity:'1',
    name:'Dwarf',
    icon:'Dwarf',
    method1:'Cobleva',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'281',
    id:'280',
    rarity:'2',
    name:'Rolling Tankard',
    icon:'RollingTankard',
    method1:'Cobleva',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'282',
    id:'281',
    rarity:'3',
    name:'Lugus',
    icon:'Lugus',
    method1:'Triple Triad Trader - 21,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'283',
    id:'282',
    rarity:'4',
    name:'Elidibus',
    icon:'Elidibus',
    method1:'Triple Triad Trader - 56,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'284',
    id:'283',
    rarity:'4',
    name:'Shadowbringers Thancred',
    icon:'ShadowbringersThancred',
    method1:'Triple Decker X - Obtain 300 Triple Triad cards',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'285',
    id:'284',
    rarity:'4',
    name:'The Sapphire Weapon',
    icon:'TheSapphireWeapon',
    method1:'Furtive Former Imperial',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'286',
    id:'285',
    rarity:'5',
    name:'2P',
    icon:'2P',
    method1:'The Puppets\' Bunker',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'287',
    id:'286',
    rarity:'5',
    name:'Shadowbringers Warrior of Light',
    icon:'ShadowbringersWarriorofLight',
    method1:'The Seat of Sacrifice',
    type1:'duty',
    droprate1:'???',
    method2:'The Seat of Sacrifice (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.3',
  },
  {
    number:'288',
    id:'287',
    rarity:'2',
    name:'Ehll Tou',
    icon:'EhllTou',
    method1:'500 Skybuilder\'s Scrips',
    type1:'scrip',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.31',
  },
  {
    number:'289',
    id:'288',
    rarity:'3',
    name:'Dawon',
    icon:'Dawon',
    method1:'Arsieu',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.35',
  },
  {
    number:'290',
    id:'289',
    rarity:'3',
    name:'Adrammelech',
    icon:'Adrammelech',
    method1:'Arsieu',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.35',
  },
  {
    number:'291',
    id:'290',
    rarity:'2',
    name:'Mother Porxie',
    icon:'MotherPorxie',
    method1:'Matoya\'s Relict',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.4',
  },
  {
    number:'292',
    id:'291',
    rarity:'4',
    name:'The Emerald Weapon',
    icon:'TheEmeraldWeapon',
    method1:'Castrum Marinum',
    type1:'duty',
    droprate1:'???',
    method2:'Castrum Marinum (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.4',
  },
  {
    number:'293',
    id:'292',
    rarity:'4',
    name:'Ryne',
    icon:'Ryne',
    method1:'Lewto-Sue',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.4',
  },
  {
    number:'294',
    id:'293',
    rarity:'4',
    name:'Gaia',
    icon:'Gaia',
    method1:'Lewto-Sue',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.4',
  },
  {
    number:'295',
    id:'294',
    rarity:'5',
    name:'Eden\'s Promise',
    icon:'EdensPromise',
    method1:'Eden\'s Promise: Eternity',
    type1:'duty',
    droprate1:'???',
    method2:'Eden\'s Promise: Eternity (Savage)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.4',
  },
  {
    number:'296',
    id:'295',
    rarity:'5',
    name:'Phoenix',
    icon:'Phoenix',
    method1:'Open and Shut I',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.4',
  },
  {
    number:'297',
    id:'296',
    rarity:'1',
    name:'The Great Azuro',
    icon:'TheGreatAzuro',
    method1:'Maudlin Latool Ja - 100 Allied Seals',
    type1:'seal',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.45',
  },
  {
    number:'298',
    id:'297',
    rarity:'3',
    name:'Trinity Seeker',
    icon:'TrinitySeeker',
    method1:'Resistance Quartermaster - 3 Bozjan Clusters',
    type1:'cluster',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.45',
  },
  {
    number:'299',
    id:'298',
    rarity:'3',
    name:'Trinity Avowed',
    icon:'TrinityAvowed',
    method1:'Resistance Quartermaster - 3 Bozjan Clusters',
    type1:'cluster',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.45',
  },
  {
    number:'300',
    id:'299',
    rarity:'3',
    name:'Azulmagia',
    icon:'Azulmagia',
    method1:'Droyn',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.45',
  },
  {
    number:'301',
    id:'300',
    rarity:'3',
    name:'Siegfried',
    icon:'Siegfried',
    method1:'Droyn',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.45',
  },
  {
    number:'302',
    id:'301',
    rarity:'3',
    name:'Gogo, Master of Mimicry',
    icon:'GogoMasterofMimicry',
    method1:'Maudlin Latool Ja - 300 Allied Seals',
    type1:'seal',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.45',
  },
  {
    number:'303',
    id:'302',
    rarity:'2',
    name:'Keeper of the Keys',
    icon:'KeeperoftheKeys',
    method1:'Il Mheg (Rank 1) - 60 Gemstones',
    type1:'gem',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.5',
  },
  {
    number:'304',
    id:'303',
    rarity:'3',
    name:'Lunar Bahamut',
    icon:'LunarBahamut',
    method1:'Paglth\'an',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.5',
  },
  {
    number:'305',
    id:'304',
    rarity:'3',
    name:'Valens van Varro',
    icon:'ValensvanVarro',
    method1:'Triple Triad Trader - 22,400 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.5',
  },
  {
    number:'306',
    id:'305',
    rarity:'4',
    name:'G-Warrior',
    icon:'G-Warrior',
    method1:'C\'intana - 1 Ruby Totem, 1 Emerald Totem, 1 Diamond Totem',
    type1:'',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.5',
  },
  {
    number:'307',
    id:'306',
    rarity:'4',
    name:'The Diamond Weapon',
    icon:'TheDiamondWeapon',
    method1:'The Cloud Deck',
    type1:'duty',
    droprate1:'???',
    method2:'The Cloud Deck (Extreme)',
    type2:'duty',
    droprate2:'???',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.5',
  },
  {
    number:'308',
    id:'307',
    rarity:'5',
    name:'2B',
    icon:'2B',
    method1:'The Tower at Paradigm\'s Breach',
    type1:'duty',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.5',
  },
  {
    number:'309',
    id:'308',
    rarity:'3',
    name:'Lunar Ifrit',
    icon:'LunarIfrit',
    method1:'Triple Triad Trader - 22,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.55',
  },
  {
    number:'310',
    id:'309',
    rarity:'3',
    name:'4th-make Shemhazai',
    icon:'4th-makeShemhazai',
    method1:'Sladkey',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.55',
  },
  {
    number:'311',
    id:'310',
    rarity:'3',
    name:'4th-make Cuchulainn',
    icon:'4th-makeCuchulainn',
    method1:'Sladkey',
    type1:'npc',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.55',
  },
  {
    number:'312',
    id:'311',
    rarity:'4',
    name:'The Diablo Armament',
    icon:'TheDiabloArmament',
    method1:'Resistance Quartermaster - 7 Bozjan Clusters',
    type1:'cluster',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'5.55',
  },
  {
    number:'313',
    id:'312',
    rarity:'5',
    name:'Warrior of Light',
    icon:'WarriorofLight',
    method1:'Gold Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'314',
    id:'313',
    rarity:'5',
    name:'Firion',
    icon:'Firion',
    method1:'Platinum Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'1st, 2nd, or 3rd place in the Durai Memorial tournament',
    type2:'tournament',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'315',
    id:'314',
    rarity:'5',
    name:'Onion Knight',
    icon:'OnionKnight',
    method1:'The World of Darkness',
    type1:'duty',
    droprate1:'???',
    method2:'Lewena',
    type2:'npc',
    droprate2:'???',
    method3:'Mythril Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'316',
    id:'315',
    rarity:'5',
    name:'Cecil Harvey',
    icon:'CecilHarvey',
    method1:'Platinum Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'1st, 2nd, or 3rd place in the Spinner\'s Pull tournament',
    type2:'tournament',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'317',
    id:'316',
    rarity:'5',
    name:'Bartz Klauser',
    icon:'BartzKlauser',
    method1:'Battle in the Big Keep',
    type1:'duty',
    droprate1:'???',
    method2:'Lewena',
    type2:'npc',
    droprate2:'???',
    method3:'Mythril Triad Card pack',
    type3:'pack',
    droprate3:'???',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'318',
    id:'317',
    rarity:'5',
    name:'Terra Branford',
    icon:'TerraBranford',
    method1:'The Dragon\'s Neck',
    type1:'duty',
    droprate1:'???',
    method2:'Lewena',
    type2:'npc',
    droprate2:'???',
    method3:'Hall Overseer',
    type3:'npc',
    droprate3:'???',
    method4:'Mythril Triad Card pack',
    type4:'pack',
    droprate4:'???',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'319',
    id:'318',
    rarity:'5',
    name:'Cloud Strife',
    icon:'CloudStrife',
    method1:'Triple Triad Trader - 1,000,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'320',
    id:'319',
    rarity:'5',
    name:'Squall Leonhart',
    icon:'SquallLeonhart',
    method1:'Triple Team III - defeat 30 NPCs',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'321',
    id:'320',
    rarity:'5',
    name:'Zidane Tribal',
    icon:'ZidaneTribal',
    method1:'Gold Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'322',
    id:'321',
    rarity:'5',
    name:'Tidus',
    icon:'Tidus',
    method1:'Platinum Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'1st, 2nd, or 3rd place in the Rowena Cup Classic tournament',
    type2:'tournament',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'323',
    id:'322',
    rarity:'5',
    name:'Shantotto',
    icon:'Shantotto',
    method1:'Kumite Kumite Kumite - win 30 Triple Triad tournament matches',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'324',
    id:'323',
    rarity:'5',
    name:'Vaan',
    icon:'Vaan',
    method1:'Wheel of Fortune V - win 300 roulette matches',
    type1:'achievement',
    droprate1:'',
    method2:'',
    type2:'',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'325',
    id:'324',
    rarity:'5',
    name:'Lightning',
    icon:'Lightning',
    method1:'Platinum Triad Card pack',
    type1:'pack',
    droprate1:'???',
    method2:'1st, 2nd, or 3rd place in the Manderville Tournament of Champions',
    type2:'tournament',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'2.51',
  },
  {
    number:'326',
    id:'325',
    rarity:'5',
    name:'Noctis Lucis Caelum',
    icon:'NoctisLucisCaelum',
    method1:'Triple Triad Trader - 200,000 MGP',
    type1:'mgp',
    droprate1:'',
    method2:'A Nocturne For Heroes - 10,000 MGP',
    type2:'event',
    droprate2:'',
    method3:'',
    type3:'',
    droprate3:'',
    method4:'',
    type4:'',
    droprate4:'',
    method5:'',
    type5:'',
    droprate5:'',
    patch:'4.56',
  },
]