import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-o12s2',
  templateUrl: './o12s2.component.html',
  styleUrls: ['./o12s2.component.less']
})
export class O12s2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
