import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-male',
  templateUrl: './male.component.html',
  styleUrls: ['./male.component.less']
})
export class MaleComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Male Character Voices'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, ffxiv male character voices'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Male Character Voices - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }

}
