<h1 class="seoh1">Final Fantasy XIV - Toadskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-toadskin">
    <dt>
      <button class="js-badger-accordion-header">
        THANALAN
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="18.3,17.5,42" src="assets/img/treasuremap-icons/toadskin/centralthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.0,21.2,42" src="assets/img/treasuremap-icons/toadskin/centralthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.3,33.6,42" src="assets/img/treasuremap-icons/toadskin/centralthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="27.2,17.7,42" src="assets/img/treasuremap-icons/toadskin/easternthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.9,23.9,42" src="assets/img/treasuremap-icons/toadskin/easternthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.7,30.1,42" src="assets/img/treasuremap-icons/toadskin/easternthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthernThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTHERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="16.9,10.9,42" src="assets/img/treasuremap-icons/toadskin/southernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.3,31.0,42" src="assets/img/treasuremap-icons/toadskin/southernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="13.7,39.3,42" src="assets/img/treasuremap-icons/toadskin/southernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="14.2,6.9,42" src="assets/img/treasuremap-icons/toadskin/westernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.4,16.5,42" src="assets/img/treasuremap-icons/toadskin/westernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="25.5,17.6,42" src="assets/img/treasuremap-icons/toadskin/westernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        LA NOSCEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="26.2,33.8,42" src="assets/img/treasuremap-icons/toadskin/easternlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="19.4,35.1,42" src="assets/img/treasuremap-icons/toadskin/easternlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="28.4,35.6,42" src="assets/img/treasuremap-icons/toadskin/easternlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="LowerLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">LOWER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="30.0,18.4,42" src="assets/img/treasuremap-icons/toadskin/lowerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="23.5,23.0,42" src="assets/img/treasuremap-icons/toadskin/lowerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="19.6,34.7,42" src="assets/img/treasuremap-icons/toadskin/lowerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="MiddleLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="17.3,12.5,42" src="assets/img/treasuremap-icons/toadskin/middlelanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="19.9,13.2,42" src="assets/img/treasuremap-icons/toadskin/middlelanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.1,22.8,42" src="assets/img/treasuremap-icons/toadskin/middlelanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="OuterLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">OUTER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="15.7,10.7,42" src="assets/img/treasuremap-icons/toadskin/outerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="14.2,13.9,42" src="assets/img/treasuremap-icons/toadskin/outerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="25.3,15.8,42" src="assets/img/treasuremap-icons/toadskin/outerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="UpperLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">UPPER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="28.4,18.4,42" src="assets/img/treasuremap-icons/toadskin/upperlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="9.7,21.1,42" src="assets/img/treasuremap-icons/toadskin/upperlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="12.2,25.8,42" src="assets/img/treasuremap-icons/toadskin/upperlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="21.7,23.5,42" src="assets/img/treasuremap-icons/toadskin/westernlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.9,24.5,42" src="assets/img/treasuremap-icons/toadskin/westernlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.6,31.1,42" src="assets/img/treasuremap-icons/toadskin/westernlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        THE BLACK SHROUD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="16.3,17.6,42" src="assets/img/treasuremap-icons/toadskin/centralshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.5,21.0,42" src="assets/img/treasuremap-icons/toadskin/centralshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="30.3,21.4,42" src="assets/img/treasuremap-icons/toadskin/centralshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EastShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EAST SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="25.6,22.9,42" src="assets/img/treasuremap-icons/toadskin/eastshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.9,23.6,42" src="assets/img/treasuremap-icons/toadskin/eastshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.3,30.1,42" src="assets/img/treasuremap-icons/toadskin/eastshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="NorthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">NORTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="28.5,21.7,42" src="assets/img/treasuremap-icons/toadskin/northshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.5,25.4,42" src="assets/img/treasuremap-icons/toadskin/northshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.7,25.6,42" src="assets/img/treasuremap-icons/toadskin/northshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="23.1,21.7,42" src="assets/img/treasuremap-icons/toadskin/southshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.0,23.9,42" src="assets/img/treasuremap-icons/toadskin/southshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="17.1,32.4,42" src="assets/img/treasuremap-icons/toadskin/southshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasCentralHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS CENTRAL HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="19.6,15.3,42" src="assets/img/treasuremap-icons/toadskin/coerthascentral01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.7,15.6,42" src="assets/img/treasuremap-icons/toadskin/coerthascentral02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.3,35.0,42" src="assets/img/treasuremap-icons/toadskin/coerthascentral03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="MorDhona" (click)="treasuremapChangeMap($event)">
        MOR DHONA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="17.4,8.0,42" src="assets/img/treasuremap-icons/toadskin/mordhona01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="26.9,9.3,42" src="assets/img/treasuremap-icons/toadskin/mordhona02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.1,12.5,42" src="assets/img/treasuremap-icons/toadskin/mordhona03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>