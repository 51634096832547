  <div id="g-topnav">
    <div class="topnav-button">
      <p>A REALM REBORN</p>
      <div class="dropdown">
        <a routerLink="leather">
          <div class="dropdown-button">LEATHER MAPS</div>
        </a>
        <a routerLink="goatskin">
          <div class="dropdown-button">GOATSKIN MAPS</div>
        </a>
        <a routerLink="toadskin">
          <div class="dropdown-button">TOADSKIN MAPS</div>
        </a>
        <a routerLink="boarskin">
          <div class="dropdown-button">BOARSKIN MAPS</div>
        </a>
        <a routerLink="peisteskin">
          <div class="dropdown-button">PEISTESKIN MAPS</div>
        </a>
        <div class="dropdown-button disabled-button disabled-text">UNHIDDEN LEATHER MAPS</div>
      </div>
    </div>
    <div class="topnav-button">
      <p>HEAVENSWARD</p>
      <div class="dropdown">
        <a routerLink="archaeoskin">
          <div class="dropdown-button">ARCHAEOSKIN MAPS</div>
        </a>
        <a routerLink="wyvernskin">
          <div class="dropdown-button">WYVERNSKIN MAPS</div>
        </a>
        <a routerLink="dragonskin">
          <div class="dropdown-button">DRAGONSKIN MAPS</div>
        </a>
      </div>
    </div>
    <div class="topnav-button">
      <p>STORMBLOOD</p>
      <div class="dropdown">
        <a routerLink="gaganaskin">
          <div class="dropdown-button">GAGANASKIN MAPS</div>
        </a>
        <a routerLink="gazelleskin">
          <div class="dropdown-button">GAZELLESKIN MAPS</div>
        </a>
        <a routerLink="fabledthiefs">
          <div class="dropdown-button">FABLED THIEF'S MAPS</div>
        </a>
      </div>
    </div>
    <div class="topnav-button">
      <p>SHADOWBRINGERS</p>
      <div class="dropdown">
        <a routerLink="gliderskin">
          <div class="dropdown-button">GLIDERSKIN MAPS</div>
        </a>
        <a routerLink="zonureskin">
          <div class="dropdown-button">ZONURESKIN MAPS</div>
        </a>
      </div>
    </div>
    <div class="topnav-button">
      <p>ENDWALKER</p>
      <div class="dropdown">
        <a routerLink="saigaskin">
          <div class="dropdown-button">SAIGASKIN MAPS</div>
        </a>
        <a routerLink="kumbhiraskin">
          <div class="dropdown-button">KUMBHIRASKIN MAPS</div>
        </a>
      </div>
    </div>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>
