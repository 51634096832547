<h1 class="seoh1">Final Fantasy XIV - Leather Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-leather">
    <dt>
      <button class="js-badger-accordion-header">
        THANALAN
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="25.3,18.0,42" src="assets/img/treasuremap-icons/leather/centralthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="17.5,18.9,42" src="assets/img/treasuremap-icons/leather/centralthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.3,34.1,42" src="assets/img/treasuremap-icons/leather/centralthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="23.9,18.5,42" src="assets/img/treasuremap-icons/leather/easternthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="14.9,19.1,42" src="assets/img/treasuremap-icons/leather/easternthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="26.6,19.0,42" src="assets/img/treasuremap-icons/leather/easternthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthernThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTHERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="22.5,13.1,42" src="assets/img/treasuremap-icons/leather/southernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="14.7,15.9,42" src="assets/img/treasuremap-icons/leather/southernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.0,29.7,42" src="assets/img/treasuremap-icons/leather/southernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="16.4,14.6,42" src="assets/img/treasuremap-icons/leather/westernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="23.3,24.0,42" src="assets/img/treasuremap-icons/leather/westernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.2,26.8,42" src="assets/img/treasuremap-icons/leather/westernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        LA NOSCEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="22.5,23.5,42" src="assets/img/treasuremap-icons/leather/easternlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="26.8,31.9,42" src="assets/img/treasuremap-icons/leather/easternlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="17.5,33.2,42" src="assets/img/treasuremap-icons/leather/easternlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="LowerLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">LOWER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="30.4,21.3,42" src="assets/img/treasuremap-icons/leather/lowerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.7,25.0,42" src="assets/img/treasuremap-icons/leather/lowerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="19.9,32.3,42" src="assets/img/treasuremap-icons/leather/lowerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="MiddleLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="22.5,15.6,42" src="assets/img/treasuremap-icons/leather/middlelanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.4,19.0,42" src="assets/img/treasuremap-icons/leather/middlelanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.2,27.5,42" src="assets/img/treasuremap-icons/leather/middlelanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="OuterLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">OUTER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="20.1,15.8,42" src="assets/img/treasuremap-icons/leather/outerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="14.2,16.1,42" src="assets/img/treasuremap-icons/leather/outerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="20.4,17.0,42" src="assets/img/treasuremap-icons/leather/outerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="UpperLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">UPPER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="14.4,24.3,42" src="assets/img/treasuremap-icons/leather/upperlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="35.2,24.8,42" src="assets/img/treasuremap-icons/leather/upperlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="29.8,25.1,42" src="assets/img/treasuremap-icons/leather/upperlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="24.6,23.6,42" src="assets/img/treasuremap-icons/leather/westernlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="30.5,26.9,42" src="assets/img/treasuremap-icons/leather/westernlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="34.2,28.0,42" src="assets/img/treasuremap-icons/leather/westernlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        THE BLACK SHROUD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="19.4,18.2,42" src="assets/img/treasuremap-icons/leather/centralshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="28.7,22.6,42" src="assets/img/treasuremap-icons/leather/centralshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.6,26.9,42" src="assets/img/treasuremap-icons/leather/centralshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EastShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EAST SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="13.3,23.1,42" src="assets/img/treasuremap-icons/leather/eastshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.6,25.0,42" src="assets/img/treasuremap-icons/leather/eastshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.1,29.1,42" src="assets/img/treasuremap-icons/leather/eastshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="NorthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">NORTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="26.4,21.3,42" src="assets/img/treasuremap-icons/leather/northshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.3,24.8,42" src="assets/img/treasuremap-icons/leather/northshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="20.3,28.1,42" src="assets/img/treasuremap-icons/leather/northshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="27.6,17.6,42" src="assets/img/treasuremap-icons/leather/southshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="17.2,21.6,42" src="assets/img/treasuremap-icons/leather/southshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.2,29.5,42" src="assets/img/treasuremap-icons/leather/southshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasCentralHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS CENTRAL HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="23.7,19.8,42" src="assets/img/treasuremap-icons/leather/coerthascentral01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.0,23.8,42" src="assets/img/treasuremap-icons/leather/coerthascentral02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.6,27.2,42" src="assets/img/treasuremap-icons/leather/coerthascentral03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>