import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloadvoiceicons',
  templateUrl: './preloadvoiceicons.component.html',
  styleUrls: ['./preloadvoiceicons.component.less']
})
export class PreloadvoiceiconsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
