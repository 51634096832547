import { Component, OnInit, ElementRef, Renderer2, HostListener } from '@angular/core';
import { version } from '../../../package.json';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {

  public version: string = version;

  ngOnInit() {
  }
}
