<h1 class="seoh1">Final Fantasy XIV - Dragonskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-dragonskin">
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasWesternHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS WESTERN HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="36.9,26.7,44" src="assets/img/treasuremap-icons/dragonskin/coerthaswestern01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.1,15.5,44" src="assets/img/treasuremap-icons/dragonskin/coerthaswestern02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="6.7,12.1,44" src="assets/img/treasuremap-icons/dragonskin/coerthaswestern03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="34.8,7.6,44" src="assets/img/treasuremap-icons/dragonskin/coerthaswestern04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.6,12.1,44" src="assets/img/treasuremap-icons/dragonskin/coerthaswestern05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.0,24.4,44" src="assets/img/treasuremap-icons/dragonskin/coerthaswestern06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.2,30.7,44" src="assets/img/treasuremap-icons/dragonskin/coerthaswestern07.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheChurningMists" (click)="treasuremapChangeMap($event)">
        THE CHURNING MISTS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="17.3,33.7,44" src="assets/img/treasuremap-icons/dragonskin/churningmists01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.7,19.2,44" src="assets/img/treasuremap-icons/dragonskin/churningmists02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.0,37.5,44" src="assets/img/treasuremap-icons/dragonskin/churningmists03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.3,9.5,44" src="assets/img/treasuremap-icons/dragonskin/churningmists04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.7,13.2,44" src="assets/img/treasuremap-icons/dragonskin/churningmists05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="8.0,17.6,44" src="assets/img/treasuremap-icons/dragonskin/churningmists06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.4,20.0,44" src="assets/img/treasuremap-icons/dragonskin/churningmists07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.1,25.5,44" src="assets/img/treasuremap-icons/dragonskin/churningmists08.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.5,37.8,44" src="assets/img/treasuremap-icons/dragonskin/churningmists09.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheDravanianForelands" (click)="treasuremapChangeMap($event)">
        THE DRAVANIAN FORELANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="27.4,30.8,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.6,30.1,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.0,24.1,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.4,25.8,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.1,34.8,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.5,34.6,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.0,34.3,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.9,33.9,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands08.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.5,31.8,44" src="assets/img/treasuremap-icons/dragonskin/dravanianforelands09.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheSeaOfClouds" (click)="treasuremapChangeMap($event)">
        THE SEA OF CLOUDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="10.4,39.1,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.8,34.9,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.2,16.7,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="14.4,26.9,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.6,5.3,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.1,24.1,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="6.6,7.1,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.8,10.6,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds08.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.8,18.8,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds09.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.4,32.0,44" src="assets/img/treasuremap-icons/dragonskin/seaofclouds10.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>

