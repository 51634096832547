<h1 class="seoh1">Final Fantasy XIV - Elite Hunt Enemy Locations</h1>
  <div id="huntsearch">
      <input type="text" [(ngModel)]="searchText" placeholder="Search by name or location">
  </div>
  <div id="huntsearchresults">
    <ul class="elitehunts" *ngIf="(elitehunts | filter: searchText).length > 0">
      <li *ngFor="let elitehunt of elitehunts | sortBy: 'asc':'location' | filter: searchText; let i = index" id="{{elitehunt.name}}" (click)="onSelect(elitehunt)">
        <span class="huntrank">{{elitehunt.rank}}</span>
        <span class="huntinfo">
          <span class="huntname">{{elitehunt.name}}<br></span>
          <span class="huntlocation">{{elitehunt.location}}</span>
        </span>
      </li>
    </ul>
  </div>
  <div id="map-viewer" style="position:relative;width:85.5vh;height:85.5vh;margin-top:-3vh">
  </div>
