<h1 class="seoh1">Final Fantasy XIV - Goatskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-goatskin">
    <dt>
      <button class="js-badger-accordion-header">
        THANALAN
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="20.6,13.0,42" src="assets/img/treasuremap-icons/goatskin/centralthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="16.0,20.2,42" src="assets/img/treasuremap-icons/goatskin/centralthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.8,22.5,42" src="assets/img/treasuremap-icons/goatskin/centralthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="29.4,17.4,42" src="assets/img/treasuremap-icons/goatskin/easternthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.7,22.2,42" src="assets/img/treasuremap-icons/goatskin/easternthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.7,23.7,42" src="assets/img/treasuremap-icons/goatskin/easternthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthernThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTHERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="23.9,7.9,42" src="assets/img/treasuremap-icons/goatskin/southernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.2,14.0,42" src="assets/img/treasuremap-icons/goatskin/southernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.9,20.0,42" src="assets/img/treasuremap-icons/goatskin/southernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternThanalan" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN THANALAN</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="12.2,11.9,42" src="assets/img/treasuremap-icons/goatskin/westernthanalan01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="26.9,17.0,42" src="assets/img/treasuremap-icons/goatskin/westernthanalan02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.6,23.6,42" src="assets/img/treasuremap-icons/goatskin/westernthanalan03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        LA NOSCEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EasternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EASTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="29.5,24.3,42" src="assets/img/treasuremap-icons/goatskin/easternlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.3,24.8,42" src="assets/img/treasuremap-icons/goatskin/easternlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="29.6,36.3,42" src="assets/img/treasuremap-icons/goatskin/easternlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="LowerLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">LOWER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="29.4,13.7,42" src="assets/img/treasuremap-icons/goatskin/lowerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="29.1,16.2,42" src="assets/img/treasuremap-icons/goatskin/lowerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.7,27.1,42" src="assets/img/treasuremap-icons/goatskin/lowerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="MiddleLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="18.0,13.4,42" src="assets/img/treasuremap-icons/goatskin/middlelanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.4,20.9,42" src="assets/img/treasuremap-icons/goatskin/middlelanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="25.2,25.0,42" src="assets/img/treasuremap-icons/goatskin/middlelanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="OuterLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">OUTER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="13.6,14.8,42" src="assets/img/treasuremap-icons/goatskin/outerlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="22.8,15.0,42" src="assets/img/treasuremap-icons/goatskin/outerlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="15.7,15.7,42" src="assets/img/treasuremap-icons/goatskin/outerlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="UpperLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">UPPER LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="27.3,23.7,42" src="assets/img/treasuremap-icons/goatskin/upperlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="33.1,23.9,42" src="assets/img/treasuremap-icons/goatskin/upperlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="14.0,25.3,42" src="assets/img/treasuremap-icons/goatskin/upperlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="WesternLaNoscea" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">WESTERN LA NOSCEA</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="23.3,21.6,42" src="assets/img/treasuremap-icons/goatskin/westernlanoscea01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="29.5,21.9,42" src="assets/img/treasuremap-icons/goatskin/westernlanoscea02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="36.1,30.3,42" src="assets/img/treasuremap-icons/goatskin/westernlanoscea03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header">
        THE BLACK SHROUD
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <mat-accordion>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="CentralShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">CENTRAL SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="17.6,20.5,42" src="assets/img/treasuremap-icons/goatskin/centralshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="25.5,20.9,42" src="assets/img/treasuremap-icons/goatskin/centralshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="24.7,32.0,42" src="assets/img/treasuremap-icons/goatskin/centralshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="EastShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">EAST SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="17.3,21.6,42" src="assets/img/treasuremap-icons/goatskin/eastshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.5,25.4,42" src="assets/img/treasuremap-icons/goatskin/eastshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="23.7,30.8,42" src="assets/img/treasuremap-icons/goatskin/eastshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="NorthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">NORTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="29.7,22.8,42" src="assets/img/treasuremap-icons/goatskin/northshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="28.5,26.1,42" src="assets/img/treasuremap-icons/goatskin/northshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="18.1,26.9,42" src="assets/img/treasuremap-icons/goatskin/northshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
          <mat-expansion-panel class="subaccordion">
            <mat-expansion-panel-header data="SouthShroud" (click)="treasuremapChangeMap($event)">
              <div class="alib-acc-header">SOUTH SHROUD</div>
            </mat-expansion-panel-header>
            <img class="smallmapimg" data="25.5,18.1,42" src="assets/img/treasuremap-icons/goatskin/southshroud01.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="21.8,19.5,42" src="assets/img/treasuremap-icons/goatskin/southshroud02.webp" (click)="treasuremapMoveFlag($event)">
            <img class="smallmapimg" data="27.8,22.3,42" src="assets/img/treasuremap-icons/goatskin/southshroud03.webp" (click)="treasuremapMoveFlag($event)">
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasCentralHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS CENTRAL HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="26.4,13.7,42" src="assets/img/treasuremap-icons/goatskin/coerthascentralhighlands01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.8,24.9,42" src="assets/img/treasuremap-icons/goatskin/coerthascentralhighlands02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.9,27.4,42" src="assets/img/treasuremap-icons/goatskin/coerthascentralhighlands03.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>
