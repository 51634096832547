import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kingelmer',
  templateUrl: './kingelmer.component.html',
  styleUrls: ['./kingelmer.component.less']
})
export class KingelmerComponent implements OnInit {

  constructor() { }

  sqb0 = "url('assets/img/triadsolver/b0.webp')";
  sqb1 = "url('assets/img/triadsolver/b1.webp')";
  sqb2 = "url('assets/img/triadsolver/b2.webp')";
  sqb3 = "url('assets/img/triadsolver/b3.webp')";
  sqb4 = "url('assets/img/triadsolver/b4.webp')";
  sqb5 = "url('assets/img/triadsolver/b5.webp')";
  sqb6 = "url('assets/img/triadsolver/b6.webp')";
  sqb7 = "url('assets/img/triadsolver/b7.webp')";
  sqb8 = "url('assets/img/triadsolver/b8.webp')";
  sqb9 = "url('assets/img/triadsolver/b9.webp')";

  sqr0 = "url('assets/img/triadsolver/r0.webp')";
  sqr1 = "url('assets/img/triadsolver/r1.webp')";
  sqr2 = "url('assets/img/triadsolver/r2.webp')";
  sqr3 = "url('assets/img/triadsolver/r3.webp')";
  sqr4 = "url('assets/img/triadsolver/r4.webp')";
  sqr5 = "url('assets/img/triadsolver/r5.webp')";
  sqr6 = "url('assets/img/triadsolver/r6.webp')";
  sqr7 = "url('assets/img/triadsolver/r7.webp')";
  sqr8 = "url('assets/img/triadsolver/r8.webp')";
  sqr9 = "url('assets/img/triadsolver/r9.webp')";

  cardBahamut = "url('assets/img/triadsolver/bahamut.webp')";
  cardBomb = "url('assets/img/triadsolver/bomb.webp')";
  cardGilgamesh = "url('assets/img/triadsolver/gilgamesh.webp')";
  cardGodbert = "url('assets/img/triadsolver/godbert.webp')";
  cardHildibrand = "url('assets/img/triadsolver/hildibrand.webp')";
  cardMinfilia = "url('assets/img/triadsolver/minfilia.webp')";
  cardMoogle = "url('assets/img/triadsolver/moogle.webp')";
  cardMorbol = "url('assets/img/triadsolver/morbol.webp')";
  cardSylph = "url('assets/img/triadsolver/sylph.webp')";
  cardTitan = "url('assets/img/triadsolver/titan.webp')";
  cardUrianger = "url('assets/img/triadsolver/urianger.webp')";
  cardYshtola = "url('assets/img/triadsolver/yshtola.webp')";

  opponentCard = "";
  opponentSquare = "";
  opponentNumber = 0;

  currentSolve = "";

  ngOnInit(): void {
  }

  funcCreateMove(): void {
    this.opponentNumber++;
    var fillOpponentMove = document.getElementById(JSON.stringify(this.opponentNumber));
    fillOpponentMove.innerHTML =  '<div class="opponentcard" style="background-image:' + this.opponentCard + '"></div><div class="opponentsquare" style="background-image:' + this.opponentSquare + '"></div>';
    fillOpponentMove.style.display = "block";
  }

  funcClearMoves(): void {
    var opponentMoves = document.getElementsByClassName("possiblemove") as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < opponentMoves.length; i++) {
      opponentMoves[i].style.display = "none";
    }
  }

  funcCongratulate(): void {
    var congratulationsElement = document.getElementById("congratulations") as HTMLElement;
    congratulationsElement.style.display = "block";
  }

  funcSolver(event): void {
    var locationHome = document.getElementById("home") as HTMLElement;
    var locationSolver = document.getElementById("solverwrap") as HTMLElement;
    var playerCard = document.getElementById("playercard") as HTMLElement;
    var playerSquare = document.getElementById("playersquare") as HTMLElement;
    var congratulationsElement = document.getElementById("congratulations") as HTMLElement;
    congratulationsElement.style.display = "none";
    var selectedValue = event.currentTarget.attributes.id.nodeValue;
    this.opponentNumber = 0;
    if (selectedValue !== "return" && selectedValue !== "prev" && selectedValue !== "congratulations") {
      this.currentSolve = this.currentSolve.concat(selectedValue);
    } else if (selectedValue == "return" || selectedValue == "congratulations") {
      this.currentSolve = "";
      var playerCard = document.getElementById("playercard") as HTMLElement;
      var playerSquare = document.getElementById("playersquare") as HTMLElement;
      playerCard.style.backgroundImage = "";
      playerSquare.style.backgroundImage = "";
    } else if (selectedValue == "prev") {
      this.currentSolve = this.currentSolve.slice(0, -1);
    }
    switch(this.currentSolve) {
      case "":
        locationSolver.style.display = "none";
        locationHome.style.display = "block";
      break;
      case "a":
        locationHome.style.display = "none";
        locationSolver.style.display = "block";
        this.funcClearMoves();
        playerCard.style.backgroundImage = this.cardMinfilia;
        playerSquare.style.backgroundImage = this.sqb5;
        this.opponentCard = this.cardGodbert;
        this.opponentSquare = this.sqr2;
        this.funcCreateMove();
        this.opponentCard = this.cardGodbert;
        this.opponentSquare = this.sqr4;
        this.funcCreateMove();
      break;
        case "a1":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardSylph;
          playerSquare.style.backgroundImage = this.sqb4;
          this.opponentCard = this.cardYshtola;
          this.opponentSquare = this.sqr3;
          this.funcCreateMove();
          this.opponentCard = this.cardYshtola;
          this.opponentSquare = this.sqr7;
          this.funcCreateMove();
          this.opponentCard = this.cardTitan;
          this.opponentSquare = this.sqr7;
          this.funcCreateMove();
          this.opponentCard = this.cardTitan;
          this.opponentSquare = this.sqr8;
          this.funcCreateMove();
          this.opponentCard = this.cardGilgamesh;
          this.opponentSquare = this.sqr3;
          this.funcCreateMove();
          this.opponentCard = this.cardGilgamesh;
          this.opponentSquare = this.sqr7;
          this.funcCreateMove();
          this.opponentCard = this.cardUrianger;
          this.opponentSquare = this.sqr1;
          this.funcCreateMove();
          this.opponentCard = this.cardUrianger;
          this.opponentSquare = this.sqr3;
          this.funcCreateMove();
        break;
          case "a11":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardBomb;
            playerSquare.style.backgroundImage = this.sqb6;
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardUrianger;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "a111":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb7;
              this.funcCongratulate();
            break;
            case "a112":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb1;
              this.opponentCard = this.cardTitan;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "a1121":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardSylph;
                playerSquare.style.backgroundImage = this.sqb8;
                this.funcCongratulate();
              break;
          case "a12":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardMorbol;
            playerSquare.style.backgroundImage = this.sqb3;
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
          break;
            case "a121":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr8;
              this.funcCreateMove();
            break;
              case "a1211":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
          case "a13":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardBomb;
            playerSquare.style.backgroundImage = this.sqb8;
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "a131":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb3;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "a1311":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a132":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb3;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr6;
              this.funcCreateMove();
            break;
              case "a1321":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a133":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb3;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "a1331":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a134":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb6;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr3;
              this.funcCreateMove();
              this.opponentCard = this.cardGilgamesh;
              this.opponentSquare = this.sqr3;
              this.funcCreateMove();
            break;
              case "a1341":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "a1342":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
          case "a14":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardBomb;
            playerSquare.style.backgroundImage = this.sqb3;
            this.opponentCard = this.cardUrianger;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "a141":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
              this.opponentCard = this.cardGilgamesh;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "a1411":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "a1412":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a142":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "a1421":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a143":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb6;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "a1431":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
          case "a15":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardBomb;
            playerSquare.style.backgroundImage = this.sqb7;
            this.opponentCard = this.cardUrianger;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
          break;
            case "a151":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr8;
              this.funcCreateMove();
            break;
              case "a1511":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a152":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr8;
              this.funcCreateMove();
            break;
              case "a1521":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a153":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr6;
              this.funcCreateMove();
            break;
              case "a1531":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
          case "a16":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardMorbol;
            playerSquare.style.backgroundImage = this.sqb3;
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
          break;
            case "a161":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardYshtola;
              this.opponentSquare = this.sqr8;
              this.funcCreateMove();
            break;
            case "a1611":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb1;
              this.funcCongratulate();
            break;
            case "a17":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb6;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "a171":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb8;
                this.opponentCard = this.cardBahamut;
                this.opponentSquare = this.sqr7;
                this.funcCreateMove();
              break;
                case "a1711":
                  this.funcClearMoves();
                  playerCard.style.backgroundImage = this.cardMorbol;
                  playerSquare.style.backgroundImage = this.sqb3;
                  this.funcCongratulate();
                break;
          case "a18":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardMorbol;
            playerSquare.style.backgroundImage = this.sqb9;
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr7;
            this.funcCreateMove();
          break;
            case "a181":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb6;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
              this.opponentCard = this.cardGilgamesh;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "a1811":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb7;
                this.funcCongratulate();
              break;
              case "a1812":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "a1813":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "a1814":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "a182":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb6;
              this.opponentCard = this.cardGilgamesh;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
              this.opponentCard = this.cardGilgamesh;
              this.opponentSquare = this.sqr8;
              this.funcCreateMove();
            break;
              case "a1821":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb8;
                this.funcCongratulate();
              break;
              case "a1822":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
        case "a2":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardMorbol;
          playerSquare.style.backgroundImage = this.sqb2;
          this.opponentCard = this.cardYshtola;
          this.opponentSquare = this.sqr3;
          this.funcCreateMove();
          this.opponentCard = this.cardYshtola;
          this.opponentSquare = this.sqr7;
          this.funcCreateMove();
          this.opponentCard = this.cardGilgamesh;
          this.opponentSquare = this.sqr6;
          this.funcCreateMove()
          this.opponentCard = this.cardGilgamesh;
          this.opponentSquare = this.sqr3;
          this.funcCreateMove()
          this.opponentCard = this.cardUrianger;
          this.opponentSquare = this.sqr3;
          this.funcCreateMove()
        break;
          case "a21":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardBomb;
            playerSquare.style.backgroundImage = this.sqb6;
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardUrianger;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "a211":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardSylph;
              playerSquare.style.backgroundImage = this.sqb1;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "a2111":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb7;
                this.funcCongratulate();
              break;
            case "a212":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb1;
              this.opponentCard = this.cardTitan;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "a2121":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardSylph;
                playerSquare.style.backgroundImage = this.sqb8;
                this.funcCongratulate();
              break;
          case "a22":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb6;
            this.opponentCard = this.cardUrianger;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "a221":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardTitan;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
              this.opponentCard = this.cardTitan;
              this.opponentSquare = this.sqr3;
              this.funcCreateMove();
              this.opponentCard = this.cardGilgamesh;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "a2211":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
              case "a2212":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "a2213":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
              case "a2214":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
            case "a222":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "a2221":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
          case "a23":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb9;
            this.opponentCard = this.cardBahamut;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr7;
            this.funcCreateMove();
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardTitan;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
          break;
            case "a231":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb7;
              this.opponentCard = this.cardTitan;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "a2311":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
            case "a232":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb7;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "a2321":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
            case "a233":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb7;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "a2331":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
            case "a234":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb7;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "a2341":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
          case "a24":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardBomb;
            playerSquare.style.backgroundImage = this.sqb6;
            this.opponentCard = this.cardUrianger;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "a241":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb1;
              this.opponentCard = this.cardTitan;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "a2411":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardSylph;
                playerSquare.style.backgroundImage = this.sqb8;
                this.funcCongratulate();
              break;
          case "a25":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardBomb;
            playerSquare.style.backgroundImage = this.sqb6;
            this.opponentCard = this.cardHildibrand;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
          break;
            case "a251":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardSylph;
              playerSquare.style.backgroundImage = this.sqb1;
              this.opponentCard = this.cardTitan;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "a2511":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb7;
                this.funcCongratulate();
              break;
      case "b":
        locationHome.style.display = "none";
        locationSolver.style.display = "block";
        this.funcClearMoves();
        this.opponentCard = this.cardYshtola;
        this.opponentSquare = this.sqr1;
        this.funcCreateMove();
        this.opponentCard = this.cardYshtola;
        this.opponentSquare = this.sqr2;
        this.funcCreateMove();
        this.opponentCard = this.cardUrianger;
        this.opponentSquare = this.sqr3;
        this.funcCreateMove();
        this.opponentCard = this.cardUrianger;
        this.opponentSquare = this.sqr6;
        this.funcCreateMove();
        this.opponentCard = this.cardTitan;
        this.opponentSquare = this.sqr7;
        this.funcCreateMove();
        this.opponentCard = this.cardTitan;
        this.opponentSquare = this.sqr8;
        this.funcCreateMove();
      break;
        case "b1":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardMinfilia;
          playerSquare.style.backgroundImage = this.sqb5;
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr2;
          this.funcCreateMove();
        break;
          case "b11":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardMoogle;
            playerSquare.style.backgroundImage = this.sqb6;
            this.opponentCard = this.cardTitan;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardTitan;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr4;
            this.funcCreateMove();
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "b111":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardSylph;
              playerSquare.style.backgroundImage = this.sqb4;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b1111":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMorbol;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
              case "b1112":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMorbol;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
            case "b112":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardSylph;
              playerSquare.style.backgroundImage = this.sqb4;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
            case "b1121":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb3;
              this.funcCongratulate();
            break;
            case "b113":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb7;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr8;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b1131":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMorbol;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
              case "b1132":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMorbol;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
              case "b1133":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMorbol;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
            case "b114":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardSylph;
              playerSquare.style.backgroundImage = this.sqb4;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "b1141":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMorbol;
                playerSquare.style.backgroundImage = this.sqb3;
                this.funcCongratulate();
              break;
        case "b2":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardMinfilia;
          playerSquare.style.backgroundImage = this.sqb5;
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr4;
          this.funcCreateMove();
        break;
          case "b21":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb7;
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr3;
            this.funcCreateMove();
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardTitan;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
          break;
            case "b211":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr6;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b2111":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "b2112":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b212":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b2121":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b213":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb3;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr6;
              this.funcCreateMove();
            break;
              case "b2131":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
        case "b3":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardMinfilia;
          playerSquare.style.backgroundImage = this.sqb5;
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr2;
          this.funcCreateMove();
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr4;
          this.funcCreateMove();
        break;
          case "b31":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb4;
            this.opponentCard = this.cardYshtola;
            this.opponentSquare = this.sqr7;
            this.funcCreateMove();
            this.opponentCard = this.cardYshtola;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardTitan;
            this.opponentSquare = this.sqr7;
            this.funcCreateMove();
            this.opponentCard = this.cardTitan;
            this.opponentSquare = this.sqr8;
            this.funcCreateMove();
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr7;
            this.funcCreateMove();
          break;
            case "b311":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb6;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b3111":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b312":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "b3121":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b313":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b3131":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b314":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "b3141":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b315":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr8;
              this.funcCreateMove();
            break;
              case "b3151":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "b3152":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b316":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb6;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b3161":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
          case "b32":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardMorbol;
            playerSquare.style.backgroundImage = this.sqb6;
            this.opponentCard = this.cardYshtola;
            this.opponentSquare = this.sqr1;
            this.funcCreateMove();
            this.opponentCard = this.cardTitan;
            this.opponentSquare = this.sqr9;
            this.funcCreateMove();
          break;
            case "b321":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b3211":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb2;
                this.funcCongratulate();
              break;
              case "b3212":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb2;
                this.funcCongratulate();
              break;
            case "b322":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMoogle;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr1;
              this.funcCreateMove();
            break;
              case "b3221":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardBomb;
                playerSquare.style.backgroundImage = this.sqb2;
                this.funcCongratulate();
              break;
        case "b4":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardMinfilia;
          playerSquare.style.backgroundImage = this.sqb5;
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr2;
          this.funcCreateMove();
        break;
          case "b41":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb4;
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr3;
            this.funcCreateMove();
            this.opponentCard = this.cardYshtola;
            this.opponentSquare = this.sqr3;
            this.funcCreateMove();
          break;
            case "b411":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "b4111":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b412":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardHildibrand;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "b4121":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
        case "b5":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardMinfilia;
          playerSquare.style.backgroundImage = this.sqb5;
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr2;
          this.funcCreateMove();
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr4;
          this.funcCreateMove();
        break;
          case "b51":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb4;
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr3;
            this.funcCreateMove();
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
          break;
            case "b511":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr6;
              this.funcCreateMove();
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b5111":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
              case "b5112":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b512":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardBomb;
              playerSquare.style.backgroundImage = this.sqb8;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b5121":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
          case "b52":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb8;
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr6;
            this.funcCreateMove();
          break;
            case "b521":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb2;
              this.opponentCard = this.cardUrianger;
              this.opponentSquare = this.sqr9;
              this.funcCreateMove();
            break;
              case "b5211":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
        case "b6":
          this.funcClearMoves();
          playerCard.style.backgroundImage = this.cardMinfilia;
          playerSquare.style.backgroundImage = this.sqb5;
          this.opponentCard = this.cardGodbert;
          this.opponentSquare = this.sqr2;
          this.funcCreateMove();
        break;
          case "b61":
            this.funcClearMoves();
            playerCard.style.backgroundImage = this.cardSylph;
            playerSquare.style.backgroundImage = this.sqb4;
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr3;
            this.funcCreateMove();
            this.opponentCard = this.cardGilgamesh;
            this.opponentSquare = this.sqr7;
            this.funcCreateMove();
          break;
            case "b611":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr7;
              this.funcCreateMove();
            break;
              case "b6111":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
            case "b612":
              this.funcClearMoves();
              playerCard.style.backgroundImage = this.cardMorbol;
              playerSquare.style.backgroundImage = this.sqb9;
              this.opponentCard = this.cardBahamut;
              this.opponentSquare = this.sqr6;
              this.funcCreateMove();
            break;
              case "b6121":
                this.funcClearMoves();
                playerCard.style.backgroundImage = this.cardMoogle;
                playerSquare.style.backgroundImage = this.sqb1;
                this.funcCongratulate();
              break;
    }
  }
}