import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less']
})
export class ReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

  dscSubmitReport(): void {
    var request = new XMLHttpRequest();
    request.open("POST", "https://discordapp.com/api/webhooks/705709644320079973/cTGkpF35Ig8t-02oDEOycw1mLZwhBZgNURRzaQHJ94EiXASLm0lUpz59ba8RaCDj8Sm6");
    request.setRequestHeader('Content-type', 'application/json');
    var reportcontent = (document.getElementById("reportmessage") as HTMLInputElement).value;
    if (reportcontent.length > 0) {
      var params = {
        content: ("<@706032371568345129>") + ("\n") + (reportcontent) + ("\n\n") + ("**Operating system=**") + (navigator.platform) + ("\n") + ("**Browser type=**") + (navigator.appCodeName) + ("\n") + ("**Cookies enabled=**") + (navigator.cookieEnabled) + ("\n") + ("**Screen=**") + (screen.width) + ("x") + (screen.height)
      }
      request.send(JSON.stringify(params));
      document.getElementById("reporter").style.display = "none";
      document.getElementById("reporter-done").style.display = "block";
    }
  }
}
