<h1 class="seoh1">Final Fantasy XIV - Wyvernskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-wyvernskin">
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasWesternHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS WESTERN HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="33.2,5.7,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.8,10.4,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.0,11.2,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="36.0,16.0,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="33.7,19.3,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.9,23.0,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.0,27.3,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.7,31.7,44" src="assets/img/treasuremap-icons/wyvernskin/coerthaswestern08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheChurningMists" (click)="treasuremapChangeMap($event)">
        THE CHURNING MISTS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="11.7,16.2,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.3,16.4,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="38.1,17.6,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.3,23.9,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.8,24.3,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.0,29.0,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.9,30.0,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.9,35.8,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists08.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.0,36.4,44" src="assets/img/treasuremap-icons/wyvernskin/churningmists09.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheDravanianForelands" (click)="treasuremapChangeMap($event)">
        THE DRAVANIAN FORELANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="28.2,11.6,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.1,12.7,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="37.4,17.3,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.0,24.0,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.5,25.1,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.1,26.6,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.4,33.7,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.7,35.9,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands08.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="9.3,36.2,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianforelands09.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheDravanianHinterlands" (click)="treasuremapChangeMap($event)">
        THE DRAVANIAN HINTERLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="15.1,21.3,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.7,21.1,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="38.4,24.0,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.2,25.7,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.8,28.2,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.4,29.8,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="14.8,33.8,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.9,35.0,44" src="assets/img/treasuremap-icons/wyvernskin/dravanianhinterlands08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheSeaOfClouds" (click)="treasuremapChangeMap($event)">
        THE SEA OF CLOUDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="13.6,10.6,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.4,10.3,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="37.0,15.4,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.3,17.9,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="37.3,20.7,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.8,21.5,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.8,23.2,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="9.9,26.5,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds08.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.1,35.5,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds09.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="35.2,37.3,44" src="assets/img/treasuremap-icons/wyvernskin/seaofclouds10.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>

