import { Component, OnInit } from '@angular/core';
import sort from 'fast-sort';
import { TriadCards } from '../triadcard';
import { TRIADCARDS } from '../list-triadcards';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cardlist',
  templateUrl: './cardlist.component.html',
  styleUrls: ['./cardlist.component.less']
})
export class CardlistComponent implements OnInit {

  triadcards = TRIADCARDS;

  searchText: string;ˇ

  cardlistElement = document.getElementsByClassName("cardlistelem") as HTMLCollectionOf<HTMLElement>;

  selectedType: string;

  hideOwnedCardsEnabled: boolean;
  showNpcCardsEnabled: boolean;
  showDutyCardsEnabled: boolean;
  showPackCardsEnabled: boolean;
  showMgpCardsEnabled: boolean;
  showAchievementCardsEnabled: boolean;
  showFateCardsEnabled: boolean;
  showGemCardsEnabled: boolean;
  showSackCardsEnabled: boolean;
  showTournamentCardsEnabled: boolean;
  showEventCardsEnabled: boolean;
  showScripCardsEnabled: boolean;
  showSealCardsEnabled: boolean;
  showClusterCardsEnabled: boolean;

  constructor(private meta: Meta, private title: Title, private httpClient: HttpClient) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Triple Triad Card List'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Triple Triad, ffxiv triple triad card list'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Triple Triad Card List - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void { //after view has been fully initialized
    //get card list elements
    var cardlistElement = document.getElementsByClassName("cardlistelem");
    //if ownedCards does not exist in localstorage
    if (localStorage.getItem('ownedCards') === null) {
      //create var for local storage string
      var storageStringFull = "";
      //for every card list element, add 1 "0," to local storage string
      for (var i = 0; i < cardlistElement.length; i++) {
        storageStringFull += "0,";
      }
      //remove the last comma from local storage string
      var storageString = storageStringFull.replace(/,\s*$/, "");
      //send local storage string to localstorage
      localStorage.setItem('ownedCards', storageString);
    } else {
      //get string from localstorage
      var storageStringFull = localStorage.getItem('ownedCards');
      //make string into array
      var storageArray = storageStringFull.split(",");
      //get length of array
      var storageArrayLength = storageArray.length;
      //if currently stored array is smaller than amount of elements
      if (storageArrayLength < cardlistElement.length) {
        for (var k = storageArrayLength; k < cardlistElement.length; k++) {
          //add "false," to end of string
          storageStringFull += "0,";
          //remove last comma
          var storageString = storageStringFull.replace(/,\s*$/, "");
          //store array to localstorage
          localStorage.setItem('ownedCards', storageString);
        }
      } else {
        for (var j = 0; j < cardlistElement.length; j++) {
          //if localstorage array is true
          if (storageArray[j] === "1") {
            //get checkbox wrapper
            var checkboxWrap = cardlistElement[j].children[4];
            //get checkbox
            var checkbox = checkboxWrap.children[0];
            //add ownedCard to classlist of element
            cardlistElement[j].classList.add("ownedCard");
            //check the checkbox
            checkbox.classList.add("checked");
          }
        }
      }
    }
    //get all method elements
    var hideCardMethods = document.getElementsByClassName("method") as HTMLCollectionOf<HTMLElement>;
    //get all type elements
    var hideCardTypes = document.getElementsByClassName("type") as HTMLCollectionOf<HTMLElement>;
    //get all droprate elements
    var hideCardDroprates = document.getElementsByClassName("droprate") as HTMLCollectionOf<HTMLElement>;
    for (var l = 0; l < hideCardMethods.length; l++) {
      //if method is empty
      if (hideCardMethods[l].innerHTML === "") {
        hideCardMethods[l].classList.add("hidden");
        hideCardTypes[l].classList.add("hidden");
        hideCardDroprates[l].classList.add("hidden");
      }
    }
    //change heights based on content
    //get cardlistelem as an html element
    var cardlistElementHTML = document.getElementsByClassName("cardlistelem") as HTMLCollectionOf<HTMLElement>;
    for (var h = 0; h < cardlistElement.length; h++) {
      var checkCardMethod3 = cardlistElement[h].children[3].children[8].innerHTML;
      var checkCardMethod4 = cardlistElement[h].children[3].children[11].innerHTML;
      var checkCardMethod5 = cardlistElement[h].children[3].children[14].innerHTML;
      if (checkCardMethod3 !== "") {
        cardlistElementHTML[h].style.height = "100px";
        if (checkCardMethod4 !== "") {
          cardlistElementHTML[h].style.height = "120px";
          if (checkCardMethod5 !== "") {
            cardlistElementHTML[h].style.height = "145px";
          }
        }
      }
    }
  }

  ngAfterViewChecked(): void { //after a change has been made in the view
    //search functionality
    //get card elements
    var cardlistElement = document.getElementsByClassName("cardlistelem") as HTMLCollectionOf<HTMLElement>;
    //if search text is not undefined (empty)
    if (this.searchText !== undefined && this.searchText !== "") {
      for (var l = 0; l < cardlistElement.length; l++) {
        if (cardlistElement[l].classList.contains("hidden")) {
        } else {
          var searchCardName = cardlistElement[l].children[3].children[0].innerHTML.toUpperCase();
          var searchCardMethod1 = cardlistElement[l].children[3].children[2].innerHTML.toUpperCase();
          var searchCardMethod2 = cardlistElement[l].children[3].children[5].innerHTML.toUpperCase();
          var searchCardMethod3 = cardlistElement[l].children[3].children[8].innerHTML.toUpperCase();
          var searchCardMethod4 = cardlistElement[l].children[3].children[11].innerHTML.toUpperCase();
          var searchCardMethod5 = cardlistElement[l].children[3].children[14].innerHTML.toUpperCase();
          if (searchCardName.includes(this.searchText.toUpperCase()) || searchCardMethod1.includes(this.searchText.toUpperCase()) || searchCardMethod2.includes(this.searchText.toUpperCase()) || searchCardMethod3.includes(this.searchText.toUpperCase()) || searchCardMethod4.includes(this.searchText.toUpperCase()) || searchCardMethod5.includes(this.searchText.toUpperCase())) {
            cardlistElement[l].classList.remove("hiddenBySearch");
          } else {
            cardlistElement[l].classList.add("hiddenBySearch");
          }
        }
      }
    } else {
      this.funcSortClearAll();
    }
    //hide owned cards button check
    //get hide owned cards button
    var hideOwnedCardsButton = document.getElementById("ownedcardstoggle");
    //if hide owned cards is active
    if (hideOwnedCardsButton.classList.contains("active")) {
      for (var i = 0; i < cardlistElement.length; i++) {
        //if card element is ownedCard
        if (cardlistElement[i].classList.contains("ownedCard")) {
          //hide card element
          cardlistElement[i].classList.add("hidden");
        }
      }
    }
  }

  onSelect(triadcard: TriadCards) { //function for when a card was clicked
    //get id of selected card
    var selectedCardId = triadcard.id;
    //get element of selected card
    var selectedCard = document.getElementById(selectedCardId);
    //get checkbox wrapper of selected card
    var checkboxWrap = selectedCard.children[4];
    //get checkbox of selected card
    var checkbox = checkboxWrap.children[0];
    //if selected card has ownedCard class
    if (selectedCard.classList.contains("ownedCard")) {
      //remove the ownedCard class
      selectedCard.classList.remove("ownedCard");
      //remove check from checkbox
      checkbox.classList.remove("checked");
      //get localstorage string
      var storageString = localStorage.getItem('ownedCards');
      //convert localstorage string to array
      var storageArray = storageString.split(",");
      //mark card owned as false in localstorage array
      storageArray[selectedCardId] = "0";
      //convert array back to string
      storageString = storageArray.join(",");
      //send to localstorage
      localStorage.setItem('ownedCards', storageString);
      //check if user is logged in on discord
      if (document.cookie.split("=")[0] == "DiscordOAuth") {
        var id = document.cookie.split("=")[1].split(",")[0];
        var data01 = localStorage.getItem('ownedCards');
        var payload = "https://prepull.readycheck.pro/updateuserdata.php?id=" + id + "&data01=" + data01;
        this.httpClient.post<any>(payload, { title: '9875123' })
        .subscribe();
      }
    } else {
      //add the ownedCard class
      selectedCard.classList.add("ownedCard");
      //add check to checkbox
      checkbox.classList.add("checked");
      //get localstorage string
      var storageString = localStorage.getItem('ownedCards');
      //convert localstorage string to array
      var storageArray = storageString.split(",");
      //mark card owned as true in localstorage array
      storageArray[selectedCardId] = "1";
      //convert array back to string
      storageString = storageArray.join(",");
      //send to localstorage
      localStorage.setItem('ownedCards', storageString);
      if (document.cookie.split("=")[0] == "DiscordOAuth") {
        var id = document.cookie.split("=")[1].split(",")[0];
        var data01 = localStorage.getItem('ownedCards');
        var payload = "https://prepull.readycheck.pro/updateuserdata.php?id=" + id + "&data01=" + data01;
        this.httpClient.post<any>(payload, { title: '9875123' })
        .subscribe();
      }
    }
  }

  btnHideOwned(): void {
    var hideOwnedCardsButton = document.getElementById("ownedcardstoggle");
    if (hideOwnedCardsButton.classList.contains("active")) {
      hideOwnedCardsButton.classList.remove("active");
      this.hideOwnedCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      hideOwnedCardsButton.classList.add("active");
      this.hideOwnedCardsEnabled = true;
      this.funcHideOwned();
    }
  }

  btnSortByNpc(): void {
    this.funcBtnClearAll();
    var sortByNpcButton = document.getElementById("sortbynpcbtn");
    if (sortByNpcButton.classList.contains("active")) {
      sortByNpcButton.classList.remove("active");
      this.showNpcCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByNpcButton.classList.add("active");
      this.showNpcCardsEnabled = true;
      this.selectedType = "npc";
      this.funcSortBy();
    }
  }

  btnSortByDuty(): void {
    this.funcBtnClearAll();
    var sortByDutyButton = document.getElementById("sortbydutybtn");
    if (sortByDutyButton.classList.contains("active")) {
      sortByDutyButton.classList.remove("active");
      this.showDutyCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByDutyButton.classList.add("active");
      this.showDutyCardsEnabled = true;
      this.selectedType = "duty";
      this.funcSortBy();
    }
  }

  btnSortByPack(): void {
    this.funcBtnClearAll();
    var sortByPackButton = document.getElementById("sortbypackbtn");
    if (sortByPackButton.classList.contains("active")) {
      sortByPackButton.classList.remove("active");
      this.showPackCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByPackButton.classList.add("active");
      this.showPackCardsEnabled = true;
      this.selectedType = "pack";
      this.funcSortBy();
    }
  }

  btnSortByMgp(): void {
    this.funcBtnClearAll();
    var sortByMgpButton = document.getElementById("sortbymgpbtn");
    if (sortByMgpButton.classList.contains("active")) {
      sortByMgpButton.classList.remove("active");
      this.showMgpCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByMgpButton.classList.add("active");
      this.showMgpCardsEnabled = true;
      this.selectedType = "mgp";
      this.funcSortBy();
    }
  }

  btnSortByAchievement(): void {
    this.funcBtnClearAll();
    var sortByAchievementButton = document.getElementById("sortbyachievementbtn");
    if (sortByAchievementButton.classList.contains("active")) {
      sortByAchievementButton.classList.remove("active");
      this.showAchievementCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByAchievementButton.classList.add("active");
      this.showAchievementCardsEnabled = true;
      this.selectedType = "achievement";
      this.funcSortBy();
    }
  }

  btnSortByFate(): void {
    this.funcBtnClearAll();
    var sortByFateButton = document.getElementById("sortbyfatebtn");
    if (sortByFateButton.classList.contains("active")) {
      sortByFateButton.classList.remove("active");
      this.showFateCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByFateButton.classList.add("active");
      this.showFateCardsEnabled = true;
      this.selectedType = "fate";
      this.funcSortBy();
    }
  }

  btnSortByGem(): void {
    this.funcBtnClearAll();
    var sortByGemButton = document.getElementById("sortbygembtn");
    if (sortByGemButton.classList.contains("active")) {
      sortByGemButton.classList.remove("active");
      this.showGemCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByGemButton.classList.add("active");
      this.showGemCardsEnabled = true;
      this.selectedType = "gem";
      this.funcSortBy();
    }
  }

  btnSortBySack(): void {
    this.funcBtnClearAll();
    var sortBySackButton = document.getElementById("sortbysackbtn");
    if (sortBySackButton.classList.contains("active")) {
      sortBySackButton.classList.remove("active");
      this.showSackCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortBySackButton.classList.add("active");
      this.showSackCardsEnabled = true;
      this.selectedType = "sack";
      this.funcSortBy();
    }
  }

  btnSortByTournament(): void {
    this.funcBtnClearAll();
    var sortByTournamentButton = document.getElementById("sortbytournamentbtn");
    if (sortByTournamentButton.classList.contains("active")) {
      sortByTournamentButton.classList.remove("active");
      this.showTournamentCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByTournamentButton.classList.add("active");
      this.showTournamentCardsEnabled = true;
      this.selectedType = "tournament";
      this.funcSortBy();
    }
  }

  btnSortByEvent(): void {
    this.funcBtnClearAll();
    var sortByEventButton = document.getElementById("sortbyeventbtn");
    if (sortByEventButton.classList.contains("active")) {
      sortByEventButton.classList.remove("active");
      this.showEventCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByEventButton.classList.add("active");
      this.showEventCardsEnabled = true;
      this.selectedType = "event";
      this.funcSortBy();
    }
  }

  btnSortByScrip(): void {
    this.funcBtnClearAll();
    var sortByScripButton = document.getElementById("sortbyscripbtn");
    if (sortByScripButton.classList.contains("active")) {
      sortByScripButton.classList.remove("active");
      this.showScripCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByScripButton.classList.add("active");
      this.showScripCardsEnabled = true;
      this.selectedType = "scrip";
      this.funcSortBy();
    }
  }

  btnSortBySeal(): void {
    this.funcBtnClearAll();
    var sortBySealButton = document.getElementById("sortbysealbtn");
    if (sortBySealButton.classList.contains("active")) {
      sortBySealButton.classList.remove("active");
      this.showSealCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortBySealButton.classList.add("active");
      this.showSealCardsEnabled = true;
      this.selectedType = "scrip";
      this.funcSortBy();
    }
  }

  btnSortByCluster(): void {
    this.funcBtnClearAll();
    var sortByClusterButton = document.getElementById("sortbyclusterbtn");
    if (sortByClusterButton.classList.contains("active")) {
      sortByClusterButton.classList.remove("active");
      this.showClusterCardsEnabled = false;
      this.funcSortClearAll();
    } else {
      sortByClusterButton.classList.add("active");
      this.showClusterCardsEnabled = true;
      this.selectedType = "cluster";
      this.funcSortBy();
    }
  }

  funcSortClearAll(): void {
   for (var i = 0; i < this.cardlistElement.length; i++) {
     this.cardlistElement[i].classList.remove("hidden");
     this.cardlistElement[i].classList.remove("hiddenBySearch")
   }
   this.funcCheckSorting();
  }

  funcCheckSorting(): void {
    if (this.hideOwnedCardsEnabled) {
      this.funcHideOwned();
    }
    if (this.showNpcCardsEnabled) {
      this.selectedType = "npc";
      this.funcSortBy();
    }
    if (this.showDutyCardsEnabled) {
      this.selectedType = "duty";
      this.funcSortBy();
    }
    if (this.showPackCardsEnabled) {
      this.selectedType = "pack";
      this.funcSortBy();
    }
    if (this.showMgpCardsEnabled) {
      this.selectedType = "mgp";
      this.funcSortBy();
    }
    if (this.showAchievementCardsEnabled) {
      this.selectedType = "achievement";
      this.funcSortBy();
    }
    if (this.showFateCardsEnabled) {
      this.selectedType = "fate";
      this.funcSortBy();
    }
    if (this.showGemCardsEnabled) {
      this.selectedType = "gem";
      this.funcSortBy();
    }
    if (this.showSackCardsEnabled) {
      this.selectedType = "sack";
      this.funcSortBy();
    }
    if (this.showTournamentCardsEnabled) {
      this.selectedType = "tournament";
      this.funcSortBy();
    }
    if (this.showEventCardsEnabled) {
      this.selectedType = "event";
      this.funcSortBy();
    }
    if (this.showScripCardsEnabled) {
      this.selectedType = "scrip";
      this.funcSortBy();
    }
    if (this.showSealCardsEnabled) {
      this.selectedType = "seal";
      this.funcSortBy();
    }
    if (this.showClusterCardsEnabled) {
      this.selectedType = "cluster";
      this.funcSortBy();
    }
  }

  funcBtnClearAll(): void {
    var allSortButtons = document.getElementsByClassName("button-sq");
      for (var i = 0; i < allSortButtons.length; i++) {
        allSortButtons[i].classList.remove("active");
      }
      this.showAchievementCardsEnabled = false;
      this.showDutyCardsEnabled = false;
      this.showEventCardsEnabled = false;
      this.showFateCardsEnabled = false;
      this.showGemCardsEnabled = false;
      this.showMgpCardsEnabled = false;
      this.showNpcCardsEnabled = false;
      this.showSackCardsEnabled = false;
      this.showPackCardsEnabled = false;
      this.showScripCardsEnabled = false;
      this.showSealCardsEnabled = false;
      this.showClusterCardsEnabled = false;
      this.showTournamentCardsEnabled = false;
      this.funcSortClearAll();
  }

  funcHideOwned(): void {
    for (var i = 0; i < this.cardlistElement.length; i++) {
      if (this.cardlistElement[i].classList.contains("ownedCard)")) {
        this.cardlistElement[i].classList.add("hidden");
      }
    }
  }

  funcSortBy(): void {
    for (var i = 0; i < this.cardlistElement.length; i++) {
      var sortTypes = JSON.stringify(this.cardlistElement[i].children[3].children[1].classList) + JSON.stringify(this.cardlistElement[i].children[3].children[4].classList) + JSON.stringify(this.cardlistElement[i].children[3].children[7].classList) + JSON.stringify(this.cardlistElement[i].children[3].children[10].classList) + JSON.stringify(this.cardlistElement[i].children[3].children[13].classList);
      if (sortTypes.includes(this.selectedType)) {
      } else {
        this.cardlistElement[i].classList.add("hidden");
      }
    }
  }
}
