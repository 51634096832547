<div id="content">
    <p style="color:white;text-align:center;"><br><br>REPORT A BUG OR MISTAKE</p>
    <div id="reporter" class="report-wrapper">
      <div class="textbox">
        <textarea id="reportmessage" class="report-message" placeholder="Please describe the issue..."></textarea>
      </div>
      <div class="submit">
        <button (click)="dscSubmitReport()">SUBMIT</button>
      </div>
      <p style="color:white;text-align:center;font-size:12px;"><br><br>NOTE: Your report will contain generic information about your browser.</p>
    </div>
    <div id="reporter-done" class="report-wrapper" style="display:none">
      <p style="color:white;text-align:center;"><br><br>YOUR MESSAGE HAS BEEN FORWARDED TO THE DEVELOPERS<br><br>THANK YOU! ♥</p>
    </div>
  </div>
