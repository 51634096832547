import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-triadsolver',
  templateUrl: './triadsolver.component.html',
  styleUrls: ['./triadsolver.component.less']
})
export class TriadsolverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
