<h1 class="seoh1">Final Fantasy XIV - Pugilist Hunting Log Locations</h1>
<div id="pugilist-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-pugilist">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CentralThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">CENTRAL THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="19.6,26.6,42,10;21.8,28.5,42,7;22.6,31.4,42,8;23.0,34.3,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hornet.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Huge Hornet</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="19.6,26.6,42,10;21.8,28.5,42,7;22.6,31.4,42,8;23.0,34.3,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Star Marmot</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="23.6,26.6,42,10;25.8,31.7,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SnappingShrew.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Snapping Shrew</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="21.2,24.5,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Orobon.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Orobon</p></div>
                                <div class="level">Lv 5</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="16.9,24.0,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SprigganGraverobber.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Spriggan Graverobber</p></div>
                                <div class="level">Lv 7</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="22.8,19.7,42,4;24.9,16.4,42,4;23.9,17.8,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Eft</p></div>
                                <div class="level">Lv 10</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="27.4,24.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Cactuar.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Cactuar</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="21.0,25.4,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Nesting Buzzard</p></div>
                                <div class="level">Lv 6</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="18.9,26.0,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goblin.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Goblin Mugger</p></div>
                                <div class="level">Lv 8</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="24.3,21.7,42,5;22.7,23.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sandtoad</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="24.3,20.0,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sun Midge Swarm</p></div>
                                <div class="level">Lv 10</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="24.3,20.0,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Basilisk.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Desert Pieste</p></div>
                                <div class="level">Lv 10</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="24.4,20.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Cactuar.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Cochineal Cactuar</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="17.0,14.2,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Antling.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Antling Sentry</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="11.7,27.2,42,5;14.0,26.2,42,6;17.5,30.5,42,5;16.2,24.2,42,3;14.7,20.5,42,5;11.9,19.1,42,7;12.5,16.0,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/TucoTuco.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Tuco-tuco</p></div>
                            <div class="level">Lv 15-19</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="17.5,23.4,42,10;16.5,27.0,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/MyotragusBilly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Myotragus Nanny</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="11.8,22.5,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Blowfly Swarm</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="14.4,18.6,42,3;13.7,16.9,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Imp.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Vandalous Imp</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="14.7,16.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Rotting Corpse</p></div>
                            <div class="level">Lv 17</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="14.7,16.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Rotting Noble</p></div>
                            <div class="level">Lv 17</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="27.4,17.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bomb</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="26.7,23.9,42,2;28.6,25.2,42,2;24.0,26.3,42,2;20.1,28.4,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Tortoise.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Giant Tortoise</p></div>
                            <div class="level">Lv 12-17</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="16.3,16.2,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Arbor Buzzard</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="17.0,14.7,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Scaphite.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Scaphite</p></div>
                            <div class="level">Lv 14</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="16.3,15.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Thickshell.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Thickshell</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="13.5,11.7,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bogy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>BloatedBogy</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="29.1,35.5,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Apkallu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Apkallu</p></div>
                            <div class="level">Lv 30</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.1,21.9,42,5;11.1,21.3,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Forest Yarson</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="9.2,21.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurl Pup</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.5,16.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Fallen Mage</p></div>
                            <div class="level">Lv 26</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="23.9,9.4,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Corpse Brigade Knuckledancer</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.3,18.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lindwurm</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.6,29.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Overgrown Ivy</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="17.2,27.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Slug.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Shroud Hare</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="17.2,27.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ziz</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="16.5,29.4,42,3;17.9,28.4,42,3;19.7,28.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Clay Golem</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="17.1,18.5,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Orobon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Smallmouth Orobon</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="19.8,22.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bark Eft</p></div>
                            <div class="level">Lv 23</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="30.2,20.9,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Poacher.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurlclaw Hunter</p></div>
                            <div class="level">Lv 29</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="18.1,30.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Orobon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bigmouth Orobon</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="18.7,28.0,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Jungle Coeurl</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="17.0,31.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Goobbue</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.4,15.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Basalt Golem</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.6,16.6,42,9" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Velociraptor</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="33.3,25.6,42,4;34.9,23.9,42,4;28.5,25.6,42,4;27.5,23.2,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/MamoolJaSophist.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mamool Ja Breeder</p></div>
                            <div class="level">Lv 31-33</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.0,32.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Russet Yarzon</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="19.4,35.9,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Smoke Bomb</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="17.0,22.1,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DeathGaze.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Death Gaze</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.9,24.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ochu</p></div>
                            <div class="level">Lv 40</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="26.9,24.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Molted Ziz</p></div>
                            <div class="level">Lv 40</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.7,19.6,42,4;24.7,20.6,42,4;28.3,23.4,42,4;25.1,25.9,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Highland Goobbue</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="26.0,22.0,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Croc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Feral Croc</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="24.8,13.1,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ogre.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redhorn Ogre</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="23.6,18.2,42,4;24.2,16.1,42,4;22.5,17.1,42,4;20.3,16.0,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Snowstorm Goobbue</p></div>
                            <div class="level">Lv 39</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="15.4,35.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Skeleton.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dead Man's Moan</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                        <div class="huntlog-element" data="13.4,16.9,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/ShelfclawReaver.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Shelfclaw Reaver</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="28.2,25.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Quartz Doblyn</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="21.9,26.8,42,8;23.5,23.2,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Basilisk.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Basilisk</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.0,19.5,42,4;32.0,19.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Biast.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Zahar'ak Battle Drake</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="32.5,20.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>3rd Cohort Signifier</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="27.7,18.3,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dreamtoad</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.7,24.8,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wild Hog</p></div>
                                <div class="level">Lv 45-46</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.7,30.1,42,7;15.4,29.6,42,7;11.3,28.6,42,7;7.0,28.6,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Biast.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Biast</p></div>
                                <div class="level">Lv 45-47</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="11.8,30.6,42,5;13.2,31.3,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Snow Wolf</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="17.0,15.6,42,4;17.0,17.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Raging Harrier</p></div>
                                <div class="level">Lv 45</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="27.4,11.0,42,3;30.4,14.,42,3;31.8,12.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gigas.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Gigas Shramana</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="12.2,12.6,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>5th Cohort Hoplomachus</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                        <div class="huntlog-element" data="31.0,5.6,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ogre.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hapalit</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>