import { Component, OnInit } from '@angular/core';
import BadgerAccordion from 'badger-accordion';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hlrogue',
  templateUrl: './hlrogue.component.html',
  styleUrls: ['./hlrogue.component.less']
})
export class HlrogueComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Rogue Hunting Log Locations'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Hunting Log, ffxiv rogue hunting log'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Rogue Hunting Log - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit() {
    const accordionDomNode = document.querySelector('.js-badger-accordion-huntlog-rogue');
    const accordion = new BadgerAccordion(accordionDomNode);
  }

  ngAfterViewInit() {
    var accordionPanels = document.getElementsByClassName("badger-accordion__panel") as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < accordionPanels.length; i++) {
    }
  }

  funcClearButtons(): void {
    var huntElementButtons = document.getElementsByClassName("huntlog-element") as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < huntElementButtons.length; i++) {
      huntElementButtons[i].classList.remove("active");
    }
  }

  funcMapFadeIn(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.classList.add("fade");
  }

  funcMapFadeOut(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.classList.remove("fade");
  }

  funcClearMarker(): void {
    var mapViewer = document.getElementById("map-viewer");
    mapViewer.innerHTML = "";
  }

  btnChangeMap(event): void {
    this.funcClearButtons();
    var selectedMap = event.currentTarget.attributes.data.nodeValue;
    var mapViewer = document.getElementById("map-viewer");
    function funcChangeMap() {
      mapViewer.style.backgroundImage = 'url(assets/img/maps/' + selectedMap + '.webp';
    }
    if (mapViewer.style.backgroundImage.includes(selectedMap)) {
    //  this.funcMapFadeOut();
      setTimeout(this.funcClearMarker, 150);
    } else {
      this.funcMapFadeOut();
      setTimeout(funcChangeMap, 150);
      setTimeout(this.funcClearMarker, 150);
      setTimeout(this.funcMapFadeIn, 200);
    }
  }

  btnMoveMarker(event): void {
    this.funcClearButtons();
    this.funcClearMarker();
    event.currentTarget.classList.add("active");
    var mapViewer = document.getElementById("map-viewer");
    var coords = event.currentTarget.attributes.data.nodeValue
    for (var i = 0; i < coords.split(";").length; i++) {
      var coordsX = (((parseFloat(coords.split(";")[i].split(",")[0]) / (parseFloat(coords.split(";")[i].split(",")[2]) - 1)) * 100) - ((1 / parseFloat(coords.split(";")[i].split(",")[2])) * 100)).toFixed(3);
      var coordsY = (((parseFloat(coords.split(";")[i].split(",")[1]) / (parseFloat(coords.split(";")[i].split(",")[2]) - 1)) * 100) - ((1 / parseFloat(coords.split(";")[i].split(",")[2])) * 100)).toFixed(3);
      var areaSize = coords.split(";")[i].split(",")[3];
      mapViewer.innerHTML += '<div class="g-marker-area" style="margin:' + coordsY + '% 0% 0% ' + coordsX + '%;width:' + areaSize + '%;height:' + areaSize + '%;top:-' + (areaSize/2) + '%;left:-' + (areaSize/2) + '%"></div>';
    }
  }
}
