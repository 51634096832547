<div class="alib-content-wrapper">
  <div id="sightseeingfilter">
      <div class="button" id="sortbycompletebtn">HIDE COMPLETED</div>
      <div class="button-sq" id="sortbyidbtn">2.0</div>
      <div class="button-sq" id="sortbyidbtn">3.0</div>
      <div class="button-sq" id="sortbyidbtn">4.0</div>
      <div class="button-sq" id="sortbyidbtn">5.0</div>
      <div class="button-time" id="sortbytime">00:00</div>
  </div>
  <div id="sightseeinglogresults">
    <ul class="sightseeinglogs" *ngIf="(sightseeinglogs | filter: searchText).length > 0">
      <li *ngFor="let sightseeinglog of sightseeinglogs | sortBy: 'asc':'patch' | filter: searchText; let i = index" id="{{sightseeinglog.dbid}};{{sightseeinglog.patch}}" class="sslog-element" (click)="onSelect(sightseeinglog)">
        <span class="sstopinfo">
          <span class="ssname">#{{sightseeinglog.id}} {{sightseeinglog.name}}<br></span>
          <span class="sslocation">{{sightseeinglog.location}}</span>
        </span>
        <span class="ssbotinfo">
          <span class="ssleftinfo">
            <span class="ssweathericon" style="background-image:url('assets/img/weather-icons/{{sightseeinglog.weather1}}.webp')"></span><span class="ssweatherlabel">{{sightseeinglog.weather1}}</span>
            <span class="ssweathericon" style="background-image:url('assets/img/weather-icons/{{sightseeinglog.weather2}}.webp')"></span><span class="ssweatherlabel">{{sightseeinglog.weather2}}</span>
          </span>
          <span class="ssrightinfo">
            <span class="sstime">{{sightseeinglog.starttime}}-{{sightseeinglog.endtime}}</span>
            <span class="sstime">{{sightseeinglog.emote}}</span>
          </span>
        </span>
      </li>
    </ul>
  </div>
  <div id="alib-map-viewer" style="position:relative;width:85.5vh;height:85.5vh;margin-top:-3vh">
  </div>
</div>
