<h1 class="seoh1">Final Fantasy XIV - Kumbhiraskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
    <dl class="accordion js-badger-accordion-kumbhiraskin">
      <!--
      <dt>
        <button class="js-badger-accordion-header" data="Elpis" (click)="treasuremapChangeMap($event)">
          ELPIS
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
        </div>
      </dd>
    -->
      <dt>
        <button class="js-badger-accordion-header" data="Garlemald" (click)="treasuremapChangeMap($event)">
          GARLEMALD
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="16.2,17.6,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="29.2,35.2,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="25.5,12.4,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="28.1,26.4,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="11.8,25.7,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="34.4,17.3,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald06.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="16.6,11.9,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald07.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="34.2,10.5,42" src="assets/img/treasuremap-icons/kumbhiraskin/garlemald08.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="Labyrinthos" (click)="treasuremapChangeMap($event)">
          LABYRINTHOS
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="31.7,14.2,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="8.4,30.3,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="6.8,20.9,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="24.2,10.9,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="21.8,37.5,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="18.1,18.2,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos06.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="24.7,21.8,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos07.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="24.6,29.4,42" src="assets/img/treasuremap-icons/kumbhiraskin/labyrinthos08.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="MareLamentorum" (click)="treasuremapChangeMap($event)">
          MARE LAMENTORUM
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="22.8,36.2,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="18.8,25.7,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="17.1,19.3,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="12.1,26.7,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="15.9,34.4,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="30.5,24.9,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum06.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="25.4,33.2,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum07.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="34.9,29.9,42" src="assets/img/treasuremap-icons/kumbhiraskin/marelamentorum08.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="Thavnair" (click)="treasuremapChangeMap($event)">
          THAVNAIR
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="27.0,8.7,42" src="assets/img/treasuremap-icons/kumbhiraskin/thavnair01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="15.0,26.6,42" src="assets/img/treasuremap-icons/kumbhiraskin/thavnair02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="32.2,14.4,42" src="assets/img/treasuremap-icons/kumbhiraskin/thavnair03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="21.0,27.3,42" src="assets/img/treasuremap-icons/kumbhiraskin/thavnair04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="26.8,29.1,42" src="assets/img/treasuremap-icons/kumbhiraskin/thavnair05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="19.4,13.9,42" src="assets/img/treasuremap-icons/kumbhiraskin/thavnair06.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="14.7,7.6,42" src="assets/img/treasuremap-icons/kumbhiraskin/thavnair07.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
      <dt>
        <button class="js-badger-accordion-header" data="UltimaThule" (click)="treasuremapChangeMap($event)">
          ULTIMA THULE
        </button>
      </dt>
      <dd class="badger-accordion__panel js-badger-accordion-panel">
        <div class="js-badger-accordion-panel-inner">
          <img class="smallmapimg" data="6.2,18.9,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule01.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="12.7,13.2,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule02.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="21.5,36.3,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule03.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="25.7,17.2,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule04.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="29.6,9.4,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule05.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="26.9,35.6,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule06.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="8.2,30.8,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule07.webp" (click)="treasuremapMoveFlag($event)">
          <img class="smallmapimg" data="18.0,27.0,42" src="assets/img/treasuremap-icons/kumbhiraskin/ultimathule08.webp" (click)="treasuremapMoveFlag($event)">
        </div>
      </dd>
    </dl>
  </div>
  <div id="map-viewer">
  </div>
  