<h1 class="seoh1">Final Fantasy XIV - Rogue Hunting Log Locations</h1>
<div id="rogue-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-rogue">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MiddleLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="22.4,24.0,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wharf Rat</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="24,25.2,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lost Lamb</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="22.2,16.4,42,3;20.2,17.8,42,4;19.2,18.9,42,4;21.4,22.4,42,5;23.5,22.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Pugil.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Pugil</p></div>
                                <div class="level">Lv 4-6</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="23.9,21.5,42,2" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goblin.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Goblin Fisher</p></div>
                                <div class="level">Lv 5</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                        <div class="huntlog-element" data="21.7,17.9,42,8;19.2,15.4,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mandragora.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Tiny Mandragora</p></div>
                                <div class="level">Lv 5-7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.1,17.0,42,2" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Grounded Pirate</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                        <div class="huntlog-element" data="20.1,17.0,42,2" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Grounded Raider</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                        <div class="huntlog-element" data="15.7,13.9,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Crab.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Megalocrab</p></div>
                                <div class="level">Lv 10</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="LowerLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">LOWER LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="25.0,27.2,42,8;26.1,22.4,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Aurelia.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Aurelia</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="28.6,20.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dodo.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wild Dodo</p></div>
                                <div class="level">Lv 4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="26.5,16.3,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Cave Bat</p></div>
                                <div class="level">Lv 7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="30,14.4,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/OpoOpo.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Galago</p></div>
                                <div class="level">Lv 8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="LowerLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">LOWER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="20.5,31.3,42,4;20.5,33.3,42,6;22.9,34.5,42,4;22.8,35.9,42,3;22.3,38.3,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Wild Jackal</p></div>
                            <div class="level">Lv 10-14</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="20.8,34.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SnappingShrew.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Moraby Mole</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="18.3,34.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Eggdigger</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="36,29.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kedtrap.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Roseling</p></div>
                            <div class="level">Lv 10</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                    <div class="huntlog-element" data="33.8,28.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SnappingShrew.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sewer Mole</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="33.5,28.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dodo.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Fat Dodo</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="27.6,24.3,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Pteroc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Puk Hatchling</p></div>
                            <div class="level">Lv 15</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="24.3,22.9,42,5;23.5,24.3,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Rothlyt Pelican</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="21.5,23.0,42,5;22.8,20.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mantis.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Killer Mantis</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="26.4,23.4,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/TucoTuco.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Hedgemole</p></div>
                            <div class="level">Lv 15</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.3,25.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Wild Wolf</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="13.3,25.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Weevil.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bumble Beetle</p></div>
                            <div class="level">Lv 20-21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.9,9.4,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p style="font-size:0.7em;">Corpse Brigade Knuckledancer</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="23.9,9.4,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeFiredancer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Corpse Brigade Firedancer</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="23.7,11.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sandstone Golem</p></div>
                            <div class="level">Lv 29</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="18.4,24.8,42,5;16.4,22.8,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Black Bat</p></div>
                            <div class="level">Lv 20-23</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="22,30.4,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gnat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Gall Gnat</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="23.6,29.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Overgrown Ivy</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.8,22.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bark Eft</p></div>
                            <div class="level">Lv 23</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="23.4,18.3,42,4;24.5,18.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CoeurlclawCutter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redbelly Larcener</p></div>
                            <div class="level">Lv 27</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="23.4,18.3,42,4;24.5,18.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CoeurlclawCutter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redbelly Lookout</p></div>
                            <div class="level">Lv 27</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="26.8,18.2,42,5;26.8,22.1,42,3;21.9,19.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Antelope.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Antelope Stag</p></div>
                            <div class="level">Lv 25-28</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="23.2,22.5,42,2;24.1,22.1,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>River Yarzon</p></div>
                            <div class="level">Lv 25</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="30.1,20.8,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CoeurlclawCutter.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurlclaw Cutter</p></div>
                            <div class="level">Lv 29</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="30.1,20.8,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Poacher.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurlclaw Hunter</p></div>
                            <div class="level">Lv 29</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="29.8,32.1,42,3;27.7,29.6,42,7;29.4,25.8,42,2;26.8,33.5,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Buffalo.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Large Buffalo</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="24.1,21.1,42,6;19.0,35.0,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Grass Raptor</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="26.3,32.4,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Gullroaster</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="30.7,24.4,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Colibri.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Colibri</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.5,14.8,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurl</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                    <div class="huntlog-element" data="15.2,18.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Highland Condor</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="14.4,15.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Basalt Golem</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="19.6,16.6,42,9" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Velociraptor</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.7,19.6,42,4;24.7,20.6,42,4;28.3,23.4,42,4;25.1,25.9,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Highland Goobbue</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="26.0,22.0,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Croc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Feral Croc</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="24.8,13.1,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ogre.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Redhorn Ogre</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="34.3,13.4,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Taurus.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Taurus</p></div>
                            <div class="level">Lv 39</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="13.6,19.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Chinchilla</p></div>
                            <div class="level">Lv 39</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="17.8,18.3,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bateleur</p></div>
                            <div class="level">Lv 39</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="29.8,20.3,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>2nd Cohort Eques</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="29.8,20.3,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>2nd Cohort Signifier</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="29.8,20.3,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>2nd Cohort Secutor</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="29.8,20.3,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/5thCohortVanguard.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>2nd Cohort Vanguard</p></div>
                                <div class="level">Lv 50</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="27.7,24.1,42,8;25.6,26.9,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Fleece.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Golden Fleece</p></div>
                                <div class="level">Lv 40-42</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="28.2,25.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Quartz Doblyn</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.4,8.8,42,8;15.6,10.7,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Nix</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="13.5,10.8,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Mudpuppy</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="17.0,15.6,42,4;17.0,17.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Daring Harrier</p></div>
                                <div class="level">Lv 45</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="17.0,15.6,42,4;17.0,17.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Raging Harrier</p></div>
                                <div class="level">Lv 45</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="27.4,11.0,42,3;30.4,14.,42,3;31.8,12.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gigas.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Gigas Shramana</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="27.4,11.0,42,3;30.4,14.,42,3;31.8,12.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gigas.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Gigas Sozu</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="28.8,6.8,42,5;33.1,11.3,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hippocerf.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hippogryph</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="31.0,5.6,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ogre.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Hapalit</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>