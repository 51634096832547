import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Knowledgebase. Guides and tools for Final Fantasy XIV. Treasure map locations, character voices, aether currents, etc.'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, ffxiv tools, ffxiv treasure maps, ffxiv character voices'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('The Studium | FFXIV Knowledgebase');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    var logo1 = document.getElementById("alib-rdck-logo").style.backgroundImage;
    var logo2 = document.getElementById("alib-home-logo");
    logo2.style.backgroundImage = logo1;
  }
}
