<h1 class="seoh1">Final Fantasy XIV - Stormblood Aether Current Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-acshb">
    <dt>
      <button class="js-badger-accordion-header" data="TheAzimSteppe;42;27.1,12.2;16.1,14.2;36.3,17.5;23.5,20.4;13.2,20.9;5.9,28.4;23.6,29.2;33.9,30.6;15.3,34.4;8.6,35.5" (click)="acChangeMap($event)">
        THE AZIM STEPPE
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;31.2,12.1;28.2,14.8;23.0,23.3;32.9,28.3;12.7,34.4" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheFringes;42;19.3,4.5;25.0,11.1;17.4,15.1;11.7,16.4;35.7,16.7;27.9,21.6;25.0,26.2;9.6,27.8;29.6,28.8;34.1,30.0" (click)="acChangeMap($event)">
        THE FRINGES
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;9.2,10.9;8.4,11.2;31.6,16.6;27.0,21.0;30.1,26.1" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheLochs;42;34.9,7.2;15.0,12.3;8.6,15.6;22.5,16.3;14.3,21.7;29.5,22.8;18.0,30.9;34.9,31.6;13.3,36.2;23.6,37.2" (click)="acChangeMap($event)">
        THE LOCHS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;8.2,20.5;11.3,20.6;10.9,21.0;10.6,22.5;32.6,22.9" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="ThePeaks;42;11.8,5.8;26.7,6.9;35.7,11.6;15.8,16.9;27.5,20.8;21.2,22.0;10.8,26.4;16.7,31.9;23.8,31.8;8.2,32.5" (click)="acChangeMap($event)">
        THE PEAKS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;24.3,6.8;27.7,28.7;26.7,36.2;27.1,36.5;14.9,36.6" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheRubySea;42;22.7,3.9;31.5,6.4;17.7,7.5;21.9,9.0;8.4,9.2;35.5,20.5;31.4,25.0;7.9,28.9;15.2,38.3;29.9,38.8" (click)="acChangeMap($event)">
        THE RUBY SEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;23.4,9.1;6.2,12.3;6.2,12.3;20.2,20.1;31.4,37.1" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Yanxia;42;29.1,16.8;39.3,20.7;24.7,21.2;29.8,23.4;11.6,27.7;16.1,29.4;31.4,29.5;23.6,30.9;19.7,32.7;30.9,37.9" (click)="acChangeMap($event)">
        YANXIA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
      <div class="js-badger-accordion-panel-inner">
        <button class="js-badger-accordion-header-inner" data="42;29.9,15.6;30.5,17.7;30.7,18.0;30.1,18.9;31.2,28.9" (click)="acToggleQuestMarkers($event)">
          TOGGLE QUESTS
        </button>
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>
