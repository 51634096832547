<h1 class="seoh1">Final Fantasy XIV - Gaganaskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-gaganaskin">
    <dt>
      <button class="js-badger-accordion-header" data="TheAzimSteppe" (click)="treasuremapChangeMap($event)">
        THE AZIM STEPPE
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="16.8,9.8,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.6,19.8,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.2,19.0,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="9.6,27.6,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="34.5,28.6,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.3,32.6,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.1,35.7,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="26.0,35.8,42" src="assets/img/treasuremap-icons/gaganaskin/azimsteppe08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheFringes" (click)="treasuremapChangeMap($event)">
        THE FRINGES
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="16.5,9.5,42" src="assets/img/treasuremap-icons/gaganaskin/fringes01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.5,10.1,42" src="assets/img/treasuremap-icons/gaganaskin/fringes02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="16.2,17.0,42" src="assets/img/treasuremap-icons/gaganaskin/fringes03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.9,16.4,42" src="assets/img/treasuremap-icons/gaganaskin/fringes04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="14.8,21.4,42" src="assets/img/treasuremap-icons/gaganaskin/fringes05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.3,26.9,42" src="assets/img/treasuremap-icons/gaganaskin/fringes06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="16.7,28.5,42" src="assets/img/treasuremap-icons/gaganaskin/fringes07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.8,33.4,42" src="assets/img/treasuremap-icons/gaganaskin/fringes08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheLochs" (click)="treasuremapChangeMap($event)">
        THE LOCHS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="6.0,10.0,42" src="assets/img/treasuremap-icons/gaganaskin/lochs01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="4.3,16.2,42" src="assets/img/treasuremap-icons/gaganaskin/lochs02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.3,14.3,42" src="assets/img/treasuremap-icons/gaganaskin/lochs03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.0,12.2,42" src="assets/img/treasuremap-icons/gaganaskin/lochs04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="14.6,20.0,42" src="assets/img/treasuremap-icons/gaganaskin/lochs05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="10.1,23.8,42" src="assets/img/treasuremap-icons/gaganaskin/lochs06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.1,26.6,42" src="assets/img/treasuremap-icons/gaganaskin/lochs07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.0,33.3,42" src="assets/img/treasuremap-icons/gaganaskin/lochs08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="ThePeaks" (click)="treasuremapChangeMap($event)">
        THE PEAKS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="8.2,11.8,42" src="assets/img/treasuremap-icons/gaganaskin/peaks01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="11.9,9.9,42" src="assets/img/treasuremap-icons/gaganaskin/peaks02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.2,13.0,42" src="assets/img/treasuremap-icons/gaganaskin/peaks03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.0,15.3,42" src="assets/img/treasuremap-icons/gaganaskin/peaks04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.6,26.1,42" src="assets/img/treasuremap-icons/gaganaskin/peaks05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.9,28.1,42" src="assets/img/treasuremap-icons/gaganaskin/peaks06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="27.1,32.4,42" src="assets/img/treasuremap-icons/gaganaskin/peaks07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="13.0,34.0,42" src="assets/img/treasuremap-icons/gaganaskin/peaks08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheRubySea" (click)="treasuremapChangeMap($event)">
        THE RUBY SEA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="11.1,6.9,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.9,6.3,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="38.4,18.8,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="31.6,22.6,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="9.2,25.2,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.0,33.0,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="36.3,31.1,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.1,38.3,42" src="assets/img/treasuremap-icons/gaganaskin/rubysea08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="Yanxia" (click)="treasuremapChangeMap($event)">
        YANXIA
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="21.4,8.7,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="25.2,9.4,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="32.8,7.9,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.2,15.2,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.0,21.7,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.4,23.2,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="15.9,27.4,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.2,35.4,42" src="assets/img/treasuremap-icons/gaganaskin/yanxia08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>
