<h1 class="seoh1">Final Fantasy XIV - Arcanist Hunting Log Locations</h1>
<div id="arcanist-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-arcanist">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MiddleLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MIDDLE LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="20.9,23.6,42,5;22.2,24.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/LittleLadybug.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Little Ladybug</p></div>
                                <div class="level">Lv 1-2</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="22.4,24.0,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/StarMarmot.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wharf Rat</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="24,25.2,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lost Lamb</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="21.5,20.2,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Pteroc.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Puk Hatchling</p></div>
                                <div class="level">Lv 4-6</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.3,18.7,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bogy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Bogy</p></div>
                                <div class="level">Lv 7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.1,17.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeKnuckledancer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Grounded Pirate</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="LowerLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">LOWER LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="29.3,13.8,42,4;32.6,13.7,42,6;34.6,15,42,6;34.9,17.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/WindSprite.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wind Sprite</p></div>
                                <div class="level">Lv 7-9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="32.3,16.3,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Nesting Buzzard</p></div>
                                <div class="level">Lv 6</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="26.5,16.3,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Cave Bat</p></div>
                                <div class="level">Lv 7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="30,14.4,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/OpoOpo.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Galago</p></div>
                                <div class="level">Lv 8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20.5,32.2,42,6;18.8,34.4,42,3;22.9,35.6,42,4;22.7,37,42,2;24.1,38.5,42,5;25.8,37.2,42,2" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/LightningSprite.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lightning Sprite</p></div>
                                <div class="level">Lv 10-13</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="33.8,28.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SnappingShrew.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sewer Mole</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="28.1,29.7,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Arbor Buzzard</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="26.6,24.7,42,4;24.4,23.5,42,4;25.8,23.4,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Dusk Bat</p></div>
                            <div class="level">Lv 15-16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="27.6,24.3,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Pteroc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Puk Hatchling</p></div>
                            <div class="level">Lv 15</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="26.4,23.4,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/TucoTuco.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Hedgemole</p></div>
                            <div class="level">Lv 15</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="24.3,22.9,42,5;23.5,24.3,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Rothlyt Pelican</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="21.5,23.0,42,5;22.8,20.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mantis.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Killer Mantis</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="LowerLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">LOWER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="28.9,19.3,42,3;25.6,22.3,42,3;22.8,24.6,42,3;23.3,23.1,42,3;25.4,26.3,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mossless Goobbue</p></div>
                            <div class="level">Lv 12-17</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="18.3,34.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Eggdigger</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="33.5,28.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dodo.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Fat Dodo</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.3,25.7,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Weevil.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bumble Beetle</p></div>
                            <div class="level">Lv 20-21</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="9.2,21.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurl Pup</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.8,10.0,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lead Coblyn</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="22.4,19.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Chasm Buzzard</p></div>
                            <div class="level">Lv 25</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="24.1,18.5,42,5;26.7,17.7,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Axe Beak</p></div>
                            <div class="level">Lv 25-26</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.7,11.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sandstone Golem</p></div>
                            <div class="level">Lv 29</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.6,29.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Overgrown Ivy</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="21.7,21.7,42,4;19.8,20.3,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kedtrap.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Kedtrap</p></div>
                            <div class="level">Lv 21-22</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="26.8,18.2,42,5;26.8,22.1,42,3;21.9,19.8,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Antelope.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Antelope Stag</p></div>
                            <div class="level">Lv 25-28</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="21.3,30.4,42,7;19.2,28.7,42,4;17.6,28.6,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Balloon</p></div>
                            <div class="level">Lv 25-28</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="16.5,29.4,42,3;17.9,28.4,42,3;19.7,28.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Clay Golem</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="16.7,20.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/HammerBeak.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Brood Ziz</p></div>
                            <div class="level">Lv 30</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="14.3,18.6,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Lindwurm</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.3,32.4,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Qiqirn Gullroaster</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="24.1,21.1,42,6;19.0,35.0,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Velociraptor.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Grass Raptor</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="19.5,26,42,5;17.2,27.1,42,4;14.9,28.8,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Gigantoad</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="30.7,24.4,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Colibri.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Colibri</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="16.8,32,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Goobbue.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mildewed Goobbue</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">1</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="15.5,14.8,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coeurl.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Coeurl</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="24.7,38.7,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Biast.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Sundrake</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">NORTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.1,25.1,42,7;22.4,23.2,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Dryad</p></div>
                            <div class="level">Lv 37</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="26.9,24.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Molted Ziz</p></div>
                            <div class="level">Lv 40</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.1,27.4,42,6;21.4,29.0,42,5;29.9,14.6,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Snow Wolf Pup</p></div>
                            <div class="level">Lv 35</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="26.0,22.0,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Croc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Feral Croc</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="34.3,13.4,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Taurus.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Taurus</p></div>
                            <div class="level">Lv 39</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="14,15.6,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Axolotl</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="28.2,25.6,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Quartz Doblyn</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="12.1,36.0,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Lammergeyer.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lammergeyer</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="11.1,7.2,42,3;12.0,6.1,42,3;9.8,5.6,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/.webp5thCohortVanguard')"></div>
                            <div class="wrap">
                                <div class="name"><p>4th Cohort Vanguard</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.0,19.5,42,4;32.0,19.0,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Biast.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Zahar'ak Battle Drake</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="29.1,20.7,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>3rd Cohort Laquearius</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="30.7,24.8,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Wild Hog</p></div>
                                <div class="level">Lv 45-46</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="NorthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">NORTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="19.7,19.5,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Watchwolf</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="11.8,30.6,42,5;13.2,31.3,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Snow Wolf</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="34,22.4,42,13" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/DustyMongrel.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Natalan Watchwolf</p></div>
                                <div class="level">Lv 28</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.4,8.8,42,8;15.6,10.7,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Nix</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">4</div>
                        </div>
                        <div class="huntlog-element" data="13.5,10.8,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Mudpuppy</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="12.2,12.5,42,3;9.3,14.1,42,3;10.8,15.0,42,3;11.7,16.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>5th Cohort Laquearius</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>