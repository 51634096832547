<h1 class="seoh1">Final Fantasy XIV - Thaumaturge Hunting Log Locations</h1>
<div id="thaumaturge-container" class="huntlogclasscontainer">
    <dl class="accordion js-badger-accordion-huntlog-thaumaturge">
        <dt>
          <button class="js-badger-accordion-header">
            RANK 1
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="28.4,24.6,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/LittleLadybug.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Little Ladybug</p></div>
                                <div class="level">Lv 1-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="27.4,24.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Cactuar.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Cactuar</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="23.8,27,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Yarzon Feeder</p></div>
                                <div class="level">Lv 5</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20,27.5,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Rusty Coblyn</p></div>
                                <div class="level">Lv 6-8</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CentralThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">CENTRAL THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="19.6,26.6,42,10;21.8,28.5,42,7;22.6,31.4,42,8;23.0,34.3,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Hornet.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Huge Hornet</p></div>
                                <div class="level">Lv 1-3</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="23.6,26.6,42,10;25.8,31.7,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SnappingShrew.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Snapping Shrew</p></div>
                                <div class="level">Lv 3-4</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="20,23.6,42,3;20.4,21.9,42,3;18.8,20.1,42,3;17.5,21.9,42,4;16.6,21,42,2;14.7,21.8,42,4;16.9,19.2,42,3;18.7,18.5,42,5;20,17,42,3;21.5,15.3,42,3;22.5,14.6,42,4;23.4,17.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Syrphid Cloud</p></div>
                                <div class="level">Lv 5-9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="16.9,24.0,42,7" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SprigganGraverobber.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Spriggan Graverobber</p></div>
                                <div class="level">Lv 7</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="16.8,19.3,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QiqirnShellsweeper.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Qiqirn Shellsweeper</p></div>
                                <div class="level">Lv 9</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                        <div class="huntlog-element" data="27.2,19.6,42,12;25,15.5,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Sun Bat</p></div>
                                <div class="level">Lv 10-13</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 2
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="27.4,17.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bomb</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="27.4,17.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Copper Coblyn</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="26.7,23.9,42,2;28.6,25.2,42,2;24.0,26.3,42,2;20.1,28.4,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Tortoise.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Giant Tortoise</p></div>
                            <div class="level">Lv 12-17</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="16.3,15.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Thickshell.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Thickshell</p></div>
                            <div class="level">Lv 13</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="13.5,11.7,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bogy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bloated Bogy</p></div>
                            <div class="level">Lv 20</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="24.4,20.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Cactuar.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Cochineal Cactuar</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="23.9,21.0,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/QuiveronGuard.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Quiveron Attendant</p></div>
                            <div class="level">Lv 11</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="17.0,14.2,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Antling.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Antling Sentry</p></div>
                            <div class="level">Lv 12</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="27.8,19.2,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Toxic Toad</p></div>
                            <div class="level">Lv 14</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="11.7,27.2,42,5;14.0,26.2,42,6;17.5,30.5,42,5;16.2,24.2,42,3;14.7,20.5,42,5;11.9,19.1,42,7;12.5,16.0,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/TucoTuco.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Tuco-tuco</p></div>
                            <div class="level">Lv 15-19</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="17.5,23.4,42,10;16.5,27.0,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/MyotragusBilly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Myotragus Nanny</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="11.8,22.5,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Blowfly Swarm</p></div>
                            <div class="level">Lv 16</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="14.7,16.6,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Noble.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Rotting Corpse</p></div>
                            <div class="level">Lv 17</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 3
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent badger-accordion-scroll">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.1,21.9,42,5;11.1,21.3,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Forest Yarzon</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">WESTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="14.4,7.6,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Yarzon Scavenger</p></div>
                            <div class="level">Lv 22</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="14.8,6.9,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Laughing Toad</p></div>
                            <div class="level">Lv 23</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.8,13.8,42,2;15.9,13.2,42,2;18.4,10.8,42,2;20.4,9.4,42,4;23.1,8,42,3;24.8,11.1,42,2;23.4,12.3,42,3;22.2,14.3,42,4;19.5,16,42,5;16.7,16.4,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Potter Wasp Swarm</p></div>
                            <div class="level">Lv 25-29</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="23.9,9.4,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/CorpseBrigadeFiredancer.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p style="font-size:0.7em;">Corpse Brigade Knuckledancer</p></div>
                            <div class="level">Lv 28</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="15,20.1,42,9" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/FireSprite.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Fire Sprite</p></div>
                            <div class="level">Lv 30</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="24.5,22.2,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Fleece.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Phurble</p></div>
                            <div class="level">Lv 25-29</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EAST SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.6,29.9,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Ochu.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Overgrown Ivy</p></div>
                            <div class="level">Lv 21</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="15.7,20.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Chigoe.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Jumping Djigga</p></div>
                            <div class="level">Lv 24</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                    <div class="huntlog-element" data="15.7,20.8,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Firefly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Glowfly</p></div>
                            <div class="level">Lv 24</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="21.7,21.7,42,4;19.8,20.3,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Kedtrap.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Kedtrap</p></div>
                            <div class="level">Lv 21-22</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="19.8,22.7,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Bark Eft</p></div>
                            <div class="level">Lv 23</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="23.2,22.5,42,2;24.1,22.1,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>River Yarzon</p></div>
                            <div class="level">Lv 25</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.4,22.3,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Morbol.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Stroper</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">4</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </dd>
        <dt>
          <button class="js-badger-accordion-header">
            RANK 4
          </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
          <div class="js-badger-accordion-panel-inner">
            <mat-accordion>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="UpperLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">UPPER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="33.3,25.6,42,4;34.9,23.9,42,4;28.5,25.6,42,4;27.5,23.2,42,4" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/MamoolJaSophist.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Mamool Ja Executioner</p></div>
                            <div class="level">Lv 31-33</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="28.2,23.5,42,8" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Mudpuppy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Salamander</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="23.3,20.6,42,4;16.3,25.9,42,5;16,28.1,42,2;15.2,30,41,2;16.7,32.1,42,4;18.9,34.6,42,6;20.2,32.5,42,6;19.2,27.5,42,6;21.3,28,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Midge.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Dung Midge Swarm</p></div>
                            <div class="level">Lv 32-33</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="19.5,26,42,5;17.2,27.1,42,4;14.9,28.8,42,2" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Gigantoad</p></div>
                            <div class="level">Lv 33</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">OUTER LA NOSCEA</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="25.1,18.3,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Firefly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Plasmoid</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTHERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="13.0,32.1,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Yarzon.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Russet Yarzon</p></div>
                            <div class="level">Lv 31</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="19.4,35.9,42,7" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bomb.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Smoke Bomb</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="EasternThanalan" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">EASTERN THANALAN</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="27.7,24.1,42,8;25.6,26.9,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Fleece.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Golden Fleece</p></div>
                            <div class="level">Lv 40-42</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">SOUTH SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="19.3,29.4,42,8;15.7,30.4,42,6" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Tortoise.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Adamantoise</p></div>
                            <div class="level">Lv 31-33</div>
                        </div>
                        <div class="amount">3</div>
                    </div>
                    <div class="huntlog-element" data="23.4,25.1,42,5" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Firefly.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Will-o'-the-wisp</p></div>
                            <div class="level">Lv 38</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">CENTRAL SHROUD</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="11.4,20.3,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bogy.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Revenant</p></div>
                            <div class="level">Lv 32</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                    <div class="huntlog-element" data="11.2,16.8,42,9" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/SprigganGraverobber.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Spriggan</p></div>
                            <div class="level">Lv 34</div>
                        </div>
                        <div class="amount">2</div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="subaccordion">
                    <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                        <div class="alib-acc-header">COERTHAS CENTRAL</div>
                    </mat-expansion-panel-header>
                    <div class="huntlog-element" data="25.3,19.7,42,3;26.1,20.6,42,3;25.1,22.1,42,3;26.5,22.9,42,3;25.2,23.5,42,3;27.1,25.4,42,3" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/IceSprite.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Ice Sprite</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                    <div class="huntlog-element" data="26.0,22.0,42,10" (click)="btnMoveMarker($event)">
                        <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Croc.webp')"></div>
                        <div class="wrap">
                            <div class="name"><p>Feral Croc</p></div>
                            <div class="level">Lv 36</div>
                        </div>
                        <div class="amount">5</div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </dd>
    <dt>
        <button class="js-badger-accordion-header">
            RANK 5
        </button>
        </dt>
        <dd class="badger-accordion__panel js-badger-accordion-panel bg-transparent">
            <div class="js-badger-accordion-panel-inner">
                <mat-accordion>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="15.4,35.5,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Skeleton.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dead Man's Moan</p></div>
                                <div class="level">Lv 42</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="OuterLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">OUTER LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.9,7.1,42,12" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Coblyn.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Synthetic Doblyn</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EasternLaNoscea" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EASTERN LA NOSCEA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="29.8,20.3,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>2nd Cohort Signifier</p></div>
                                <div class="level">Lv 49</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthernThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTHERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.7,23.1,42,3;20.5,23.8,42,3;16.2,25.1,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Tortoise.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Iron Tortoise</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="WesternThanalan" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">WESTERN THANALAN</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="11.1,7.2,42,3;12.0,6.1,42,3;9.8,5.6,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>4th Cohort Hoplomachus</p></div>
                                <div class="level">Lv 48</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="EastShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">EAST SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="27.1,22.1,42,4;28.6,20.8,42,3;25.9,20.8,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Treant.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Old-growth Treant</p></div>
                                <div class="level">Lv 41</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="23.3,21.0,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Morbol.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Morbol</p></div>
                                <div class="level">Lv 43</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                        <div class="huntlog-element" data="32.5,20.6,42,5" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/3rdCohortEques.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>3rd Cohort Secutor</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CentralShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">CENTRAL SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="10.0,17.8,42,6" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Golem.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Crater Golem</p></div>
                                <div class="level">Lv 43</div>
                            </div>
                            <div class="amount">1</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="SouthShroud" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">SOUTH SHROUD</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="23.1,24.9,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Bat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Lesser Kalong</p></div>
                                <div class="level">Lv 36-37</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                        <div class="huntlog-element" data="32.1,23.9,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gnat.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Ked</p></div>
                                <div class="level">Lv 47</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="CoerthasCentralHighlands" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">COERTHAS CENTRAL</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="9.7,13.6,42,8" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Dragonfly.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Dragonfly</p></div>
                                <div class="level">Lv 40</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                        <div class="huntlog-element" data="14.0,26.7,42,10" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Giant.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Giant Logger</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">3</div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="subaccordion">
                        <mat-expansion-panel-header data="MorDhona" (click)="btnChangeMap($event)">
                            <div class="alib-acc-header">MOR DHONA</div>
                        </mat-expansion-panel-header>
                        <div class="huntlog-element" data="18.4,8.8,42,8;15.6,10.7,42,4" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Frog.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Nix</p></div>
                                <div class="level">Lv 44</div>
                            </div>
                            <div class="amount">6</div>
                        </div>
                        <div class="huntlog-element" data="27.4,11.0,42,3;30.4,14.,42,3;31.8,12.4,42,3" (click)="btnMoveMarker($event)">
                            <div class="icon" style="background-image:url('assets/img/huntinglog-icons/Gigas.webp')"></div>
                            <div class="wrap">
                                <div class="name"><p>Gigas Sozu</p></div>
                                <div class="level">Lv 46</div>
                            </div>
                            <div class="amount">2</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </dd>
    </dl>
</div>