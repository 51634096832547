import { Component, OnInit } from '@angular/core';
import { EliteHunts } from '../elitehunt';
import { ELITEHUNTS } from '../list-elitehunts';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-elitehunts',
  templateUrl: './elitehunts.component.html',
  styleUrls: ['./elitehunts.component.less']
})
export class ElitehuntsComponent implements OnInit {

  elitehunts = ELITEHUNTS;
  selectedEliteHunt: EliteHunts;

  searchText: string;

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'FFXIV Elite Hunt Locations'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Elite Hunts, ffxiv elite hunt locations'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Hunt Locations - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  onSelect (elitehunt: EliteHunts): void {
    if (document.getElementsByClassName("active").length !== 0) {
      var prevSelectedHuntElement = document.getElementsByClassName("active") as HTMLCollectionOf<HTMLElement>;
      prevSelectedHuntElement[0].classList.remove("active");
    }
    this.selectedEliteHunt = elitehunt;
    var prevSelectedHuntElement = document.getElementsByClassName("active") as HTMLCollectionOf<HTMLElement>;
    var selectedHuntElement = document.getElementById(elitehunt.name);
    var mapviewer = document.getElementById("map-viewer") as HTMLElement;
    var selectedMap = elitehunt.location.replace(/\s/g,"").replace("'", "");
    var coordinates = elitehunt.coords.split(";");
    var maxCoordinates = elitehunt.maxcoords - 1;

    function funcChangeMap() {
      mapviewer.style.backgroundImage = "url('assets/img/maps/" + selectedMap + ".webp')";
    }

    function funcAddMarkers() {
      for (var i = 0; i < coordinates.length; i++) {
        var coordsX = (((parseFloat(coordinates[i].split(",")[0]) / maxCoordinates) * 100) - ((1 / maxCoordinates) * 100)).toFixed(3);
        var coordsY = (((parseFloat(coordinates[i].split(",")[1]) / maxCoordinates) * 100) - ((1 / maxCoordinates) * 100)).toFixed(3);
        mapviewer.innerHTML += '<div class="g-marker-area" style="margin-left:' + coordsX + '%;margin-top:' + coordsY + '%"></div>';
      }
    }
    selectedHuntElement.classList.add("active");
    this.funcMapFadeOut();
    setTimeout(this.funcClearMap, 150);
    setTimeout(funcChangeMap, 150);
    setTimeout(funcAddMarkers, 150);
    setTimeout(this.funcMapFadeIn, 200);
  }

  funcMapFadeIn() {
    var mapviewer = document.getElementById("map-viewer") as HTMLElement;
    mapviewer.classList.add("fade");
  }

  funcMapFadeOut() {
    var mapviewer = document.getElementById("map-viewer") as HTMLElement;
    mapviewer.classList.remove("fade");
  }

  funcClearMap() {
    var mapviewer = document.getElementById("map-viewer") as HTMLElement;
    mapviewer.innerHTML = "";
  }
}
