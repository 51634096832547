import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-treasuremaps',
  templateUrl: './treasuremaps.component.html',
  styleUrls: ['./treasuremaps.component.less'],
})
export class TreasuremapsComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Final Fantasy XIV Knowledgebase - Treasure Map Locations'},
      {name: 'keywords', content: 'Final Fantasy XIV, FFXIV, sharlayan.org, Treasure Maps, ffxiv treasure maps'},
      {name: 'robots', content: 'index, follow'}
    ]);
    this.setTitle('Treasure Map Locations - sharlayan.org');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle)
  }

  ngOnInit() { }
}
