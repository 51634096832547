<h1 class="seoh1">Final Fantasy XIV - Archaeoskin Treasure Map Locations</h1>
<div class="g-left-sidebar">
  <dl class="accordion js-badger-accordion-archaeoskin">
    <dt>
      <button class="js-badger-accordion-header" data="CoerthasWesternHighlands" (click)="treasuremapChangeMap($event)">
        COERTHAS WESTERN HIGHLANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="11.0,10.6,44" src="assets/img/treasuremap-icons/archaeoskin/coerthaswestern01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.1,10.9,44" src="assets/img/treasuremap-icons/archaeoskin/coerthaswestern02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="20.5,17.8,44" src="assets/img/treasuremap-icons/archaeoskin/coerthaswestern03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.5,23.7,44" src="assets/img/treasuremap-icons/archaeoskin/coerthaswestern04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="22.8,24.8,44" src="assets/img/treasuremap-icons/archaeoskin/coerthaswestern05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="24.9,33.0,44" src="assets/img/treasuremap-icons/archaeoskin/coerthaswestern06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="29.7,32.9,44" src="assets/img/treasuremap-icons/archaeoskin/coerthaswestern07.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheChurningMists" (click)="treasuremapChangeMap($event)">
        THE CHURNING MISTS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="27.6,10.4,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="28.3,17.8,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.0,21.1,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.3,21.7,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="34.3,22.4,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.3,25.5,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="18.3,27.3,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.6,27.1,44" src="assets/img/treasuremap-icons/archaeoskin/churningmists08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
    <dt>
      <button class="js-badger-accordion-header" data="TheDravanianForelands" (click)="treasuremapChangeMap($event)">
        THE DRAVANIAN FORELANDS
      </button>
    </dt>
    <dd class="badger-accordion__panel js-badger-accordion-panel">
      <div class="js-badger-accordion-panel-inner">
        <img class="smallmapimg" data="35.4,9.7,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands01.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="38.1,9.7,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands02.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="21.2,19.2,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands03.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="36.0,19.6,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands04.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="12.1,27.5,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands05.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="30.3,28.2,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands06.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="23.8,34.2,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands07.webp" (click)="treasuremapMoveFlag($event)">
        <img class="smallmapimg" data="17.0,36.6,44" src="assets/img/treasuremap-icons/archaeoskin/dravanianforelands08.webp" (click)="treasuremapMoveFlag($event)">
      </div>
    </dd>
  </dl>
</div>
<div id="map-viewer">
</div>