<div class="alib-left-sidebar">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="alib-acc-header">THANALAN</div>
      </mat-expansion-panel-header>
      <mat-accordion>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="CentralThanalan">CENTRAL THANALAN</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/centralthanalan001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/centralthanalan002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/centralthanalan003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="EasternThanalan">EASTERN THANALAN</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/easternthanalan001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/easternthanalan002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/easternthanalan003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="NorthernThanalan">NORTHERN THANALAN</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/northernthanalan001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/northernthanalan002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/northernthanalan003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="SouthernThanalan">SOUTHERN THANALAN</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/southernthanalan001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/southernthanalan002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/southernthanalan003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="WesternThanalan">WESTERN THANALAN</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/westernthanalan001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/westernthanalan002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/westernthanalan003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="alib-acc-header">LA NOSCEA</div>
      </mat-expansion-panel-header>
      <mat-accordion>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="EasternLaNoscea">EASTERN LA NOSCEA</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/easternlanoscea001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/easternlanoscea002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/easternlanoscea003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="LowerLaNoscea">LOWER LA NOSCEA</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/lowerlanoscea001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/lowerlanoscea002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/lowerlanoscea003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="MiddleLaNoscea">MIDDLE LA NOSCEA</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/middlelanoscea001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/middlelanoscea002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/middlelanoscea003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="OuterLaNoscea">OUTER LA NOSCEA</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/outerlanoscea001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/outerlanoscea002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/outerlanoscea003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="UpperLaNoscea">UPPER LA NOSCEA</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/upperlanoscea001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/upperlanoscea002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/upperlanoscea003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="WesternLaNoscea">WESTERN LA NOSCEA</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/westernlanoscea001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/westernlanoscea002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/westernlanoscea003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="alib-acc-header">THE BLACK SHROUD</div>
      </mat-expansion-panel-header>
      <mat-accordion>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="CentralShroud">CENTRAL SHROUD</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/centralshroud001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/centralshroud002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/centralshroud003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="EastShroud">EAST SHROUD</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/eastshroud001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/eastshroud002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/eastshroud003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="NorthShroud">NORTH SHROUD</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/northshroud001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/northshroud002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/northshroud003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
        <mat-expansion-panel class="subaccordion">
          <mat-expansion-panel-header onclick="treasuremapload(this.id)">
            <div class="alib-acc-header" id="SouthShroud">SOUTH SHROUD</div>
          </mat-expansion-panel-header>
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/southshroud001.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/southshroud002.webp" onclick="treasuremapmoveflag(this.id)">
          <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/southshroud003.webp" onclick="treasuremapmoveflag(this.id)">
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header onclick="treasuremapload(this.id)">
        <div class="alib-acc-header" id="CoerthasCentralHighlands">COERTHAS CENTRAL HIGHLANDS</div>
      </mat-expansion-panel-header>
      <mat-accordion>
        <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/coerthascentral001.webp" onclick="treasuremapmoveflag(this.id)">
        <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/coerthascentral002.webp" onclick="treasuremapmoveflag(this.id)">
        <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/coerthascentral003.webp" onclick="treasuremapmoveflag(this.id)">
      </mat-accordion>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header onclick="treasuremapload(this.id)">
        <div class="alib-acc-header" id="MorDhona">MOR DHONA</div>
      </mat-expansion-panel-header>
      <mat-accordion>
        <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/mordhona001.webp" onclick="treasuremapmoveflag(this.id)">
        <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/mordhona002.webp" onclick="treasuremapmoveflag(this.id)">
        <img class="smallmapimg" id="0% 0% 0% 0%" src="assets/img/treasuremap-icons/unhiddenleather/mordhona003.webp" onclick="treasuremapmoveflag(this.id)">
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div id="alib-map-viewer">
  <div id="alib-map-flag-wrapper">
    <div id="alib-map-flag">
      <img class="marker" src="assets/img/icons/marker.webp">
    </div>
  </div>
</div>
